const moment = require("moment-timezone");

const dayNames = ["Sun", "Mon", "Tues", "Wed", "Thur", "Fri", "Sat"];

export default class DateTimeDb {
  // utcDate : js Date object. timeZone : "America/New_York"
  // return local timezone if no timezone provided
  // return { dayName, year, month, day, hour, min, sec }
  // return now if utcDate is null
  static getDateObj(utcDate, timeZone) {
    try {
      const jsDate =
        utcDate == null
          ? new Date()
          : utcDate instanceof Date
          ? utcDate
          : new Date(utcDate);
      const momentDate = timeZone
        ? moment.tz(jsDate.getTime(), timeZone)
        : moment(jsDate.getTime());

      const dayName = dayNames[momentDate.day()]; // 0 - 6; sun - sat
      const dateStr = momentDate.format("MM/DD/YYYY");
      const timeStr = momentDate.format("HH:mm");
      const dateObj = momentDate.toObject();
      dateObj.months = dateObj.months + 1;
      dateObj.dateStr = dateStr;
      dateObj.timeStr = timeStr;
      dateObj.dateTimeStr = dateStr + ", " + timeStr;
      dateObj.dayName = dayName;

      return dateObj;
    } catch (ex) {
      console.error(ex);
    }
  }

  static getAllZones() {
    try {
      return moment.tz.zonesForCountry("US");
    } catch (ex) {
      console.error(ex);
    }
  }
}
