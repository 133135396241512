const axios = require("axios");

const USER_ROUTE_URL = "/api/usersAuth";

export default class AuthDb {
  // if signed in return user info, otherwise return null
  static async checkStatus() {
    try {
      const authUser = ((await axios.get(`${USER_ROUTE_URL}/isAuth`)) || {})
        .data;

      return authUser;
    } catch (ex) {
      return null;
    }
  }

  // return record if signin successful, return null if not
  static async signin(user, external) {
    try {
      if (!external) {
        console.log("email", user.email);
        if (!user.email || !user.password)
          return console.error("Invalid Credential(signin)");
      }
      const resObj = await axios.post(`${USER_ROUTE_URL}/userLogin`, {
        user: user,
        external: external,
      });

      // console.log(123, (resObj || {}).data);

      return (resObj || {}).data; // true or false
    } catch (ex) {
      console.error(ex);
    }
  }
  static async register(name, email, password) {
    try {
      // console.log("authDB register", email);
      if (!email || !password)
        return console.error("Email and Password are required");

      const resObj = await axios.post(`${USER_ROUTE_URL}/userRegister`, {
        name: name,
        email: email,
        password: password,
      });

      // console.log(123, (resObj || {}).data);

      return (resObj || {}).data; //
    } catch (ex) {
      console.error(ex);
    }
  }

  static async signout() {
    try {
      return ((await axios.post(`${USER_ROUTE_URL}/userLogout`)) || {}).data;
    } catch (ex) {
      console.error(ex);
    }
  }
  static async getUserInfo() {
    try {
      console.log("authDB userinfo function");
      const userInfo = ((await axios.get(`${USER_ROUTE_URL}/userInfo`)) || {})
        .data;
      return userInfo;
    } catch (ex) {
      // console.error(ex);
      return null;
    }
  }
  static async updateUserInfo(updatedUser) {
    try {
      // console.log('updated user info ',updatedUser);
      const userInfo = await axios.post(
        `${USER_ROUTE_URL}/updateUserInfo`,
        updatedUser
      );
      return userInfo;
    } catch (ex) {
      // console.error(ex);
      return null;
    }
  }
  static async getOrderHistory() {
    try {
      const orderHistory = (
        (await axios.get(`${USER_ROUTE_URL}/getOrderHistory`)) || {}
      ).data;
      return orderHistory;
    } catch (ex) {
      // console.error(ex);
      return null;
    }
  }
  static async getOrderAmount() {
    try {
      const orderAmount = (
        (await axios.get(`${USER_ROUTE_URL}/getOrderAmount`)) || {}
      ).data;
      return orderAmount;
    } catch (ex) {
      // console.error(ex);
      return null;
    }
  }
}
