import React, { Component } from "react";
import {
  Segment,
  Icon,
  Header,
  Dimmer,
  Loader,
  Modal,
  Container,
  Tab,
  // Message,
} from "semantic-ui-react";
// import EventSource from "eventsource";
import Sound from "react-sound";

import OrderDb from "./../dataModel/orderDb";
import BusinessInfoDb from "../dataModel/businessInfoDb";

import OrderRecallPanel from "./orderRecallPanel";
import BusinessInfoPanel from "./businessInfoPanel";
import MenuSettingPanel from "./menuSettingPanel";

import sound from "../imgs/newOrder.mp3";

import { getOneDayDateRange } from "./../dataModel/utils";

class MerchantPanel extends Component {
  PANES = {
    DASHBOARD: 0,
    ORDERS: 1,
    BUSINESS_HOURS: 2,
    MENUS: 3,
  };

  state = {
    intervalId: null,
    latestOrderId: null,

    curPane: this.PANES.ORDERS,

    loading: true,
    loading_orderList: false,

    orders: null, // displayed orders based on date range
    order_selected: null,

    curDateRange: getOneDayDateRange(), // if null then all : dateRange: { startDate:Date, endDate:Date }

    newOrderArrivalSound: false,
    showNewOrderArrivingModal: false,

    merchantId: null,
    businessInfo: null,
  };

  constructor(props) {
    super(props);

    this.state.merchantId = props.merchantId;
  }

  // _getNewOrders = async () => {
  //   const latestOrderId = this.state.latestOrderId
  //     ? this.state.latestOrderId
  //     : 0;

  //   const allOrders = await OrderDb.getOrders(this.state.merchantId);
  //   const newOrders = [];

  //   for (let index in allOrders || []) {
  //     const order = allOrders[index];
  //     if (order && Number(order.orderId) > Number(latestOrderId)) {
  //       newOrders.push(order);
  //     } else {
  //       break;
  //     }
  //   }
  //   return newOrders;
  // };

  _getNewOrders = async () => {
    const latestOrderId =
      this.state.latestOrderId != null ? this.state.latestOrderId : -1;

    const todayOrders = await OrderDb.getOrdersByQueryWithinDateRange(
      this.state.merchantId,
      null,
      getOneDayDateRange()
    );

    const newOrders = [];

    for (let index in todayOrders || []) {
      const order = todayOrders[index];
      if (order && Number(order.orderId) > Number(latestOrderId)) {
        console.log(888, order.orderId, latestOrderId);

        newOrders.push(order);
      } else {
        continue;
      }
    }

    return newOrders.sort((o1, o2) => {
      if (Number(o1.orderId) > Number(o2.orderId)) return -1;
      else if (Number(o1.orderId) < Number(o2.orderId)) return 1;
      return 0;
    });
  };

  componentDidMount = async () => {
    this.startLoading();

    // ---------------- initial this.state.latestOrderId ---------------------------
    // const allOrders = await OrderDb.getOrders(this.state.merchantId);
    // const latestOrderId = allOrders && allOrders[0] && allOrders[0].orderId;

    const latestOrder = ((await OrderDb.getLatestOrder(
      this.state.merchantId
    )) || [])[0];

    const orders = await OrderDb.getOrdersByQueryWithinDateRange(
      this.state.merchantId,
      null,
      this.state.curDateRange
    );
    const order_selected = (orders || [])[0];

    this.endLoading();

    // ---------------------- buinessinfo -----------------------
    const businessInfo = await BusinessInfoDb.getBusinessInfo(
      this.state.merchantId
    );

    // ------------------- set checking interval -------------------
    const intervalId = setInterval(async () => {
      const newComingOrders = await this._getNewOrders();

      if (newComingOrders != null && newComingOrders.length > 0) {
        this.setState({
          latestOrderId: newComingOrders[0].orderId,
        });

        this.handleNewOrderArrived();

        // newComingOrders.forEach((order) => this.handleNewOrderArrived(order));
        // this.setState({
        //   latestOrderId: newComingOrders[0].orderId,
        // });
      }
    }, 10000);

    this.setState({
      orders,
      order_selected,
      intervalId,
      latestOrderId: latestOrder && latestOrder.orderId,
      enableOrderTimeConfirmSMS:
        businessInfo && businessInfo.enableOrderTimeConfirmSMS,
      roomService: businessInfo && businessInfo.roomService,
      businessInfo: businessInfo,
    });
  };

  // componentDidMount = async () => {
  //   this.startLoading();

  //   // ---------------- initial this.state.latestOrderId ---------------------------
  //   const allOrders = await OrderDb.getOrders(this.state.merchantId);
  //   const latestOrderId = allOrders && allOrders[0] && allOrders[0].orderId;

  //   const orders = await OrderDb.getOrdersByQueryWithinDateRange(
  //     this.state.merchantId,
  //     null,
  //     this.state.curDateRange
  //   );
  //   const order_selected = (orders || [])[0];

  //   this.endLoading();

  //   // ------------------- set checking interval -------------------
  //   const intervalId = setInterval(async () => {
  //     const newComingOrders = await this._getNewOrders();

  //     if (newComingOrders != null && newComingOrders.length > 0) {
  //       newComingOrders.forEach((order) => this.handleNewOrderArrived(order));
  //       this.setState({
  //         latestOrderId: newComingOrders[0].orderId,
  //       });
  //     }
  //   }, 10000);

  //   this.setState({
  //     orders,
  //     order_selected,
  //     intervalId,
  //     latestOrderId,
  //   });
  // };

  componentWillUnmount = async () => {
    clearInterval(this.state.intervalId);
  };

  startLoading = (msg) => {
    this.setState({
      loading: true,
    });
  };

  endLoading = () => {
    this.setState({
      loading: false,
    });
  };

  startLoading_orderList = (msg) => {
    this.setState({
      loading_orderList: true,
    });
  };

  endLoading_orderList = () => {
    this.setState({
      loading_orderList: false,
    });
  };

  handleSignoutClick = () => {
    this.props.onSignoutClick && this.props.onSignoutClick();
  };

  handlePaneClick = (pane) => {
    this.setState({
      curPane: pane,
    });
  };

  handleOrderClick = (order) => {
    // flag it as read

    if (!order.hasBeenRead) {
      OrderDb.updateOrderByIdOrder(this.state.merchantId, order && order._id, {
        hasBeenRead: true,
      });
    }

    order.hasBeenRead = true;

    this.setState({
      order_selected: order,
    });
  };

  handleNewOrderArrived = async () => {
    // if (!order) return;

    this.startLoading_orderList();
    const orders = await OrderDb.getOrdersByQueryWithinDateRange(
      this.state.merchantId,
      null,
      this.state.curDateRange
    );
    this.endLoading_orderList();
    this.setState({
      orders,
      newOrderArrivalSound: true,
      showNewOrderArrivingModal: true,
    });
  };

  handleAfterPrintToKitchen = async () => {
    if (!this.state.order_selected) {
      return;
    }

    this.startLoading();
    const updatedOrder = await OrderDb.updateOrderByIdOrder(
      this.state.merchantId,
      this.state.order_selected._id,
      {
        hasPrintedToKitchen: true,
      }
    );
    // console.log(123, updatedOrder);

    const orders = await OrderDb.getOrdersByQueryWithinDateRange(
      this.state.merchantId,
      null,
      this.state.curDateRange
    );
    this.endLoading();

    this.setState({
      orders,
      order_selected: updatedOrder,
    });
  };

  handleDateSelect = async (dateRange) => {
    this.startLoading_orderList();

    const orders = await OrderDb.getOrdersByQueryWithinDateRange(
      this.state.merchantId,
      null,
      dateRange
    );
    const order_selected = (orders || [])[0];

    this.endLoading_orderList();
    this.setState({ curDateRange: dateRange, orders, order_selected });
  };

  handleTipAdjustmentComplete = async () => {
    this.startLoading_orderList();
    const orders = await OrderDb.getOrdersByQueryWithinDateRange(
      this.state.merchantId,
      null,
      this.state.curDateRange
    );

    const order_selected =
      this.state.order_selected &&
      (orders || []).find((order) => {
        return order._id === this.state.order_selected._id;
      });
    this.endLoading_orderList();

    this.setState({
      orders,
      order_selected,
    });
  };

  handleVoidOrderComplete = async () => {
    this.startLoading_orderList();
    const orders = await OrderDb.getOrdersByQueryWithinDateRange(
      this.state.merchantId,
      null,
      this.state.curDateRange
    );

    const order_selected =
      this.state.order_selected &&
      (orders || []).find((order) => {
        return order._id === this.state.order_selected._id;
      });
    this.endLoading_orderList();

    this.setState({
      orders,
      order_selected,
    });
  };

  handleNewOrderNotificationClose = () => {
    this.setState({
      showNewOrderArrivingModal: false,
      newOrderArrivalSound: false,
    });
  };

  handleRefreshOrders = async () => {
    this.startLoading_orderList();
    const orders = await OrderDb.getOrdersByQueryWithinDateRange(
      this.state.merchantId,
      null,
      this.state.curDateRange
    );
    const order_selected = (orders || []).find(
      (order) => order._id == this.state.order_selected._id
    );
    this.endLoading_orderList();
    this.setState({
      orders,
      order_selected,
    });
  };

  render() {
    const { curPane, loading, orders, order_selected } = this.state;
    const { DASHBOARD, ORDERS, MENUS, BUSINESS_HOURS } = this.PANES;

    const panes = [
      {
        menuItem: "Tab 1",
        render: () => <Tab.Pane attached={false}>Tab 1 Content</Tab.Pane>,
      },
      {
        menuItem: "Tab 2",
        render: () => <Tab.Pane attached={false}>Tab 2 Content</Tab.Pane>,
      },
      {
        menuItem: "Tab 3",
        render: () => <Tab.Pane attached={false}>Tab 3 Content</Tab.Pane>,
      },
    ];

    return (
      <div
        style={{
          display: "flex",
          width: "100%",
          height: "100%",
          margin: "0",
          padding: "0",
        }}
      >
        <Dimmer inverted active={loading} page>
          <Loader size="huge" />
        </Dimmer>
        {/* -------------------  new order arrival ---------------  */}
        <Modal
          dimmer={true}
          open={this.state.showNewOrderArrivingModal}
          onClose={this.handleNewOrderNotificationClose}
          basic
          style={{ height: "225px", width: "225px" }}
        >
          <Icon
            name="bell"
            circular
            loading
            inverted
            size="massive"
            color="teal"
            onClick={this.handleNewOrderNotificationClose}
          />
        </Modal>
        {/* <NotificationContainer /> */}
        {/* <Sound
          url={sound}
          playStatus={Sound.status.PLAYING}
          playFromPosition={300}
          onLoading={this.handleSongLoading}
          onPlaying={this.handleSongPlaying}
          onFinishedPlaying={() => {
            this.setState({
              newOrderArrivalSound: false,
            });
          }}
        /> */}
        {/* {this.state.newOrderArrivalSound ? ( */}
        <Sound
          loop={true}
          url={sound}
          playStatus={
            this.state.newOrderArrivalSound
              ? Sound.status.PLAYING
              : Sound.status.STOPPED
          }
          // playFromPosition={300 /* in milliseconds */}
          onLoading={this.handleSongLoading}
          onPlaying={this.handleSongPlaying}
          // onFinishedPlaying={() => {
          //   this.setState({
          //     newOrderArrivalSound: false,
          //   });
          // }}
        />
        {/* ) : null} */}

        {/* ---------------------------------- sidebar -------------------------------------- */}
        <Segment.Group className="merchant-sidebar" size="small">
          <Segment
            inverted={curPane !== ORDERS}
            className="item"
            onClick={() => this.handlePaneClick(ORDERS)}
            textAlign="center"
          >
            <Header as="h4" icon className="icon1">
              <Icon name="send" />
              Orders
            </Header>
          </Segment>

          <Segment
            inverted={curPane !== BUSINESS_HOURS}
            className="item"
            onClick={() => this.handlePaneClick(BUSINESS_HOURS)}
            textAlign="center"
          >
            <Header as="h4" icon className="icon1">
              <Icon name="setting" />
              Store
            </Header>
          </Segment>

          <Segment
            inverted={curPane !== MENUS}
            className="item"
            onClick={() => this.handlePaneClick(MENUS)}
            textAlign="center"
          >
            <Header as="h4" icon className="icon1">
              <Icon name="unordered list" />
              Menu
            </Header>
          </Segment>

          <Segment inverted className="exit">
            <Header
              as="h4"
              icon
              onClick={this.handleSignoutClick}
              className="icon1"
            >
              <Icon name="sign-out" />
              Sign Out
            </Header>
          </Segment>
        </Segment.Group>
        <Container textAlign="center" className="merchant-content">
          {curPane === BUSINESS_HOURS ? (
            <BusinessInfoPanel merchantId={this.state.merchantId} />
          ) : curPane === ORDERS ? (
            <OrderRecallPanel
              merchantId={this.state.merchantId}
              businessInfo={this.state.businessInfo}
              enableOrderTimeConfirmSMS={this.state.enableOrderTimeConfirmSMS}
              loading={this.state.loading_orderList}
              orders={orders}
              order_selected={order_selected}
              onOrderClick={this.handleOrderClick}
              onAfterPrintToKitchen={this.handleAfterPrintToKitchen}
              dateRange={this.state.curDateRange}
              onDateSelect={this.handleDateSelect}
              onTipAdjustmentComplete={this.handleTipAdjustmentComplete}
              onVoidOrderComplete={this.handleVoidOrderComplete}
              onRefresh={this.handleRefreshOrders}
              roomService={this.state.roomService}
            />
          ) : curPane === MENUS ? (
            <MenuSettingPanel merchantId={this.state.merchantId} />
          ) : null}
        </Container>
      </div>
    );
  }
}

export default MerchantPanel;
