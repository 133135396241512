import React, { Component } from "react";
import { Segment, Dimmer, Loader, Dropdown } from "semantic-ui-react";

import _ from "lodash";

import swal from "sweetalert";
import MenuDb from "./../dataModel/menuDb";
import ItemUpdatingModal from "./ItemUpdatingModal";
import ItemAddingModal from "./ItemAddingModal";
import ItemSizeAddingModal from "./ItemSizeAddingModal";
import ItemOptionAddingModal from "./ItemOptionAddingModal";
import ItemOptionValueAddingModal from "./ItemOptionValueAddingModal";
import ItemImageEditModal from "./itemImageEditModal";
import MenuTable from "./menuTable";
import PrinterDb from "../dataModel/printerDb";

class MenuSettingPanel extends Component {
  state = {
    items: null, // displayed items
    allCategories: null,

    printers: null,

    // used for table sort
    columnName: null,
    direction: null,

    category_selected: null,
    item_selected: null,

    _idItemOption_selected: null,
    itemOptionIndex_selected: null,

    item_updated: null,
    item_updated_image: null,
    item_added: null,

    itemSize_added: null,
    itemOption_added: null,
    itemOptionValue_added: null,

    showItemImageEditModal: false,

    merchantId: null,

    loading: true,
  };

  componentDidMount = async () => {
    const merchantId = this.props.merchantId;

    const items = await MenuDb.getItems_category(merchantId);
    const allCategories = await MenuDb.getCategories(merchantId);

    const printers = await PrinterDb.getPrinters(merchantId);

    this.setState({
      items,
      allCategories,
      loading: false,
      merchantId,
      printers,
    });
  };

  _getDisplayedItems = () => {
    if (!this.state.items) return [];

    if (!this.state.columnName) return this.state.items;

    return _.orderBy(
      this.state.items,
      [this.state.columnName],
      [this.state.direction === "ascending" ? "asc" : "desc"]
    );
  };

  handleCategoryChange = async (category) => {
    this.setState({
      loading: true,
    });

    const items = category
      ? await MenuDb.getItemsByIdCategory_category(
          this.state.merchantId,
          category && category._id
        )
      : await MenuDb.getItems_category(this.state.merchantId);

    this.setState({
      items,
      category_selected: category,
      loading: false,
    });
  };

  handleItemModalClose = async () => {
    const willCancel = await swal({
      title: "Are you sure?",
      text: "Changes will not be saved.",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    });

    if (!willCancel) {
      return;
    }

    this.setState({
      item_selected: null,
      item_updated: null,
      item_updated_image: null,
      item_added: null,
      itemSize_added: null,
      itemOption_added: null,
      itemOptionValue_added: null,
      //   _idItemOption_selected: null,
      itemOptionIndex_selected: null,
    });
  };

  handleItemSubModalClose = async () => {
    // const willCancel = await swal({
    //   title: "Are you sure?",
    //   text: "Changes will not be saved.",
    //   icon: "warning",
    //   buttons: true,
    //   dangerMode: true,
    // });

    // if (!willCancel) {
    //   return;
    // }

    this.setState({
      itemSize_added: null,
      itemOption_added: null,
      itemOptionValue_added: null,
      //   _idItemOption_selected: null,
      itemOptionIndex_selected: null,
      showItemImageEditModal: null,
    });
  };

  // ------------------------- item ------------------------------

  handleItemClick = (item) => {
    this.setState({
      item_selected: item,
      item_updated: item && JSON.parse(JSON.stringify(item)),
      item_updated_image: item && item.imgUrl,
    });
  };

  handleSortClick = (columnName) => {
    if (this.state.columnName !== columnName) {
      return this.setState({
        columnName: columnName,
        direction: "ascending",
      });
    }

    this.setState({
      direction:
        this.state.direction === "ascending" ? "descending" : "ascending",
    });
  };

  handleItemUpdateChange = (name, val) => {
    const updated = { ...this.state.item_updated };

    if (!updated) {
      return console.error("Internal error in handleItemUpdateChange()");
    }

    updated[name] = val;

    this.setState({
      item_updated: updated,
    });
  };

  handleItemUpdateSave = async () => {
    const { item_updated, merchantId, item_updated_image, category_selected } =
      this.state;

    //return console.log(123, item_updated_image);

    if (!item_updated || !merchantId) {
      return swal({
        title: "Fail to save item",
        text: "Please check the network or database setting1",
        icon: "error", // replace type with icon, if using new version
        timer: 1500,
        buttons: false,
      });
    }

    this.setState({
      loading: true,
    });

    const updatedItem = await MenuDb.updateItemById_complete(
      merchantId,
      item_updated._id,
      item_updated,
      item_updated_image
    );

    if (updatedItem) {
      // const items = await MenuDb.getItems_category(merchantId);
      //   const items = this.state.items || [];
      //   const index = items.findIndex((item) => item._id === updatedItem._id);
      //   if (index > -1) {
      //     items.splice(index, 1, updatedItem);
      //   }

      const items = category_selected
        ? await MenuDb.getItemsByIdCategory_category(
            merchantId,
            category_selected._id
          )
        : await MenuDb.getItems_category(merchantId);

      this.setState({
        item_selected: null,
        item_updated: null,
        item_updated_image: null,
        items,
        loading: false,
      });
      swal({
        title: "Updated",
        icon: "success", // replace type with icon, if using new version
        timer: 1000,
        buttons: false,
      });
    } else {
      this.setState({
        item_selected: null,
        item_updated: null,
        item_updated_image: null,
      });

      swal({
        title: "Fail to save item",
        text: "Please check the network or database setting",
        icon: "error", // replace type with icon, if using new version
        timer: 1500,
        buttons: false,
      });
    }
  };

  handleItemRemoveClick = async (item) => {
    if (!item && !this.state.merchantId) {
      return swal({
        title: "Fail to remove item",
        text: "Please check the network or database setting",
        icon: "error", // replace type with icon, if using new version
        timer: 1500,
        buttons: false,
      });
    }

    const willRemove = await swal({
      title: "Are you sure?",
      text: "This item will be removed.",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    });

    if (!willRemove) {
      return;
    }

    this.setState({
      loading: true,
    });
    const removedItem = await MenuDb.removeItemById(
      this.state.merchantId,
      item._id
    );

    if (removedItem) {
      swal({
        title: "Updated",
        icon: "success", // replace type with icon, if using new version
        timer: 1000,
        buttons: false,
      });

      //   const items = await MenuDb.getItems_category(this.state.merchantId);
      const items = this.state.items || [];
      const index = items.findIndex((item) => item._id === removedItem._id);
      if (index > -1) {
        items.splice(index, 1);
      }

      this.setState({
        item_selected: null,
        item_updated: null,
        item_updated_image: null,
        items,
        loading: false,
      });
    } else {
      this.setState({
        item_selected: null,
        item_updated: null,
        item_updated_image: null,
      });

      swal({
        title: "Fail to save item",
        text: "Please check the network or database setting",
        icon: "error", // replace type with icon, if using new version
        timer: 1500,
        buttons: false,
      });
    }
  };

  handleItemSoldoutCheckChange = async (item) => {
    if (!item && !this.state.merchantId) {
      return swal({
        title: "Fail to update availability",
        text: "Please check the network or database setting",
        icon: "error", // replace type with icon, if using new version
        timer: 1500,
        buttons: false,
      });
    }

    if (item) {
      item.soldout = !item.soldout;
    }

    this.setState({
      loading: true,
    });
    const updatedItem = await MenuDb.updateItemById(
      this.state.merchantId,
      item._id,
      item
    );

    if (updatedItem) {
      swal({
        title: "Updated",
        icon: "success", // replace type with icon, if using new version
        timer: 1000,
        buttons: false,
      });

      //   const items = await MenuDb.getItems_category(this.state.merchantId);
      const items = this.state.items || [];
      const index = items.findIndex((item) => item._id === updatedItem._id);
      if (index > -1) {
        items[index] = updatedItem;
      }

      this.setState({
        item_selected: null,
        item_updated: null,
        item_updated_image: null,
        items,
        loading: false,
      });
    } else {
      this.setState({
        item_selected: null,
        item_updated: null,
        item_updated_image: null,
      });

      swal({
        title: "Fail to save item availability",
        text: "Please check the network or database setting",
        icon: "error", // replace type with icon, if using new version
        timer: 1500,
        buttons: false,
      });
    }
  };

  handleItemAddClick = () => {
    this.setState({
      item_added: {},
    });
  };

  handleItemAddChange = (name, val) => {
    const added = { ...this.state.item_added };

    if (!added) {
      return console.error("Internal error in handleItemAddChange()");
    }

    added[name] = val;

    this.setState({
      item_added: added,
    });
  };

  handleItemAddSave = async () => {
    const { merchantId, item_added } = this.state;

    if (!item_added || !merchantId) {
      return swal({
        title: "Fail to add item",
        text: "Please check the network or database setting",
        icon: "error", // replace type with icon, if using new version
        timer: 1500,
        buttons: false,
      });
    }

    const addedItem = await MenuDb.addItem(merchantId, item_added);

    if (addedItem) {
      this.setState({
        loading: true,
      });

      const items = await MenuDb.getItems_category(merchantId);

      //   const items = this.state.items || [];
      //   items.push(addedItem);

      this.setState({
        item_selected: null,
        item_updated: null,
        item_updated_image: null,
        item_added: null,
        items,
        loading: false,
      });
      swal({
        title: "Added",
        icon: "success", // replace type with icon, if using new version
        timer: 1000,
        buttons: false,
      });
    } else {
      this.setState({
        item_selected: null,
        item_updated: null,
        item_updated_image: null,
        item_added: null,
      });

      swal({
        title: "Fail to save item",
        text: "Please check the network or database setting",
        icon: "error", // replace type with icon, if using new version
        timer: 1500,
        buttons: false,
      });
    }
  };

  handleItemCategoryChange = async (category) => {
    const added = { ...this.state.item_added };

    if (!added) {
      return console.error("Internal error in handleItemAddChange()");
    }

    added._idCategory = category && category._id;

    this.setState({
      item_added: added,
    });
  };

  handleItemImageClick = () => {
    this.setState({
      showItemImageEditModal: true,
    });
  };

  // handleItemUpdateImageChange = (image) => {
  //   const updated = { ...this.state.item_updated };

  //   if (!updated) {
  //     return console.error("Internal error in handleItemUpdateImageChange()");
  //   }

  //   updated.image = image;

  //   this.setState({
  //     item_updated: updated,
  //   });
  // };

  handleItemImageDoneClick = (image) => {
    // const updated = { ...this.state.item_updated };

    // if (!updated) {
    //   return console.error("Internal error in handleItemImageDoneClick()");
    // }

    // updated.image = image;

    this.setState({
      // item_updated: updated,
      item_updated_image: image,
      showItemImageEditModal: false,
    });
  };

  handleItemPrinterUpdate = async (item, _idPrinter, checked) => {
    if (!item && !this.state.merchantId) {
      return swal({
        title: "Fail to update printers",
        text: "Please check the network or database setting",
        icon: "error", // replace type with icon, if using new version
        timer: 1500,
        buttons: false,
      });
    }

    const _idPrinters = item._idPrinters || [];

    const index = _idPrinters.indexOf(_idPrinter);
    if (checked && index < 0) {
      _idPrinters.push(_idPrinter);
    } else if (!checked && index > -1) {
      _idPrinters.splice(index, 1);
    }

    item._idPrinters = _idPrinters;

    this.setState({
      loading: true,
    });
    const updatedItem = await MenuDb.updateItemById(
      this.state.merchantId,
      item._id,
      item
    );

    if (updatedItem) {
      // swal({
      //   title: "Updated",
      //   icon: "success", // replace type with icon, if using new version
      //   timer: 1000,
      //   buttons: false,
      // });

      //   const items = await MenuDb.getItems_category(this.state.merchantId);
      const items = this.state.items || [];
      const index = items.findIndex((item) => item._id === updatedItem._id);
      if (index > -1) {
        items[index] = updatedItem;
      }

      this.setState({
        item_selected: null,
        item_updated: null,
        item_updated_image: null,
        items,
        loading: false,
      });
    } else {
      this.setState({
        item_selected: null,
        item_updated: null,
        item_updated_image: null,
      });

      swal({
        title: "Fail to save item printers",
        text: "Please check the network or database setting",
        icon: "error", // replace type with icon, if using new version
        timer: 1500,
        buttons: false,
      });
    }
  };

  // ------------------------ item size -----------------------------

  handleItemSizeUpdateChange = (_idSize, name, val) => {
    const updated = { ...this.state.item_updated };

    if (!updated) {
      return console.error("Internal error in handleItemSizeUpdateChange()");
    }

    const size = (updated.size || []).find((size) => size._id === _idSize);
    if (!size) {
      return console.error("Internal error in handleItemSizeUpdateChange()");
    }

    size[name] = val;

    this.setState({
      item_updated: updated,
    });
  };

  handleItemSizeAddClick = () => {
    this.setState({
      itemSize_added: {},
    });
  };

  handleItemSizeAddChange = (name, val) => {
    const added = { ...this.state.itemSize_added };

    if (!added) {
      return console.error("Internal error in handleItemSizeAddChange()");
    }

    added[name] = val;

    this.setState({
      itemSize_added: added,
    });
  };

  handleItemSizeAddSave = () => {
    const { itemSize_added, item_updated } = this.state;
    if (!itemSize_added || !item_updated) {
      return swal({
        title: "Fail to add item size",
        text: "Please check the network or database setting",
        icon: "error", // replace type with icon, if using new version
        timer: 1500,
        buttons: false,
      });
    }
    item_updated.size = item_updated.size || [];
    item_updated.size.push(itemSize_added);

    this.setState({
      item_updated,
      itemSize_added: null,
    });

    // const { merchantId, itemSize_added, item_selected } = this.state;

    // if (!itemSize_added || !merchantId || !item_selected) {
    //   return swal({
    //     title: "Fail to add item size",
    //     text: "Please check the network or database setting",
    //     icon: "error", // replace type with icon, if using new version
    //     timer: 1500,
    //     buttons: false,
    //   });
    // }

    // const addedItemSize = await MenuDb.addItemSizeById(
    //   merchantId,
    //   item_selected._id,
    //   itemSize_added
    // );

    // if (addedItemSize) {
    //   this.setState({
    //     loading: true,
    //   });

    //   //   const items = await MenuDb.getItems_category(merchantId);
    //   //   const item = await MenuDb.getItemById(merchantId, item_selected._id);
    //   const item = this.state.item_selected;
    //   if (item) {
    //     item.size = item.size || [];
    //     item.size.push(addedItemSize);
    //   }

    //   this.setState({
    //     item_selected: item,
    //     item_updated: item && JSON.parse(JSON.stringify(item)),
    //     item_added: null,
    //     itemSize_added: null,
    //     // items,
    //     loading: false,
    //   });
    //   swal({
    //     title: "Added",
    //     icon: "success", // replace type with icon, if using new version
    //     timer: 1000,
    //     buttons: false,
    //   });
    // } else {
    //   this.setState({
    //     itemSize_added: null,
    //   });

    //   swal({
    //     title: "Fail to add item size",
    //     text: "Please check the network or database setting",
    //     icon: "error", // replace type with icon, if using new version
    //     timer: 1500,
    //     buttons: false,
    //   });
    // }
  };

  handleItemSizeRemove = (sizeIndex) => {
    const { item_updated } = this.state;

    if (!item_updated) {
      return swal({
        title: "Fail to remove item size",
        text: "Please check the network or database setting",
        icon: "error", // replace type with icon, if using new version
        timer: 1500,
        buttons: false,
      });
    }

    if (sizeIndex < 0) {
      return swal({
        title: "Fail to remove item size",
        text: "Please check the network or database setting",
        icon: "error", // replace type with icon, if using new version
        timer: 1500,
        buttons: false,
      });
    }

    (item_updated.size || []).splice(sizeIndex, 1);

    this.setState({
      item_updated,
    });

    // const { merchantId, item_selected } = this.state;

    // if (!merchantId || !item_selected || !_idSize) {
    //   return swal({
    //     title: "Fail to remove item size",
    //     text: "Please check the network or database setting",
    //     icon: "error", // replace type with icon, if using new version
    //     timer: 1500,
    //     buttons: false,
    //   });
    // }

    // const willRemove = await swal({
    //   title: "Are you sure?",
    //   text: "This item size will be removed.",
    //   icon: "warning",
    //   buttons: true,
    //   dangerMode: true,
    // });

    // if (!willRemove) {
    //   return;
    // }

    // const removedItemSize = await MenuDb.removeItemSizeByIdByIdItemSize(
    //   merchantId,
    //   item_selected._id,
    //   _idSize
    // );

    // if (removedItemSize) {
    //   this.setState({
    //     loading: true,
    //   });

    //   //   const items = await MenuDb.getItems_category(merchantId);
    //   //   const item = await MenuDb.getItemById(merchantId, item_selected._id);
    //   const item = this.state.item_selected;
    //   if (item) {
    //     const sizes = item.size || [];
    //     const index = sizes.findIndex(
    //       (size) => size._id === removedItemSize._id
    //     );
    //     if (index > -1) {
    //       sizes.splice(index, 1);
    //     }
    //   }

    //   this.setState({
    //     item_selected: item,
    //     item_updated: item && JSON.parse(JSON.stringify(item)),
    //     // items,
    //     loading: false,
    //   });
    //   swal({
    //     title: "Removed",
    //     icon: "success", // replace type with icon, if using new version
    //     timer: 1000,
    //     buttons: false,
    //   });
    // } else {
    //   swal({
    //     title: "Fail to remove item size",
    //     text: "Please check the network or database setting",
    //     icon: "error", // replace type with icon, if using new version
    //     timer: 1500,
    //     buttons: false,
    //   });
    // }
  };

  // ------------------------ item option ---------------------------

  handleItemOptionUpdateChange = (_idOption, name, val) => {
    const updated = { ...this.state.item_updated };

    if (!updated) {
      return console.error("Internal error in handleItemOptionUpdateChange()");
    }

    const option = (updated.options || []).find(
      (option) => option._id === _idOption
    );
    if (!option) {
      return console.error("Internal error in handleItemOptionUpdateChange()");
    }

    option[name] = val;

    this.setState({
      item_updated: updated,
    });
  };

  handleItemOptionValueUpdateChange = (
    _idOption,
    _idOptionValue,
    name,
    val
  ) => {
    const updated = { ...this.state.item_updated };

    if (!updated) {
      return console.error(
        "Internal error in handleItemOptionValueUpdateChange()"
      );
    }

    const option = (updated.options || []).find(
      (option) => option._id === _idOption
    );
    if (!option) {
      return console.error(
        "Internal error in handleItemOptionValueUpdateChange()"
      );
    }

    const optionValue = (option.optionValues || []).find(
      (optionValue) => optionValue._id === _idOptionValue
    );
    if (!optionValue) {
      return console.error(
        "Internal error in handleItemOptionValueUpdateChange()"
      );
    }

    optionValue[name] = val;

    this.setState({
      item_updated: updated,
    });
  };

  handleItemOptionAddClick = () => {
    this.setState({
      itemOption_added: { numberToSelect: "0" },
    });
  };

  handleItemOptionAddChange = (name, val) => {
    const added = { ...this.state.itemOption_added };

    if (!added) {
      return console.error("Internal error in handleItemOptionAddChange()");
    }

    added[name] = val;

    this.setState({
      itemOption_added: added,
    });
  };

  handleItemOptionAddSave = () => {
    const { itemOption_added, item_updated } = this.state;

    if (!itemOption_added || !item_updated) {
      return swal({
        title: "Fail to add item option",
        text: "Please check the network or database setting",
        icon: "error", // replace type with icon, if using new version
        timer: 1500,
        buttons: false,
      });
    }
    item_updated.options = item_updated.options || [];
    item_updated.options.push(itemOption_added);

    this.setState({
      item_updated,
      itemOption_added: null,
    });

    // const { merchantId, itemOption_added, item_selected } = this.state;

    // if (!itemOption_added || !merchantId || !item_selected) {
    //   return swal({
    //     title: "Fail to add item size",
    //     text: "Please check the network or database setting",
    //     icon: "error", // replace type with icon, if using new version
    //     timer: 1500,
    //     buttons: false,
    //   });
    // }

    // const addedItemOption = await MenuDb.addItemOptionById(
    //   merchantId,
    //   item_selected._id,
    //   itemOption_added
    // );

    // if (addedItemOption) {
    //   this.setState({
    //     loading: true,
    //   });

    //   //   const items = await MenuDb.getItems_category(merchantId);
    //   //   const item = await MenuDb.getItemById(merchantId, item_selected._id);
    //   const item = this.state.item_selected;
    //   if (item) {
    //     item.options = item.options || [];
    //     item.options.push(addedItemOption);
    //   }

    //   this.setState({
    //     item_selected: item,
    //     item_updated: item && JSON.parse(JSON.stringify(item)),
    //     item_added: null,
    //     itemOption_added: null,
    //     // items,
    //     loading: false,
    //   });
    //   swal({
    //     title: "Added",
    //     icon: "success", // replace type with icon, if using new version
    //     timer: 1000,
    //     buttons: false,
    //   });
    // } else {
    //   this.setState({
    //     itemOption_added: null,
    //   });

    //   swal({
    //     title: "Fail to add item option",
    //     text: "Please check the network or database setting",
    //     icon: "error", // replace type with icon, if using new version
    //     timer: 1500,
    //     buttons: false,
    //   });
    // }
  };

  handleItemOptionRemoveClick = (optionIndex) => {
    const { item_updated } = this.state;

    if (!item_updated) {
      return swal({
        title: "Fail to remove item option",
        text: "Please check the network or database setting",
        icon: "error", // replace type with icon, if using new version
        timer: 1500,
        buttons: false,
      });
    }

    if (optionIndex < 0) {
      return swal({
        title: "Fail to remove item size",
        text: "Please check the network or database setting",
        icon: "error", // replace type with icon, if using new version
        timer: 1500,
        buttons: false,
      });
    }

    (item_updated.options || []).splice(optionIndex, 1);

    this.setState({
      item_updated,
    });

    // const { merchantId, item_selected } = this.state;

    // if (!merchantId || !item_selected) {
    //   return swal({
    //     title: "Fail to remove item option",
    //     text: "Please check the network or database setting",
    //     icon: "error", // replace type with icon, if using new version
    //     timer: 1500,
    //     buttons: false,
    //   });
    // }

    // const willRemove = await swal({
    //   title: "Are you sure?",
    //   text: "This item option and all belonging option values will be removed.",
    //   icon: "warning",
    //   buttons: true,
    //   dangerMode: true,
    // });

    // if (!willRemove) {
    //   return;
    // }

    // const removedItemOption = await MenuDb.removeItemOptionByIdByIdOption(
    //   merchantId,
    //   item_selected._id,
    //   _idOption
    // );

    // if (removedItemOption) {
    //   this.setState({
    //     loading: true,
    //   });

    //   //   const items = await MenuDb.getItems_category(merchantId);
    //   //   const item = await MenuDb.getItemById(merchantId, item_selected._id);
    //   const item = this.state.item_selected;
    //   if (item) {
    //     const options = item.options || [];
    //     const index = options.findIndex(
    //       (option) => option._id === removedItemOption._id
    //     );
    //     if (index > -1) {
    //       options.splice(index, 1);
    //     }
    //   }

    //   this.setState({
    //     item_selected: item,
    //     item_updated: item && JSON.parse(JSON.stringify(item)),
    //     // items,
    //     loading: false,
    //   });
    //   swal({
    //     title: "Removed",
    //     icon: "success", // replace type with icon, if using new version
    //     timer: 1000,
    //     buttons: false,
    //   });
    // } else {
    //   swal({
    //     title: "Fail to remove item option",
    //     text: "Please check the network or database setting",
    //     icon: "error", // replace type with icon, if using new version
    //     timer: 1500,
    //     buttons: false,
    //   });
    // }
  };

  handleItemOptionValueAddClick = (optionIndex) => {
    this.setState({
      //   _idItemOption_selected: _idOption,
      itemOptionIndex_selected: optionIndex,
      itemOptionValue_added: { price: "0" },
    });
  };

  handleItemOptionValueAddChange = (name, val) => {
    const added = { ...this.state.itemOptionValue_added };

    if (!added) {
      return console.error(
        "Internal error in handleItemOptionValueAddChange()"
      );
    }

    added[name] = val;

    this.setState({
      itemOptionValue_added: added,
    });
  };

  handleItemOptionValueAddSave = () => {
    const { itemOptionValue_added, itemOptionIndex_selected, item_updated } =
      this.state;

    if (
      !itemOptionValue_added ||
      itemOptionIndex_selected == null ||
      !item_updated
    ) {
      return swal({
        title: "Fail to add item option value",
        text: "Please check the network or database setting",
        icon: "error", // replace type with icon, if using new version
        timer: 1500,
        buttons: false,
      });
    }

    const itemOption = item_updated.options[itemOptionIndex_selected];
    if (!itemOption) {
      return swal({
        title: "Fail to add item option value",
        text: "Please check the network or database setting",
        icon: "error", // replace type with icon, if using new version
        timer: 1500,
        buttons: false,
      });
    }

    itemOption.optionValues = itemOption.optionValues || [];
    itemOption.optionValues.push(itemOptionValue_added);

    this.setState({
      item_updated,
      itemOptionValue_added: null,
    });

    // const {
    //   merchantId,
    //   itemOptionValue_added,
    //   item_selected,
    //   _idItemOption_selected,
    // } = this.state;
    // if (
    //   !itemOptionValue_added ||
    //   !merchantId ||
    //   !item_selected ||
    //   !_idItemOption_selected
    // ) {
    //   return swal({
    //     title: "Fail to add item option value",
    //     text: "Please check the network or database setting",
    //     icon: "error", // replace type with icon, if using new version
    //     timer: 1500,
    //     buttons: false,
    //   });
    // }
    // const addedItemOptionValue = await MenuDb.addItemOptionValueByIdByIdOption(
    //   merchantId,
    //   item_selected._id,
    //   _idItemOption_selected,
    //   itemOptionValue_added
    // );
    // if (addedItemOptionValue) {
    //   this.setState({
    //     loading: true,
    //   });
    //   //   const items = await MenuDb.getItems_category(merchantId);
    //   //   const item = await MenuDb.getItemById(merchantId, item_selected._id);
    //   const item = this.state.item_selected;
    //   if (item) {
    //     const option = (item.options || []).find(
    //       (option) => option._id === _idItemOption_selected
    //     );
    //     if (option) {
    //       option.optionValues = option.optionValues || [];
    //       option.optionValues.push(addedItemOptionValue);
    //     }
    //   }
    //   this.setState({
    //     item_selected: item,
    //     item_updated: item && JSON.parse(JSON.stringify(item)),
    //     itemOptionValue_added: null,
    //     _idItemOption_selected: null,
    //     // items,
    //     loading: false,
    //   });
    //   swal({
    //     title: "Added",
    //     icon: "success", // replace type with icon, if using new version
    //     timer: 1000,
    //     buttons: false,
    //   });
    // } else {
    //   this.setState({
    //     itemOptionValue_added: null,
    //     _idItemOption_selected: null,
    //   });
    //   swal({
    //     title: "Fail to add item option value",
    //     text: "Please check the network or database setting",
    //     icon: "error", // replace type with icon, if using new version
    //     timer: 1500,
    //     buttons: false,
    //   });
    // }
  };

  handleItemOptionValueRemoveClick = (optionIndex, optionValueIndex) => {
    const { item_updated } = this.state;

    if (!item_updated || optionIndex == null || optionValueIndex == null) {
      return swal({
        title: "Fail to remove item option value",
        text: "Please check the network or database setting",
        icon: "error", // replace type with icon, if using new version
        timer: 1500,
        buttons: false,
      });
    }

    const itemOption = (item_updated.options || [])[optionIndex];
    if (!itemOption) {
      return swal({
        title: "Fail to remove item option value",
        text: "Please check the network or database setting",
        icon: "error", // replace type with icon, if using new version
        timer: 1500,
        buttons: false,
      });
    }

    (itemOption.optionValues || []).splice(optionValueIndex, 1);

    this.setState({
      item_updated,
    });

    // const { merchantId, item_selected } = this.state;

    // if (!merchantId || !item_selected) {
    //   return swal({
    //     title: "Fail to remove item option value",
    //     text: "Please check the network or database setting",
    //     icon: "error", // replace type with icon, if using new version
    //     timer: 1500,
    //     buttons: false,
    //   });
    // }

    // const willRemove = await swal({
    //   title: "Are you sure?",
    //   text: "This item option value will be removed.",
    //   icon: "warning",
    //   buttons: true,
    //   dangerMode: true,
    // });

    // if (!willRemove) {
    //   return;
    // }

    // const removedItemOptionValue = await MenuDb.removeItemOptionValueByIdByIdOptionByIdOptionValue(
    //   merchantId,
    //   item_selected._id,
    //   _idOption,
    //   _idOptionValue
    // );

    // if (removedItemOptionValue) {
    //   this.setState({
    //     loading: true,
    //   });

    //   //   const items = await MenuDb.getItems_category(merchantId);
    //   //   const item = await MenuDb.getItemById(merchantId, item_selected._id);
    //   const item = this.state.item_selected;
    //   if (item) {
    //     const option = (item.options || []).find(
    //       (option) => option._id === _idOption
    //     );

    //     if (option) {
    //       option.optionValues = option.optionValues || [];
    //       const index = option.optionValues.findIndex(
    //         (optionValue) => optionValue._id === removedItemOptionValue._id
    //       );
    //       if (index > -1) {
    //         option.optionValues.splice(index, 1);
    //       }
    //     }
    //   }

    //   this.setState({
    //     item_selected: item,
    //     item_updated: item && JSON.parse(JSON.stringify(item)),
    //     // items,
    //     loading: false,
    //   });
    //   swal({
    //     title: "Removed",
    //     icon: "success", // replace type with icon, if using new version
    //     timer: 1000,
    //     buttons: false,
    //   });
    // } else {
    //   swal({
    //     title: "Fail to remove item option value",
    //     text: "Please check the network or database setting",
    //     icon: "error", // replace type with icon, if using new version
    //     timer: 1500,
    //     buttons: false,
    //   });
    // }
  };

  render() {
    const {
      category_selected,
      items,
      allCategories,
      loading,
      item_selected,
      item_updated,
      item_added,
      itemSize_added,
      itemOption_added,
      itemOptionValue_added,
      showItemImageEditModal,
      item_updated_image,
    } = this.state;

    const itemCount = (this.state.items || []).length;

    const categoryDropdownOptions = (allCategories || []).map(
      (category, index) => {
        return {
          key: index,
          text: category.name,
          value: category._id,
        };
      }
    );

    return (
      <Segment
        basic
        style={{ width: "100%", height: "100%", overflowY: "auto" }}
        textAlign="left"
      >
        <Dimmer inverted active={loading}>
          <Loader size="huge" />
        </Dimmer>

        <Dropdown
          style={{
            textAlign: "center",
            display: "block",
            // display: "inline-block",
            // width: "calc(100% - 120px)",
          }}
          fluid
          clearable
          text={
            (category_selected ? category_selected.name : "All Categories") +
            `, ${itemCount} items`
          }
          labeled
          // button
          // icon="list alternate outline"
          className="sumi-global-btn-color"
          selection
          options={categoryDropdownOptions}
          onChange={(evt, data) =>
            this.handleCategoryChange(
              (allCategories || []).find((cate) => cate._id === data.value)
            )
          }
        />
        {/* 
        <Button
          style={{
            textAlign: "center",
            // display: "block",
            width: "120px",
          }}
          lassName="sumi-global-btn-color"
        >
          + New
        </Button> */}

        {/* <Table striped fixed singleLine size="small">
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell width={3}>Name</Table.HeaderCell>
              <Table.HeaderCell width={3}>Name (2nd language)</Table.HeaderCell>
              <Table.HeaderCell width={2}>Unit Price</Table.HeaderCell>
              <Table.HeaderCell width={3}>Category</Table.HeaderCell>
              <Table.HeaderCell width={4}>Description</Table.HeaderCell>
              <Table.HeaderCell width={1}>
                <Button
                  className="sumi-global-btn-color"
                  style={{
                    width: "50px",
                    height: "25px",
                    margin: "0",
                    padding: "0",
                    float: "right",
                    fontSize: "12px",
                  }}
                  onClick={this.handleItemAddClick}
                >
                  + New
                </Button>
              </Table.HeaderCell>
            </Table.Row>
          </Table.Header>

          <Table.Body>{displayedItems}</Table.Body>
        </Table> */}

        <MenuTable
          items={this._getDisplayedItems()}
          printers={this.state.printers}
          columnName={this.state.columnName}
          direction={this.state.direction}
          onItemClick={this.handleItemClick}
          onItemRemoveClick={this.handleItemRemoveClick}
          onItemSoldoutCheckChange={this.handleItemSoldoutCheckChange}
          onItemAddClick={this.handleItemAddClick}
          onSortClick={this.handleSortClick}
          onItemPrinterUpdate={this.handleItemPrinterUpdate}
        />

        <ItemUpdatingModal
          item_updated={item_updated}
          item_updated_image={item_updated_image}
          onClose={this.handleItemModalClose}
          onItemChange={this.handleItemUpdateChange}
          onItemSizeChange={this.handleItemSizeUpdateChange}
          onItemSizeAddClick={this.handleItemSizeAddClick}
          onItemSizeRemove={this.handleItemSizeRemove}
          onItemOptionChange={this.handleItemOptionUpdateChange}
          onItemOptionValueChange={this.handleItemOptionValueUpdateChange}
          onItemOptionAddClick={this.handleItemOptionAddClick}
          onItemOptionRemoveClick={this.handleItemOptionRemoveClick}
          onItemOptionValueAddClick={this.handleItemOptionValueAddClick}
          onItemOptionValueRemoveClick={this.handleItemOptionValueRemoveClick}
          onItemImageClick={this.handleItemImageClick}
          onSave={this.handleItemUpdateSave}
          loading={this.state.loading}
        />

        <ItemSizeAddingModal
          itemSize_added={itemSize_added}
          onClose={this.handleItemSubModalClose}
          onItemSizeAddChange={this.handleItemSizeAddChange}
          onItemSizeAddSave={this.handleItemSizeAddSave}
          loading={this.state.loading}
        />

        <ItemOptionAddingModal
          itemOption_added={itemOption_added}
          onClose={this.handleItemSubModalClose}
          onItemOptionAddChange={this.handleItemOptionAddChange}
          onItemOptionAddSave={this.handleItemOptionAddSave}
          loading={this.state.loading}
        />

        <ItemOptionValueAddingModal
          itemOptionValue_added={itemOptionValue_added}
          onClose={this.handleItemSubModalClose}
          onItemOptionValueAddChange={this.handleItemOptionValueAddChange}
          onItemOptionValueAddSave={this.handleItemOptionValueAddSave}
          loading={this.state.loading}
        />

        <ItemAddingModal
          item_added={item_added}
          allCategories={allCategories}
          onClose={this.handleItemModalClose}
          onItemAddChange={this.handleItemAddChange}
          onItemCategoryChange={this.handleItemCategoryChange}
          onItemAddSave={this.handleItemAddSave}
          loading={this.state.loading}
        />

        <ItemImageEditModal
          open={showItemImageEditModal}
          image={item_updated_image}
          onClose={this.handleItemSubModalClose}
          onDoneClick={this.handleItemImageDoneClick}
          // onItemImageChange={this.handleItemUpdateImageChange}
          // onItemImageSaveClick={this.handleItemImageSaveClick}
        />
      </Segment>
    );
  }
}

export default MenuSettingPanel;
