import React, { Component, useEffect } from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  Redirect,
} from "react-router-dom";
// import "semantic-ui/dist/semantic.min.css";
import "semantic-ui-css/semantic.min.css";

import { Dimmer, Loader } from "semantic-ui-react";

import HomePanel from "./components/homePanel";
import OwnerLoginPanel from "./components/ownerLoginPanel";

import MerchantUserDb from "./dataModel/merchantUserDb";
import AuthDb from "./dataModel/authDb";

import MerchantPanel from "./components/merchantPanel";
import OrderPlacedPanel from "./components/orderPlacedPanel";
import TermsOfServicePage from "./components/termsOfServicePage";
import PrivacyPolicyPage from "./components/privacyPolicyPage";
import WelcomePanel from "./components/welcomePanel";

// force refreshing page if back is clicked in browser
window &&
  window.addEventListener("pageshow", function (event) {
    var historyTraversal =
      event.persisted ||
      (typeof window.performance != "undefined" &&
        window.performance.navigation.type === 2);
    if (historyTraversal) {
      // Handle page restore.
      window.location.reload();
    }
  });

class App extends Component {
  state = {
    ownerLoggedIn: false,
    merchantId: null,
    loading: true,

    userLoggedIn: false,
    errMsg: "",
    userName: "",
    userInfo: {},
    customerAddressStr: null,
    userDashboardMessage: "",
    userDashboardErrMsg: "",
  };

  componentDidMount = async () => {
    try {
      // ------------------- test ------------------------------
      // const script = document.createElement("script");

      // script.src =
      //   "https://stagegw.transnox.com/transit-tsep-web/jsView/88800000344702?b23a04cbd0335317fc745a1a207ca2dce1424d8083189c1ff40a0f8680bd5b129b0484db048cf5d0423d28b2fd8c55f77b418d69957c8802cc2aab0af788e8d46df2e3bd";
      // script.async = true;
      // document.body.appendChild(script);
      // console.log(123123123);

      // -------------------------------------------------------

      const ownerLoggedIn = await MerchantUserDb.checkStatus();

      const userLoggedIn = await AuthDb.checkStatus();

      this.setState({
        ownerLoggedIn: !!ownerLoggedIn,
        merchantId: ownerLoggedIn && ownerLoggedIn.username,
        userLoggedIn: !!userLoggedIn,
      });

      if (userLoggedIn) {
        this.setState({ userInfo: userLoggedIn.user });
      }
      this.endLoading();
    } catch (ex) {
      console.log(ex);
    }
  };

  startLoading = (msg) => {
    this.setState({
      loading: true,
    });
  };

  endLoading = () => {
    this.setState({
      loading: false,
    });
  };

  componentWillUnmount() {
    clearInterval(this.interval);
  }

  handleUserLogoutClick = async () => {
    this.startLoading();
    const record = await AuthDb.signout();
    this.endLoading();
    this.setState({
      userLoggedIn: false,
    });
  };

  handleUserInfoUpdate = async (updatedUser) => {
    const result = await AuthDb.updateUserInfo(updatedUser);
    if (result.data.success) {
      this.setState({ userDashboardMessage: "Updated succesfully!" });
    } else {
      this.setState({ userDashboardErrMsg: "Unable to update info!" });
    }
    this.getUserInfo();
  };
  clearUserDashboardMsg = () => {
    this.setState({ userDashboardErrMsg: "", userDashboardMessage: "" });
  };

  handleUserSigninClick = async (user, external) => {
    this.startLoading();
    const record = await AuthDb.signin(user, external);
    this.endLoading();

    if (record.success) {
      this.setState({
        userLoggedIn: true,
        errMsg: null,
        userName: record.user.name,
        userInfo: record.user,
      });
    } else {
      this.setState({
        userLoggedIn: false,
        errMsg: record.errMessage,
      });
    }
  };

  handleSigninClick = async (merchantId, password) => {
    this.startLoading();
    const record = await MerchantUserDb.signin(merchantId, password);
    this.endLoading();

    if (record) {
      this.setState({
        ownerLoggedIn: true,
        merchantId: merchantId,
        errMsg: null,
      });
    } else {
      this.setState({
        ownerLoggedIn: false,
        merchantId: null,
        errMsg: "Invalid credentials provided",
      });
    }
  };

  handleSignoutClick = async () => {
    this.startLoading();
    const record = await MerchantUserDb.signout(this.state.merchantId);
    this.endLoading();
    if (record) {
      this.setState({
        ownerLoggedIn: false,
        errMsg: null,
        merchantId: null,
      });
    } else {
      this.setState({
        errMsg: "Fail to signout",
      });
    }
  };

  getUserInfo = async () => {
    const result = await AuthDb.getUserInfo();
    if (result) {
      this.setState({ userInfo: result.user });
    } else {
      this.setState({
        sessionWarning: "You have been logged out, please refresh this page;",
      });
    }
  };

  render() {
    return (
      <div
        style={
          {
            // backgroundColor: "#fdfdfd",
            // fontFamily:
            //   "-apple-system,BlinkMacSystemFont,San Francisco,Helvetica Neue,Helvetica,Ubuntu,Roboto,Noto,Segoe UI,Arial,sans-serif",
          }
        }
      >
        <Dimmer inverted active={this.state.loading} page>
          <Loader size="huge" />
        </Dimmer>
        <Router>
          <Switch>
            <Route exact path="/">
              <WelcomePanel />
            </Route>
            {/* ---------------------- store ------------------------ */}
            <Route
              exact
              path="/store/:merchantId/order_placed/:_idOrder"
              render={({ match }) => (
                <OrderPlacedPanel
                  _idOrder={match.params._idOrder}
                  merchantId={match.params.merchantId}
                >
                  <Link to="/">Start a new Order</Link>
                </OrderPlacedPanel>
              )}
            />
            {/* ----------------- for dinein --------------------- */}
            <Route
              exact
              path="/store/:merchantId/dinein/:tablename"
              render={({ match }) => (
                <HomePanel
                  merchantId={match.params.merchantId}
                  errMsg={this.state.errMsg}
                  userLoggedIn={this.state.userLoggedIn}
                  userInfo={this.state.userInfo}
                  customerAddressStr={this.state.customerAddressStr}
                  userName={this.state.userName}
                  onLogoutClick={this.handleUserLogoutClick}
                  userDashboardErrMsg={this.state.userDashboardErrMsg}
                  userDashboardMessage={this.state.userDashboardMessage}
                  handleUserInfoUpdate={this.handleUserInfoUpdate}
                  clearUserDashboardMsg={this.clearUserDashboardMsg}
                  onSigninClick={this.handleUserSigninClick}
                ></HomePanel>
              )}
            />
            )} />
            <Route
              exact
              path="/store/:merchantId"
              render={({ match }) => (
                <HomePanel
                  merchantId={match.params.merchantId}
                  storeName={match.params.storeName}
                  errMsg={this.state.errMsg}
                  userLoggedIn={this.state.userLoggedIn}
                  userInfo={this.state.userInfo}
                  customerAddressStr={this.state.customerAddressStr}
                  userName={this.state.userName}
                  onLogoutClick={this.handleUserLogoutClick}
                  userDashboardErrMsg={this.state.userDashboardErrMsg}
                  userDashboardMessage={this.state.userDashboardMessage}
                  handleUserInfoUpdate={this.handleUserInfoUpdate}
                  clearUserDashboardMsg={this.clearUserDashboardMsg}
                  onSigninClick={this.handleUserSigninClick}
                ></HomePanel>
              )}
            />
            <Route
              exact
              path="/store/:merchantId/:storeName"
              render={({ match }) => (
                <HomePanel
                  merchantId={match.params.merchantId}
                  storeName={match.params.storeName}
                ></HomePanel>
              )}
            />
            {/* ---------------------- merchant ------------------------ */}
            <Route exact path="/merchant/login">
              {this.state.ownerLoggedIn ? (
                <Redirect
                  to={`/merchant/merchantId/${this.state.merchantId}`}
                />
              ) : (
                <OwnerLoginPanel
                  errMsg={this.state.errMsg}
                  onSigninClick={this.handleSigninClick}
                />
              )}
            </Route>
            <Route exact path="/merchant/merchantId/:merchantId">
              {!this.state.ownerLoggedIn ? (
                <Redirect to="/merchant/login" />
              ) : (
                <MerchantPanel
                  onSignoutClick={this.handleSignoutClick}
                  merchantId={this.state.merchantId}
                />
              )}
            </Route>
            {/* ---------------------- terms of service & policy ------------------------ */}
            <Route exact path="/document/terms-of-service">
              <TermsOfServicePage />
            </Route>
            <Route exact path="/document/privacy-policy">
              <PrivacyPolicyPage />
            </Route>
            {/* -------------------------- all other --------------------------------------- */}
            <Route path="*">
              <Redirect to="/" />
            </Route>
          </Switch>
        </Router>
      </div>
    );
  }
}

export default App;
