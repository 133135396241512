import React, { Component } from "react";
import { Message, Segment, Header } from "semantic-ui-react";

import {
  withScriptjs,
  withGoogleMap,
  GoogleMap,
  Marker,
} from "react-google-maps";

import Autocomplete from "react-google-autocomplete";

const googleMapKey = "AIzaSyCi5MqSwmmuTW1R-Axt3dWOabhZUx386tg";

class GoogleMapPanel extends React.PureComponent {
  handleCustomerAddressSelected = (address) => {
    if (!address) return;
    const { formatted_address, address_components, geometry } = address; // geometry:{location:{lat(), lng()}}

    const postalCode = (
      (address_components || []).find((comp) => {
        return (comp.types || []).indexOf("postal_code") > -1;
      }) || {}
    ).long_name;

    this.props.onCustomerAddressSelected &&
      this.props.onCustomerAddressSelected(
        formatted_address,
        postalCode,
        geometry
      );
  };

  render() {
    const { lat, lng, width, maxHeight } = this.props;

    const AsyncMap = withScriptjs(
      withGoogleMap((props) => {
        return (
          <GoogleMap
            defaultZoom={15}
            defaultCenter={{ lat: lat || 0, lng: lng || 0 }}
          >
            {props.isMarkerShown && (
              <Marker position={{ lat: lat || 0, lng: lng || 0 }} />
            )}
          </GoogleMap>
        );
      })
    );

    return (
      <div
        style={{
          width: width,
          maxHeight: maxHeight,
          margin: "0",
          padding: "0",
        }}
      >
        {lat != null &&
        lng != null ? //       <div style={{ width: "100%", height: `100%` }} /> //     containerElement={ //     loadingElement={<div style={{ height: `100%` }} />} //     googleMapURL={`https://maps.googleapis.com/maps/api/js?key=${googleMapKey}&v=3.exp&libraries=geometry,drawing,places`} //     isMarkerShown //   <AsyncMap // > //   style={{ width: "100%", height: "100%", margin: "0", padding: "0" }} // <Segment
        //     }
        //     mapElement={<div style={{ height: `100%` }} />}
        //   />
        // </Segment>
        null : (
          <React.Fragment>
            <Header as="h4" textAlign="left">
              Delivery Address
            </Header>
            <Autocomplete
              style={{
                width: "100%",
                height: "40px",
                paddingLeft: "16px",
                marginTop: "2px",
                // marginBottom: "100px",
                border: "1px solid rgba(34,36,38,.15)",
                borderRadius: ".28571429rem",
                fontFamily: "Lato,'Helvetica Neue',Arial,Helvetica,sans-serif",
              }}
              onPlaceSelected={this.handleCustomerAddressSelected}
              types={["address"]}
              componentRestrictions={{ country: "us" }}
            />

            {this.props.needCustomerAddrMsgShow ? (
              <Message
                // style={{ padding: "0 10px" }}
                size="tiny"
                icon="warning sign"
                error={true}
                content="Please provide your delivery address."
              />
            ) : null}

            {this.props.deliveryOutRangeWarning ? (
              <Message warning size="tiny">
                <Message.Header>You are out of delivery range</Message.Header>
                <p>Dear customer, it can't be delivered to your location.</p>
              </Message>
            ) : null}
          </React.Fragment>
        )}
      </div>
    );
  }
}

export default GoogleMapPanel;
