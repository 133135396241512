import React, { Component } from "react";
import {
  Table,
  Segment,
  Label,
  Dimmer,
  Loader,
  Divider,
  Icon,
  Button,
  Transition,
  Header,
  Modal,
  Message,
  Input,
  Popup,
  Statistic,
} from "semantic-ui-react";

import { DateRange } from "react-date-range";
import moment from "moment";
import swal from "sweetalert";

import DigitInputSect from "./digitInputSect";
import OrderDb from "../dataModel/orderDb";

class OrderTimeNoticeModal extends Component {
  state = {
    selectedMinutes: null,
    inQuickMode: false,

    loading: false,
  };

  handleOrderTimeNoticeMinutesChange = (mins) => {
    const minsNumber = Number(mins);

    if (isNaN(minsNumber)) {
      return this.setState({
        selectedMinutes: null,
      });
    }

    if (minsNumber > 999) {
      return;
    }

    this.setState({
      selectedMinutes: mins,
    });
  };

  handleCloseClick = () => {
    this.props.onClose && this.props.onClose();
    this.setState({
      selectedMinutes: null,
    });
  };

  handleSendSMSClick = async () => {
    if (this.state.selectedMinutes == null) {
      return swal({
        title: "Fail to send SMS [Empty minutes selected]",
        text: "Please retry",
        icon: "error", // replace type with icon, if using new version
        timer: 1500,
        buttons: false,
      });
    }

    // const willSend = await swal({
    //   title: `Comfirm that the food will be ready in ${this.state.selectedMinutes} minutes ?`,
    //   icon: "warning",
    //   buttons: true,
    //   dangerMode: true,
    // });

    // if (!willSend) return;

    this.setState({
      loading: true,
    });
    this.props.onSendSMSClick &&
      (await this.props.onSendSMSClick(this.state.selectedMinutes));
    this.setState({
      loading: false,
    });
  };

  handleReadyClick = async () => {
    this.setState({
      loading: true,
    });
    this.props.onSendSMSClick && (await this.props.onSendSMSClick("0"));
    this.setState({
      loading: false,
    });
  };

  render() {
    const {
      customerAddrStr,
      customerName,
      orderMethod,
      open,
      onClose,
    } = this.props;
    const { selectedMinutes } = this.state;

    const timeQuickBtns = ["5", "10", "15", "20", "25", "30", "35", "40"].map(
      (time, index) => {
        return (
          <Button
            basic
            key={index}
            style={{
              margin: "10px",
              width: "calc(25% - 20px)",
              height: "120px",
            }}
            onClick={() => {
              this.handleOrderTimeNoticeMinutesChange(time);
              this.setState({
                inQuickMode: true,
              });
            }}
          >
            <Statistic>
              <Statistic.Value>{time}</Statistic.Value>
              <Statistic.Label>minutes</Statistic.Label>
            </Statistic>
          </Button>
        );
      }
    );

    return (
      // {/* -------------------  order Time Notice SMS modal ---------------  */}
      <Modal
        size="large"
        closeIcon
        dimmer={true}
        open={open}
        onClose={this.handleCloseClick}
      >
        <Dimmer inverted active={this.state.loading}>
          <Loader size="huge">Sending...</Loader>
        </Dimmer>

        <Modal.Header style={{ fontSize: "20px", fontWeight: "500" }}>
          <div style={{ display: "flex" }}>
            <div style={{ width: "150px" }}>
              <Button
                basic
                style={{
                  margin: "0",
                  padding: "0",
                  width: "120px",
                  height: "85px",
                }}
              >
                <Statistic color="teal">
                  <Statistic.Value>
                    {selectedMinutes != null ? selectedMinutes : "?"}
                  </Statistic.Value>
                  <Statistic.Label>minutes</Statistic.Label>
                </Statistic>
              </Button>
            </div>

            <div style={{ width: "calc(100% - 270px)", padding: "20px 0" }}>
              {orderMethod === "delivery"
                ? `When will this order be delivered to ${customerAddrStr}?`
                : `When will this order be ready for pickup?`}
              <span
                style={{
                  fontSize: "14px",
                  color: "#8a8a8a",
                  display: "block",
                }}
              >{`We will send an Text Message to ${customerName} regarding this information`}</span>
            </div>

            <div style={{ width: "120px" }}>
              <Button
                style={{
                  margin: "0",
                  padding: "0",
                  width: "120px",
                  height: "85px",
                }}
                className="sumi-global-btn-color"
                onClick={this.handleReadyClick}
              >
                Ready
              </Button>
            </div>
          </div>
        </Modal.Header>

        <Modal.Content style={{}}>
          <div style={{ display: "flex" }}>
            <div style={{ width: "40%", margin: "0 20px" }}>
              <div style={{ height: "calc(100%)" }}>
                <DigitInputSect
                  value={this.state.inQuickMode ? null : selectedMinutes}
                  onChange={(mins) => {
                    this.handleOrderTimeNoticeMinutesChange(mins);
                    this.setState({
                      inQuickMode: false,
                    });
                  }}
                />
              </div>
            </div>
            <div style={{ width: "60%" }}> {timeQuickBtns}</div>
          </div>
        </Modal.Content>

        <Modal.Actions>
          <Button onClick={this.handleCloseClick} style={{ height: "50px" }}>
            Cancel
          </Button>
          <Button
            className="sumi-global-btn-color"
            disabled={this.state.selectedMinutes == null}
            onClick={this.handleSendSMSClick}
            style={{ height: "50px" }}
          >
            Confirm
          </Button>
        </Modal.Actions>
      </Modal>
    );
  }
}

export default OrderTimeNoticeModal;
