import React, { Component } from "react";
import { Segment, Button, Icon } from "semantic-ui-react";

class DigitInputSect extends Component {
  handlePanelClick = (val) => {
    if (val >= "0" && val <= "9") {
      this.handleNumberClick(val);
    } else if (val === "C") {
      this.handleClearClick();
    } else {
      throw "Invalid input found through login panel";
    }
  };

  handleNumberClick = (num) => {
    // this.setState({ input: this.state.input + num });
    const val = this.props.value;
    this.props.onChange && this.props.onChange((val || "") + num);
  };

  handleClearClick = () => {
    // this.setState({ input: "" });
    this.props.onChange && this.props.onChange(null);
  };

  render() {
    const btnStyle = {
      height: "25%",
      width: "33%",
      fontSize: "xx-large",
      // margin: "1px 2px",
      margin: "0",
      padding: "0px",
      backgroundColor: "white",
      fontWeight: "300",
      // boxShadow: "0px 0px 5px 0px rgba(0,0,0,0.75)",
      position: "relative",
      color: "black",
      // opacity: "0.9",
      //   backgroundColor: "#003162",
      //   color: "white"

      // backgroundColor:
      //   "conic-gradient(#d7d7d7, #c3c3c3, #cccccc, #c6c6c6, #d3d3d3, #d8d8d8, #d5d5d5, #d8d8d8, #d3d3d3, #c5c5c5, #c0c0c0, #bfbfbf, #d0d0d0, #d9d9d9, #d1d1d1, #c5c5c5, #c8c8c8, #d7d7d7, #d5d5d5, #cdcdcd, #c4c4c4, #d9d9d9, #cecece, #c5c5c5, #c5c5c5, #cdcdcd, #d8d8d8, #d9d9d9, #d7d7d7) content-box, linear-gradient(#d4d4d4, #d4d4d4) padding-box, radial-gradient(rgba(#787878, .9), rgba(#787878, 0) 70%) 50% bottom / 80% .75*6.25em no-repeat border-box"
    };

    const numbericKeyboard = (
      <div style={{ width: "100%", height: "100%" }}>
        <Button style={btnStyle} onClick={() => this.handlePanelClick("1")}>
          <span>1</span>
        </Button>
        <Button style={btnStyle} onClick={() => this.handlePanelClick("2")}>
          2
        </Button>
        <Button style={btnStyle} onClick={() => this.handlePanelClick("3")}>
          3
        </Button>
        <br />
        <Button style={btnStyle} onClick={() => this.handlePanelClick("4")}>
          4
        </Button>
        <Button style={btnStyle} onClick={() => this.handlePanelClick("5")}>
          5
        </Button>
        <Button style={btnStyle} onClick={() => this.handlePanelClick("6")}>
          6
        </Button>
        <br />
        <Button style={btnStyle} onClick={() => this.handlePanelClick("7")}>
          7
        </Button>
        <Button style={btnStyle} onClick={() => this.handlePanelClick("8")}>
          8
        </Button>
        <Button style={btnStyle} onClick={() => this.handlePanelClick("9")}>
          9
        </Button>
        <br />
        <Button style={btnStyle}>
          <span style={{ color: "white" }}>*</span>
        </Button>
        <Button style={btnStyle} onClick={() => this.handlePanelClick("0")}>
          0
        </Button>
        <Button style={btnStyle} onClick={() => this.handlePanelClick("C")}>
          <Icon name="undo alternate" style={{ margin: "0" }} />
        </Button>
      </div>
    );

    return (
      <Segment
        textAlign="center"
        basic
        style={{
          margin: "0",
          padding: "0px",
          width: "100%",
          height: "100%",
        }}
      >
        {numbericKeyboard}
      </Segment>
    );
  }
}

export default DigitInputSect;
