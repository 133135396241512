import React, { Component } from "react";
import {
  Segment,
  Modal,
  Header,
  Form,
  Dimmer,
  Loader,
  Button,
} from "semantic-ui-react";

class ItemOptionAddingModal extends Component {
  state = { name_required_show: false };

  _isValid = (name) => {
    return (
      this.props.itemOption_added && this.props.itemOption_added[name] != null
    );
  };

  handleClose = () => {
    this.props.onClose && this.props.onClose();
  };

  handleItemOptionAddChange = (name, value) => {
    this.props.onItemOptionAddChange &&
      this.props.onItemOptionAddChange(name, value);
  };

  handleItemOptionAddSave = () => {
    if (!this._isValid("name")) {
      return this.setState({
        name_required_show: true,
      });
    }

    this.props.onItemOptionAddSave && this.props.onItemOptionAddSave();

    this.setState({
      name_required_show: false,
    });
  };

  render() {
    const { itemOption_added, loading } = this.props;

    return (
      <Modal
        closeIcon
        dimmer={true}
        open={!!itemOption_added}
        onClose={this.handleClose}
        className="goorder-online-itemDetail"
      >
        <Dimmer inverted active={!!loading}>
          <Loader size="huge" />
        </Dimmer>

        <Header as="h2" className="header">
          <Form>
            <Form.Group style={{ fontSize: "12px" }}>
              <Form.Input
                width={8}
                label="Option Name *"
                value={(itemOption_added && itemOption_added.name) || ""}
                onChange={(evt) => {
                  this.handleItemOptionAddChange("name", evt.target.value);
                }}
                error={this.state.name_required_show && !this._isValid("name")}
                onBlur={() => {
                  this.setState({
                    name_required_show: true,
                  });
                }}
                onFocus={() => {
                  this.setState({
                    name_required_show: false,
                  });
                }}
              />
              <Form.Input
                width={4}
                label="Min Number"
                value={
                  itemOption_added && itemOption_added.minNumberToSelect != null
                    ? itemOption_added.minNumberToSelect
                    : ""
                }
                // placeholder="Set 0 or leave it blank if not required"
                onChange={(evt) => {
                  this.handleItemOptionAddChange(
                    "minNumberToSelect",
                    evt.target.value
                  );
                }}
              />
              <Form.Input
                width={4}
                label="Max Number"
                value={
                  itemOption_added && itemOption_added.maxNumberToSelect != null
                    ? itemOption_added.maxNumberToSelect
                    : ""
                }
                // placeholder="Set 0 or leave it blank if not required"
                onChange={(evt) => {
                  this.handleItemOptionAddChange(
                    "maxNumberToSelect",
                    evt.target.value
                  );
                }}
              />
              {/* <Form.Input
                width={8}
                label="Number to Select"
                value={
                  itemOption_added && itemOption_added.numberToSelect != null
                    ? itemOption_added.numberToSelect
                    : ""
                }
                placeholder="Set 0 or leave it blank if not required"
                onChange={(evt) => {
                  this.handleItemOptionAddChange(
                    "numberToSelect",
                    evt.target.value
                  );
                }}
              /> */}
            </Form.Group>
          </Form>
        </Header>

        {/* ----------------------------  bottom --------------------- */}
        <Segment className="footer" raised>
          <Button
            className="sumi-global-btn-color"
            style={{
              width: "100px",
              height: "50px",
              margin: "10px 10px 10px 0",
              float: "right",
            }}
            onClick={this.handleItemOptionAddSave}
          >
            Add
          </Button>
          <Button
            style={{
              width: "100px",
              height: "50px",
              margin: "10px 10px 10px 0",
              float: "right",
            }}
            onClick={this.handleClose}
          >
            Cancel
          </Button>
        </Segment>
      </Modal>
    );
  }
}

export default ItemOptionAddingModal;
