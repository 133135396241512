const axios = require("axios");

const TSYS_ROUTE_URL = "/api/tsyses";

export default class TsysDb {
  /*
        creditcardInfo:{
            acount:String,
            expiry:String,
            cvv2:String,
            postal:String,
            billingAddr:String
        }

        return: {
            approved:Boolean,
            amount:Number,
            merchantId:String
            cardNumber:String,
            cardExpiry:String,
            cardType:String,
            authCode:String,
            retref:String
        }
    */
  static async authorizePayment(
    merchantId,
    amount,
    creditcardInfo,
    recaptchaToken
  ) {
    try {
      if (!merchantId)
        return console.error("Invalid merchantId(tsys.authorizePaymenta)");

      if (!creditcardInfo)
        return console.error(
          "Null creditcard info provided(tsys.authorizePayment)"
        );

      if (!recaptchaToken) {
        return console.error(`Unauthorized, Invalid Token:[${recaptchaToken}]`);
      }

      const {
        acount,
        expiry: expiry_MMYY,
        cvv2,
        postal,
        billingAddr,
      } = creditcardInfo;

      if (
        acount == null ||
        amount == null ||
        expiry_MMYY == null ||
        cvv2 == null ||
        billingAddr == null
      ) {
        return { error: "Missing required field" };
      }

      // convert number to decimal string
      let decimal = amount.toFixed(2);
      const amount_tsys = decimal.replace(".", "");
      //console.log(123, decimal);

      // convert expiry from MMYY to YYMM
      const expiry_YYMM =
        expiry_MMYY.substring(2, 4) + expiry_MMYY.substring(0, 2);

      return (
        (await axios.post(`${TSYS_ROUTE_URL}/merchantId/${merchantId}/auth`, {
          acount: acount,
          expiry: expiry_YYMM,
          cvv2: cvv2,
          amount: amount_tsys,
          postal: postal,
          billingAddr,
          recaptchaToken,
        })) || {}
      ).data;
    } catch (ex) {
      console.error(ex);
    }
  }

  // void
  /*
        return: {
            approved:Boolean,
            amount:Number,
            merchantId:String
            retref:String,
            respcode:String,
        }
    */
  static async voidPayment(merchantId, retref) {
    try {
      if (!merchantId) return console.error("Invalid merchantId(voidPayment)");
      if (!retref) return console.error("Null retref info provided");

      return (
        (await axios.post(`${TSYS_ROUTE_URL}/merchantId/${merchantId}/void`, {
          retref: retref,
        })) || {}
      ).data;
    } catch (ex) {
      console.error(ex);
    }
  }
}
