import React, { Component } from "react";
import { Icon } from "semantic-ui-react";

class CategoryListScrollbarModal extends Component {
  handleCategoryListScroll = (evt) => {
    this.props.onCategoryListScroll && this.props.onCategoryListScroll(evt);
  };

  handleCategoryChange = (category) => {
    this.props.onCategoryChange && this.props.onCategoryChange(category);
  };

  render() {
    const {
      showLeftScrollingSign,
      showRightScrollingSign,
      categories,
      category_selected,
    } = this.props;

    return (
      <div
        className="category-scrolling-bar"
        style={{ width: "calc(100% -90px) !important" }}
      >
        {/* </div> */}
        <div
          className="category-list-scroll-bar"
          id="category-list-icon"
          style={{ maxHeight: "95vh", width: "100%!imporatant" }}
        >
          {(categories || []).map((category, index) => {
            const selected =
              category_selected && category._id === category_selected._id;

            return (
              <div key={index + "_Modal"}>
                <a
                  className="category"
                  style={{
                    borderBottom: selected
                      ? "4px solid rgb(219, 40, 40)"
                      : "none",
                    color: selected ? "rgb(219, 40, 40)" : "rgb(0, 49, 98)",
                    // : "rgb(118, 118, 118)",
                  }}
                  href={category && "#" + category._id}
                  onClick={() => this.handleCategoryChange(category)}
                >
                  {category.name}
                </a>
              </div>
            );
          })}
        </div>
      </div>
    );
  }
}

export default CategoryListScrollbarModal;
