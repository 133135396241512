import React, { Component } from "react";
import {
  Table,
  Segment,
  Label,
  Dimmer,
  Loader,
  Divider,
  Icon,
  Button,
  Transition,
  Header,
  Modal,
  Message,
  Input,
  Popup,
  Statistic,
} from "semantic-ui-react";

import { DateRange } from "react-date-range";
import moment from "moment";
import swal from "sweetalert";

import TransactionDb from "./../dataModel/transactionDb";
// import CardPointeDb from "./../dataModel/cardPointeDb";
import ECommDb from "./../dataModel/ecommDb";
import OrderDb from "./../dataModel/orderDb";
import DateTimeDb from "./../dataModel/dateTimeDb";
import OrderTimeNoticeModal from "./orderTimeNoticeModal";

import {
  formatDateMonthDayYear,
  getMonthName,
  getDate,
  formatDateHourMin,
  formatPrice,
  countOrderItems,
  formatPhoneNumber,
  preProcessDateRange,
  getWeekDateRange,
  getMonthDateRange,
  getYearDateRange,
  calOrdersPaidByCreditcard,
  calOrders,
  isCurrentDayOrder,
  calOrdersPaidByCash,
  isToday,
} from "../dataModel/utils";

import GoogleMapPanel from "./googleMapPanel";
import Geocode from "react-geocode";

const googleMapKey = "AIzaSyCi5MqSwmmuTW1R-Axt3dWOabhZUx386tg";
Geocode.setApiKey(googleMapKey);

class OrderRecallPanel extends Component {
  PRE_DEFINED_DATERANGE = {
    TODAY: 0,
    THIS_WEEK: 1,
    THIS_MONTH: 2,
    THIS_YEAR: 3,
  };

  state = {
    showPrintOptions: false,
    showDeliveryInfo: false,
    showOrdersSummary: false,
    showDailySaleReport: false,
    showTipsAdjustModal: false,

    preDefinedDateRange: this.PRE_DEFINED_DATERANGE.TODAY,
    startDatePickSelected: false,
    dateRangeDisplay: false,

    adjustedTips: null,

    lat: null,
    lng: null,

    loading: true,

    showOrderTimeNoticeModal: false,

    orderTimeNoticeMinutes: null,
  };

  getDateStrInfo = (date) => {
    if (!date) return null;

    const monthName = getMonthName(date);
    const dateName = getDate(date);
    // const time = formatDateHourMin(date);
    const dateTimeStr = DateTimeDb.getDateObj(date).dateTimeStr;
    return dateTimeStr;
  };

  handlePrintOptionsCloseClick = () => {
    this.setState({
      showPrintOptions: false,
    });
  };

  handlePrintOptionsOpenClick = () => {
    this.setState({
      showPrintOptions: true,
    });
  };

  handleAfterPrintToKitchen = () => {
    this.setState({
      showPrintOptions: false,
    });

    this.props.onAfterPrintToKitchen && this.props.onAfterPrintToKitchen();
  };

  handleAfterPrintToCustomer = () => {
    this.setState({
      showPrintOptions: false,
    });
  };

  // -------------------------- date range -----------------------------

  handlePreDefinedDateRangeClick = async (dateRange) => {
    try {
      this.props.onDateSelect &&
        this.props.onDateSelect(preProcessDateRange(dateRange));

      this.setState({
        dateRangeDisplay: false,
      });
    } catch (err) {
      console.error(err);
    }
  };

  handleTodayBtnClick = () => {
    if (this.props.loading) return;

    this.handlePreDefinedDateRangeClick({
      startDate: new Date(),
      endDate: new Date(),
    });
    this.setState({ preDefinedDateRange: this.PRE_DEFINED_DATERANGE.TODAY });
  };

  handleThisWeekBtnClick = () => {
    if (this.props.loading) return;

    this.handlePreDefinedDateRangeClick(getWeekDateRange());
    this.setState({
      preDefinedDateRange: this.PRE_DEFINED_DATERANGE.THIS_WEEK,
    });
  };

  handleThisMonthBtnClick = () => {
    if (this.props.loading) return;

    this.handlePreDefinedDateRangeClick(getMonthDateRange());
    this.setState({
      preDefinedDateRange: this.PRE_DEFINED_DATERANGE.THIS_MONTH,
    });
  };

  handleThisYearBtnClick = () => {
    if (this.props.loading) return;

    this.handlePreDefinedDateRangeClick(getYearDateRange());
    this.setState({
      preDefinedDateRange: this.PRE_DEFINED_DATERANGE.THIS_YEAR,
    });
  };

  handleDateRangeBtnClick = () => {
    if (this.props.loading) return;

    if (this.state.dateRangeDisplay == true) {
      this.setState({
        dateRangeDisplay: false,
      });
    } else {
      this.setState({
        dateRangeDisplay: true,
      });
    }
  };

  handleDateSelect = async (dateRange) => {
    try {
      if (!this.state.startDatePickSelected) {
        return this.setState({
          startDatePickSelected: true,
        });
      }

      this.props.onDateSelect &&
        this.props.onDateSelect(preProcessDateRange(dateRange));

      this.setState({
        preDefinedDateRange: null,
        startDatePickSelected: false,
        dateRangeDisplay: false,
      });
    } catch (err) {
      console.error(err);
    }
  };

  handleDeliveryInfoCloseClick = () => {
    this.setState({
      showDeliveryInfo: false,
    });
  };

  handleDeliveryInfoClick = async () => {
    const { order_selected } = this.props;
    const { customerAddrStr } = order_selected || {};

    if (!customerAddrStr) return;

    const response =
      customerAddrStr && (await Geocode.fromAddress(customerAddrStr));
    const location = response && response.results[0].geometry.location;
    const { lat, lng } = location || {};

    this.setState({
      showDeliveryInfo: true,
      lat,
      lng,
    });
  };

  // ---------------- show orders summary modal -------------------
  handleOrderSummaryStatClick = () => {
    this.setState({
      showOrdersSummary: true,
    });
  };

  handleAdjustTipsClick = async () => {
    this.setState({
      showTipsAdjustModal: true,
    });
  };

  handleAdjustedTipsChange = (val) => {
    if (Number.isNaN(Number(val))) return;

    this.setState({
      adjustedTips: val,
    });
  };

  handleAdjustTipsSubmitClick = async () => {
    try {
      const adjustedTips =
        this.state.adjustedTips != null && Number(this.state.adjustedTips);

      if (adjustedTips == null || Number.isNaN(adjustedTips)) {
        return swal({
          title: "Adjusted Tips can not be empty",
          text: "Please retry",
          icon: "error", // replace type with icon, if using new version
          timer: 1500,
          buttons: false,
        });
      }

      const willSubmit = await swal({
        title: "The tips amount will be changed. Continue ?",
        icon: "warning",
        buttons: true,
        dangerMode: true,
      });

      if (!willSubmit) {
        return;
      }

      const selectedOrder = this.props.order_selected;
      if (!selectedOrder) {
        throw "Null order";
      }

      if (!selectedOrder.transactionId) {
        throw "Null transactionId";
      }

      const transaction = await TransactionDb.getTransaction(
        this.props.merchantId,
        selectedOrder.orderId
      );

      if (!transaction) {
        throw "Null transaction fetched";
      }

      const newTotalAmt =
        selectedOrder.total - (selectedOrder.tips || 0) + adjustedTips;

      const retref = transaction.retref;
      // const captureRes = await CardPointeDb.capturePayment(
      //   this.props.merchantId,
      //   newTotalAmt,
      //   retref
      // );
      const adjustRes = await ECommDb.adjustPayment(
        this.props.merchantId,
        newTotalAmt,
        retref,
        adjustedTips
      );

      if (adjustRes && adjustRes.approved) {
        swal({
          title: "Adjustment successfully",
          icon: "success", // replace type with icon, if using new version
          timer: 1000,
          buttons: false,
        });

        // -----------------  update order and transaction table ------------------------
        const updatedOrder = await OrderDb.updateOrderByIdOrder(
          this.props.merchantId,
          selectedOrder._id,
          {
            total: newTotalAmt,
            tips: adjustedTips,
          }
        );

        !updatedOrder &&
          swal({
            title: "Fail to update order.",
            icon: "error", // replace type with icon, if using new version
            timer: 1000,
            buttons: false,
          });

        const updatedTransaction = await TransactionDb.updateTransactionAmt(
          this.props.merchantId,
          selectedOrder.transactionId,
          newTotalAmt
        );
        !updatedTransaction &&
          swal({
            title: "Fail to update transaction.",
            icon: "error", // replace type with icon, if using new version
            timer: 1000,
            buttons: false,
          });

        // ---------------- update current panel ----------------------
        this.props.onTipAdjustmentComplete &&
          this.props.onTipAdjustmentComplete();

        this.setState({
          showTipsAdjustModal: false,
          adjustedTips: null,
        });
      } else {
        return swal({
          title: "Fail to adjust tips amount",
          text: "Please try later",
          icon: "error", // replace type with icon, if using new version
          timer: 1500,
          buttons: false,
        });
      }
    } catch (ex) {
      return swal({
        title: "Internal error [" + ex + "]",
        text: "Contact our support number.",
        icon: "error", // replace type with icon, if using new version
        timer: 1500,
        buttons: false,
      });
    }
  };

  handleVoidOrderClick = async () => {
    try {
      const selectedOrder = this.props.order_selected;
      if (!selectedOrder) {
        throw "Null order";
      }

      if (!selectedOrder.transactionId) {
        throw "Null transactionId";
      }

      const transaction = await TransactionDb.getTransaction(
        this.props.merchantId,
        selectedOrder.orderId
      );

      if (!transaction) {
        throw "Null transaction fetched";
      }

      //voidPayment
      const willVoid = await swal({
        title:
          "The transaction #" +
          selectedOrder.transactionId +
          " will be void. Continue ?",
        icon: "warning",
        buttons: true,
        dangerMode: true,
      });

      if (!willVoid) {
        return;
      }

      // const voidRes = await CardPointeDb.voidPayment(
      //   this.props.merchantId,
      //   transaction.retref
      // );
      const voidRes = await ECommDb.voidPayment(
        this.props.merchantId,
        transaction.retref
      );

      console.log(voidRes);

      if (voidRes && voidRes.approved) {
        swal({
          title: "Order void successfully",
          icon: "success", // replace type with icon, if using new version
          timer: 1000,
          buttons: false,
        });

        // -----------------  update order and transaction table ------------------------
        const updatedOrder = await OrderDb.updateOrderByIdOrder(
          this.props.merchantId,
          selectedOrder._id,
          {
            hasVoid: true,
          }
        );

        !updatedOrder &&
          swal({
            title: "Fail to update order.",
            icon: "error", // replace type with icon, if using new version
            timer: 1000,
            buttons: false,
          });

        const updatedTransaction = await TransactionDb.voidTransaction(
          this.props.merchantId,
          selectedOrder.transactionId
        );
        !updatedTransaction &&
          swal({
            title: "Fail to update transaction.",
            icon: "error", // replace type with icon, if using new version
            timer: 1000,
            buttons: false,
          });

        // ---------------- update current panel ----------------------
        this.props.onVoidOrderComplete && this.props.onVoidOrderComplete();
      } else {
        return swal({
          title: "Fail to void order",
          text: "Please try later",
          icon: "error", // replace type with icon, if using new version
          timer: 1500,
          buttons: false,
        });
      }
    } catch (ex) {
      return swal({
        title: "Internal error [" + ex + "]",
        text: "Contact our support number.",
        icon: "error", // replace type with icon, if using new version
        timer: 1500,
        buttons: false,
      });
    }
  };

  handleOrderBriefClick = (order) => {
    this.props.onOrderClick && this.props.onOrderClick(order);
  };

  handleViewDailyReportClick = async () => {
    this.setState({
      loading: true,
      showDailySaleReport: true,
    });
    const dailySaleObjs = await OrderDb.getOrdersByQueryWithinDateRange_daily(
      this.props.merchantId,
      null,
      this.props.dateRange
    );

    this.setState({
      dailySaleObjs,
      loading: false,
    });
  };

  handleDailyReportCloseClick = () => {
    this.setState({
      showDailySaleReport: false,
    });
  };

  handleSendTimeNoticeClick = () => {
    this.setState({
      showOrderTimeNoticeModal: true,
    });
  };

  handleSendTimeNoticeClose = () => {
    this.setState({
      showOrderTimeNoticeModal: false,
    });
  };

  handleSendOrderTimeReadySMSClick = async (readyInMinutes) => {
    const { merchantId, order_selected } = this.props;

    swal({
      title: "Sending Text Message...",
      icon: "success", // replace type with icon, if using new version
      timer: 1000,
      buttons: false,
    });
    this.handleSendTimeNoticeClose();

    const updatedOrder = await OrderDb.sendOrderReadyMinutesSMS(
      merchantId,
      order_selected._id,
      readyInMinutes
    );

    if (updatedOrder && updatedOrder.hasSentOrderTimeSMS) {
      this.props.onRefresh && this.props.onRefresh();
      // swal({
      //   title: "Text Message sent",
      //   icon: "success", // replace type with icon, if using new version
      //   timer: 1000,
      //   buttons: false,
      // });
    } else {
      swal({
        title: "Fail to send Text Message",
        text: "Please try later",
        icon: "error", // replace type with icon, if using new version
        timer: 1500,
        buttons: false,
      });
    }
  };

  orderItemOptionRemap = (orderItemOption) => {
    if (orderItemOption) {
      let remapDic = {};
      for (let i = 0; i < orderItemOption.length; i++) {
        let itemOptionId = orderItemOption[i]._id;
        if (itemOptionId in remapDic) {
          remapDic[itemOptionId].original_index.push(i);
          remapDic[itemOptionId].qty = remapDic[itemOptionId].qty + 1;
        } else {
          orderItemOption[i]["qty"] = 1;
          orderItemOption[i]["original_index"] = [i];
          remapDic[itemOptionId] = orderItemOption[i];
        }
      }
      let finalReMap = [];
      for (let j in remapDic) {
        finalReMap.push(remapDic[j]);
      }
      return finalReMap;
    } else {
      return orderItemOption;
    }
  };

  // handleOrderTimeNoticeMinutesChange = (mins) => {
  //   const minsNumber = Number(mins);

  //   if (isNaN(minsNumber)) {
  //     return this.setState({
  //       orderTimeNoticeMinutes: null,
  //     });
  //   }

  //   if (minsNumber > 999) {
  //     return;
  //   }

  //   this.setState({
  //     orderTimeNoticeMinutes: mins,
  //   });
  // };

  render() {
    const { lat, lng, showOrderTimeNoticeModal } = this.state;
    const {
      orders,
      order_selected,
      loading,
      dateRange,
      enableOrderTimeConfirmSMS,
      roomService,
      businessInfo,
    } = this.props;

    const {
      subtotal,
      tips,
      fixedTips,
      total,
      discountAmt,
      tax,
      phone,
      hasPrintedToKitchen,
      customerAddrStr,
      customerName,
      orderMethod,
      dineinTableName,
      deliveryNote,
      paymentMethod,
      paid,
      carMakeModel,
      carColor,
      processingFeeAmt,
      serviceFeeAmt,
      deliveryFee,
      roomServiceDeliveryFee,
      scheduleTimeStr,
      scheduleDate,
      hasSentOrderTimeSMS,
      willBeReadyInMinutes,
      orderSaveDate,
      roomServiceRoom,
      isBranch,
    } = order_selected || {};

    const tipsAmt = (tips || 0) + (fixedTips || 0);
    const deliveryFeeAmt = (deliveryFee || 0) + (roomServiceDeliveryFee || 0);

    const orderSaleObj_creditcard = calOrdersPaidByCreditcard(orders);
    const orderSaleObj = calOrders(orders);
    const orderSaleObj_cash = calOrdersPaidByCash(orders);

    // console.log(123, orderSaleObj_cash);

    const isCurrentOrder = isCurrentDayOrder(order_selected);
    const hasVoidOrder = order_selected && !!order_selected.hasVoid;

    const orderBriefSegmnts = (orders || []).map((order, index) => {
      const {
        dailyOrderId,
        orderId,
        orderSaveDate,
        hasPrintedToKitchen,
        phone,
        orderMethod,
        dineinTableName,
        hasVoid,
        hasBeenRead,
        roomServiceRoom,
      } = order;

      const itemCount = countOrderItems(order);
      const orderDateInfo = this.getDateStrInfo(orderSaveDate);

      // console.log(123, orderMethod);

      return (
        <Segment
          inverted={!(order_selected && order_selected._id === order._id)}
          key={index}
          size="big"
          compact
          textAlign="left"
          className="item"
          onClick={() => this.handleOrderBriefClick(order)}
        >
          {/* <Label as="a" attached="right" color="green"> */}
          {/* <div style={{ margin: "2px 9px 5px 25px" }}>5 min</div> */}

          {hasBeenRead ? null : (
            <Label corner="right" color="teal" icon="bell outline"></Label>
          )}

          <div className="no-margin">
            <span
              style={{
                marginRight: "10px",
                // fontSize: "12px",
                fontWeight: "800",
              }}
            >
              #{dailyOrderId || orderId}
            </span>

            {!!hasVoid ? (
              <Icon
                name="minus circle"
                color="red"
                style={{ fontSize: "small", display: "inline" }}
              ></Icon>
            ) : null}

            {orderMethod === "delivery" ? (
              <Icon
                name="truck"
                style={{ fontSize: "small", display: "inline" }}
                inverted={!(order_selected && order_selected._id === order._id)}
              ></Icon>
            ) : orderMethod === "pickup" ? (
              <Icon
                name="hand paper"
                style={{ fontSize: "small", display: "inline" }}
                inverted={!(order_selected && order_selected._id === order._id)}
              ></Icon>
            ) : orderMethod === "curbside" ? (
              <Icon
                name="map pin"
                style={{ fontSize: "small", display: "inline" }}
                inverted={!(order_selected && order_selected._id === order._id)}
              ></Icon>
            ) : orderMethod === "roomServiceDelivery" ? (
              <Icon
                name="heart"
                style={{ fontSize: "small", display: "inline" }}
                inverted={!(order_selected && order_selected._id === order._id)}
              ></Icon>
            ) : null}

            {hasPrintedToKitchen ? (
              <Icon
                name="send"
                style={{ fontSize: "small" }}
                inverted={!(order_selected && order_selected._id === order._id)}
              ></Icon>
            ) : null}
            <span
              // style={{ margin: "0 10px", fontSize: "12px" }}
              style={{ margin: "0 10px" }}
              className="blockdisplay-if-small"
            >
              {formatPhoneNumber(phone)}
            </span>
          </div>
          <div className="no-margin">
            {itemCount} items
            <span style={{ margin: "0 20px", float: "right" }}>
              {orderDateInfo}
            </span>
          </div>
        </Segment>
      );
    });

    const orderDetailTableRows =
      order_selected &&
      (order_selected.orderItems || []).map((orderedItem, index) => {
        const { name, price, qty, orderItemOptions, note, size } = orderedItem;

        let itemTotalPrice = price;
        (orderItemOptions || []).forEach((ov) => {
          itemTotalPrice += ov.price;
        });
        itemTotalPrice = itemTotalPrice * Number(qty || 0);

        const orderItemOptionsRemap =
          this.orderItemOptionRemap(orderItemOptions);
        return (
          <Table.Row key={index} verticalAlign="top">
            <Table.Cell>
              <b>{qty}</b>
            </Table.Cell>

            <Table.Cell>
              <b>{size ? name + "(" + size + ")" : name}</b>
              {(orderItemOptionsRemap || []).map((itemOption, optionIndex) => {
                return (
                  <React.Fragment key={optionIndex}>
                    <br />
                    <span
                      style={{
                        fontSize: "smaller",
                        color: "rgb(125, 125, 125)",
                      }}
                    >
                      {itemOption.qty > 1 ? itemOption.qty + " x " : null}
                      {itemOption.name}
                    </span>
                  </React.Fragment>
                );
              })}
            </Table.Cell>
            <Table.Cell>
              <b>${formatPrice(itemTotalPrice)}</b>
            </Table.Cell>

            <Table.Cell style={{ fontSize: "smaller" }}>{note}</Table.Cell>
          </Table.Row>
        );
      });

    return (
      <div style={{ width: "100%", height: "100%" }}>
        {/* -------------------  order Time Notice SMS modal ---------------  */}
        <OrderTimeNoticeModal
          open={!!showOrderTimeNoticeModal}
          onClose={this.handleSendTimeNoticeClose}
          customerAddrStr={customerAddrStr}
          customerName={customerName}
          orderMethod={orderMethod}
          onSendSMSClick={this.handleSendOrderTimeReadySMSClick}
        />

        {/* -------------------  delivery info modal ---------------  */}
        <Modal
          size="large"
          closeIcon
          dimmer={true}
          open={this.state.showDeliveryInfo}
          onClose={this.handleDeliveryInfoCloseClick}
        >
          <Modal.Header>{customerAddrStr}</Modal.Header>

          {deliveryNote ? (
            <Message color="orange" style={{ margin: "0" }}>
              <Message.Header>Delivery note</Message.Header>
              <p>{deliveryNote}</p>
            </Message>
          ) : null}

          <Modal.Content style={{ padding: "0" }}>
            <GoogleMapPanel
              width={"100%"}
              height={"500px"}
              lat={lat}
              lng={lng}
            />
          </Modal.Content>
        </Modal>
        {/* ------------------- order summary details ---------------  */}
        <Modal
          size="large"
          closeIcon
          dimmer={true}
          open={this.state.showOrdersSummary}
          onClose={() => {
            this.setState({
              showOrdersSummary: false,
            });
          }}
        >
          <Modal.Header style={{ fontSize: "medium" }}>
            Sale detail from
            <span
              style={{ fontSize: "larger", margin: "0 10px", color: "#003161" }}
            >
              {dateRange
                ? `${DateTimeDb.getDateObj(dateRange.startDate).dateStr} - ${
                    DateTimeDb.getDateObj(dateRange.endDate).dateStr
                  }`
                : "All Period"}
            </span>
            <Button
              size="mini"
              style={{ float: "right" }}
              className="sumi-global-btn-color"
              onClick={this.handleViewDailyReportClick}
            >
              View Daily Report
            </Button>
          </Modal.Header>

          <Modal.Content
            style={{ padding: "10px 5px", fontFamily: `"Rubik", sans-serif` }}
          >
            <Table definition>
              <Table.Header>
                <Table.Row textAlign="right">
                  <Table.HeaderCell />
                  <Table.HeaderCell>Subtotal</Table.HeaderCell>
                  <Table.HeaderCell>Tax</Table.HeaderCell>
                  <Table.HeaderCell>Tips</Table.HeaderCell>
                  <Table.HeaderCell>Discount</Table.HeaderCell>
                  <Table.HeaderCell>Processing</Table.HeaderCell>
                  <Table.HeaderCell>Service</Table.HeaderCell>
                  <Table.HeaderCell>Delivery</Table.HeaderCell>
                  <Table.HeaderCell>Total Sale</Table.HeaderCell>
                  <Table.HeaderCell>Orders</Table.HeaderCell>
                </Table.Row>
              </Table.Header>
              <Table.Body>
                <Table.Row textAlign="right">
                  <Table.Cell>Paid by Creditcard</Table.Cell>
                  <Table.Cell>
                    ${formatPrice(orderSaleObj_creditcard.subtotal)}
                  </Table.Cell>
                  <Table.Cell>
                    ${formatPrice(orderSaleObj_creditcard.tax)}
                  </Table.Cell>
                  <Table.Cell>
                    ${formatPrice(orderSaleObj_creditcard.tips)}
                  </Table.Cell>
                  <Table.Cell negative>
                    -${formatPrice(orderSaleObj_creditcard.discountAmt)}
                  </Table.Cell>
                  <Table.Cell>
                    ${formatPrice(orderSaleObj_creditcard.processingFee)}
                  </Table.Cell>
                  <Table.Cell>
                    ${formatPrice(orderSaleObj_creditcard.serviceFeeAmt)}
                  </Table.Cell>
                  <Table.Cell>
                    ${formatPrice(orderSaleObj_creditcard.deliveryFee)}
                  </Table.Cell>
                  <Table.Cell>
                    ${formatPrice(orderSaleObj_creditcard.total)}
                  </Table.Cell>
                  <Table.Cell>{orderSaleObj_creditcard.number}</Table.Cell>
                </Table.Row>

                <Table.Row textAlign="right">
                  <Table.Cell>Other</Table.Cell>
                  <Table.Cell>
                    ${formatPrice(orderSaleObj_cash.subtotal)}
                  </Table.Cell>
                  <Table.Cell>${formatPrice(orderSaleObj_cash.tax)}</Table.Cell>
                  <Table.Cell>
                    ${formatPrice(orderSaleObj_cash.tips)}
                  </Table.Cell>
                  <Table.Cell negative>
                    -${formatPrice(orderSaleObj_cash.discountAmt)}
                  </Table.Cell>
                  <Table.Cell>
                    ${formatPrice(orderSaleObj_cash.processingFee)}
                  </Table.Cell>
                  <Table.Cell>
                    ${formatPrice(orderSaleObj_cash.serviceFeeAmt)}
                  </Table.Cell>
                  <Table.Cell>
                    ${formatPrice(orderSaleObj_cash.deliveryFee)}
                  </Table.Cell>
                  <Table.Cell>
                    ${formatPrice(orderSaleObj_cash.total)}
                  </Table.Cell>
                  <Table.Cell>{orderSaleObj_cash.number}</Table.Cell>
                </Table.Row>

                <Table.Row
                  textAlign="right"
                  style={{ fontWeight: "600", backgroundColor: "#f9fafb" }}
                >
                  <Table.Cell>TOTAL</Table.Cell>
                  <Table.Cell>${formatPrice(orderSaleObj.subtotal)}</Table.Cell>
                  <Table.Cell>${formatPrice(orderSaleObj.tax)}</Table.Cell>
                  <Table.Cell>${formatPrice(orderSaleObj.tips)}</Table.Cell>
                  <Table.Cell negative>
                    -${formatPrice(orderSaleObj.discountAmt)}
                  </Table.Cell>
                  <Table.Cell>
                    ${formatPrice(orderSaleObj.processingFee)}
                  </Table.Cell>
                  <Table.Cell>
                    ${formatPrice(orderSaleObj.serviceFeeAmt)}
                  </Table.Cell>
                  <Table.Cell>
                    ${formatPrice(orderSaleObj.deliveryFee)}
                  </Table.Cell>
                  <Table.Cell>${formatPrice(orderSaleObj.total)}</Table.Cell>
                  <Table.Cell>{orderSaleObj.number}</Table.Cell>
                </Table.Row>
              </Table.Body>
            </Table>
          </Modal.Content>
        </Modal>
        {/* ------------------- daily sales report ---------------  */}
        <Modal
          size="large"
          closeIcon
          dimmer={true}
          open={this.state.showDailySaleReport}
          onClose={this.handleDailyReportCloseClick}
        >
          <Modal.Content
            style={{ padding: "10px 5px", fontFamily: `"Rubik", sans-serif` }}
          >
            <Dimmer inverted active={this.state.loading}>
              <Loader size="huge" />
            </Dimmer>

            <Table definition size="small" striped>
              <Table.Header>
                <Table.Row
                  textAlign="right"
                  style={{
                    display: "table",
                    tableLayout: "fixed",
                    width: "100%",
                  }}
                >
                  <Table.HeaderCell />
                  <Table.HeaderCell>Subtotal</Table.HeaderCell>
                  <Table.HeaderCell>Tax</Table.HeaderCell>
                  <Table.HeaderCell>Tips</Table.HeaderCell>
                  <Table.HeaderCell negative>Discount</Table.HeaderCell>
                  <Table.HeaderCell>Processing</Table.HeaderCell>
                  <Table.HeaderCell>Service</Table.HeaderCell>
                  <Table.HeaderCell>Delivery</Table.HeaderCell>
                  <Table.HeaderCell>Total Sale</Table.HeaderCell>
                  <Table.HeaderCell>Orders</Table.HeaderCell>
                </Table.Row>
              </Table.Header>
              <Table.Body
                style={{
                  display: "block",
                  maxHeight: "calc(100vh - 250px)",
                  overflow: "auto",
                }}
              >
                {(this.state.dailySaleObjs || []).map((saleObj, index) => {
                  const {
                    _id: date,
                    subTotal,
                    tax,
                    tips,
                    discountAmt,
                    processingFee,
                    serviceFeeAmt,
                    deliveryFee,
                    total,
                    orders,
                  } = saleObj;

                  return (
                    <Table.Row
                      key={index}
                      textAlign="right"
                      style={{
                        display: "table",
                        tableLayout: "fixed",
                        width: "100%",
                      }}
                    >
                      <Table.Cell textAlign="left">{date}</Table.Cell>
                      <Table.Cell>${formatPrice(subTotal)}</Table.Cell>
                      <Table.Cell>${formatPrice(tax)}</Table.Cell>
                      <Table.Cell>${formatPrice(tips)}</Table.Cell>
                      <Table.Cell negative>
                        -${formatPrice(discountAmt)}
                      </Table.Cell>
                      <Table.Cell>${formatPrice(processingFee)}</Table.Cell>
                      <Table.Cell>${formatPrice(serviceFeeAmt)}</Table.Cell>
                      <Table.Cell>${formatPrice(deliveryFee)}</Table.Cell>
                      <Table.Cell>${formatPrice(total)}</Table.Cell>
                      <Table.Cell>{orders || 0}</Table.Cell>
                    </Table.Row>
                  );
                })}
              </Table.Body>
            </Table>

            {(this.state.dailySaleObjs || []).length > 0 ? null : (
              <Header as="h1" textAlign="center">
                No Data Found
              </Header>
            )}
          </Modal.Content>
        </Modal>
        {/* ------------------- adjust tips ---------------  */}
        <Modal
          size="tiny"
          dimmer={true}
          open={this.state.showTipsAdjustModal}
          onClose={() => {
            this.setState({
              showTipsAdjustModal: false,
            });
          }}
        >
          <Modal.Header>
            Tips adjustment for Transaction #
            {order_selected && order_selected.transactionId}
          </Modal.Header>

          <Modal.Content style={{ padding: "0" }}>
            <Segment>
              Adjust tips from
              <span
                style={{
                  fontSize: "20px",
                  fontWeight: "600",
                  margin: "0 10px",
                }}
              >
                {order_selected && formatPrice(order_selected.tips)}
              </span>
              to
              <Input
                type="text"
                labelPosition="left"
                style={{ margin: "0 10px" }}
                value={this.state.adjustedTips || ""}
                onChange={(evt) => {
                  this.handleAdjustedTipsChange(evt.target.value);
                }}
              >
                <Label basic>
                  <Icon name="dollar" style={{ margin: "0" }} />
                </Label>
                <input />
              </Input>
            </Segment>
          </Modal.Content>
          <Modal.Actions>
            <Button
              onClick={() => {
                this.setState({
                  showTipsAdjustModal: false,
                });
              }}
            >
              Cancel
            </Button>
            <Button
              onClick={this.handleAdjustTipsSubmitClick}
              className="sumi-global-btn-color"
            >
              Submit
            </Button>
          </Modal.Actions>
        </Modal>
        <div
          style={{
            display: "flex",
            position: "relative",
            height: "calc(100%)",
          }}
        >
          {/* -----------------------------  selected order details -------------------------------- */}
          <Segment basic textAlign="left" className="order-recall-detail">
            <Dimmer active={hasVoidOrder} inverted style={{ opacity: "0.5" }}>
              <Header as="h2" icon color="red" className="noselect">
                <Icon name="minus circle" />
                This order has been VOID.
              </Header>
            </Dimmer>

            {!order_selected ? null : (
              <React.Fragment>
                {/* ----------------------------------- detail header-------------------------------- */}
                <Segment
                  raised
                  basic
                  style={{
                    position: "sticky",
                    top: "0",
                    backgroundColor: "white",
                    zIndex: "3",
                    padding: "0",
                    margin: "10px 15px",
                    height: "68px",
                    // padding: "0 30px",
                  }}
                >
                  <div>
                    <span
                      style={{
                        marginRight: "10px",
                        fontSize: "25px",
                        color: "#212121",
                      }}
                    >
                      {order_selected &&
                        "#" +
                          (order_selected.dailyOrderId ||
                            order_selected.orderId) +
                          ","}
                    </span>

                    {/* <span className="blockdisplay-if-medium"> */}
                    <span style={{ fontSize: "25px" }}>
                      {order_selected &&
                        formatPhoneNumber(order_selected.phone)}
                    </span>

                    {/* -------------------------------- payment method --------------------------------- */}
                    {paymentMethod == "creditcard" && paid ? (
                      <Label
                        size="medium"
                        style={{
                          margin: "2px 5px",
                          verticalAlign: "bottom",
                        }}
                      >
                        <Icon name="payment" color="teal" />
                        Paid (Creditcard)
                      </Label>
                    ) : // ) : paymentMethod == "cash" ? (
                    !paid ? (
                      <Label
                        size="medium"
                        style={{
                          margin: "2px 5px",
                          verticalAlign: "bottom",
                        }}
                      >
                        <Icon name="money bill alternate" color="red" />
                        Unpaid
                      </Label>
                    ) : null}

                    {/* --------------------  customer name --------------------- */}
                    {customerName ? (
                      <Label
                        size="medium"
                        style={{
                          margin: "2px 5px",
                          verticalAlign: "bottom",
                        }}
                      >
                        <Icon name="user" color="black" />
                        {customerName}
                      </Label>
                    ) : null}

                    {/* --------------------  order method --------------------- */}

                    {orderMethod === "delivery" ? (
                      <Label
                        size="medium"
                        style={{
                          margin: "2px 5px",
                          verticalAlign: "bottom",
                        }}
                        // onClick={this.handleDeliveryInfoClick}
                      >
                        <Icon name="truck" color="orange" />
                        {"Delivery"}{" "}
                        {scheduleDate
                          ? `On ${formatDateMonthDayYear(scheduleDate)}`
                          : null}
                        {scheduleTimeStr ? ` @ ${scheduleTimeStr}` : null}
                      </Label>
                    ) : orderMethod === "pickup" ? (
                      <Label
                        size="medium"
                        style={{
                          margin: "2px 5px",
                          verticalAlign: "bottom",
                        }}
                      >
                        <Icon name="hand paper" color="blue" />
                        {"Pickup"}{" "}
                        {scheduleDate
                          ? `On ${formatDateMonthDayYear(scheduleDate)}`
                          : null}
                        {scheduleTimeStr ? ` @ ${scheduleTimeStr}` : null}
                      </Label>
                    ) : orderMethod === "curbside" ? (
                      <Label
                        size="medium"
                        style={{
                          margin: "2px 5px",
                          verticalAlign: "bottom",
                        }}
                      >
                        <Icon name="map pin" color="violet" />
                        {"Curbside"}{" "}
                        {scheduleDate
                          ? `On ${formatDateMonthDayYear(scheduleDate)}`
                          : null}
                        {scheduleTimeStr ? ` @ ${scheduleTimeStr}` : null}
                      </Label>
                    ) : orderMethod === "dinein" ? (
                      <Label
                        size="medium"
                        style={{
                          margin: "2px 5px",
                          verticalAlign: "bottom",
                        }}
                      >
                        <Icon name="food" color="green" />
                        {`Dinein #${dineinTableName}`}{" "}
                        {scheduleTimeStr ? ` @ ${scheduleTimeStr}` : null}
                      </Label>
                    ) : orderMethod === "roomServiceDelivery" ? (
                      <Label
                        size="medium"
                        style={{
                          margin: "2px 5px",
                          verticalAlign: "bottom",
                        }}
                      >
                        <Icon name="heart" color="blue" />
                        {`Room #${roomServiceRoom}`}{" "}
                        {scheduleTimeStr ? ` @ ${scheduleTimeStr}` : null}
                      </Label>
                    ) : null}

                    {!enableOrderTimeConfirmSMS ||
                    !isToday(orderSaveDate) ? null : (
                      <Button
                        basic={!!hasSentOrderTimeSMS}
                        inverted
                        style={{
                          float: "right",
                          width: "90px",
                          height: "70px",
                          backgroundColor: "teal",
                          padding: "0",
                        }}
                        onClick={() => {
                          if (!!hasSentOrderTimeSMS) return;
                          this.handleSendTimeNoticeClick();
                        }}
                      >
                        {!!hasSentOrderTimeSMS &&
                        willBeReadyInMinutes != null ? (
                          willBeReadyInMinutes == "0" ? (
                            <Statistic size="mini">
                              <Statistic.Value>Ready</Statistic.Value>
                              <Statistic.Label></Statistic.Label>
                            </Statistic>
                          ) : (
                            <Statistic size="small">
                              <Statistic.Value>
                                {willBeReadyInMinutes}
                              </Statistic.Value>
                              <Statistic.Label>minutes</Statistic.Label>
                            </Statistic>
                          )
                        ) : (
                          "Send Time Message"
                        )}
                      </Button>
                    )}

                    {hasPrintedToKitchen ? (
                      <Icon
                        name="send"
                        size="tiny"
                        style={{
                          fontSize: "x-large",
                          margin: "2px 5px",
                          float: "right",
                        }}
                      ></Icon>
                    ) : null}
                  </div>
                  <div
                    style={{
                      color: "#7d7d7d",
                      fontSize: "15px",
                      margin: "10px 0",
                      fontWeight: "500",
                      // lineHeight: "30px",
                    }}
                  >
                    {/* <span
                      style={{
                        marginRight: "15px",
                        fontSize: "25px",
                        color: "#212121",
                      }}
                    >
                      {order_selected &&
                        "#" +
                          (order_selected.dailyOrderId ||
                            order_selected.orderId)}
                    </span> */}
                    {order_selected &&
                      countOrderItems(order_selected) + " items "}{" "}
                    {order_selected &&
                      " at " +
                        this.getDateStrInfo(order_selected.orderSaveDate)}
                    {businessInfo && !!businessInfo.enableBranch
                      ? isBranch
                        ? " @ " + businessInfo.branchName
                        : " @ " + businessInfo.name
                      : null}
                    {/* <Label
                      as="a"
                      style={{
                        backgroundColor: "rgb(0, 49,98)",
                        color: "white",     
                        float: "right",
                        marginLeft: "10px",
                      }}
                      size="large"
                      onClick={this.handlePrintOptionsOpenClick}
                    >
                      <Icon name="print" />
                      Print
                    </Label> */}
                    {!isCurrentOrder || !paid ? null : (
                      <React.Fragment>
                        {businessInfo &&
                        (businessInfo.ecomm_provider == "cardPointe" ||
                          businessInfo.ecomm_provider == "tsys" ||
                          businessInfo.ecomm_provider == "tsys2") ? (
                          <Label
                            as="a"
                            size="large"
                            style={{
                              backgroundColor: "#db2828",
                              color: "white",
                              float: "right",
                              marginLeft: "10px",
                              fontSize: "12px",
                            }}
                            onClick={this.handleVoidOrderClick}
                          >
                            Void Order
                          </Label>
                        ) : null}

                        {businessInfo &&
                        (businessInfo.ecomm_provider == "cardPointe" ||
                          businessInfo.ecomm_provider == "tsys2") ? (
                          <Label
                            as="a"
                            size="large"
                            style={{
                              backgroundColor: "rgb(0, 49,98)",
                              color: "white",
                              float: "right",
                              marginLeft: "10px",
                              fontSize: "12px",
                            }}
                            onClick={this.handleAdjustTipsClick}
                          >
                            Adjust Tips
                          </Label>
                        ) : null}
                      </React.Fragment>
                    )}
                    {orderMethod && orderMethod === "delivery" ? (
                      <span
                        style={{
                          margin: "0",
                          display: "block",
                          fontSize: "12px",
                          color: "#a5643a",
                        }}
                      >
                        <Icon name="info circle" />
                        Delivery to: {customerAddrStr}{" "}
                        {deliveryNote ? (
                          <Popup
                            content={deliveryNote}
                            position="bottom center"
                            trigger={
                              <span
                                style={{
                                  textDecoration: "underline",
                                  color: "rgb(0, 49, 98)",
                                  fontWeight: "600",
                                  cursor: "pointer",
                                  margin: "0 5px",
                                }}
                              >
                                Note
                              </span>
                            }
                          />
                        ) : null}
                      </span>
                    ) : null}
                    {orderMethod && orderMethod === "curbside" ? (
                      <span
                        style={{
                          margin: "0",
                          display: "block",
                          fontSize: "12px",
                          color: "#a5643a",
                        }}
                      >
                        <Icon name="info circle" />
                        Car info for curbside: {carMakeModel}, {carColor}
                      </span>
                    ) : null}
                  </div>
                </Segment>
                <Divider style={{ margin: "0 10px", padding: "0" }} />
                <Segment basic className="detail-list">
                  <Table
                    fixed
                    size="small"
                    textAlign="left"
                    // basic="very"
                    striped
                    style={{ fontSize: "14px" }}
                  >
                    <Table.Header>
                      <Table.Row>
                        <Table.HeaderCell width={2}>Qty</Table.HeaderCell>
                        <Table.HeaderCell width={7}>Item</Table.HeaderCell>
                        <Table.HeaderCell width={3}>Price</Table.HeaderCell>
                        <Table.HeaderCell width={4}>Note</Table.HeaderCell>
                      </Table.Row>
                    </Table.Header>

                    <Table.Body>{orderDetailTableRows}</Table.Body>
                  </Table>
                </Segment>
                {/* ----------------------------------- detail bottom-------------------------------- */}
                <Segment
                  style={{
                    height: "70px",
                    // width: "100%",
                    margin: "0",
                    // padding: "0",
                    display: "flex",
                    fontSize: "12px",
                  }}
                  raised
                >
                  <div
                    style={{
                      width: "25%",
                      fontSize: "16px",
                      textAlign: "center",
                    }}
                  >
                    <span>${subtotal != null && formatPrice(subtotal)}</span>
                    <span style={{ display: "block", fontSize: "smaller" }}>
                      Subtotal
                    </span>
                  </div>

                  <div
                    style={{
                      width: "25%",
                      fontSize: "16px",
                      textAlign: "center",
                    }}
                  >
                    <span>${tax != null && formatPrice(tax)}</span>
                    <span style={{ display: "block", fontSize: "smaller" }}>
                      Tax
                    </span>
                  </div>

                  <div
                    style={{
                      width: "25%",
                      fontSize: "16px",
                      textAlign: "center",
                    }}
                  >
                    <span> ${tipsAmt != null && formatPrice(tipsAmt)}</span>
                    <span style={{ display: "block", fontSize: "smaller" }}>
                      Tips
                    </span>
                  </div>

                  {discountAmt ? (
                    <div
                      style={{
                        width: "25%",
                        fontSize: "16px",
                        textAlign: "center",
                      }}
                    >
                      <span>
                        {" "}
                        ${discountAmt != null && formatPrice(discountAmt)}
                      </span>
                      <span style={{ display: "block", fontSize: "smaller" }}>
                        Discount
                      </span>
                    </div>
                  ) : null}

                  {processingFeeAmt ? (
                    <div
                      style={{
                        width: "25%",
                        fontSize: "16px",
                        textAlign: "center",
                      }}
                    >
                      <span>
                        $
                        {processingFeeAmt != null &&
                          formatPrice(processingFeeAmt)}
                      </span>
                      <span style={{ display: "block", fontSize: "smaller" }}>
                        Processing
                      </span>
                    </div>
                  ) : null}

                  {serviceFeeAmt ? (
                    <div
                      style={{
                        width: "25%",
                        fontSize: "16px",
                        textAlign: "center",
                      }}
                    >
                      <span>
                        ${serviceFeeAmt != null && formatPrice(serviceFeeAmt)}
                      </span>
                      <span style={{ display: "block", fontSize: "smaller" }}>
                        Service
                      </span>
                    </div>
                  ) : null}

                  {deliveryFeeAmt ? (
                    <div
                      style={{
                        width: "25%",
                        fontSize: "16px",
                        textAlign: "center",
                      }}
                    >
                      <span>
                        ${deliveryFeeAmt != null && formatPrice(deliveryFeeAmt)}
                      </span>
                      <span style={{ display: "block", fontSize: "smaller" }}>
                        Delivery
                      </span>
                    </div>
                  ) : null}

                  <div
                    style={{
                      width: "25%",
                      fontSize: "16px",
                      textAlign: "center",
                    }}
                  >
                    <span
                      style={{
                        color: "rgb(0,49,98)",
                        fontWeight: "900",
                      }}
                    >
                      ${total != null && formatPrice(total)}
                    </span>
                    <span style={{ display: "block", fontSize: "smaller" }}>
                      Total
                    </span>
                  </div>
                </Segment>
              </React.Fragment>
            )}
          </Segment>

          {/* -----------------------------  brief orders -------------------------------- */}

          <div className="noselect order-recall-sidebar">
            {/* -------------------------- filter ---------------------------- */}
            <Segment
              style={{
                height: "100px",
                width: "100%",
                margin: "10px 0",
                padding: "0",
              }}
              raised
              basic
            >
              <div
                style={{
                  width: "100%",
                  height: "45px",
                  padding: "0 0 0 0",
                  display: "flex",
                }}
              >
                <Button
                  className={
                    this.state.preDefinedDateRange ===
                    this.PRE_DEFINED_DATERANGE.TODAY
                      ? "sumi-global-btn-color"
                      : ""
                  }
                  style={{
                    position: "relative",
                    // boxShadow: "0px 0px 5px 0px rgba(0,0,0,0.75)",
                    width: "calc(25% - 5px)",
                    margin: "0 5px 0 0",
                    padding: "7px 2px",
                    fontSize: "x-small",
                  }}
                  onClick={this.handleTodayBtnClick}
                >
                  {/* <Ink recenter="true" duration="500" /> */}
                  Today
                </Button>
                <Button
                  className={
                    this.state.preDefinedDateRange ===
                    this.PRE_DEFINED_DATERANGE.THIS_WEEK
                      ? "sumi-global-btn-color"
                      : ""
                  }
                  style={{
                    position: "relative",
                    // boxShadow: "0px 0px 5px 0px rgba(0,0,0,0.75)",
                    width: "calc(25% - 10px)",
                    margin: "0 5px",
                    padding: "7px 2px",
                    fontSize: "x-small",
                  }}
                  onClick={this.handleThisWeekBtnClick}
                >
                  {/* <Ink recenter="true" duration="500" /> */}
                  This Week
                </Button>
                <Button
                  className={
                    this.state.preDefinedDateRange ===
                    this.PRE_DEFINED_DATERANGE.THIS_MONTH
                      ? "sumi-global-btn-color"
                      : ""
                  }
                  style={{
                    position: "relative",
                    // boxShadow: "0px 0px 5px 0px rgba(0,0,0,0.75)",
                    width: "calc(25% - 10px)",
                    margin: "0 5px",
                    padding: "7px 2px",
                    fontSize: "x-small",
                  }}
                  onClick={this.handleThisMonthBtnClick}
                >
                  {/* <Ink recenter="true" duration="500" /> */}
                  This Month
                </Button>
                <Button
                  className={
                    this.state.preDefinedDateRange ===
                    this.PRE_DEFINED_DATERANGE.THIS_YEAR
                      ? "sumi-global-btn-color"
                      : ""
                  }
                  style={{
                    position: "relative",
                    // boxShadow: "0px 0px 5px 0px rgba(0,0,0,0.75)",
                    width: "calc(25% - 5px)",
                    margin: "0 0 0 5px",
                    padding: "7px 2px",
                    fontSize: "x-small",
                  }}
                  onClick={this.handleThisYearBtnClick}
                >
                  {/* <Ink recenter="true" duration="500" /> */}
                  This Year
                </Button>
              </div>

              <div
                style={{
                  width: "100%",
                  height: "50px",
                  padding: "0",
                  margin: "0",
                  display: "flex",
                }}
              >
                <Button
                  className="sumi-global-btn-color date-filter"
                  onClick={this.handleDateRangeBtnClick}
                >
                  {dateRange
                    ? `${
                        DateTimeDb.getDateObj(dateRange.startDate).dateStr
                      } - ${DateTimeDb.getDateObj(dateRange.endDate).dateStr}`
                    : "All Period"}
                  <Icon
                    name={
                      this.state.dateRangeDisplay ? "angle up" : "angle down"
                    }
                    style={{ float: "left" }}
                  />
                  <Icon
                    name={
                      this.state.dateRangeDisplay ? "angle up" : "angle down"
                    }
                    style={{ float: "right" }}
                  />
                </Button>
              </div>

              <Transition
                animation="fade down"
                duration={130}
                visible={this.state.dateRangeDisplay}
              >
                <div
                  style={{
                    width: "calc(100%)",
                    paddingTop: "1px",
                    position: "absolute",

                    zIndex: "10",
                    border: "1px solid #e8e3e3",
                  }}
                >
                  <DateRange
                    startDate={
                      dateRange && dateRange.startDate
                        ? moment(dateRange && dateRange.startDate)
                        : null
                    }
                    endDate={
                      dateRange && dateRange.endDate
                        ? moment(dateRange && dateRange.endDate)
                        : null
                    }
                    onChange={this.handleDateSelect}
                    calendars={1}
                    staticRanges={[]}
                    format="MM/DD/YYY"
                  />
                </div>
              </Transition>
            </Segment>

            {/* -------------------------- brief list ---------------------------- */}
            <Segment.Group
              style={{
                width: "100%",
                height: "calc(100% - 120px - 70px)",
                margin: "0",
                padding: "0",
                overflowY: "auto",
                overflowX: "hidden",
                borderWidth: "0",
                boxShadow: "none",
                backgroundColor: "#1b1c1d",
              }}
            >
              <Dimmer inverted active={loading} style={{ position: "sticky" }}>
                <Loader size="huge" />
              </Dimmer>
              {orderBriefSegmnts}
            </Segment.Group>

            <div
              style={{
                height: "70px",
                width: "100%",
                margin: "0",
                padding: "0",
                // top: "-500px",
                display: "flex",
                cursor: "pointer",
              }}
              onClick={this.handleOrderSummaryStatClick}
            >
              <Segment
                inverted
                style={{
                  width: "50%",
                  height: "100%",
                  margin: "0",
                  borderRadius: "unset",
                  backgroundColor: "#003161",
                }}
              >
                <div
                  style={{
                    fontSize: "16px",
                    textAlign: "center",
                  }}
                >
                  <span
                    style={{
                      fontWeight: "900",
                    }}
                  >
                    ${formatPrice(orderSaleObj.total)}
                  </span>
                  <span style={{ display: "block", fontSize: "smaller" }}>
                    Total Sale
                  </span>
                </div>
              </Segment>
              <Segment
                inverted
                style={{
                  width: "50%",
                  height: "100%",
                  margin: "0",
                  borderRadius: "unset",
                  backgroundColor: "#003161",
                }}
              >
                <div
                  style={{
                    fontSize: "16px",
                    textAlign: "center",
                  }}
                >
                  <span
                    style={{
                      fontWeight: "900",
                    }}
                  >
                    {orderSaleObj.number}
                  </span>
                  <span style={{ display: "block", fontSize: "smaller" }}>
                    Orders
                  </span>
                </div>
              </Segment>
            </div>
          </div>
        </div>

        {/* -------------------  print modal ---------------  */}
        {/* <Modal
          closeIcon
          dimmer={true}
          open={this.state.showPrintOptions}
          onClose={this.handlePrintOptionsCloseClick}
        >
          <div style={{ display: "flex" }}>
            <Segment
              style={{ width: "100%", margin: "20px" }}
              textAlign="center"
            >
              <ReactToPrint
                trigger={() => (
                  <Button className="sumi-global-btn-color">Print</Button>
                )}
                content={() => this.componentRef_kitchen}
                onAfterPrint={this.handleAfterPrintToKitchen}
                // onAfterPrint={() => console.log(123)}
                onPrintError={(errorLocation, error) =>
                  console.log(errorLocation, error)
                }
              />
              <Divider />
              <KitchenStyleReceipt
                ref={(el) => (this.componentRef_kitchen = el)}
                order={order_selected}
              />
            </Segment>

            <Segment
              style={{ width: "100%", margin: "20px" }}
              textAlign="center"
            >
              {paid && paymentMethod == "creditcard" ? (
                <ReactToPrint
                  trigger={() => (
                    <Button className="sumi-global-btn-color">Print</Button>
                  )}
                  content={() => this.componentRef_afterpayment}
                  onAfterPrint={this.handleAfterPrintToCustomer}
                />
              ) : (
                <ReactToPrint
                  trigger={() => (
                    <Button className="sumi-global-btn-color">Print</Button>
                  )}
                  content={() => this.componentRef_beforepayment}
                  onAfterPrint={this.handleAfterPrintToCustomer}
                />
              )}

              <Divider />

              {paid && paymentMethod == "creditcard" ? (
                <ReceiptAfterPayment
                  merchantId={this.props.merchantId}
                  ref={(el) => (this.componentRef_afterpayment = el)}
                  order={order_selected}
                  printMerchantCopy={true}
                  printCustomerCopy={true}
                />
              ) : (
                <ReceiptBeforePayment
                  merchantId={this.props.merchantId}
                  ref={(el) => (this.componentRef_beforepayment = el)}
                  order={order_selected}
                />
              )}
            </Segment>
          </div>
        </Modal> */}
      </div>
    );
  }
}

export default OrderRecallPanel;
