import React, { Component } from "react";
import {
  Button,
  Segment,
  Transition,
  Header,
  List,
  Divider,
  Input,
  Icon,
  Message,
  Dimmer,
  Loader,
  Label,
  Popup,
  // Image,
} from "semantic-ui-react";

import ReCAPTCHA from "react-google-recaptcha";

import {
  formatPrice,
  formatPhoneNumber,
  checkIfWithinBusinessHour2,
} from "../dataModel/utils";

let inPaymentProcess = false;

class ShoppingCartPanel extends Component {
  recaptchaRef = React.createRef();

  state = {
    loading: false,
    showMsg: false,
    showPromoCodeMsg: false,
    showUnavailableItemPopup: false,

    orderedItemsAfterPromoMethod: null,
  };

  constructor(props) {
    super(props);
    this.phoneInputRef = React.createRef();
  }

  onCaptchaChange = (value) => {
    const recaptchaValue = this.recaptchaRef.current.getValue();
    this.setState({ captchaValue: recaptchaValue });
  };

  startLoading = (msg) => {
    this.setState({
      loading: true,
    });
  };

  endLoading = () => {
    this.setState({
      loading: false,
    });
  };

  handleCheckoutClick = () => {
    // const hasUnavailableItem = (this.props.orderedItems_PromoMethod || []).some(
    //   ({ availableHour }) => {
    //     return availableHour && !checkIfWithinBusinessHour2(availableHour);
    //   }
    // );

    // if (hasUnavailableItem) {
    //   return this.setState({
    //     showUnavailableItemPopup: true,
    //   });
    // }

    this.props.onCheckoutClick && this.props.onCheckoutClick();
  };

  // handlePayByCreditcardClick = (amount) => {
  //   this.props.onPayByCreditcardClick &&
  //     this.props.onPayByCreditcardClick(amount);
  // };

  // handlePayByCashClick = (amount) => {
  //   this.props.onPayByCashClick && this.props.onPayByCashClick(amount);
  // };

  handlePlaceOrderClick = async (amount, evt) => {
    try {
      const { paymentMethod, PAYMENT_METHOD } = this.props;

      const token = await this.recaptchaRef.current.executeAsync();

      let token_forCreditcardCaptcha = null;
      if (PAYMENT_METHOD && paymentMethod === PAYMENT_METHOD.CREDITCARD) {
        token_forCreditcardCaptcha =
          await this.recaptchaRef.current.executeAsync();
      }

      if (!token) {
        console.error("reCAPTCHA Failed to create token");
        return;
      }

      evt.preventDefault();

      this.startLoading();

      fetch("/verify-captcha", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ captchaResponse: token }),
      })
        .then((response) => response.json())
        .then(async (data) => {
          console.log(234, data);
          if (data.success) {
            // Proceed with form submission
            if (this.props.onPlaceOrderClick) {
              const placed = await this.props.onPlaceOrderClick(
                amount,
                token_forCreditcardCaptcha
              );
              if (placed) {
                return;
              }
            }
          } else {
            // Handle verification failure
            console.log("something wrong");
          }

          this.endLoading();
        });
    } catch (ex) {
      console.error(ex);
    } finally {
    }
  };

  handleBackToMenuClick = () => {
    this.props.onBackToMenuClick && this.props.onBackToMenuClick();
  };
  //  OLD: orderedItem:{
  //     item: item, // { name, price, ... }
  //     qty:Number,
  //     orderItemOptions: [ optionValue, ... ], // optionValue: {name, price, _idItemOption}
  //     note: String
  //   }

  handleOrderTipChange = (pct) => {
    this.props.onOrderTipChange && this.props.onOrderTipChange(pct);
  };

  handleCustomTipClick = () => {
    this.props.onOrderCustomTipClick && this.props.onOrderCustomTipClick();
  };

  handleCustomTipChange = (tips) => {
    if (Number(tips) < 0) {
      return;
    }

    this.props.onCustomTipChange && this.props.onCustomTipChange(tips);
  };

  handleCloseIconClick = () => {
    this.props.onCloseIconClick && this.props.onCloseIconClick();
  };

  handlePromoCodeChange = (val) => {
    this.props.onPromoCodeChange &&
      this.props.onPromoCodeChange((val || "").toUpperCase());
  };

  handlePromoCodeApplyClick = async () => {
    const applied =
      this.props.onPromoCodeApplyClick &&
      (await this.props.onPromoCodeApplyClick());

    if (!applied) {
      this.setState({
        showPromoCodeMsg: true,
      });
      setTimeout(() => {
        this.setState({
          showPromoCodeMsg: false,
        });
      }, 2000);
    }
  };

  handlePromoCodeRemove = () => {
    this.props.onPromoCodeRemove && this.props.onPromoCodeRemove();
  };

  // ----------------------- remove ---------------------------
  handleRemoveItem = (index) => {
    this.props.onRemoveFromCartClick && this.props.onRemoveFromCartClick(index);
  };

  handleRemoveUnavailableItems = () => {
    this.props.onRemoveUnavailableItems &&
      this.props.onRemoveUnavailableItems();
  };

  orderItemOptionRemap = (orderItemOption) => {
    if (orderItemOption) {
      let remapDic = {};
      for (let i = 0; i < orderItemOption.length; i++) {
        let itemOptionId = orderItemOption[i]._id;
        if (itemOptionId in remapDic) {
          remapDic[itemOptionId].original_index.push(i);
          remapDic[itemOptionId].qty = remapDic[itemOptionId].qty + 1;
        } else {
          orderItemOption[i]["qty"] = 1;
          orderItemOption[i]["original_index"] = [i];
          remapDic[itemOptionId] = orderItemOption[i];
        }
      }
      let finalReMap = [];
      for (let j in remapDic) {
        finalReMap.push(remapDic[j]);
      }
      return finalReMap;
    } else {
      return orderItemOption;
    }
  };

  render() {
    //const { orderedItems, order_tips_pct, isCheckingout } = this.props;
    const {
      orderedItems,
      // orderedItems_PromoMethod,
      subtotal,
      tax,
      tip,
      fixedTips,
      fixedTipRate,
      discount,
      total,
      isCheckingout,
      order_tips_pct,
      custom_tips,
      promoCodeApplied,
      promoCode,
      processingFeeAmt,
      serviceFeeAmt,
      deliveryFee,
      roomServiceDeliveryFee,
      orderMethod,
      METHOD,
    } = this.props;

    const orderedItemsList = (orderedItems || []).map((orderedItem, index) => {
      const {
        name,
        price,
        qty,
        orderItemOptions,
        note,
        size,
        availableHour,
        discount,
      } = orderedItem;

      const orderItemOptionsRemap = this.orderItemOptionRemap(orderItemOptions);
      let itemTotalPrice = price;
      (orderItemOptions || []).forEach((ov) => {
        itemTotalPrice += ov.price;
      });
      itemTotalPrice = itemTotalPrice * Number(qty || 0);

      let discountStr = null;
      if (discount) {
        if (discount.type === "coff") {
          // do later
        } else if (discount.type === "poff" && discount.amount != null) {
          itemTotalPrice = itemTotalPrice * (1 - discount.amount);
          discountStr =
            discount.amount == 1 ? "Free" : discount.amount * 100 + "% OFF";
        }
      }

      const isCategoryAvailableNow = checkIfWithinBusinessHour2(
        availableHour,
        this.props.scheduleDate
      );

      return (
        <List.Item
          key={index}
          size="big"
          className="noselect"
          style={{ padding: "10px" }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              fontFamily: '"Rubik", sans-serif',
            }}
          >
            <div style={{ flex: "2 1 0%" }}>
              <div
                style={{
                  textAlign: "center",
                  fontSize: "15px",
                  fontWeight: "600",
                }}
              >
                {qty}
              </div>
            </div>
            <div style={{ flex: "10 1 0%" }}>
              {size ? (
                <div
                  key={index}
                  style={{
                    margin: "0",
                    padding: "0",
                    color: "#7d7d7d",
                    fontSize: "13px",
                    fontWeight: "600",
                  }}
                >
                  {size}
                </div>
              ) : null}
              <div
                style={{
                  fontWeight: "600",
                  color: "rgba(0,0,0,.87)",
                  fontSize: "15px",
                }}
              >
                {name}
              </div>
              {isCategoryAvailableNow ? null : (
                <Label
                  size="mini"
                  color="red"
                  basic
                  style={{ display: "block" }}
                >
                  <Icon name="warning circle" />
                  This item unavailable now
                </Label>
              )}
              {note ? (
                <div
                  style={{
                    margin: "0",
                    padding: "0",
                    color: "#7d7d7d",
                    fontSize: "13px",
                  }}
                >
                  Note: {note}
                </div>
              ) : null}
              {(orderItemOptionsRemap || []).map((optionVal, index) => (
                <div
                  key={index}
                  style={{
                    margin: "0 10px",
                    color: "#7d7d7d",
                    // fontWeight: "700",
                    fontWeight: "600",
                    fontSize: "12px",
                  }}
                >
                  {optionVal.qty > 1 ? optionVal.qty + " x " : null}{" "}
                  {optionVal && optionVal.name}
                </div>
              ))}

              <div
                style={{
                  margin: "10px 0 0 0",
                  cursor: "pointer",
                  display: "inline-block",
                  color: "#db2828",
                  fontSize: "13px",
                  fontWeight: "600",
                }}
                onClick={() => this.handleRemoveItem(index)}
              >
                Remove
              </div>
            </div>
            <div style={{ flex: "2 1 0%" }}>
              <div
                style={{
                  textAlign: "center",
                  fontSize: "15px",
                  fontWeight: "600",
                }}
              >
                ${formatPrice(itemTotalPrice)}
                {discountStr ? (
                  <span
                    style={{
                      display: "block",
                      fontSize: "12px",
                      color: "#a5643a",
                    }}
                  >
                    {discountStr}
                  </span>
                ) : null}
              </div>
            </div>
          </div>
        </List.Item>
      );
    });

    const hasUnavailableItem = (this.props.orderedItems || []).some(
      ({ availableHour }) => {
        return (
          availableHour &&
          !checkIfWithinBusinessHour2(availableHour, this.props.scheduleDate)
        );
      }
    );

    return (
      <div
        style={{
          height: "100%",
          width: "100%",
          margin: "0",
          padding: "0",
        }}
      >
        <Dimmer inverted active={this.state.loading} page>
          <Loader size="huge" />
        </Dimmer>

        <Icon
          className="close-icon"
          name="remove circle"
          size="large"
          onClick={this.handleCloseIconClick}
        />

        {isCheckingout ? (
          // ------------------------------- during checkingout -----------------------------
          <Segment style={{ height: "100%", overflowY: "auto", margin: "0" }}>
            {/* ---------------------- subtotal --------------------- */}
            <Header
              as="h3"
              style={{
                height: "25px",
                margin: "5px 10px",
                padding: "0",
                width: "calc(100% - 20px)",
                textAlign: "left",
              }}
            >
              Subtotal
              <span style={{ float: "right" }}>${formatPrice(subtotal)}</span>
            </Header>
            {/* ---------------------- tax --------------------- */}
            <Header
              as="h3"
              style={{
                height: "25px",
                margin: "5px 10px",
                padding: "0",
                width: "calc(100% - 20px)",
                textAlign: "left",
              }}
            >
              Taxes
              <span style={{ float: "right" }}>${formatPrice(tax)}</span>
            </Header>
            {/* ----------------------------- fixed tips ----------------- */}
            {fixedTips ? (
              <Header
                as="h3"
                style={{
                  height: "25px",
                  margin: "5px 10px",
                  padding: "0",
                  width: "calc(100% - 20px)",
                  textAlign: "left",
                }}
              >
                Tips{" "}
                <Popup
                  trigger={
                    <Icon
                      style={{ fontSize: "11px", display: "inline" }}
                      name="question circle"
                    ></Icon>
                  }
                  content={`${
                    fixedTipRate * 100
                  }% gratuity would be charged automatically.`}
                  on="click"
                  hideOnScroll
                />
                <span style={{ float: "right" }}>
                  ${formatPrice(fixedTips)}
                </span>
              </Header>
            ) : null}

            {/* ---------------------- tips --------------------- */}
            <Divider />
            <Header
              as="h3"
              style={{
                height: "25px",
                margin: "5px 10px",
                padding: "0",
                width: "calc(100% - 20px)",
                textAlign: "left",
              }}
            >
              {fixedTips ? "Additional Tips" : "Tips"}
              <span style={{ float: "right" }}>${formatPrice(tip)}</span>
            </Header>
            <Button.Group
              size="large"
              style={{
                width: "calc(100%)",
                height: "50px",
                margin: "5px 0",
              }}
            >
              {["0", "0.1", "0.15", "0.20"].map((pct, index) => {
                return (
                  <Button
                    key={index}
                    style={{ padding: "2px" }}
                    onClick={() => this.handleOrderTipChange(pct)}
                    secondary={
                      order_tips_pct != null &&
                      formatPrice(order_tips_pct) == formatPrice(pct)
                    }
                  >
                    {/* ${formatPrice(Number(pct) * subTotal)} */}
                    {Number(pct) * 100}%
                  </Button>
                );
              })}
              <Button
                key={10}
                style={{ padding: "2px" }}
                onClick={this.handleCustomTipClick}
                secondary={order_tips_pct === "other"}
              >
                {/* {Number(pct) * 100}% */}Other
              </Button>
            </Button.Group>
            {order_tips_pct === "other" ? (
              <Input
                icon="dollar"
                iconPosition="left"
                size="big"
                value={custom_tips || ""}
                style={{
                  width: "calc(100%)",
                  height: "50px",
                  margin: "0",
                }}
                onChange={(evt) => {
                  //console.log(123, evt.target.value);
                  this.handleCustomTipChange(evt.target.value);
                }}
              />
            ) : null}
            <Divider />

            {/* // ---------------------- processing fee if applied ----------------------- */}
            {processingFeeAmt || serviceFeeAmt ? (
              <React.Fragment>
                <Header
                  as="h3"
                  style={{
                    height: "25px",
                    margin: "5px 10px",
                    padding: "0",
                    width: "calc(100% - 20px)",
                    textAlign: "left",
                  }}
                >
                  Processing fee{" "}
                  <Popup
                    trigger={
                      <Icon
                        style={{ fontSize: "11px", display: "inline" }}
                        name="question circle"
                      ></Icon>
                    }
                    content="This amount is charged by the restaurant as convenience fee"
                    on="click"
                    hideOnScroll
                  />
                  :
                  <span style={{ float: "right" }}>
                    $
                    {formatPrice(
                      (processingFeeAmt || 0) + (serviceFeeAmt || 0)
                    )}
                  </span>
                </Header>
                <Divider />
              </React.Fragment>
            ) : null}
            {/* // ---------------------- room service delivery fee if applied ----------------------- */}
            {roomServiceDeliveryFee &&
            orderMethod === METHOD.ROOM_SERVICE_DELIVERY ? (
              <React.Fragment>
                <Header
                  as="h3"
                  style={{
                    height: "25px",
                    margin: "5px 10px",
                    padding: "0",
                    width: "calc(100% - 20px)",
                    textAlign: "left",
                  }}
                >
                  Delivery fee :
                  <span style={{ float: "right" }}>
                    ${formatPrice(roomServiceDeliveryFee)}
                  </span>
                </Header>
                <Divider />
              </React.Fragment>
            ) : null}

            {/* // ---------------------- delivery fee if applied ----------------------- */}
            {deliveryFee ? (
              <React.Fragment>
                <Header
                  as="h3"
                  style={{
                    height: "25px",
                    margin: "5px 10px",
                    padding: "0",
                    width: "calc(100% - 20px)",
                    textAlign: "left",
                  }}
                >
                  Delivery fee :
                  <span style={{ float: "right" }}>
                    ${formatPrice(deliveryFee)}
                  </span>
                </Header>
                <Divider />
              </React.Fragment>
            ) : null}
            {/* ---------------------- promotion code --------------------- */}
            {promoCodeApplied ? (
              <React.Fragment>
                <Header
                  as="h3"
                  style={{
                    height: "25px",
                    margin: "5px 10px",
                    padding: "0",
                    width: "calc(100% - 20px)",
                    textAlign: "left",
                  }}
                >
                  Discount
                  <span style={{ float: "right" }}>
                    -${formatPrice(discount)}
                  </span>
                </Header>
              </React.Fragment>
            ) : null}

            {promoCodeApplied ? (
              <Button
                icon="remove circle"
                labelPosition="left"
                // className="sumi-global-btn-color"
                style={{
                  width: "100%",
                  height: "50px",
                  padding: "10px 20px",
                  textAlign: "center",
                  margin: "0",
                  backgroundColor: "#fbbd08",
                }}
                onClick={() => {
                  this.handlePromoCodeRemove();
                }}
                content={promoCodeApplied.percentageOff * 100 + "% OFF APPLIED"}
              />
            ) : (
              <React.Fragment>
                <Input
                  size="huge"
                  value={promoCode || ""}
                  style={{
                    width: "calc(100% - 80px)",
                    height: "50px",
                    margin: "0",
                  }}
                  placeholder="Add Promo Code"
                  onChange={(evt) => {
                    this.handlePromoCodeChange(evt.target.value);
                  }}
                />

                <Button
                  className="sumi-global-btn-color"
                  style={{
                    width: "80px",
                    height: "50px",
                    padding: "10px 20px",
                    textAlign: "center",
                    margin: "0",
                  }}
                  onClick={() => {
                    this.handlePromoCodeApplyClick();
                  }}
                >
                  Apply
                </Button>
              </React.Fragment>
            )}

            {this.state.showPromoCodeMsg ? (
              <Message warning icon size="mini">
                <Icon name="info" />
                <Message.Header>Invalid promotion code.</Message.Header>
              </Message>
            ) : null}

            <Divider />

            {/* ---------------------- total amount --------------------- */}
            <Header
              as="h2"
              style={{
                height: "25px",
                margin: "5px 10px",
                padding: "0",
                width: "calc(100% - 20px)",
                textAlign: "left",
              }}
            >
              Total
              <span style={{ float: "right" }}>${formatPrice(total)}</span>
            </Header>
            <Divider />
            {/* {orderedItems && orderedItems.length > 0 ? ( */}
            <React.Fragment>
              <ReCAPTCHA
                ref={this.recaptchaRef}
                size="invisible"
                sitekey="6LcXIDIpAAAAALqW-bXauVO9d67fyTSgbOYESSv-"
                onChange={this.onCaptchaChange}
              />

              <Button
                className="sumi-global-btn-color"
                style={{
                  width: "calc(100%)",
                  height: "50px",
                  padding: "10px 20px",
                  textAlign: "center",
                  margin: "0",
                }}
                onClick={async (evt) => {
                  if (inPaymentProcess) {
                    console.log("reject. in payment");
                    return;
                  }

                  inPaymentProcess = true;

                  orderedItems && orderedItems.length > 0
                    ? await this.handlePlaceOrderClick(total, evt)
                    : this.handleBackToMenuClick();

                  inPaymentProcess = false;
                }}
              >
                {orderedItems && orderedItems.length > 0
                  ? "Place the order"
                  : "Back to Menu"}
              </Button>
            </React.Fragment>
            {/* ) : null} */}
          </Segment>
        ) : orderedItems && orderedItems.length > 0 ? (
          // ------------------------------- during ordering -----------------------
          <React.Fragment>
            {/* <Segment
              raised
              textAlign="center"
              style={{ margin: "0", padding: "0", height: "80px" }}
            > */}

            {/* </Segment> */}

            {this.props.offBusinessHour ? (
              <Button
                // className="sumi-global-btn-color"
                color="red"
                style={{
                  width: "calc(100% - 30px)",
                  height: "50px",
                  padding: "10px 20px",
                  textAlign: "center",
                  margin: "15px",
                }}
                // onClick={this.handleRemoveUnavailableItems}
              >
                Store closed
              </Button>
            ) : hasUnavailableItem ? (
              <Button
                // className="sumi-global-btn-color"
                color="red"
                style={{
                  width: "calc(100% - 30px)",
                  height: "50px",
                  padding: "10px 20px",
                  textAlign: "center",
                  margin: "15px",
                }}
                onClick={this.handleRemoveUnavailableItems}
              >
                {" "}
                Remove unavailable items
              </Button>
            ) : (
              <Button
                className="sumi-global-btn-color"
                style={{
                  width: "calc(100% - 30px)",
                  height: "50px",
                  padding: "10px 20px",
                  textAlign: "left",
                  margin: "15px",
                }}
                onClick={this.handleCheckoutClick}
              >
                Checkout
                <span
                  style={{ float: "right", fontFamily: '"Rubik", sans-serif' }}
                >
                  ${formatPrice(subtotal)}
                </span>
              </Button>
            )}

            <Divider style={{ padding: "0", margin: "0" }} />
            <div className="item-list">
              <Transition.Group
                as={List}
                duration={200}
                divided
                relaxed
                size="big"
                style={{ padding: "10px" }}
              >
                {orderedItemsList}
              </Transition.Group>
            </div>
          </React.Fragment>
        ) : (
          <Segment
            basic
            textAlign="center"
            vertical
            style={{ fontFamily: '"Rubik", sans-serif', color: "#767676" }}
          >
            Your cart is empty
            <br />
            Add items to get started
            {/* <Image
              src={require("../imgs/empty_cart.jpg")}
              size="medium"
              rounded
            /> */}
          </Segment>
        )}
      </div>
    );
  }
}

export default ShoppingCartPanel;
