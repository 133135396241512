import UserDb from "./userDb";
const axios = require("axios");

const CART_ROUTE_URL = "/api/shoppingCarts";

// ----------------- use for setting cookies --------------------
export default class ShoppingCartDb {
  static async getOrderItems(merchantId) {
    try {
      if (!merchantId)
        return console.error("Invalid merchantId(getOrderItems)");

      const user = await UserDb.getCookie();
      if (!user) {
        return console.error("Fail to fetch user cookies");
      }

      return (
        (await axios.get(
          `${CART_ROUTE_URL}/merchantId/${merchantId}/userId/${user.uid}`
        )) || {}
      ).data;
    } catch (ex) {
      console.error(ex);
    }
  }

  // in use
  static async updateOrderItems(merchantId, orderItems) {
    try {
      if (!merchantId)
        return console.error("Invalid merchantId(updateOrderItems)");

      const user = await UserDb.getCookie();

      if (!user) {
        return console.error("Fail to fetch user cookies");
      }

      return (
        (await axios.post(
          `${CART_ROUTE_URL}/merchantId/${merchantId}/orderItems/${user.uid}`,
          {
            orderItems,
          }
        )) || {}
      ).data;
    } catch (ex) {
      console.error(ex);
    }
  }

  static async emptyShoppingChart(merchantId) {
    try {
      if (!merchantId)
        return console.error("Invalid merchantId(emptyShoppingChart)");

      const user = await UserDb.getCookie();
      if (!user) {
        return console.error("Fail to fetch user cookies");
      }

      return (
        (await axios.delete(
          `${CART_ROUTE_URL}/merchantId/${merchantId}/orderItems/${user.uid}`
        )) || {}
      ).data;
    } catch (ex) {
      console.error(ex);
    }
  }

  // including tips, subtotal, total, customer phone number, orderMethod, carInfo, customer addr string, deliveryNote, discount
  /*
    checkoutInfo:{
      subtotal:Number,
      tax:Number,
      tips:Number,
      total:Number,
      phone:String
    }
  */
  static async updateOrderCheckoutInfo(merchantId, checkoutInfo) {
    try {
      if (!merchantId)
        return console.error("Invalid merchantId(emptyShoppingChart)");

      const user = await UserDb.getCookie();

      if (!user) {
        return console.error("Fail to fetch user cookies");
      }

      if (!checkoutInfo) {
        return console.error("Null checkoutinfo provided");
      }

      return (
        (await axios.put(
          `${CART_ROUTE_URL}/merchantId/${merchantId}/checkoutInfo/${user.uid}`,
          checkoutInfo
        )) || {}
      ).data;
    } catch (ex) {
      console.error(ex);
    }
  }

  // --------------------- send order to order table ------------------------

  //   authResponse: {
  //     approved:Boolean,
  //     amount:Number,
  //     merchantId:String
  //     cardNumber:String,
  //     cardExpiry:String,
  //     cardType:String,
  //     authCode:String,
  //    retref:String,
  // }
  static async sendOrder(merchantId, userInfo, authResponse) {
    try {
      if (!merchantId)
        return console.error("Invalid merchantId(emptyShoppingChart)");

      const user = await UserDb.getCookie();
      if (!user) {
        return console.error("Fail to fetch user cookies");
      }

      if (userInfo && userInfo.length !== 0) {
        if (authResponse) {
          authResponse.userId = userInfo._id;
        }
        return (
          (await axios.post(
            `${CART_ROUTE_URL}/merchantId/${merchantId}/sendOrder/${user.uid}`,
            authResponse || { userId: userInfo._id }
          )) || {}
        ).data;
      } else {
        return (
          (await axios.post(
            `${CART_ROUTE_URL}/merchantId/${merchantId}/sendOrder/${user.uid}`,
            authResponse || {}
          )) || {}
        ).data;
      }
    } catch (ex) {
      console.error(ex);
    }
  }
}
