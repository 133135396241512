const axios = require("axios");

const TSYS2_ROUTE_URL = "/api/tsyses2";

export default class Tsys2Db {
  /*
    response:{
      status:"PASS" | "FAIL",
      transactionKey:""
    }
  */
  static async generateKey(merchantId) {
    try {
      if (!merchantId)
        return console.error("Invalid merchantId(tsys2.generateKey)");

      const response =
        (await axios.post(
          `${TSYS2_ROUTE_URL}/merchantId/${merchantId}/generateKey`
        )) || {};

      return response.data;
    } catch (ex) {
      console.error(ex);
    }
  }

  /*

  */
  static async sale(merchantId, amount, creditcardInfo, recaptchaToken) {
    try {
      if (!merchantId) return console.error("Invalid merchantId(tsys2.sale)");
      if (!amount) return console.error("Invalid amount(tsys2.sale)");
      if (!creditcardInfo)
        return console.error("Invalid creditcardInfo(tsys2.sale)");

      if (!recaptchaToken) {
        return console.error(`Unauthorized, Invalid Token:[${recaptchaToken}]`);
      }

      const { success, transactionKey } =
        (await Tsys2Db.generateKey(merchantId)) || {};

      if (!success) {
        return console.error("Fail to generate transaction key(tsys2.sale)");
      }

      const saleReqObj = {
        transactionKey,
        amount,
        ...creditcardInfo,
        recaptchaToken,
      };

      const saleResObj = (
        (await axios.post(
          `${TSYS2_ROUTE_URL}/merchantId/${merchantId}/sale`,
          saleReqObj
        )) || {}
      ).data;

      if (!saleResObj || !saleResObj.SaleResponse) {
        return console.error(
          "Fail to get sale response when doing sale (tsys2)"
        );
      }

      // void order if cvv or avs not pass
      if (
        saleResObj.SaleResponse.responseCode === "A0000" &&
        (saleResObj.SaleResponse.addressVerificationCode != "Y" ||
          (saleResObj.SaleResponse.cvvVerificationCode != "M" &&
            saleResObj.SaleResponse.cvvVerificationCode != "P"))
      ) {
        const voidReqObj = {
          transactionKey,
          retref: saleResObj.SaleResponse.transactionID,
        };

        const voidResObj = (
          (await axios.post(
            `${TSYS2_ROUTE_URL}/merchantId/${merchantId}/void`,
            voidReqObj
          )) || {}
        ).data;

        console.log("void", voidResObj);
      }

      return {
        approved:
          saleResObj.SaleResponse.responseCode === "A0000" &&
          saleResObj.SaleResponse.addressVerificationCode === "Y" &&
          (saleResObj.SaleResponse.cvvVerificationCode === "M" ||
            saleResObj.SaleResponse.cvvVerificationCode === "P"),
        amount: saleResObj.SaleResponse.totalAmount,
        merchantId: saleResObj.SaleResponse.hostReferenceNumber,
        cardNumber: creditcardInfo.acount,
        cardExpiry: creditcardInfo.expiry,
        cardType: saleResObj.SaleResponse.cardType,
        authCode: saleResObj.SaleResponse.authCode,
        retref: saleResObj.SaleResponse.transactionID,
      };
    } catch (ex) {
      console.error(ex);
    }
  }

  static async void(merchantId, retref) {
    try {
      if (!merchantId) return console.error("Invalid merchantId(tsys2.void)");
      if (!retref) return console.error("Invalid retref(tsys2.void)");

      const { success, transactionKey } =
        (await Tsys2Db.generateKey(merchantId)) || {};

      if (!success) {
        return console.error("Fail to generate transaction key(tsys2.void)");
      }

      const reqObj = {
        transactionKey,
        retref,
      };

      const resObj = (
        (await axios.post(
          `${TSYS2_ROUTE_URL}/merchantId/${merchantId}/void`,
          reqObj
        )) || {}
      ).data;

      if (!resObj || !resObj.VoidResponse) {
        return console.error(
          "Fail to get return response when doing return (tsys2)"
        );
      }

      console.log(resObj);

      return {
        approved: resObj.VoidResponse.responseCode === "A0000",
        authCode: resObj.VoidResponse.authCode,
        retref: resObj.VoidResponse.transactionID,
      };
    } catch (ex) {
      console.error(ex);
    }
  }

  static async return(merchantId, retref) {
    try {
      if (!merchantId) return console.error("Invalid merchantId(tsys2.sale)");
      if (!retref) return console.error("Invalid retref(tsys2.sale)");

      const { responseCode, transactionKey } =
        (await Tsys2Db.generateKey(merchantId)) || {};

      if (responseCode != "A0000") {
        return console.error("Fail to generate transaction key(tsys2.return)");
      }

      const returnReqObj = {
        transactionKey,
        retref,
      };

      const returnResObj = (
        (await axios.post(
          `${TSYS2_ROUTE_URL}/merchantId/${merchantId}/return`,
          returnReqObj
        )) || {}
      ).data;

      if (!returnResObj || !returnResObj.ReturnResponse) {
        return console.error(
          "Fail to get return response when doing return (tsys2)"
        );
      }

      console.log(returnResObj);

      return {
        approved: returnResObj.ReturnResponse.responseCode === "A0000",
        authCode: returnResObj.ReturnResponse.authCode,
        retref: returnResObj.ReturnResponse.transactionID,
      };
    } catch (ex) {
      console.error(ex);
    }
  }

  static async adjustSale(merchantId, adjustedTips, retref) {
    try {
      if (!merchantId)
        return console.error("Invalid merchantId(tsys2.adjustSale)");
      if (!adjustedTips)
        return console.error("Invalid adjustedTips(tsys2.adjustSale)");
      if (!retref) return console.error("Invalid retref(tsys2.adjustSale)");

      const { success, transactionKey } =
        (await Tsys2Db.generateKey(merchantId)) || {};

      if (!success) {
        return console.error(
          "Fail to generate transaction key(tsys2.adjustSale)"
        );
      }

      const transactionAdjustmentReqObj = {
        transactionKey,
        adjustedTips,
        retref,
      };

      const resObj = (
        (await axios.post(
          `${TSYS2_ROUTE_URL}/merchantId/${merchantId}/transactionAdjustment`,
          transactionAdjustmentReqObj
        )) || {}
      ).data;

      if (!resObj || !resObj.TipAdjustmentResponse) {
        return console.error(
          "Fail to get sale response when doing sale adjustment (tsys2)"
        );
      }

      console.log(resObj);

      return {
        approved: resObj.TipAdjustmentResponse.responseCode === "A0000",
        retref: resObj.TipAdjustmentResponse.transactionID,
      };
    } catch (ex) {
      console.error(ex);
    }
  }

  static async adjustTip(merchantId, adjustedTips, retref) {
    try {
      if (!merchantId)
        return console.error("Invalid merchantId(tsys2.adjustTip)");
      if (!adjustedTips)
        return console.error("Invalid tip amount(tsys2.adjustTip)");
      if (!retref) return console.error("Invalid retref(tsys2.adjustTip)");

      const { success, transactionKey } =
        (await Tsys2Db.generateKey(merchantId)) || {};

      if (!success) {
        return console.error(
          "Fail to generate transaction key(tsys2.adjustTip)"
        );
      }

      const reqObj = {
        transactionKey,
        adjustedTips,
        retref,
      };

      const resObj = (
        (await axios.post(
          `${TSYS2_ROUTE_URL}/merchantId/${merchantId}/tipAdjustment`,
          reqObj
        )) || {}
      ).data;

      if (!resObj || !resObj.TipAdjustmentResponse) {
        return console.error(
          "Fail to get sale response when doing tip adjustment (tsys2)"
        );
      }

      console.log(resObj);

      return {
        approved: resObj.TipAdjustmentResponse.responseCode === "A0000",
        retref: resObj.TipAdjustmentResponse.transactionID,
      };
    } catch (ex) {
      console.error(ex);
    }
  }
}
