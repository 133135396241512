const axios = require("axios");

const BusinessInfoDb = require("./../dataModel/businessInfoDb");

const ELAVON_ROUTE_URL = "/api/elavons";

export default class ElavonDb {
  static async getTokenSession(merchantId, amount) {
    try {
      if (!merchantId || !amount)
        return console.error("Invalid merchantId or amount(getTokenSession)");

      return (
        (await axios.get(
          `${ELAVON_ROUTE_URL}/merchantId/${merchantId}/amount/${amount}/tokenSession`
        )) || {}
      ).data;
    } catch (ex) {
      console.error(ex);
    }
  }

  static async sale(merchantId, amount, creditcardInfo) {
    try {
      if (!merchantId) return console.error("Invalid merchantId(sale)");

      if (!creditcardInfo)
        return console.error("Null creditcard info provided");

      if (!amount) return console.error("Null amount provided");

      const tokenSession = await ElavonDb.getTokenSession(
        merchantId,
        amount.toFixed(2)
      );

      if (!tokenSession) {
        return console.error("Invalid Token session fetched");
      }

      const { acount, expiry, cvv2, postal, billingAddr, name, phone, email } =
        creditcardInfo;
      if (acount == null || amount == null || expiry == null || cvv2 == null) {
        return { error: "Missing required field" };
      }

      const cardInfo = {
        ssl_card_number: acount,
        ssl_exp_date: expiry,
        ssl_cvv2cvc2: cvv2,
        ssl_avs_address: billingAddr,
        ssl_avs_zip: postal,
      };

      // console.log("Request Obj:", {
      //   ssl_txn_auth_token: tokenSession,
      //   ...cardInfo,
      //   amount: amount.toFixed(2),
      //   ssl_avs_address: billingAddr,
      //   ssl_avs_zip: postal,
      // });
      const response = await new Promise((resolve, reject) => {
        window.ConvergeEmbeddedPayment.pay(
          {
            ssl_txn_auth_token: tokenSession,
            ...cardInfo,
          },
          {
            onError: function (error) {
              reject(error);
            },
            onDeclined: function (response) {
              resolve(response);
            },
            onApproval: function (response) {
              resolve(response);
            },
          }
        );
      });

      // console.log(123, response);

      return {
        approved: response && response.ssl_result === "0",
        amount: response && response.ssl_amount,
        cardNumber: response && response.ssl_card_number,
        cardExpiry: response && response.ssl_exp_date,
        cardType: response && response.ssl_card_short_description,
        authCode: response && response.ssl_approval_code,
        retref: response && response.ssl_txn_id,
      };
    } catch (ex) {
      console.error(ex);
    }
  }
}
