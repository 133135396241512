const axios = require("axios");

const CARDPOINTE_ROUTE_URL = "/api/cardPointes";

export default class CardPointeDb {
  /*    orderID format "LK" + order.orderid

        return:{
            approved: Boolean,
            orderID: String,
            amount: Number,
            merchantId: String,
            account: String,
            name: String,
        }
  */
  // not in use
  static async inquireByOrderID(merchantId, orderID) {
    try {
      if (!merchantId)
        return console.error("Invalid merchantId(inquireByOrderID)");

      if (!orderID) return console.error("Invalid orderID provided");

      return (
        (await axios.get(
          `${CARDPOINTE_ROUTE_URL}/merchantId/${merchantId}/inquireByOrderID/${orderID}`
        )) || {}
      ).data;
    } catch (ex) {
      console.error(ex);
    }
  }

  // check if account is valid
  /*
        creditcardInfo:{
            acount:String,
            expiry:String,
            cvv2:String
        }

        return true/false
  */
  // not in use
  static async verifyCardInfo(merchantId, creditcardInfo) {
    try {
      if (!merchantId)
        return console.error("Invalid merchantId(verifyCardInfo)");

      if (!creditcardInfo)
        return console.error("Null creditcard info provided");

      const { acount, expiry, cvv2 } = creditcardInfo;
      if (acount == null || expiry == null || cvv2 == null) {
        return { error: "Missing required field" };
      }

      const veriRes = (
        (await axios.post(
          `${CARDPOINTE_ROUTE_URL}/merchantId/${merchantId}/auth`,
          {
            acount: acount,
            expiry: expiry,
            cvv2: cvv2,
            amount: 0,
          }
        )) || {}
      ).data;

      console.log(veriRes);

      return veriRes && veriRes.approved;
    } catch (ex) {
      console.error(ex);
    }
  }

  /*
        creditcardInfo:{
            acount:String,
            expiry:String,
            cvv2:String,
            postal:String,
            billingAddr:String,
            name: String,
            phone: String,
            email: String,
        }

        return: {
            approved:Boolean,
            amount:Number,
            merchantId:String
            cardNumber:String,
            cardExpiry:String,
            cardType:String,
            authCode:String,
            retref:String
        }
    */
  static async authorizePayment(
    merchantId,
    amount,
    creditcardInfo,
    recaptchaToken
  ) {
    try {
      if (!merchantId)
        return console.error("Invalid merchantId(authorizePayment)");

      if (!creditcardInfo)
        return console.error("Null creditcard info provided");

      if (!recaptchaToken) {
        return console.error(`Unauthorized, Invalid Token:[${recaptchaToken}]`);
      }

      const { acount, expiry, cvv2, postal, billingAddr, name, phone, email } =
        creditcardInfo;
      if (acount == null || amount == null || expiry == null || cvv2 == null) {
        return { error: "Missing required field" };
      }

      // convert number to decimal string
      const decimal = amount.toFixed(2);

      //console.log(123, decimal);

      return (
        (await axios.post(
          `${CARDPOINTE_ROUTE_URL}/merchantId/${merchantId}/auth`,
          {
            acount: acount,
            expiry: expiry,
            cvv2: cvv2,
            amount: decimal,
            postal: postal,
            address: billingAddr,
            name,
            phone,
            email,
            recaptchaToken,
          }
        )) || {}
      ).data;
    } catch (ex) {
      console.error(ex);
    }
  }

  // capture
  /*
        return: {
            approved:Boolean,
            amount:Number,
            merchantId:String
            retref:String,
            respcode:String,
        }
    */
  static async capturePayment(merchantId, amount, retref) {
    try {
      if (!merchantId)
        return console.error("Invalid merchantId(capturePayment)");

      if (!retref) return console.error("Null retref info provided");

      const decimal = amount.toFixed(2);

      return (
        (await axios.post(
          `${CARDPOINTE_ROUTE_URL}/merchantId/${merchantId}/capture`,
          {
            amount: decimal,
            retref: retref,
          }
        )) || {}
      ).data;
    } catch (ex) {
      console.error(ex);
    }
  }

  // void
  /*
        return: {
            approved:Boolean,
            amount:Number,
            merchantId:String
            retref:String,
            respcode:String,
        }
    */
  static async voidPayment(merchantId, retref) {
    try {
      if (!merchantId) return console.error("Invalid merchantId(voidPayment)");
      if (!retref) return console.error("Null retref info provided");

      return (
        (await axios.post(
          `${CARDPOINTE_ROUTE_URL}/merchantId/${merchantId}/void`,
          {
            retref: retref,
          }
        )) || {}
      ).data;
    } catch (ex) {
      console.error(ex);
    }
  }
}
