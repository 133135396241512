import React, { Component } from "react";
import {
  Segment,
  Dimmer,
  Loader,
  Dropdown,
  Button,
  Header,
  Table,
  Form,
  Container,
  Checkbox,
  List,
  Card,
  TextArea,
  Icon,
  Transition,
  Input,
  Modal,
} from "semantic-ui-react";
import swal from "sweetalert";
import moment from "moment";
import TimePicker from "rc-time-picker";
import BusinessInfoDb from "../dataModel/businessInfoDb";
import MenuDb from "./../dataModel/menuDb";
import {
  formatPhoneNumber,
  getBusinessHourStr,
  verifyMerchantPsw,
  getAvailableHourStr,
  secondsToTimeStr,
} from "../dataModel/utils";
import QRCode from "qrcode.react";
import Pdf from "react-to-pdf";

const dayNames = [
  "sunday",
  "monday",
  "tuesday",
  "wednesday",
  "thursday",
  "friday",
  "saturday",
];

const foodPreparingTimeOptions = [
  {
    key: "normal",
    text: "Normal",
    value: "normal",
  },
  {
    key: "busy",
    text: "Busy",
    value: "busy",
  },
  {
    key: "veryBusy",
    text: "Very busy",
    value: "veryBusy",
  },
];

const pdf_ref = React.createRef(); //

class BusinessInfoPanel extends Component {
  state = {
    loading: true,

    businessInfo: null,
    businessInfo_update: null,

    businessHour: null,
    businessHour_update: null, // { "monday":[{"open":Number, "close":Number}, ...],  }
    businessClosedDayNames: [], // [ "monday", "tuesday", ... ]

    foodPreparingTime: null,
    foodPreparingTime_update: null,
    fromMin: null, // [  ] 0 - sunday
    toMin: null, // [  ] 0 sunday

    inBusinessInfoEdit: false,
    inBusinessHoursEdit: false,
    inFoodPreparingTimeEdit: false,
    inCategoryHoursEdit: false,
    inCategoryPromoMethodEdit: false,
    inNoticeEdit: false,
    inNotificationEdit: false,
    inOrderPromotionEdit: false,

    inStoreSettingEdit: false,
    showPasswordValidness: false,

    categories: null,
    categoriesSelected_hour: [],
    categoryHour_update: null, // { "monday":[{"open":Number, "close":Number}, ...],  }
    categoryAlwaysAvailableDayNames: [],

    categoriesSelected_promo: [],
    categoryPromoMethod_update: null,

    freeItemsListStr: null,
    deliveryHour_update: null,

    enableDinein: null,
    showQRCodeGeneratorModal: false,
    dineinTableNameEntered: null,
  };

  componentDidMount = async () => {
    const { merchantId } = this.props;

    this.startLoading();
    const businessInfo = await BusinessInfoDb.getBusinessInfo(merchantId);
    const businessHour = businessInfo && businessInfo.businessHour;
    const foodPreparingTime = businessInfo && businessInfo.foodPreparingTime;

    const categories = await MenuDb.getCategories(merchantId);
    this.endLoading();

    this.setState({
      businessInfo: businessInfo && JSON.parse(JSON.stringify(businessInfo)),
      businessInfo_update:
        businessInfo && JSON.parse(JSON.stringify(businessInfo)),
      businessHour,
      foodPreparingTime,
      categories,

      storeTaxRate: businessInfo && businessInfo.taxRate,
      deliveryHour_update: businessInfo.deliveryAvailableTime,

      freeItemsListStr:
        businessInfo &&
        businessInfo.orderPromotion &&
        businessInfo.orderPromotion.freeItemNames &&
        businessInfo.orderPromotion.freeItemNames.join(", "),

      enableDinein: businessInfo.dinein,
    });
  };

  startLoading = (msg) => {
    this.setState({
      loading: true,
    });
  };

  endLoading = () => {
    this.setState({
      loading: false,
    });
  };

  // ----------------------------- businessInfo_update --------------------------------

  handlePswChange = (value) => {
    const updated = { ...this.state.businessInfo_update };
    updated.password = value;
    this.setState({
      businessInfo_update: updated,
    });
  };

  handlePhoneChange = (value) => {
    const updated = { ...this.state.businessInfo_update };
    updated.phone = value;
    this.setState({
      businessInfo_update: updated,
    });
  };

  handleEmailChange = (value) => {
    const updated = { ...this.state.businessInfo_update };
    updated.email = value;
    this.setState({
      businessInfo_update: updated,
    });
  };

  handleWebsiteChange = (value) => {
    const updated = { ...this.state.businessInfo_update };
    updated.webSite = value;
    this.setState({
      businessInfo_update: updated,
    });
  };

  handleStreetChange = (value) => {
    const updated = { ...this.state.businessInfo_update };
    updated.address = updated.address || {};
    updated.address.street = value;
    this.setState({
      businessInfo_update: updated,
    });
  };

  handleCityChange = (value) => {
    const updated = { ...this.state.businessInfo_update };
    updated.address = updated.address || {};
    updated.address.city = value;
    this.setState({
      businessInfo_update: updated,
    });
  };

  handleStateChange = (value) => {
    const updated = { ...this.state.businessInfo_update };
    updated.address = updated.address || {};
    updated.address.state = value;
    this.setState({
      businessInfo_update: updated,
    });
  };

  handlePostalCodeChange = (value) => {
    const updated = { ...this.state.businessInfo_update };
    updated.address = updated.address || {};
    updated.address.postalCode = value;
    this.setState({
      businessInfo_update: updated,
    });
  };

  handleBusinessInfoEditSave = async () => {
    const { businessInfo_update } = this.state;
    if (
      verifyMerchantPsw(businessInfo_update && businessInfo_update.password)
        .error
    ) {
      return this.setState({
        showPasswordValidness: true,
      });
    }

    if (!businessInfo_update || !businessInfo_update.merchantId) {
      return swal({
        title: "Fail to save businessInfo_update",
        text: "Internal error",
        icon: "error", // replace type with icon, if using new version
        timer: 1500,

        buttons: false,
      });
    }

    const updatedBusinessInfo = await BusinessInfoDb.updateBusinessInfo(
      this.props.merchantId,
      businessInfo_update
    );
    if (updatedBusinessInfo) {
      swal({
        title: "Updated",
        icon: "success", // replace type with icon, if using new version
        timer: 1000,
        buttons: false,
      });

      this.setState({
        businessInfo:
          updatedBusinessInfo &&
          JSON.parse(JSON.stringify(updatedBusinessInfo)),
        businessInfo_update:
          updatedBusinessInfo &&
          JSON.parse(JSON.stringify(updatedBusinessInfo)),
        inBusinessInfoEdit: false,
      });
    } else {
      swal({
        title: "Fail to save businessInfo_update",
        text: "Please check the network or database setting",
        icon: "error", // replace type with icon, if using new version
        timer: 1500,

        buttons: false,
      });
    }

    // this.setState({
    //   businessInfo_update: JSON.parse(JSON.stringify(this.state.businessInfo)),
    //   inBusinessInfoEdit: false,
    // });
  };

  handleBusinessInfoEditCancel = async () => {
    const willCancel = await swal({
      title: "Are you sure?",
      text: "Changes will not be saved.",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    });

    if (!willCancel) {
      return;
    }

    this.setState({
      businessInfo_update:
        this.state.businessInfo &&
        JSON.parse(JSON.stringify(this.state.businessInfo)),
      inBusinessInfoEdit: false,
    });
  };

  // ----------------------------- businessHour --------------------------------

  // day 0 - 6, sun - sat
  // isFrom: false means "to"
  // periodOfTime: 0 - n
  handleBusinessHourChange = (moment, day, isFrom, periodOfTime) => {
    if (!moment) return;

    var mmtMidnight = moment.clone().startOf("day");
    // Difference in minutes
    var diffMinutes = moment.diff(mmtMidnight, "minutes");

    const dayName = dayNames[day];
    if (!dayName) return console.log("Invalid day provided");

    const businessHour_update = this.state.businessHour_update || {};
    let businessHour = businessHour_update[dayName];

    if (!businessHour) {
      businessHour_update[dayName] = [];
      businessHour = businessHour_update[dayName];
    }

    const period = periodOfTime || 0;
    businessHour[period] = businessHour[period] || {};

    if (!!isFrom) {
      businessHour[period].open = diffMinutes;
    } else {
      businessHour[period].close = diffMinutes;
    }

    this.setState({ businessHour_update });
  };

  handleCloseBusinessDay = (day, checked) => {
    const dayName = dayNames[day];
    if (!dayName) return console.log("Invalid day provided");

    const businessClosedDayNames = this.state.businessClosedDayNames || [];
    const index = businessClosedDayNames.indexOf(dayName);
    if (checked) {
      index < 0 && businessClosedDayNames.push(dayName);
    } else {
      index >= 0 && businessClosedDayNames.splice(index, 1);
    }

    this.setState({
      businessClosedDayNames,
    });
  };

  handleBusinessHoursEditClick = () => {
    const businessClosedDayNames = [];
    for (let dayName in this.state.businessHour || {}) {
      const hourRanges = this.state.businessHour[dayName];
      if (!hourRanges || hourRanges.length <= 0) {
        businessClosedDayNames.push(dayName);
      }
    }

    this.setState({
      inBusinessHoursEdit: true,
      businessHour_update:
        this.state.businessHour &&
        JSON.parse(JSON.stringify(this.state.businessHour)),
      businessClosedDayNames,
    });
  };

  handleBusinessHoursEditSave = async () => {
    const { businessClosedDayNames, businessHour_update, businessInfo } =
      this.state;

    (businessClosedDayNames || []).forEach((dayName) => {
      businessHour_update && delete businessHour_update[dayName];
    });

    businessInfo.businessHour = businessHour_update;
    const updatedBusinessInfo =
      this.props.merchantId &&
      (await BusinessInfoDb.updateBusinessInfo(
        this.props.merchantId,
        businessInfo
      ));

    if (updatedBusinessInfo) {
      swal({
        title: "Updated",
        icon: "success", // replace type with icon, if using new version
        timer: 1000,
        buttons: false,
      });

      this.setState({
        businessHour:
          updatedBusinessInfo.businessHour &&
          JSON.parse(JSON.stringify(updatedBusinessInfo.businessHour)),
        businessHour_update:
          updatedBusinessInfo.businessHour &&
          JSON.parse(JSON.stringify(updatedBusinessInfo.businessHour)),
        inBusinessHoursEdit: false,
      });
    } else {
      swal({
        title: "Fail to save business Hours",
        text: "Please check the network or database setting",
        icon: "error", // replace type with icon, if using new version
        timer: 1500,
        buttons: false,
      });
    }
  };

  handleBusinessHoursEditCancel = async () => {
    const willCancel = await swal({
      title: "Are you sure?",
      text: "Changes will not be saved.",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    });

    if (!willCancel) {
      return;
    }

    this.setState({
      inBusinessHoursEdit: false,
      businessHour_update:
        this.state.businessHour &&
        JSON.parse(JSON.stringify(this.state.businessHour)),
      businessClosedDayNames: [],
    });
  };

  // day 0 - 6, sun - sat
  handleHourRangeAddClick = async (day) => {
    const dayName = dayNames[day];
    if (!dayName) return console.log("Invalid day provided");

    const businessHour =
      this.state.businessHour_update &&
      JSON.parse(JSON.stringify(this.state.businessHour_update));
    businessHour &&
      businessHour[dayName] &&
      businessHour[dayName].push({ open: 0, close: 0 });

    this.setState({ businessHour_update: businessHour });
  };

  handleHourRangeRemoveClick = async (day, index) => {
    if (index == null) return;

    const dayName = dayNames[day];
    if (!dayName) return console.log("Invalid day provided");

    const businessHour = JSON.parse(
      JSON.stringify(this.state.businessHour_update)
    );

    businessHour &&
      businessHour[dayName] &&
      businessHour[dayName].splice(index, 1);

    this.setState({ businessHour_update: businessHour });
  };

  // ------------------------------- food preparing time -----------------------------
  handleFoodPreparingTimeEditClick = () => {
    this.setState({
      inFoodPreparingTimeEdit: true,
      foodPreparingTime_update:
        this.state.foodPreparingTime &&
        JSON.parse(JSON.stringify(this.state.foodPreparingTime)),
    });
  };

  handleFoodPreparingTimeEditCancel = async () => {
    const willCancel = await swal({
      title: "Are you sure?",
      text: "Changes will not be saved.",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    });

    if (!willCancel) {
      return;
    }

    this.setState({
      inFoodPreparingTimeEdit: false,
      foodPreparingTime_update:
        this.state.foodPreparingTime &&
        JSON.parse(JSON.stringify(this.state.foodPreparingTime)),
    });
  };

  //day:monday...  status:busy, normal, veryBusy
  handleFoodPreparingTimeStatusChange = (day, status) => {
    if (!status) return;

    const dayName = dayNames[day];
    if (!dayName) return console.log("Invalid day provided");

    const foodPreparingTime_update = this.state.foodPreparingTime_update || {};

    if (!foodPreparingTime_update[dayName]) {
      foodPreparingTime_update[dayName] = {};
    }

    foodPreparingTime_update[dayName].status = status;

    this.setState({ foodPreparingTime_update });
  };

  handleFoodPreparingTimeEstimateChange = (day, estimate) => {
    const dayName = dayNames[day];
    if (!dayName) return console.log("Invalid day provided");

    const foodPreparingTime_update = this.state.foodPreparingTime_update || {};

    if (!foodPreparingTime_update[dayName]) {
      foodPreparingTime_update[dayName] = {};
    }

    foodPreparingTime_update[dayName].estimate = estimate;

    this.setState({ foodPreparingTime_update });
  };

  handleFoodPreparingTimeForPickupFromMinChange = (day, fromMin) => {
    const dayName = dayNames[day];
    if (!dayName) return console.log("Invalid day provided");

    const foodPreparingTime_update = this.state.foodPreparingTime_update || {};

    if (!foodPreparingTime_update[dayName]) {
      foodPreparingTime_update[dayName] = {};
    }

    foodPreparingTime_update[dayName].fromMin_pickup = fromMin;

    this.setState({ foodPreparingTime_update });
  };

  handleFoodPreparingTimeForPickupToMinChange = (day, toMin) => {
    const dayName = dayNames[day];
    if (!dayName) return console.log("Invalid day provided");

    const foodPreparingTime_update = this.state.foodPreparingTime_update || {};

    if (!foodPreparingTime_update[dayName]) {
      foodPreparingTime_update[dayName] = {};
    }

    foodPreparingTime_update[dayName].toMin_pickup = toMin;

    this.setState({ foodPreparingTime_update });
  };

  handleFoodPreparingTimeForDeliveryFromMinChange = (day, fromMin) => {
    const dayName = dayNames[day];
    if (!dayName) return console.log("Invalid day provided");

    const foodPreparingTime_update = this.state.foodPreparingTime_update || {};

    if (!foodPreparingTime_update[dayName]) {
      foodPreparingTime_update[dayName] = {};
    }

    foodPreparingTime_update[dayName].fromMin_delivery = fromMin;

    this.setState({ foodPreparingTime_update });
  };

  handleFoodPreparingTimeForDeliveryToMinChange = (day, toMin) => {
    const dayName = dayNames[day];
    if (!dayName) return console.log("Invalid day provided");

    const foodPreparingTime_update = this.state.foodPreparingTime_update || {};

    if (!foodPreparingTime_update[dayName]) {
      foodPreparingTime_update[dayName] = {};
    }

    foodPreparingTime_update[dayName].toMin_delivery = toMin;

    this.setState({ foodPreparingTime_update });
  };

  handleFoodPreparingTimeEditSave = async () => {
    const { foodPreparingTime_update, businessInfo } = this.state;

    businessInfo.foodPreparingTime = foodPreparingTime_update;
    const updatedBusinessInfo =
      this.props.merchantId &&
      (await BusinessInfoDb.updateBusinessInfo(
        this.props.merchantId,
        businessInfo
      ));

    if (updatedBusinessInfo) {
      swal({
        title: "Updated",
        icon: "success", // replace type with icon, if using new version
        timer: 1000,
        buttons: false,
      });

      this.setState({
        foodPreparingTime:
          updatedBusinessInfo.foodPreparingTime &&
          JSON.parse(JSON.stringify(updatedBusinessInfo.foodPreparingTime)),
        foodPreparingTime_update:
          updatedBusinessInfo.foodPreparingTime &&
          JSON.parse(JSON.stringify(updatedBusinessInfo.foodPreparingTime)),
        inFoodPreparingTimeEdit: false,
      });
    } else {
      swal({
        title: "Fail to save Food preparing time",
        text: "Please check the network or database setting",
        icon: "error", // replace type with icon, if using new version
        timer: 1500,
        buttons: false,
      });
    }

    // console.log(555, businessHour_update);
    //businessClosedDayNames: [],
  };

  // -------------------------------- categoryHour -----------------------------------
  handleSelectedCategoriesChanged = (value) => {
    const { availableHour: categoryHour } = (value || [])[0] || {};

    // ----------------------- categoryAlwaysAvailableDayNames -----------------------
    const categoryAlwaysAvailableDayNames = [];
    for (let dayName in categoryHour || {}) {
      const hourRanges = categoryHour[dayName];
      if (!hourRanges || hourRanges.length <= 0) {
        categoryAlwaysAvailableDayNames.push(dayName);
      }
    }

    this.setState({
      categoriesSelected_hour: value,
      categoryHour_update: categoryHour,
      categoryAlwaysAvailableDayNames,
    });
  };

  handleCategoryHourChange = (moment, day, isFrom, periodOfTime) => {
    if (!moment) return;

    var mmtMidnight = moment.clone().startOf("day");
    // Difference in minutes
    var diffMinutes = moment.diff(mmtMidnight, "minutes");

    const dayName = dayNames[day];
    if (!dayName) return console.log("Invalid day provided");

    const categoryHour_update = this.state.categoryHour_update || {};
    let categoryHour = categoryHour_update[dayName];

    if (!categoryHour) {
      categoryHour_update[dayName] = [];
      categoryHour = categoryHour_update[dayName];
    }

    const period = periodOfTime || 0;
    categoryHour[period] = categoryHour[period] || {};

    if (!!isFrom) {
      categoryHour[period].open = diffMinutes;
    } else {
      categoryHour[period].close = diffMinutes;
    }

    this.setState({ categoryHour_update });
  };

  handleCategoryHoursEditClick = () => {
    this.setState({
      inCategoryHoursEdit: true,
    });
  };

  handleCategoryHoursEditSave = async () => {
    const {
      categoryHour_update,
      categoriesSelected_hour,
      categoryAlwaysAvailableDayNames,
    } = this.state;
    const { merchantId } = this.props;

    (categoryAlwaysAvailableDayNames || []).forEach((dayName) => {
      categoryHour_update && delete categoryHour_update[dayName];
    });

    if (categoryHour_update) {
      for (let index in categoriesSelected_hour || []) {
        const category = categoriesSelected_hour[index];
        const updatedCategory = await MenuDb.updateCategoryById(
          merchantId,
          category._id,
          { availableHour: categoryHour_update }
        );
        if (!updatedCategory) {
          return swal({
            title: "Fail to save category Hours",
            text: "Please check the network or database setting",
            icon: "error", // replace type with icon, if using new version
            timer: 1500,
            buttons: false,
          });
        }
      }
    }

    swal({
      title: "Updated",
      icon: "success", // replace type with icon, if using new version
      timer: 1000,
      buttons: false,
    });

    this.setState({
      inCategoryHoursEdit: false,
      categoryHour_update: null,
      categoriesSelected_hour: [],
      categories: await MenuDb.getCategories(merchantId),
    });
  };

  handleCategoryHoursEditCancel = async () => {
    const willCancel = await swal({
      title: "Are you sure?",
      text: "Changes will not be saved.",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    });

    if (!willCancel) {
      return;
    }

    this.setState({
      inCategoryHoursEdit: false,
      categoryHour_update: null,
      categoriesSelected_hour: [],
    });
  };

  handleCategoryHourRangeRemoveClick = async (day, index) => {
    if (index == null) return;

    const dayName = dayNames[day];
    if (!dayName) return console.log("Invalid day provided");

    const categoryHour = JSON.parse(
      JSON.stringify(this.state.categoryHour_update)
    );

    categoryHour &&
      categoryHour[dayName] &&
      categoryHour[dayName].splice(index, 1);

    this.setState({ categoryHour_update: categoryHour });
  };

  handleCategoryHourAlwaysAvailable = async (day, checked) => {
    const dayName = dayNames[day];
    if (!dayName) return console.log("Invalid day provided");
    const categoryAlwaysAvailableDayNames =
      this.state.categoryAlwaysAvailableDayNames || [];
    const index = categoryAlwaysAvailableDayNames.indexOf(dayName);
    if (checked) {
      index < 0 && categoryAlwaysAvailableDayNames.push(dayName);
    } else {
      index >= 0 && categoryAlwaysAvailableDayNames.splice(index, 1);
    }

    this.setState({
      categoryAlwaysAvailableDayNames,
    });
  };

  // --------------------------------- category promo Mthod -----------------------
  handleCategoryPromoMethodEditClick = () => {
    this.setState({
      inCategoryPromoMethodEdit: true,
    });
  };

  handleCategoryPromoMethodEditCancel = async () => {
    const willCancel = await swal({
      title: "Are you sure?",
      text: "Changes will not be saved.",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    });

    if (!willCancel) {
      return;
    }

    this.setState({
      inCategoryPromoMethodEdit: false,
    });
  };

  handleSelectedCategoriesPromoChanged = (value) => {
    const { promoMethod } = (value || [])[0] || {};

    this.setState({
      categoriesSelected_promo: value,
      categoryPromoMethod_update: promoMethod,
    });
  };

  handleCategoryPromoMethodNameChange = (value) => {
    const updated = { ...this.state.categoryPromoMethod_update };
    updated.name = value;
    this.setState({
      categoryPromoMethod_update: updated,
    });
  };

  handleCategoryPromoMethodTypeChange = (value) => {
    const updated = { ...this.state.categoryPromoMethod_update };
    updated.type = value;
    this.setState({
      categoryPromoMethod_update: updated,
    });
  };

  handleCategoryPromoMethodXChange = (value) => {
    const updated = { ...this.state.categoryPromoMethod_update };
    updated.X = value;
    this.setState({
      categoryPromoMethod_update: updated,
    });
  };

  handleCategoryPromoMethodYChange = (value) => {
    const updated = { ...this.state.categoryPromoMethod_update };
    updated.Y = value;
    this.setState({
      categoryPromoMethod_update: updated,
    });
  };

  handleCategoryPromoMethodEditSave = async () => {
    const { categoryPromoMethod_update, categoriesSelected_promo } = this.state;
    const { merchantId } = this.props;

    if (!categoryPromoMethod_update) {
      return swal({
        title: "Fail to save category Promo Method",
        text: "Name, Type, X, Y fields need to be filled",
        icon: "error", // replace type with icon, if using new version
        timer: 1500,
        buttons: false,
      });
    }

    const { name, type, X, Y } = categoryPromoMethod_update;
    if (!name || !type || X == null || Y == null) {
      return swal({
        title: "Fail to save category Promo Method",
        text: "Name, Type, X, Y fields need to be filled",
        icon: "error", // replace type with icon, if using new version
        timer: 1500,
        buttons: false,
      });
    }

    // return console.log(11111, categoryPromoMethod_update);

    for (let index in categoriesSelected_promo || []) {
      const category = categoriesSelected_promo[index];
      const updatedCategory = await MenuDb.updateCategoryById(
        merchantId,
        category._id,
        { promoMethod: categoryPromoMethod_update }
      );

      if (!updatedCategory) {
        return swal({
          title: "Fail to save category Promo Method",
          text: "Please check the network or database setting",
          icon: "error", // replace type with icon, if using new version
          timer: 1500,
          buttons: false,
        });
      }
    }

    swal({
      title: "Updated",
      icon: "success", // replace type with icon, if using new version
      timer: 1000,
      buttons: false,
    });

    this.setState({
      inCategoryPromoMethodEdit: false,
      categoryPromoMethod_update: null,
      categoriesSelected_promo: [],
      categories: await MenuDb.getCategories(merchantId),
    });
  };

  handleCategoryPromoMethodRemoveClick = async (_idCategory) => {
    const { merchantId } = this.props;

    const willRemove = await swal({
      title: "Are you sure?",
      text: "",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    });

    if (!willRemove) {
      return;
    }

    console.log(111, _idCategory);
    await MenuDb.removeCategoryPromoMethodById(merchantId, _idCategory);

    this.setState({
      categories: await MenuDb.getCategories(merchantId),
    });
  };

  // ------------------------------ notice --------------------------------------------
  handleHomePageNoticeChange = (value) => {
    const updated = { ...this.state.businessInfo_update };
    updated.homePageNotice = value;
    this.setState({
      businessInfo_update: updated,
    });
  };

  handleOrderPreparingNoticeChange = (value) => {
    const updated = { ...this.state.businessInfo_update };
    updated.orderPreparingNotice = value;
    this.setState({
      businessInfo_update: updated,
    });
  };

  handleDineinNoticeChange = (value) => {
    const updated = { ...this.state.businessInfo_update };
    updated.dineinNotice = value;
    this.setState({
      businessInfo_update: updated,
    });
  };

  handleNoticeSave = async () => {
    const { businessInfo_update } = this.state;

    // const val = value.replace(/\r?\n/g, "<br/>");
    const { orderPreparingNotice, homePageNotice, dineinNotice } =
      businessInfo_update || {};
    if (orderPreparingNotice) {
      businessInfo_update.orderPreparingNotice = orderPreparingNotice.replace(
        /\r?\n/g,
        "<br/>"
      );
    }
    if (homePageNotice) {
      businessInfo_update.homePageNotice = homePageNotice.replace(
        /\r?\n/g,
        "<br/>"
      );
    }
    if (dineinNotice) {
      businessInfo_update.dineinNotice = dineinNotice.replace(
        /\r?\n/g,
        "<br/>"
      );
    }

    const updatedBusinessInfo = await BusinessInfoDb.updateBusinessInfo(
      this.props.merchantId,
      businessInfo_update
    );
    if (updatedBusinessInfo) {
      swal({
        title: "Updated",
        icon: "success", // replace type with icon, if using new version
        timer: 1000,
        buttons: false,
      });

      this.setState({
        businessInfo:
          updatedBusinessInfo &&
          JSON.parse(JSON.stringify(updatedBusinessInfo)),
        businessInfo_update:
          updatedBusinessInfo &&
          JSON.parse(JSON.stringify(updatedBusinessInfo)),
        inNoticeEdit: false,
      });
    } else {
      swal({
        title: "Fail to save Notice",
        text: "Please check the network or database setting",
        icon: "error", // replace type with icon, if using new version
        timer: 1500,

        buttons: false,
      });
    }
  };

  handleNoticeCancel = async () => {
    const willCancel = await swal({
      title: "Are you sure?",
      text: "Changes will not be saved.",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    });

    if (!willCancel) {
      return;
    }

    this.setState({
      businessInfo_update:
        this.state.businessInfo &&
        JSON.parse(JSON.stringify(this.state.businessInfo)),
      inNoticeEdit: false,
    });
  };

  // --------------------------- notification ----------------------------------
  handleEmailReceivingNotificationChange = (value) => {
    const updated = { ...this.state.businessInfo_update };
    updated.emailReceivingNotification = value;
    this.setState({
      businessInfo_update: updated,
    });
  };

  handleNotificationEditSave = async () => {
    const { businessInfo_update } = this.state;

    if (!businessInfo_update || !businessInfo_update.merchantId) {
      return swal({
        title: "Fail to save notification update",
        text: "Internal error",
        icon: "error", // replace type with icon, if using new version
        timer: 1500,

        buttons: false,
      });
    }

    const updatedBusinessInfo = await BusinessInfoDb.updateBusinessInfo(
      this.props.merchantId,
      businessInfo_update
    );
    if (updatedBusinessInfo) {
      swal({
        title: "Updated",
        icon: "success", // replace type with icon, if using new version
        timer: 1000,
        buttons: false,
      });

      this.setState({
        businessInfo:
          updatedBusinessInfo &&
          JSON.parse(JSON.stringify(updatedBusinessInfo)),
        businessInfo_update:
          updatedBusinessInfo &&
          JSON.parse(JSON.stringify(updatedBusinessInfo)),
        inNotificationEdit: false,
      });
    } else {
      swal({
        title: "Fail to save notification setting",
        text: "Please check the network or database setting",
        icon: "error", // replace type with icon, if using new version
        timer: 1500,

        buttons: false,
      });
    }
  };

  handleNotificationEditCancel = async () => {
    const willCancel = await swal({
      title: "Are you sure?",
      text: "Changes will not be saved.",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    });

    if (!willCancel) {
      return;
    }

    this.setState({
      businessInfo_update:
        this.state.businessInfo &&
        JSON.parse(JSON.stringify(this.state.businessInfo)),
      inNotificationEdit: false,
    });
  };

  handleNoticeEditClick = () => {
    this.setState({
      inNotificationEdit: true,
    });
  };

  // ------------------------------- Store setting -----------------------------
  handleStoreSettingEditClick = () => {
    this.setState({
      inStoreSettingEdit: true,
    });
  };

  handleStoreSettingSupportDeliveryChange = (checked) => {
    const updated = { ...this.state.businessInfo_update };
    updated.delivery = !!checked;

    this.setState({
      businessInfo_update: updated,
    });
  };

  handleStoreSettingSupportCurbsideChange = (checked) => {
    const updated = { ...this.state.businessInfo_update };
    updated.curbside = !!checked;

    this.setState({
      businessInfo_update: updated,
    });
  };

  handleStoreSettingTaxRateChange = (value) => {
    const updated = { ...this.state.businessInfo_update };
    updated.taxRate = value;
    this.setState({
      businessInfo_update: updated,
    });
  };

  handleDeliveryHourChange = (moment, isFrom) => {
    if (!moment) return;

    var mmtMidnight = moment.clone().startOf("day");
    // Difference in minutes
    var diffMinutes = moment.diff(mmtMidnight, "minutes");

    const deliveryHour_update = this.state.deliveryHour_update || {};
    let deliveryHour = deliveryHour_update;

    if (!deliveryHour) {
      deliveryHour_update = [];
      deliveryHour = deliveryHour_update;
    }

    if (!!isFrom) {
      deliveryHour.open = diffMinutes;
    } else {
      deliveryHour.close = diffMinutes;
    }
    console.log("deliveryHour_update", deliveryHour_update);
    this.setState({ deliveryHour_update });
  };

  handleStoreSettingEditSave = async () => {
    const { businessInfo, businessInfo_update, deliveryHour_update } =
      this.state;

    if (!businessInfo_update || !businessInfo_update.merchantId) {
      return swal({
        title: "Fail to save store setting update",
        text: "Internal error",
        icon: "error", // replace type with icon, if using new version
        timer: 1500,

        buttons: false,
      });
    }
    if (
      businessInfo_update.taxRate == "" ||
      (0 > parseFloat(businessInfo_update.taxRate) &&
        parseFloat(businessInfo_update.taxRate) > 1)
    ) {
      return swal({
        title: "Fail to save store setting update",
        text: "Store tax rate cannot be empty, and must be between 0 and 1!",
        icon: "error", // replace type with icon, if using new version
        timer: 5000,
        buttons: false,
      });
    }
    businessInfo_update.deliveryAvailableTime = deliveryHour_update;
    const updatedBusinessInfo = await BusinessInfoDb.updateBusinessInfo(
      this.props.merchantId,
      businessInfo_update
    );
    if (updatedBusinessInfo) {
      swal({
        title: "Updated",
        icon: "success", // replace type with icon, if using new version
        timer: 1000,
        buttons: false,
      });

      this.setState({
        businessInfo:
          updatedBusinessInfo &&
          JSON.parse(JSON.stringify(updatedBusinessInfo)),
        businessInfo_update:
          updatedBusinessInfo &&
          JSON.parse(JSON.stringify(updatedBusinessInfo)),
        inStoreSettingEdit: false,
      });
    } else {
      swal({
        title: "Fail to save store setting",
        text: "Please check the network or database setting",
        icon: "error", // replace type with icon, if using new version
        timer: 1500,

        buttons: false,
      });
    }
  };

  handleStoreSettingEditCancel = async () => {
    const willCancel = await swal({
      title: "Are you sure?",
      text: "Changes will not be saved.",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    });

    if (!willCancel) {
      return;
    }

    this.setState({
      businessInfo_update:
        this.state.businessInfo &&
        JSON.parse(JSON.stringify(this.state.businessInfo)),
      inStoreSettingEdit: false,
    });
  };

  // ----------------------------- order promotion ------------------------------
  handleOrderPromotionEditClick = () => {
    this.setState({
      inOrderPromotionEdit: true,
    });
  };

  handleOrderPromotionAmountChange = (value) => {
    if (Number.isNaN(Number(value))) {
      return;
    }

    const updated = { ...this.state.businessInfo_update };
    updated.orderPromotion = updated.orderPromotion || {};
    updated.orderPromotion.amount = Number(value);

    this.setState({
      businessInfo_update: updated,
    });
  };

  handleOrderPromotionItemNamesChange = (value) => {
    this.setState({
      freeItemsListStr: value,
    });
  };

  handleEnableOrderPromotionChange = (checked) => {
    const updated = { ...this.state.businessInfo_update };
    updated.enableOrderPromotion = !!checked;

    this.setState({
      businessInfo_update: updated,
    });
  };

  handleOrderPromotionEditSave = async () => {
    const { businessInfo_update, freeItemsListStr } = this.state;

    if (!businessInfo_update || !businessInfo_update.merchantId) {
      return swal({
        title: "Fail to save OrderPromotion update",
        text: "Internal error",
        icon: "error", // replace type with icon, if using new version
        timer: 1500,

        buttons: false,
      });
    }

    // ------------------------------------ update free item list -------------------------
    let itemNames = null;
    if (freeItemsListStr) {
      itemNames = (freeItemsListStr || "")
        .split(/[,;]/)
        .filter((val) => val != "")
        .map((val) => val.trim());

      console.log(555, itemNames);

      businessInfo_update.orderPromotion =
        businessInfo_update.orderPromotion || {};
      businessInfo_update.orderPromotion.freeItemNames = itemNames;
    }

    const updatedBusinessInfo = await BusinessInfoDb.updateBusinessInfo(
      this.props.merchantId,
      businessInfo_update
    );
    if (updatedBusinessInfo) {
      swal({
        title: "Updated",
        icon: "success", // replace type with icon, if using new version
        timer: 1000,
        buttons: false,
      });

      this.setState({
        businessInfo:
          updatedBusinessInfo &&
          JSON.parse(JSON.stringify(updatedBusinessInfo)),
        businessInfo_update:
          updatedBusinessInfo &&
          JSON.parse(JSON.stringify(updatedBusinessInfo)),
        inOrderPromotionEdit: false,
        freeItemsListStr: (itemNames || []).join(", "),
      });
    } else {
      swal({
        title: "Fail to save notification setting",
        text: "Please check the network or database setting",
        icon: "error", // replace type with icon, if using new version
        timer: 1500,

        buttons: false,
      });
    }
  };

  handleOrderPromotionEditCancel = async () => {
    const willCancel = await swal({
      title: "Are you sure?",
      text: "Changes will not be saved.",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    });

    if (!willCancel) {
      return;
    }

    this.setState({
      businessInfo_update:
        this.state.businessInfo &&
        JSON.parse(JSON.stringify(this.state.businessInfo)),
      inOrderPromotionEdit: false,
    });
  };

  // -------------------------------- QR code ---------------------------
  handleNewQRCodeClick = () => {
    this.setState({
      showQRCodeGeneratorModal: true,
    });
  };

  handleNewQRCodeModalClose = () => {
    this.setState({
      showQRCodeGeneratorModal: false,
      dineinTableNameEntered: null,
    });
  };

  handleDineinTableNameChange = (val) => {
    const name = (val || "").replace(/#/g, "");

    this.setState({
      dineinTableNameEntered: name,
    });
  };

  render() {
    const {
      businessInfo_update,
      businessInfo,
      businessHour_update,
      businessHour,
      businessClosedDayNames,

      foodPreparingTime,
      foodPreparingTime_update,

      loading,
      categories,
      categoriesSelected_hour,
      categoryHour_update,
      categoryAlwaysAvailableDayNames,

      categoriesSelected_promo,
      categoryPromoMethod_update,

      deliveryHour_update,

      freeItemsListStr,
    } = this.state;

    const { delivery, enableOrderPromotion } = businessInfo || {};

    // ------------------------------ business hour -------------------------------
    // 0 - 6 sun - sat
    const businessHourRow = [
      "Sunday",
      "Monday",
      "Tuesday",
      "Wednesday",
      "Thursday",
      "Friday",
      "Saturday",
    ].map((dayName, index) => {
      const hourRanges =
        businessHour_update && businessHour_update[dayNames[index]];

      const hourRange = hourRanges && hourRanges[0];
      const { open, close } = hourRange || {};

      const hour_open = open && Math.floor(open / 60);
      const minute_open = open && open - Math.floor(open / 60) * 60;

      const hour_close = close && Math.floor(close / 60);
      const minute_close = close && close - Math.floor(close / 60) * 60;

      const open_val =
        hour_open != null && minute_open != null
          ? moment().hour(hour_open).minute(minute_open)
          : null;

      const close_val =
        hour_close != null && minute_close != null
          ? moment().hour(hour_close).minute(minute_close)
          : null;

      return (
        <Table.Row key={index}>
          <Table.Cell>{dayName}</Table.Cell>
          <Table.Cell>
            {/* ---------------------------- open ------------------------- */}
            <TimePicker
              allowEmpty={false}
              showSecond={false}
              value={open_val}
              className="xxx"
              onChange={(value) => {
                this.handleBusinessHourChange(value, index, true);
              }}
              format={"h:mm a"}
              // use12Hours
              // inputReadOnly
              disabled={
                businessClosedDayNames &&
                businessClosedDayNames.indexOf(dayNames[index]) > -1
              }
              clearIcon={false}
            />

            {(hourRanges || []).slice(1).map(({ open }, index_sub) => {
              const hour_open = open && Math.floor(open / 60);
              const minute_open = open && open - Math.floor(open / 60) * 60;

              const open_val =
                hour_open != null && minute_open != null
                  ? moment().hour(hour_open).minute(minute_open)
                  : null;

              return (
                <React.Fragment key={index_sub}>
                  <br />
                  <TimePicker
                    allowEmpty={false}
                    showSecond={false}
                    value={open_val}
                    className="xxx"
                    onChange={(value) => {
                      this.handleBusinessHourChange(
                        value,
                        index,
                        true,
                        index_sub + 1
                      );
                    }}
                    format={"h:mm a"}
                    // use12Hours
                    // inputReadOnly
                    disabled={
                      businessClosedDayNames &&
                      businessClosedDayNames.indexOf(dayNames[index]) > -1
                    }
                  />
                </React.Fragment>
              );
            })}
          </Table.Cell>
          <Table.Cell>-</Table.Cell>
          <Table.Cell>
            {/* ---------------------------- close ------------------------- */}
            <TimePicker
              allowEmpty={false}
              showSecond={false}
              value={close_val}
              className="xxx"
              onChange={(value) => {
                this.handleBusinessHourChange(value, index, false);
              }}
              format={"h:mm a"}
              // use12Hours
              // inputReadOnly
              disabled={
                businessClosedDayNames &&
                businessClosedDayNames.indexOf(dayNames[index]) > -1
              }
            />
            <span
              className="noselect"
              style={{
                color: "#003161",
                cursor: "pointer",
                textDecoration: "underline",
                fontSize: "10px",
                marginLeft: "5px",
              }}
              onClick={() => {
                this.handleHourRangeAddClick(index);
              }}
            >
              Add
            </span>

            {(hourRanges || []).slice(1).map(({ close }, index_sub) => {
              const hour_close = close && Math.floor(close / 60);
              const minute_close = close && close - Math.floor(close / 60) * 60;

              const close_val =
                hour_close != null && minute_close != null
                  ? moment().hour(hour_close).minute(minute_close)
                  : null;

              return (
                <React.Fragment key={index_sub}>
                  <br />
                  <TimePicker
                    allowEmpty={false}
                    showSecond={false}
                    value={close_val}
                    className="xxx"
                    onChange={(value) => {
                      this.handleBusinessHourChange(
                        value,
                        index,
                        false,
                        index_sub + 1
                      );
                    }}
                    format={"h:mm a"}
                    // use12Hours
                    // inputReadOnly
                    disabled={
                      businessClosedDayNames &&
                      businessClosedDayNames.indexOf(dayNames[index]) > -1
                    }
                  />
                  <span
                    className="noselect"
                    style={{
                      color: "#ab0000",
                      cursor: "pointer",
                      textDecoration: "underline",
                      fontSize: "10px",
                      marginLeft: "5px",
                    }}
                    onClick={() => {
                      this.handleHourRangeRemoveClick(index, index_sub + 1);
                    }}
                  >
                    Remove
                  </span>
                </React.Fragment>
              );
            })}
          </Table.Cell>
          <Table.Cell>
            <Checkbox
              checked={
                businessClosedDayNames &&
                businessClosedDayNames.indexOf(dayNames[index]) > -1
              }
              label="Closed"
              onClick={(evt, { checked }) => {
                this.handleCloseBusinessDay(index, checked);
              }}
            />
          </Table.Cell>
        </Table.Row>
      );
    });

    // ------------------------------ food preparing time -------------------------------
    // 0 - 6 sun - sat
    const foodPreparingTimeRow = [
      "Sunday",
      "Monday",
      "Tuesday",
      "Wednesday",
      "Thursday",
      "Friday",
      "Saturday",
    ].map((dayName, index) => {
      const eachFoodPreparingTime =
        foodPreparingTime_update && foodPreparingTime_update[dayNames[index]];

      const {
        status,
        fromMin_pickup,
        toMin_pickup,
        fromMin_delivery,
        toMin_delivery,
      } = eachFoodPreparingTime || {};
      // const statusText = (
      //   foodPreparingTimeOptions.find((option) => option.value === status) || {}
      // ).text;

      return (
        <Table.Row key={index}>
          <Table.Cell>{dayName}</Table.Cell>
          <Table.Cell>
            <Dropdown
              placeholder="Status"
              selection
              options={foodPreparingTimeOptions}
              value={status || ""}
              onChange={(evt, data) =>
                this.handleFoodPreparingTimeStatusChange(index, data.value)
              }
            />
          </Table.Cell>
          <Table.Cell>
            Pickup in
            <Input
              style={{ width: "60px", margin: "0 5px" }}
              value={fromMin_pickup || ""}
              // placeholder="Example: 30-40 mins"
              onChange={(evt, data) =>
                this.handleFoodPreparingTimeForPickupFromMinChange(
                  index,
                  data.value
                )
              }
            />
            to
            <Input
              style={{ width: "60px", margin: "0 5px" }}
              value={toMin_pickup || ""}
              // placeholder="Example: 30-40 mins"
              onChange={(evt, data) =>
                this.handleFoodPreparingTimeForPickupToMinChange(
                  index,
                  data.value
                )
              }
            />
            Mins
          </Table.Cell>
          <Table.Cell>
            Delivery in
            <Input
              style={{ width: "60px", margin: "0 5px" }}
              value={fromMin_delivery || ""}
              // placeholder="Example: 30-40 mins"
              onChange={(evt, data) =>
                this.handleFoodPreparingTimeForDeliveryFromMinChange(
                  index,
                  data.value
                )
              }
            />
            to
            <Input
              style={{ width: "60px", margin: "0 5px" }}
              value={toMin_delivery || ""}
              // placeholder="Example: 30-40 mins"
              onChange={(evt, data) =>
                this.handleFoodPreparingTimeForDeliveryToMinChange(
                  index,
                  data.value
                )
              }
            />
            Mins
          </Table.Cell>
        </Table.Row>
      );
    });

    // ------------------------- category hour setting ------------------------------------
    const categoryHourCards = (categories || [])
      .filter((category) => {
        const { availableHour } = category;
        let allAvailable = true;
        if (availableHour) {
          for (let index in dayNames) {
            const dayName = dayNames[index];
            const hours = availableHour[dayName];
            const available =
              !hours || hours.every((hour) => hour.open == hour.close);
            if (!available) {
              allAvailable = false;
              break;
            }
          }
        }
        return !allAvailable;
      })
      .map((category, index) => {
        const { name, availableHour } = category;

        const style = {
          position: "absolute",
          left: "50px",
          fontWeight: "700",
        };

        return (
          <Card key={index}>
            <Card.Content>
              <Card.Header>{name}</Card.Header>
              <Card.Description>
                Sun:{" "}
                <span style={style}>
                  {getAvailableHourStr(
                    availableHour,
                    "sunday",
                    "Always Available"
                  ).join(", ")}
                </span>
                <br />
                Mon:{" "}
                <span style={style}>
                  {getAvailableHourStr(
                    availableHour,
                    "monday",
                    "Always Available"
                  ).join(", ")}
                </span>
                <br />
                Tues:{" "}
                <span style={style}>
                  {getAvailableHourStr(
                    availableHour,
                    "tuesday",
                    "Always Available"
                  ).join(", ")}
                </span>
                <br />
                Wed:{" "}
                <span style={style}>
                  {getAvailableHourStr(
                    availableHour,
                    "wednesday",
                    "Always Available"
                  ).join(", ")}
                </span>
                <br />
                Thur:{" "}
                <span style={style}>
                  {getAvailableHourStr(
                    availableHour,
                    "thursday",
                    "Always Available"
                  ).join(", ")}
                </span>
                <br />
                Fri:{" "}
                <span style={style}>
                  {getAvailableHourStr(
                    availableHour,
                    "friday",
                    "Always Available"
                  ).join(", ")}
                </span>
                <br />
                Sat:{" "}
                <span style={style}>
                  {getAvailableHourStr(
                    availableHour,
                    "saturday",
                    "Always Available"
                  ).join(", ")}
                </span>
              </Card.Description>
            </Card.Content>
          </Card>

          // <List.Item key={index}>
          //   <List.Content>
          //     {name}:
          //     <span
          //       style={{
          //         fontSize: "14px",
          //         fontWeight: "700",
          //       }}
          //     >
          //       {getAvailableHourStr(availableHour).join(", ")}
          //     </span>{" "}
          //   </List.Content>
          // </List.Item>
        );
      });

    const categoryHourRow = [
      "Sunday",
      "Monday",
      "Tuesday",
      "Wednesday",
      "Thursday",
      "Friday",
      "Saturday",
    ].map((dayName, index) => {
      // const { availableHour: availableHour_firstCate } =
      //   (categoriesSelected_hour || [])[0] || {};

      const hourRanges =
        categoryHour_update && categoryHour_update[dayNames[index]];

      const hourRange = hourRanges && hourRanges[0];
      const { open, close } = hourRange || {};

      const hour_open = open && Math.floor(open / 60);
      const minute_open = open && open - Math.floor(open / 60) * 60;

      const hour_close = close && Math.floor(close / 60);
      const minute_close = close && close - Math.floor(close / 60) * 60;

      const open_val =
        hour_open != null && minute_open != null
          ? moment().hour(hour_open).minute(minute_open)
          : null;

      const close_val =
        hour_close != null && minute_close != null
          ? moment().hour(hour_close).minute(minute_close)
          : null;

      return (
        <Table.Row key={index}>
          <Table.Cell>{dayName}</Table.Cell>
          <Table.Cell>
            {/* ---------------------------- open ------------------------- */}
            <TimePicker
              allowEmpty={false}
              showSecond={false}
              value={open_val}
              className="xxx"
              onChange={(value) => {
                this.handleCategoryHourChange(value, index, true);
              }}
              format={"h:mm a"}
              // use12Hours
              // inputReadOnly
              disabled={
                categoryAlwaysAvailableDayNames &&
                categoryAlwaysAvailableDayNames.indexOf(dayNames[index]) > -1
              }
            />

            {(hourRanges || []).slice(1).map(({ open }, index_sub) => {
              const hour_open = open && Math.floor(open / 60);
              const minute_open = open && open - Math.floor(open / 60) * 60;

              const open_val =
                hour_open != null && minute_open != null
                  ? moment().hour(hour_open).minute(minute_open)
                  : null;

              return (
                <React.Fragment key={index_sub}>
                  <br />
                  <TimePicker
                    allowEmpty={false}
                    showSecond={false}
                    value={open_val}
                    className="xxx"
                    onChange={(value) => {
                      this.handleCategoryHourChange(
                        value,
                        index,
                        true,
                        index_sub + 1
                      );
                    }}
                    format={"h:mm a"}
                    // use12Hours
                    // inputReadOnly
                    disabled={
                      categoryAlwaysAvailableDayNames &&
                      categoryAlwaysAvailableDayNames.indexOf(dayNames[index]) >
                        -1
                    }
                  />
                </React.Fragment>
              );
            })}
          </Table.Cell>
          <Table.Cell>-</Table.Cell>
          <Table.Cell>
            {/* ---------------------------- close ------------------------- */}
            <TimePicker
              allowEmpty={false}
              showSecond={false}
              value={close_val}
              className="xxx"
              onChange={(value) => {
                this.handleCategoryHourChange(value, index, false);
              }}
              format={"h:mm a"}
              // use12Hours
              // inputReadOnly
              disabled={
                categoryAlwaysAvailableDayNames &&
                categoryAlwaysAvailableDayNames.indexOf(dayNames[index]) > -1
              }
            />
            <span
              className="noselect"
              style={{
                color: "#003161",
                cursor: "pointer",
                textDecoration: "underline",
                fontSize: "10px",
                marginLeft: "5px",
              }}
              onClick={() => {
                this.handleHourRangeAddClick(index);
              }}
            >
              Add
            </span>

            {(hourRanges || []).slice(1).map(({ close }, index_sub) => {
              const hour_close = close && Math.floor(close / 60);
              const minute_close = close && close - Math.floor(close / 60) * 60;

              const close_val =
                hour_close != null && minute_close != null
                  ? moment().hour(hour_close).minute(minute_close)
                  : null;

              return (
                <React.Fragment key={index_sub}>
                  <br />
                  <TimePicker
                    allowEmpty={false}
                    showSecond={false}
                    value={close_val}
                    className="xxx"
                    onChange={(value) => {
                      this.handleCategoryHourChange(
                        value,
                        index,
                        false,
                        index_sub + 1
                      );
                    }}
                    format={"h:mm a"}
                    disabled={
                      categoryAlwaysAvailableDayNames &&
                      categoryAlwaysAvailableDayNames.indexOf(dayNames[index]) >
                        -1
                    }
                  />
                  <span
                    className="noselect"
                    style={{
                      color: "#ab0000",
                      cursor: "pointer",
                      textDecoration: "underline",
                      fontSize: "10px",
                      marginLeft: "5px",
                    }}
                    onClick={() => {
                      this.handleCategoryHourRangeRemoveClick(
                        index,
                        index_sub + 1
                      );
                    }}
                  >
                    Remove
                  </span>
                </React.Fragment>
              );
            })}
          </Table.Cell>
          <Table.Cell>
            <Checkbox
              checked={
                categoryAlwaysAvailableDayNames &&
                categoryAlwaysAvailableDayNames.indexOf(dayNames[index]) > -1
              }
              label="Always available"
              onClick={(evt, { checked }) => {
                this.handleCategoryHourAlwaysAvailable(index, checked);
              }}
            />
          </Table.Cell>
        </Table.Row>
      );
    });

    // --------------------------- category promoMethod setting ---------------------------
    const categoryPromoMethods = (categories || [])
      .filter((category) => category.promoMethod != null)
      .map((category, index) => {
        const { name, _id } = category;
        const { name: promoName, type, X, Y } = category.promoMethod || {};
        return (
          <Card key={index}>
            <Card.Content>
              <Card.Header>
                {name}{" "}
                <Icon
                  name="close"
                  style={{ float: "right", cursor: "pointer" }}
                  onClick={() => this.handleCategoryPromoMethodRemoveClick(_id)}
                />
              </Card.Header>
              <Card.Description>
                Name:{promoName}
                <br />
                Type:
                {type == "BUY_X_GET_Y_FREE"
                  ? `BUY ${X} GET ${Y} FREE`
                  : type == "MEET_X_MINUS_Y"
                  ? `BUY $${X} GET $${Y} OFF`
                  : `BUY $${X} GET ${Y}% OFF`}
              </Card.Description>
            </Card.Content>
          </Card>
        );
      });

    const hourRange = deliveryHour_update && deliveryHour_update;
    const { open, close } = hourRange || {};

    const hour_open = open && Math.floor(open / 60);
    const minute_open = open && open - Math.floor(open / 60) * 60;

    const hour_close = close && Math.floor(close / 60);
    const minute_close = close && close - Math.floor(close / 60) * 60;

    const open_val =
      hour_open != null && minute_open != null
        ? moment().hour(hour_open).minute(minute_open)
        : null;

    const close_val =
      hour_close != null && minute_close != null
        ? moment().hour(hour_close).minute(minute_close)
        : null;

    return (
      <Segment
        basic
        style={{ width: "100%", height: "100%", overflowY: "auto" }}
        textAlign="left"
      >
        <Dimmer inverted active={loading}>
          <Loader size="huge" />
        </Dimmer>
        <Container>
          {/* ---------------------- store information ---------------------- */}
          {!this.state.inBusinessInfoEdit ? (
            <Card
              raised
              style={{
                width: "100%",
                opacity: "0.9",
              }}
            >
              {/* <Image src={BusinessLogo} wrapped ui={false} /> */}
              <Card.Content className="selectable">
                <Card.Header style={{ fontSize: "xx-large" }}>
                  {businessInfo_update && businessInfo_update.name}
                </Card.Header>
                <Card.Meta style={{ fontSize: "small" }}>
                  {this.props.merchantId ? (
                    <QRCode
                      value={`https://www.luckory.com/store/${this.props.merchantId}`}
                      style={{ position: "absolute", right: "20px" }}
                    />
                  ) : null}
                  <List
                    size="small"
                    style={{ color: "black", padding: "10px" }}
                  >
                    <List.Item>
                      <List.Icon name="address card" />
                      <List.Content>
                        Merchant ID:{" "}
                        <span style={{ fontSize: "14px", fontWeight: "700" }}>
                          {businessInfo_update &&
                            businessInfo_update.merchantId}
                        </span>
                      </List.Content>
                    </List.Item>

                    <List.Item>
                      <List.Icon name="home" />
                      <List.Content>
                        <span style={{ fontSize: "14px", fontWeight: "700" }}>
                          {businessInfo_update &&
                            businessInfo_update.address &&
                            `${businessInfo_update.address.street}, ${businessInfo_update.address.city}, ${businessInfo_update.address.state} ${businessInfo_update.address.postalCode}`}
                        </span>
                      </List.Content>
                    </List.Item>
                    <List.Item>
                      <List.Icon name="mail" />
                      <List.Content>
                        <span style={{ fontSize: "14px", fontWeight: "700" }}>
                          {businessInfo_update && businessInfo_update.email}
                        </span>
                      </List.Content>
                    </List.Item>
                    <List.Item>
                      <List.Icon name="phone square" />
                      <List.Content>
                        <span style={{ fontSize: "14px", fontWeight: "700" }}>
                          {businessInfo_update &&
                            formatPhoneNumber(businessInfo_update.phone)}
                        </span>
                      </List.Content>
                    </List.Item>
                    <List.Item>
                      <List.Icon name="globe" />
                      <List.Content>
                        <span style={{ fontSize: "14px", fontWeight: "700" }}>
                          {businessInfo_update && businessInfo_update.webSite}
                        </span>
                      </List.Content>
                    </List.Item>
                    <List.Item>
                      <List.Icon name="key" />
                      <List.Content>
                        <span style={{ fontSize: "14px", fontWeight: "700" }}>
                          {businessInfo_update && businessInfo_update.password}
                        </span>
                      </List.Content>
                    </List.Item>
                  </List>

                  <div
                    style={{
                      float: "right",
                      color: "#0059b3",
                      cursor: "pointer",
                      textDecoration: "underline",
                    }}
                    onClick={() => {
                      this.setState({
                        inBusinessInfoEdit: true,
                      });
                    }}
                  >
                    Edit
                  </div>
                </Card.Meta>
                <Card.Description />
              </Card.Content>
            </Card>
          ) : (
            <Segment style={{ width: "100%", margin: "0 0 14px 0" }} raised>
              <Header as="h2">
                {businessInfo_update && businessInfo_update.name}
              </Header>

              <Form style={{ margin: "10px" }}>
                <Form.Group widths="equal">
                  <Form.Input
                    fluid
                    label="Store name"
                    placeholder="Store name"
                    value={
                      (businessInfo_update && businessInfo_update.name) || ""
                    }
                    disabled
                  />
                </Form.Group>
                <Form.Group widths="equal">
                  <Form.Input
                    fluid
                    label="Merchant ID"
                    placeholder="Merchant ID"
                    value={
                      (businessInfo_update && businessInfo_update.merchantId) ||
                      ""
                    }
                    disabled
                  />
                  <Form.Input
                    fluid
                    label="Password"
                    placeholder="Password"
                    error={
                      this.state.showPasswordValidness &&
                      verifyMerchantPsw(
                        businessInfo_update && businessInfo_update.password
                      ).error && {
                        content: verifyMerchantPsw(
                          businessInfo_update && businessInfo_update.password
                        ).error,
                      }
                    }
                    value={
                      (businessInfo_update && businessInfo_update.password) ||
                      ""
                    }
                    onChange={(evt) => this.handlePswChange(evt.target.value)}
                    onBlur={() => {
                      this.setState({
                        showPasswordValidness: true,
                      });
                    }}
                  />
                </Form.Group>
                <Form.Group widths="equal">
                  <Form.Input
                    fluid
                    label="Street"
                    placeholder="Street"
                    disabled
                    value={
                      (businessInfo_update &&
                        businessInfo_update.address &&
                        businessInfo_update.address.street) ||
                      ""
                    }
                    onChange={(evt) =>
                      this.handleStreetChange(evt.target.value)
                    }
                  />
                </Form.Group>
                <Form.Group widths="equal">
                  <Form.Input
                    fluid
                    disabled
                    label="City"
                    placeholder="City"
                    value={
                      (businessInfo_update &&
                        businessInfo_update.address &&
                        businessInfo_update.address.city) ||
                      ""
                    }
                    onChange={(evt) => this.handleCityChange(evt.target.value)}
                  />
                  <Form.Input
                    fluid
                    disabled
                    label="State"
                    placeholder="State"
                    value={
                      (businessInfo_update &&
                        businessInfo_update.address &&
                        businessInfo_update.address.state) ||
                      ""
                    }
                    onChange={(evt) => this.handleStateChange(evt.target.value)}
                  />
                  <Form.Input
                    fluid
                    disabled
                    label="Zip code"
                    placeholder="Zip code"
                    value={
                      (businessInfo_update &&
                        businessInfo_update.address &&
                        businessInfo_update.address.postalCode) ||
                      ""
                    }
                    onChange={(evt) =>
                      this.handlePostalCodeChange(evt.target.value)
                    }
                  />
                </Form.Group>
                <Form.Group widths="equal">
                  <Form.Input
                    fluid
                    label="Phone"
                    placeholder="Phone"
                    value={
                      (businessInfo_update && businessInfo_update.phone) || ""
                    }
                    onChange={(evt) => this.handlePhoneChange(evt.target.value)}
                  />
                  <Form.Input
                    fluid
                    label="Email"
                    placeholder="Email"
                    value={
                      (businessInfo_update && businessInfo_update.email) || ""
                    }
                    onChange={(evt) => this.handleEmailChange(evt.target.value)}
                  />
                </Form.Group>
                <Form.Group widths="equal">
                  <Form.Input
                    fluid
                    disabled
                    label="Web site"
                    placeholder="Web site"
                    value={
                      (businessInfo_update && businessInfo_update.webSite) || ""
                    }
                    onChange={(evt) =>
                      this.handleWebsiteChange(evt.target.value)
                    }
                  />
                </Form.Group>
              </Form>
              <Button
                className="sumi-global-btn-color"
                onClick={this.handleBusinessInfoEditSave}
              >
                Save
              </Button>
              <Button className="" onClick={this.handleBusinessInfoEditCancel}>
                Cancel
              </Button>
            </Segment>
          )}

          {/* ---------------------- business hours ---------------------- */}
          {!this.state.inBusinessHoursEdit ? (
            <Card
              raised
              style={{
                width: "100%",
                opacity: "0.9",
              }}
            >
              {/* <Image src={BusinessLogo} wrapped ui={false} /> */}
              <Card.Content className="selectable">
                <Card.Header style={{ fontSize: "xx-large" }}>
                  Business Hours{" "}
                  <span style={{ fontSize: "small" }}>
                    <Icon name="calendar outline" />
                    Week
                  </span>
                </Card.Header>
                <Card.Meta style={{ fontSize: "small" }}>
                  <List
                    size="small"
                    style={{ color: "black", padding: "10px" }}
                  >
                    <List.Item>
                      <List.Content>
                        Sunday:{" "}
                        <span
                          style={{
                            left: "100px",
                            position: "absolute",
                            fontSize: "14px",
                            fontWeight: "700",
                          }}
                        >
                          {businessHour &&
                            getBusinessHourStr(businessHour, "sunday").join(
                              ", "
                            )}
                        </span>{" "}
                      </List.Content>
                    </List.Item>
                    <List.Item>
                      <List.Content>
                        Monday:{" "}
                        <span
                          style={{
                            left: "100px",
                            position: "absolute",
                            fontSize: "14px",
                            fontWeight: "700",
                          }}
                        >
                          {businessHour &&
                            getBusinessHourStr(businessHour, "monday").join(
                              ", "
                            )}
                        </span>
                      </List.Content>
                    </List.Item>
                    <List.Item>
                      <List.Content>
                        Tuesday:{" "}
                        <span
                          style={{
                            left: "100px",
                            position: "absolute",
                            fontSize: "14px",
                            fontWeight: "700",
                          }}
                        >
                          {businessHour &&
                            getBusinessHourStr(businessHour, "tuesday").join(
                              ", "
                            )}
                        </span>{" "}
                      </List.Content>
                    </List.Item>
                    <List.Item>
                      <List.Content>
                        Wednesday:{" "}
                        <span
                          style={{
                            left: "100px",
                            position: "absolute",
                            fontSize: "14px",
                            fontWeight: "700",
                          }}
                        >
                          {businessHour &&
                            getBusinessHourStr(businessHour, "wednesday").join(
                              ", "
                            )}
                        </span>{" "}
                      </List.Content>
                    </List.Item>
                    <List.Item>
                      <List.Content>
                        Thursday:{" "}
                        <span
                          style={{
                            left: "100px",
                            position: "absolute",
                            fontSize: "14px",
                            fontWeight: "700",
                          }}
                        >
                          {businessHour &&
                            getBusinessHourStr(businessHour, "thursday").join(
                              ", "
                            )}
                        </span>{" "}
                      </List.Content>
                    </List.Item>
                    <List.Item>
                      <List.Content>
                        Friday:{" "}
                        <span
                          style={{
                            left: "100px",
                            position: "absolute",
                            fontSize: "14px",
                            fontWeight: "700",
                          }}
                        >
                          {businessHour &&
                            getBusinessHourStr(businessHour, "friday").join(
                              ", "
                            )}
                        </span>{" "}
                      </List.Content>
                    </List.Item>
                    <List.Item>
                      <List.Content>
                        Saturday:{" "}
                        <span
                          style={{
                            left: "100px",
                            position: "absolute",
                            fontSize: "14px",
                            fontWeight: "700",
                          }}
                        >
                          {businessHour &&
                            getBusinessHourStr(businessHour, "saturday").join(
                              ", "
                            )}
                        </span>{" "}
                      </List.Content>
                    </List.Item>
                  </List>

                  <div
                    style={{
                      float: "right",
                      color: "#0059b3",
                      cursor: "pointer",
                      textDecoration: "underline",
                    }}
                    onClick={this.handleBusinessHoursEditClick}
                  >
                    Edit
                  </div>
                </Card.Meta>
                <Card.Description />
              </Card.Content>
            </Card>
          ) : (
            <Segment style={{ width: "100%", margin: "0" }} raised>
              <Header as="h2">Business Hours</Header>
              <Table basic="very" collapsing>
                <Table.Body>{businessHourRow}</Table.Body>
              </Table>
              <Button
                className="sumi-global-btn-color"
                onClick={this.handleBusinessHoursEditSave}
              >
                Save
              </Button>
              <Button className="" onClick={this.handleBusinessHoursEditCancel}>
                Cancel
              </Button>
            </Segment>
          )}

          {/* ---------------------- food preparing time ---------------------- */}
          {!this.state.inFoodPreparingTimeEdit ? (
            <Card
              raised
              style={{
                width: "100%",
                opacity: "0.9",
              }}
            >
              {/* <Image src={BusinessLogo} wrapped ui={false} /> */}
              <Card.Content className="selectable">
                <Card.Header style={{ fontSize: "xx-large" }}>
                  Food Preparing{" "}
                  <span style={{ fontSize: "small" }}>
                    <Icon name="calendar outline" />
                    Week
                  </span>
                </Card.Header>
                <Card.Meta style={{ fontSize: "small" }}>
                  <List
                    size="small"
                    style={{ color: "black", padding: "10px" }}
                  >
                    {[
                      "Sunday",
                      "Monday",
                      "Tuesday",
                      "Wednesday",
                      "Thursday",
                      "Friday",
                      "Saturday",
                    ].map((dayName, index) => {
                      const {
                        status,
                        estimate,
                        fromMin_pickup,
                        toMin_pickup,
                        fromMin_delivery,
                        toMin_delivery,
                      } =
                        (foodPreparingTime &&
                          foodPreparingTime[dayNames[index]]) ||
                        {};

                      const statusText = (
                        foodPreparingTimeOptions.find(
                          (option) => option.value === status
                        ) || {}
                      ).text;

                      return (
                        <List.Item key={index}>
                          <List.Content>
                            {dayName}:
                            <span
                              style={{
                                left: "100px",
                                position: "absolute",
                                fontSize: "14px",
                                fontWeight: "700",
                              }}
                            >
                              {(statusText || "Status Not Set") +
                                ", " +
                                (fromMin_pickup || toMin_pickup
                                  ? `Pickup in ${
                                      fromMin_pickup || toMin_pickup
                                    } - ${toMin_pickup || fromMin_pickup} mins`
                                  : "Not set for Pickup") +
                                ", " +
                                (fromMin_delivery || toMin_delivery
                                  ? `Deliver in ${
                                      fromMin_delivery || toMin_delivery
                                    } - ${
                                      toMin_delivery || fromMin_delivery
                                    } mins`
                                  : "Not set for Delivery")}
                            </span>{" "}
                          </List.Content>
                        </List.Item>
                      );
                    })}
                  </List>

                  <div
                    style={{
                      float: "right",
                      color: "#0059b3",
                      cursor: "pointer",
                      textDecoration: "underline",
                    }}
                    onClick={this.handleFoodPreparingTimeEditClick}
                  >
                    Edit
                  </div>
                </Card.Meta>
                <Card.Description />
              </Card.Content>
            </Card>
          ) : (
            <Segment style={{ width: "100%", margin: "0" }} raised>
              <Header as="h2">Food Preparing</Header>
              <Table basic="very" collapsing size="small">
                <Table.Body>{foodPreparingTimeRow}</Table.Body>
              </Table>
              <Button
                className="sumi-global-btn-color"
                onClick={this.handleFoodPreparingTimeEditSave}
              >
                Save
              </Button>
              <Button
                className=""
                onClick={this.handleFoodPreparingTimeEditCancel}
              >
                Cancel
              </Button>
            </Segment>
          )}

          {/* ---------------------- category hours ---------------------- */}
          {!this.state.inCategoryHoursEdit ? (
            <Card
              raised
              style={{
                width: "100%",
                opacity: "0.9",
              }}
            >
              {/* <Image src={BusinessLogo} wrapped ui={false} /> */}
              <Card.Content className="selectable">
                <Card.Header style={{ fontSize: "xx-large" }}>
                  Category Hours{" "}
                  <span style={{ fontSize: "small" }}>
                    <Icon name="info circle" />
                    Category always available if not specified
                  </span>
                </Card.Header>
                <Card.Meta style={{ fontSize: "small" }}>
                  {categoryHourCards && categoryHourCards.length > 0 ? (
                    <Card.Group>{categoryHourCards}</Card.Group>
                  ) : (
                    "All categories are always available."
                  )}

                  <div
                    style={{
                      float: "right",
                      color: "#0059b3",
                      cursor: "pointer",
                      textDecoration: "underline",
                    }}
                    onClick={this.handleCategoryHoursEditClick}
                  >
                    Edit
                  </div>
                </Card.Meta>
                <Card.Description />
              </Card.Content>
            </Card>
          ) : (
            <Segment style={{ width: "100%", margin: "0" }} raised>
              <h2>
                Category Hours{" "}
                <span style={{ fontSize: "small" }}>
                  <Icon name="info circle" />
                  Category always available if not specified
                </span>
              </h2>

              <Dropdown
                style={{ margin: "20px 0" }}
                placeholder="Select Categories"
                clearable
                fluid
                multiple
                selection
                options={(categories || []).map(({ name, _id }) => {
                  return {
                    key: name,
                    text: name,
                    value: _id,
                  };
                })}
                value={(categoriesSelected_hour || []).map(
                  (category) => category._id
                )}
                onChange={(evt, data) => {
                  this.handleSelectedCategoriesChanged(
                    (categories || []).filter(
                      (cate) => (data.value || []).indexOf(cate._id) > -1
                    )
                  );
                }}
              />

              <Transition
                visible={
                  categoriesSelected_hour && categoriesSelected_hour.length > 0
                }
                animation="fade down"
                duration={180}
              >
                <div>
                  <Table basic="very" collapsing>
                    <Table.Body>{categoryHourRow}</Table.Body>
                  </Table>
                </div>
              </Transition>
              <Button
                className="sumi-global-btn-color"
                onClick={this.handleCategoryHoursEditSave}
              >
                Save
              </Button>
              <Button className="" onClick={this.handleCategoryHoursEditCancel}>
                Cancel
              </Button>
            </Segment>
          )}

          {/* ---------------------- notice ---------------------- */}
          {!this.state.inNoticeEdit ? (
            <Card
              raised
              style={{
                width: "100%",
                opacity: "0.9",
              }}
            >
              <Card.Content className="selectable">
                <Card.Header style={{ fontSize: "xx-large" }}>
                  Notice
                </Card.Header>
                <Card.Meta style={{ fontSize: "small" }}>
                  <List
                    size="small"
                    style={{ color: "black", padding: "10px" }}
                  >
                    <List.Item>
                      <List.Content>
                        Home Page:{" "}
                        {/* <span style={{ fontSize: "14px", fontWeight: "700" }}>
                          {businessInfo_update &&
                            businessInfo_update.homePageNotice}
                        </span> */}
                        <div
                          style={{
                            fontSize: "14px",
                            fontWeight: "700",
                            padding: "10px",
                            border: "1px solid #d8d8d8",
                          }}
                        >
                          {businessInfo_update &&
                            (businessInfo_update.homePageNotice || "")
                              .split("<br/>")
                              .map((p, i) => <p key={i}>{p}</p>)}
                        </div>
                      </List.Content>
                    </List.Item>
                    <br />
                    <List.Item>
                      <List.Content>
                        Food Preparing Time:{" "}
                        <div
                          style={{
                            fontSize: "14px",
                            fontWeight: "700",
                            padding: "10px",
                            border: "1px solid #d8d8d8",
                          }}
                        >
                          {businessInfo_update &&
                            (businessInfo_update.orderPreparingNotice || "")
                              .split("<br/>")
                              .map((p, i) => <p key={i}>{p}</p>)}
                        </div>
                      </List.Content>
                    </List.Item>
                    <br />
                    <List.Item>
                      <List.Content>
                        Message for Dinein Customer:{" "}
                        <div
                          style={{
                            fontSize: "14px",
                            fontWeight: "700",
                            padding: "10px",
                            border: "1px solid #d8d8d8",
                          }}
                        >
                          {businessInfo_update &&
                            (businessInfo_update.dineinNotice || "")
                              .split("<br/>")
                              .map((p, i) => <p key={i}>{p}</p>)}
                        </div>
                      </List.Content>
                    </List.Item>
                  </List>

                  <div
                    style={{
                      float: "right",
                      color: "#0059b3",
                      cursor: "pointer",
                      textDecoration: "underline",
                    }}
                    onClick={() => {
                      this.setState({
                        inNoticeEdit: true,
                      });
                    }}
                  >
                    Edit
                  </div>
                </Card.Meta>
                <Card.Description />
              </Card.Content>
            </Card>
          ) : (
            <Segment style={{ width: "100%", margin: "0 0 14px 0" }} raised>
              <Header as="h2">Notice</Header>

              <Form style={{ margin: "10px" }}>
                <Header as="h3">for Home Page</Header>
                <TextArea
                  placeholder="Type some message you would like to put on your main page of the website"
                  value={
                    (businessInfo_update &&
                      (businessInfo_update.homePageNotice || "")
                        .split("<br/>")
                        .join("\n")) ||
                    ""
                  }
                  onChange={(evt) =>
                    this.handleHomePageNoticeChange(evt.target.value)
                  }
                />
                <Header as="h3">for Food Preparing</Header>
                <TextArea
                  placeholder="This message will be displayed at TIME section of the Order Summary Page. e.g. Average time for preparing food is 40mins to 50mins."
                  value={
                    (businessInfo_update &&
                      (businessInfo_update.orderPreparingNotice || "")
                        .split("<br/>")
                        .join("\n")) ||
                    ""
                  }
                  onChange={(evt) =>
                    this.handleOrderPreparingNoticeChange(evt.target.value)
                  }
                />
                <Header as="h3">for Dinein Customer</Header>
                <TextArea
                  placeholder="This message will be displayed for customers when they scan the QRCode for Dinein Table. e.g. Food will be delivered to you when it's ready."
                  value={
                    (businessInfo_update &&
                      (businessInfo_update.dineinNotice || "")
                        .split("<br/>")
                        .join("\n")) ||
                    ""
                  }
                  onChange={(evt) =>
                    this.handleDineinNoticeChange(evt.target.value)
                  }
                />
              </Form>
              <Button
                className="sumi-global-btn-color"
                onClick={this.handleNoticeSave}
              >
                Save
              </Button>
              <Button className="" onClick={this.handleNoticeCancel}>
                Cancel
              </Button>
            </Segment>
          )}

          {/* ---------------------- notification ---------------------- */}
          {!this.state.inNotificationEdit ? (
            <Card
              raised
              style={{
                width: "100%",
                opacity: "0.9",
              }}
            >
              <Card.Content className="selectable">
                <Card.Header style={{ fontSize: "xx-large" }}>
                  Notification
                </Card.Header>
                <Card.Meta style={{ fontSize: "small" }}>
                  <List
                    size="small"
                    style={{ color: "black", padding: "10px" }}
                  >
                    <List.Item>
                      <List.Content>
                        Email Address receiving new order notification:{" "}
                        <span style={{ fontSize: "14px", fontWeight: "700" }}>
                          {(businessInfo_update &&
                            businessInfo_update.emailReceivingNotification) ||
                            "Not Set"}
                        </span>
                      </List.Content>
                    </List.Item>
                  </List>

                  <div
                    style={{
                      float: "right",
                      color: "#0059b3",
                      cursor: "pointer",
                      textDecoration: "underline",
                    }}
                    onClick={this.handleNoticeEditClick}
                  >
                    Edit
                  </div>
                </Card.Meta>
                <Card.Description />
              </Card.Content>
            </Card>
          ) : (
            <Segment style={{ width: "100%", margin: "0 0 14px 0" }} raised>
              <Header as="h2">Notification</Header>

              <Form style={{ margin: "10px" }}>
                <Form.Group widths="equal">
                  <Form.Input
                    fluid
                    label="Email Address receiving new order notification"
                    placeholder="notification@example.com"
                    value={
                      (businessInfo_update &&
                        businessInfo_update.emailReceivingNotification) ||
                      ""
                    }
                    onChange={(evt) =>
                      this.handleEmailReceivingNotificationChange(
                        evt.target.value
                      )
                    }
                  />
                </Form.Group>
              </Form>
              <Button
                className="sumi-global-btn-color"
                onClick={this.handleNotificationEditSave}
              >
                Save
              </Button>
              <Button className="" onClick={this.handleNotificationEditCancel}>
                Cancel
              </Button>
            </Segment>
          )}

          {/* ---------------------- order promotion ---------------------- */}
          {!this.state.inOrderPromotionEdit ? (
            <Card
              raised
              style={{
                width: "100%",
                opacity: "0.9",
                display: "none",
              }}
            >
              <Card.Content className="selectable">
                <Card.Header style={{ fontSize: "xx-large" }}>
                  Order Promotion
                </Card.Header>
                <Card.Meta style={{ fontSize: "small" }}>
                  <List
                    size="small"
                    style={{ color: "black", padding: "10px" }}
                  >
                    <List.Item>
                      <List.Content>
                        <Checkbox
                          disabled
                          label={
                            <label>
                              If order's subtotal above{" "}
                              <b>
                                $
                                {businessInfo_update &&
                                businessInfo_update.orderPromotion &&
                                businessInfo_update.orderPromotion.amount !=
                                  null
                                  ? businessInfo_update.orderPromotion.amount
                                  : "Not set"}
                              </b>
                              , one free item is selected by user from{" "}
                              <b>{freeItemsListStr || "Not set"}</b>
                            </label>
                          }
                          checked={
                            !!(
                              businessInfo_update &&
                              businessInfo_update.enableOrderPromotion
                            )
                          }
                        />
                      </List.Content>
                    </List.Item>
                  </List>

                  <div
                    style={{
                      float: "right",
                      color: "#0059b3",
                      cursor: "pointer",
                      textDecoration: "underline",
                    }}
                    onClick={this.handleOrderPromotionEditClick}
                  >
                    Edit
                  </div>
                </Card.Meta>
                <Card.Description />
              </Card.Content>
            </Card>
          ) : (
            <Segment style={{ width: "100%", margin: "0 0 14px 0" }} raised>
              <Header as="h2">Order Promotion</Header>
              <Form style={{ margin: "10px" }}>
                <Form.Group widths="equal">
                  <Form.Field>
                    <Checkbox
                      label={"Enable"}
                      checked={
                        !!(
                          businessInfo_update &&
                          businessInfo_update.enableOrderPromotion
                        )
                      }
                      onClick={(evt, { checked }) => {
                        this.handleEnableOrderPromotionChange(checked);
                      }}
                    />
                  </Form.Field>
                </Form.Group>
                <Form.Group widths="equal">
                  <Form.Input
                    disabled={
                      !(
                        businessInfo_update &&
                        businessInfo_update.enableOrderPromotion
                      )
                    }
                    width={4}
                    fluid
                    label="Amount"
                    placeholder="Example: 100"
                    value={
                      businessInfo_update &&
                      businessInfo_update.orderPromotion &&
                      businessInfo_update.orderPromotion.amount != null
                        ? businessInfo_update.orderPromotion.amount
                        : ""
                    }
                    onChange={(evt) =>
                      this.handleOrderPromotionAmountChange(evt.target.value)
                    }
                  />
                  <Form.Input
                    width={12}
                    fluid
                    label="Free items List. Separated by comma or semicolon"
                    placeholder="Example: Egg roll, Coke, Soup"
                    value={this.state.freeItemsListStr || ""}
                    onChange={(evt) => {
                      this.handleOrderPromotionItemNamesChange(
                        evt.target.value
                      );
                    }}
                    disabled={
                      !(
                        businessInfo_update &&
                        businessInfo_update.enableOrderPromotion
                      )
                    }
                  />
                </Form.Group>
              </Form>
              <Button
                className="sumi-global-btn-color"
                onClick={this.handleOrderPromotionEditSave}
              >
                Save
              </Button>
              <Button
                className=""
                onClick={this.handleOrderPromotionEditCancel}
              >
                Cancel
              </Button>
            </Segment>
          )}

          {/* ---------------------- business info setting ---------------------- */}
          {!this.state.inStoreSettingEdit ? (
            <Card
              raised
              style={{
                width: "100%",
                opacity: "0.9",
              }}
            >
              <Card.Content className="selectable">
                <Card.Header style={{ fontSize: "xx-large" }}>
                  Store setting
                </Card.Header>
                <Card.Meta style={{ fontSize: "small" }}>
                  <List
                    size="small"
                    style={{ color: "black", padding: "10px" }}
                  >
                    <List.Item>
                      <List.Content>
                        Sale Tax:{" "}
                        <span style={{ fontSize: "14px", fontWeight: "700" }}>
                          {businessInfo_update && businessInfo_update.taxRate}
                        </span>
                      </List.Content>
                    </List.Item>
                    <List.Item>
                      <List.Content>
                        <Form.Group>
                          <Checkbox
                            disabled
                            label={<label>Support Delivery:</label>}
                            checked={
                              !!(
                                businessInfo_update &&
                                businessInfo_update.delivery
                              )
                            }
                          />
                          <span style={{ opacity: ".5", color: "#000" }}>
                            From {secondsToTimeStr(open)} To{" "}
                            {secondsToTimeStr(close)}
                          </span>
                        </Form.Group>
                        <Checkbox
                          disabled
                          label={<label>Support Curbside</label>}
                          checked={
                            !!(
                              businessInfo_update &&
                              businessInfo_update.curbside
                            )
                          }
                        />
                      </List.Content>
                    </List.Item>
                  </List>

                  <div
                    style={{
                      float: "right",
                      color: "#0059b3",
                      cursor: "pointer",
                      textDecoration: "underline",
                    }}
                    onClick={this.handleStoreSettingEditClick}
                  >
                    Edit
                  </div>
                </Card.Meta>
                <Card.Description />
              </Card.Content>
            </Card>
          ) : (
            <Segment style={{ width: "100%", margin: "0 0 14px 0" }} raised>
              <Header as="h2">Store Setting</Header>
              <Form style={{ margin: "10px" }}>
                <Form.Group>
                  <Form.Input
                    width={4}
                    fluid
                    label="Sale Tax"
                    placeholder="Example: 0.07"
                    value={
                      businessInfo_update && businessInfo_update.taxRate
                        ? businessInfo_update.taxRate
                        : ""
                    }
                    onChange={(evt) =>
                      this.handleStoreSettingTaxRateChange(evt.target.value)
                    }
                  />
                </Form.Group>
                <Form.Group>
                  <Table
                    basic="very"
                    collapsing
                    style={{ paddingLeft: "0.5em" }}
                  >
                    <Table.Body>
                      <Table.Row>
                        <Table.Cell>
                          <Form.Field>
                            <Checkbox
                              label={"Support Delivery"}
                              checked={
                                !!(
                                  businessInfo_update &&
                                  businessInfo_update.delivery
                                )
                              }
                              onClick={(evt, { checked }) => {
                                this.handleStoreSettingSupportDeliveryChange(
                                  checked
                                );
                              }}
                            />
                          </Form.Field>
                        </Table.Cell>
                        <Table.Cell>
                          {/* ---------------------------- open ------------------------- */}
                          <TimePicker
                            allowEmpty={false}
                            showSecond={false}
                            value={open_val}
                            className="xxx"
                            onChange={(value) => {
                              this.handleDeliveryHourChange(value, true);
                            }}
                            format={"h:mm a"}
                            disabled={
                              businessInfo_update &&
                              !businessInfo_update.delivery
                            }
                            clearIcon={false}
                          />
                        </Table.Cell>
                        <Table.Cell>-</Table.Cell>
                        <Table.Cell>
                          {/* ---------------------------- close ------------------------- */}
                          <TimePicker
                            allowEmpty={false}
                            showSecond={false}
                            value={close_val}
                            className="xxx"
                            onChange={(value) => {
                              this.handleDeliveryHourChange(value, false);
                            }}
                            format={"h:mm a"}
                            disabled={
                              businessInfo_update &&
                              !businessInfo_update.delivery
                            }
                          />
                        </Table.Cell>
                      </Table.Row>
                    </Table.Body>
                  </Table>
                </Form.Group>
                <Form.Group>
                  <Form.Field>
                    <Checkbox
                      label={"Support Curbside"}
                      checked={
                        !!(businessInfo_update && businessInfo_update.curbside)
                      }
                      onClick={(evt, { checked }) => {
                        this.handleStoreSettingSupportCurbsideChange(checked);
                      }}
                    />
                  </Form.Field>
                </Form.Group>
              </Form>
              <Button
                className="sumi-global-btn-color"
                onClick={this.handleStoreSettingEditSave}
              >
                Save
              </Button>
              <Button className="" onClick={this.handleStoreSettingEditCancel}>
                Cancel
              </Button>
            </Segment>
          )}

          {/* ---------------------- Dinein Table QR code generater ---------------------- */}

          <Modal
            closeIcon
            dimmer={true}
            open={!!this.state.showQRCodeGeneratorModal}
            onClose={this.handleNewQRCodeModalClose}
            className="goorder-online-itemDetail"
          >
            <Modal.Header>Enter the Table Name</Modal.Header>
            <Modal.Content>
              <Input
                fluid
                value={this.state.dineinTableNameEntered || ""}
                onChange={(evt) =>
                  this.handleDineinTableNameChange(evt.target.value)
                }
              />
              {this.state.dineinTableNameEntered ? (
                <div>
                  <div
                    ref={pdf_ref}
                    style={{
                      width: "100%",
                      textAlign: "center",
                      padding: "10px",
                    }}
                  >
                    <QRCode
                      style={{ minWidth: "200px", minHeight: "200px" }}
                      value={`https://www.luckory.com/store/${this.props.merchantId}/dinein/${this.state.dineinTableNameEntered}`}
                    />
                  </div>
                </div>
              ) : null}
            </Modal.Content>
            <Modal.Actions>
              <Button onClick={this.handleNewQRCodeModalClose}>Back</Button>

              {this.state.dineinTableNameEntered ? (
                <Pdf
                  targetRef={pdf_ref}
                  filename={`Table_${this.state.dineinTableNameEntered}.pdf`}
                >
                  {({ toPdf }) => (
                    <Button className={"sumi-global-btn-color"} onClick={toPdf}>
                      Download QRCode
                    </Button>
                  )}
                </Pdf>
              ) : null}
            </Modal.Actions>
          </Modal>

          {this.state.enableDinein ? (
            <Card
              raised
              style={{
                width: "100%",
                opacity: "0.9",
              }}
            >
              <Card.Content className="selectable">
                <Card.Header style={{ fontSize: "xx-large" }}>
                  Dine In Table
                </Card.Header>
                <Card.Meta style={{ fontSize: "small" }}>
                  <Button
                    className="sumi-global-btn-color"
                    style={{ margin: "15px 0" }}
                    onClick={this.handleNewQRCodeClick}
                  >
                    Create New QR Code for Table
                  </Button>
                </Card.Meta>
                <Card.Description />
              </Card.Content>
            </Card>
          ) : null}
        </Container>
      </Segment>
    );
  }
}

export default BusinessInfoPanel;
