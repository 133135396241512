import React, { Component } from "react";
import { Container, Segment, Divider, Header, Image } from "semantic-ui-react";
import luckoryLogo from "../imgs/logo7.png";

class PrivacyPolicyPage extends Component {
  render() {
    return (
      <div
        style={{
          width: "100%",
          height: "100%",
          margin: "0",
          padding: "0",
          backgroundColor: "white",
        }}
      >
        {/* ------------------------------  header panel ----------------------------- */}
        <Segment
          style={{
            height: "50px",
            top: "-1px",
            width: "100%",
            position: "fixed",
            padding: "5px",
            margin: "0",
            zIndex: "10",
          }}
        >
          <Image
            src={luckoryLogo}
            size="small"
            style={{
              margin: "0px auto",
              display: "inline-block",
              left: "calc(50% - 68px)",
              verticalAlign: "0",
              position: "absolute",
              height: "25px",
              width: "auto",
              top: "12px",
            }}
            // className="hide-if-medium noselect"
            className="noselect"
          />
        </Segment>

        <Container>
          <Segment textAlign="left" basic style={{ top: "50px" }}>
            <Header as="h2" textAlign="center">
              Privacy Policy
            </Header>
            <p>
              Thank you for choosing Luckory (hereafter “Luckory,” “we,” “us,”
              or “our”). Here at Luckory, we take your privacy and data security
              seriously.  This Privacy Policy (“Privacy Policy” or “Policy”)
              details what information we collect information from you, what
              information we share about you, and what we do with your
              information.  This Policy applies when you (“you” or “user”) visit
              our website at www.Luckory.com, or use any of our services
              (collectively, “Sites”).  Please take the time to review this
              Policy and understand it. When you provide us with any
              information, including Personal Information (as defined below),
              you consent to our collection, use, retention, and disclosure of
              your information in accordance with this Policy and as permitted
              by law. If there are any terms in this Policy that you do not
              agree with, please discontinue use of our Sites.
            </p>
            <Header as="h3">
              By using our Sites, you hereby agree to the terms of this Privacy
              Policy and our Customer Terms of Use.
            </Header>
            <Header as="h3">I. Type of Information We Collect</Header>
            <Header as="h4">1. Personal Information</Header>

            <p>
              When you use our Site, we collect personal information that you
              voluntarily provide to us.  This may include your first and last
              name, email address, mailing address, phone number, and other
              similar contact data. The Personal Information we collect depends
              on the context of your interactions with us and your use of the
              Sites.  We do not collect Personal Information unless it is
              provided voluntarily. Please know this information allows us to
              identify who you are. All Personal Information you provide us must
              be true, complete and accurate, and you must notify us of any
              changes to such personal information.
            </p>
            <Header as="h4">2. Payment Information</Header>
            <p>
              When placing an order through the Sites, you must submit payment
              information.  In addition to Personal Information, you will be
              required to submit financial information, such as a credit card
              number, debit card number, Google Pay, Venmo, Paypal, or other
              financial account information (collectively “Payment
              Information”). If you complete an order through the Sites as a
              “Guest,” we do not create an account for you. The information you
              provide when completing an order is kept only for the purposes
              outlined in this Privacy Policy and Terms of Use.
            </p>
            <Header as="h4">3. Social Media Information</Header>
            <p>
              We provide you with the option to use your social media account,
              such as Facebook or Google, to place an order. If you engage with
              us this way, your social media account may share Personal
              Information with us. The Personal Information we receive varies
              depending on the social media provider, but will often include
              your name, e-mail address, contacts list, profile picture, and
              other information you choose to make public.  Please note that we
              do not control, and are not responsible for, other uses of your
              Personal Information by your third-party social media provider. If
              you place an order using a social media login through the Sites,
              you may remove Luckory’s access to your social media account
              information.  This can be done through the settings of your social
              media account. Please note if you remove Luckory’s access to your
              social media account, your social media account may retain certain
              information you provided to it.  Luckory is not responsible for
              the information retained by your social media account.
            </p>
            <Header as="h4">4. Geolocation Information</Header>
            <p>
              With your consent, we may obtain geolocation information from your
              mobile device, or certain third-party providers while you are
              using our Sites, to provide location-based services. You may
              control the collection of geolocation information based on your
              mobile device’s settings.  If you decline to allow Luckory to
              collect geolocation data, Luckory may not be able to provide
              certain services to you.
            </p>
            <Header as="h4">5. Other Information</Header>
            <p>
              We collect other information you voluntarily provide us, such as
              your food orders, and favorite restaurants or foods.
            </p>
            <Header as="h4">6. Information Collected Automatically</Header>
            <p>
              We may automatically collect certain types of information when you
              use or visit our Sites. We use this information to improve and
              enhance our Sites, your experience, and for security purposes.
              This information does not reveal your Personal Information. It may
              include: 1) the device and usage information you use to access the
              Sites; 2) your IP address; 3) browser and device characteristics
              4) operating system 5) referring URLs, 6) your location, and 7)
              what parts of the Site you use and how often. If you use your
              mobile device to use the Sites, we may automatically collect
              device information (such as your mobile device ID, model and
              manufacturer), operating system, version information, and IP
              address.
            </p>
            <Header as="h4">7. Cookies</Header>
            <p>
              We may use cookies and similar tracking technologies (i.e. web
              beacons, embedded scripts, location-identifying technologies, and
              similar technologies) to access or store information and collect
              information about your use of the Sites to analyze, improve, and
              enhance users’ experience. This may include cookies that expire
              once you close your web browser and cookies that remain on your
              computer until you delete them. Most web browsers are set to
              accept cookies by default. If you prefer, you can usually choose
              to set your browser to remove cookies and to reject cookies. If
              you choose not to accept the use of cookies, you may not be able
              to use all aspects of the Sites.
            </p>
            <Header as="h4">8. How We Use Your Information</Header>
            <p>
              We use Personal Information collected through our Sites: - To
              facilitate, fulfill, and manage your order(s); - To delivery your
              order (if applicable); - To send you marketing and promotional
              communications. - To send you information about our Sites and
              services, and changes to our Terms of Use and policies; - To post
              reviews and ratings; - To request feedback about your use of the
              Sites; - To perform analytics and customer research; - To enforce
              our Terms of Use, policies, or legal obligations; - To respond to
              your inquiries and solve any potential issues you might have with
              the Sites. We also may use your information in an anonymized and
              aggregated form for data analysis, identifying usage trends, and
              to improve Sites, services, and your experience. We will not use
              identifiable Personal Information without your consent.
            </p>
            <Header as="h4">9. How Do We Share Your Information? </Header>
            <p>
              We may share your information: - if you have given us specific
              consent to use your personal information for a specific purpose; -
              if it is reasonably necessary to achieve our business interests; -
              to fulfill, manage, and complete your order, including delivery,
              if applicable; - to third-party service providers and vendors,
              such as payment processing, data analysis, email delivery, hosting
              services, customer service, food delivery service providers, and
              marketing services; - with our business partners to offer you
              certain products, services, or promotions; - to enforce our Terms
              of Use; - with restaurants with which you placed an order; - with
              third-party advertising companies to serve ads when you visit the
              Sites; - when we are legally required to do so to comply with
              applicable law, governmental requests, a judicial proceeding,
              court order, or subpoena; - in connection with, or during
              negotiations of, any merger, sale of company assets, financing, or
              acquisition of all or a portion of our business to another
              company; - where we believe it is necessary to investigate,
              prevent, or act regarding potential violations of our policies,
              suspected fraud, situations involving potential threats to the
              safety of any person, situations of suspected illegal activities,
              or as evidence in litigation in which we are involved.
            </p>
            <Header as="h4">10. Third-Party Websites </Header>
            <p>
              This Privacy Policy does not apply to or cover your use of
              third-party websites to which the Sites may link, including
              advertisements and restaurants. Inclusion of a third-party website
              link on the Sites is not an endorsement by us, and we have not
              verified the safety or privacy of your data on any third-party
              website. Luckory is not responsible for any third-party websites,
              and will not assume any liability arising from your use of
              third-party websites. Please note that your access and/or use of
              any third-party websites, including providing of any information,
              materials and/or other content to such sites, is entirely at your
              own risk. You should review the policies of such third-parties and
              contact them directly to respond to your questions.
            </p>
            <Header as="h4">11. Retention of Your Information</Header>
            <p>
              We will retain your Personal Information for as long as it is
              necessary for the purposes set out in this Privacy Policy, unless
              a longer retention period is required or permitted by law. We may
              retain and use your non-personal information in an aggregate an
              anonymized fashion for the reasons set forth in this Privacy
              Policy.
            </p>
            <Header as="h4">12. Security</Header>
            <p>
              We implement appropriate technical and organizational security
              measures designed to protect the security of Personal Information
              we receive or process. Please remember, however, that we cannot
              100% guarantee the security of transactions on the internet.
              Although we do our best to protect your information, the
              transmission of your data, including Personal Information, to and
              from our Sites is at your own risk. You should only access the
              Sites only within a secure environment.
            </p>
            <Header as="h4">13. Children Under 13 Years Old</Header>
            <p>
              Our Terms of Use prohibit use of our Sites by anyone under 13
              years old. We do not knowingly solicit data from or market to
              children under 13 years of age. If you become aware of any data we
              have collected from anyone under 13 years old, please contact us
              at info@Luckory.com.
            </p>
            <Header as="h4">14. Marketing</Header>
            <p>
              You can unsubscribe from our marketing emails at any time by
              clicking on the unsubscribe link contained in the emails. You may
              also unsubscribe from receiving certain order-related text (SMS)
              or multimedia (MMS) messages by replying "STOP" to the number
              sending the message. You may also contact us at info@Luckory.com.
              For more information about communications with Luckory, please see
              our Terms of Use.
            </p>
            <Header as="h4">15. California Residents</Header>
            <p>
              California Civil Code Section 1798.83, also known as the “Shine
              The Light” law, permits California residents to request and obtain
              from us, once a year and free of charge, information about
              categories of Personal Information (if any) we disclosed to
              third-parties for direct marketing purposes and the names and
              addresses of all third parties with which we shared personal
              information in the immediately preceding calendar year. If you are
              a California resident and would like to make such a request,
              please submit your request in writing to us using the contact
              information provided below.
            </p>
            <Header as="h4">16. UPDATES TO THIS POLICY</Header>
            <p>
              We may update this Privacy Policy from time to time without prior
              notice. Changes to this policy are effective as soon as they are
              posted and will be indicated by the “Last Updated” date. If we
              make material changes to this Privacy Policy, we may notify you
              either by prominently posting a notice of such changes or by
              directly sending you a notification. We encourage you to review
              this Privacy Policy frequently to be informed of how we are
              protecting your information and informed of any updates.
            </p>

            <Header as="h4">17. SMS</Header>
            <p>
              No mobile information will be shared with third parties/affiliates
              for marketing/promotional purposes. All the above categories
              exclude text messaging originator opt-in data and consent; this
              information will not be shared with any third parties.
            </p>

            <Header as="h3">Contact Information</Header>
            <p>
              Luckory welcomes your questions or comments regarding the Terms:
              <br />
              Luckory
              <br /> 3063 Peachtree Industrial Blvd STE 200 <br />
              Duluth, GA 30097
              <br />
              Phone Number: (678) 846-6666
            </p>
          </Segment>
        </Container>

        <Divider
          className="no-margin"
          style={{
            top: "50px",
            position: "relative",
          }}
        />
        <Segment
          basic
          style={{
            margin: "0",
            color: "#6f6f6f",
            fontSize: "11px",
            top: "50px",
          }}
        >
          <a
            href="/document/terms-of-service"
            style={{ margin: "0 10px", color: "#6f6f6f" }}
          >
            Terms of Service
          </a>

          <a
            href="/document/privacy-policy"
            style={{ margin: "0 10px", color: "#6f6f6f" }}
          >
            Privacy
          </a>
          <span style={{ margin: "0 10px" }}>© 2023 Luckory</span>
        </Segment>
      </div>
    );
  }
}

export default PrivacyPolicyPage;
