import React, { Component } from "react";
import {
  Segment,
  Divider,
  Icon,
  Header,
  Dimmer,
  Loader,
  Transition,
  Table,
  Form,
  TextArea,
  Modal,
  Message,
  Radio,
  Checkbox,
  Button,
  Input,
  Popup,
  Image,
  Label,
} from "semantic-ui-react";

import ReCAPTCHA from "react-google-recaptcha";

import visaIcon from "../imgs/visa.svg";
import masterIcon from "../imgs/mastercard.svg";
import discoverIcon from "../imgs/discover.svg";
import americanExpIcon from "../imgs/americanExp.svg";

import { ReactComponent as SelectedIcon } from "../imgs/selected.svg";

import pickupIcon from "../imgs/pickup1.png";
import pickupIconSelected from "../imgs/pickup1_selected.png";

import deliveryIcon from "../imgs/delivery1.png";
import curbsideIcon from "../imgs/curbside.png";
import roomServiceDeliveryIcon from "../imgs/roomServiceDelivery.png";

import {
  formatPrice,
  formatAddress,
  formatPhoneNumber,
  formatCreditCardNumber,
  formatCreditCardExpiration,
  formatCreditCardCVC,
  verifyCustomerName,
  verifyCustomerPhone,
  verifyCardNumber,
  verifyCreditCardCVC,
  verifyCreditCardExpiration,
  verifyUSPostalCode,
  verifyRoomServiceRoom,
  getTimeScheduleList,
  getBusinessHour,
  getXDayBusinessHour,
  getTimeStringFromMin,
  toUrl,
  secondsToTimeStr,
  checkIfWithinBusinessHour2,
  formatDateMonthDayYear,
} from "../dataModel/utils";
import GoogleMapPanel from "./googleMapPanel";
import { isSmallDevice, checkIfWithinDeliveryHour } from "./../dataModel/utils";

var inPaymentProcess = false;

class OrderSummaryPanel extends Component {
  recaptchaRef = React.createRef();

  state = {
    loading_googleMap: false,
    loading_page: false,
    loading_promotion: false,
    loading_summary: false,

    showPromoCodeMsg: false, // for small device
    businessHour_today: this.props.withinBusinessHour_today,

    scheduledTimeSelectOpen: false,
    timeSchedualList: [],
    showRoomServiceRoomError: false,
    showCustomerNameError: false,

    captchaValue: null,
  };

  constructor(props) {
    super(props);
  }

  onCaptchaChange = (value) => {
    const recaptchaValue = this.recaptchaRef.current.getValue();
    this.setState({ captchaValue: recaptchaValue });
  };

  componentDidMount = () => {
    // const businessHour_today =
    //   this.props.businessInfo &&
    //   getBusinessHour(this.props.businessInfo.businessHour);
    // const timeSchedualList = getTimeScheduleList(
    //   null,
    //   businessHour_today &&
    //     businessHour_today[businessHour_today.length - 1] &&
    //     businessHour_today[businessHour_today.length - 1].close
    // );
    // this.setState({ timeSchedualList });
  };

  componentDidUpdate = () => {
    if (this.props.orderMethod === this.props.METHOD.DELIVERY) {
      setTimeout(() => {
        const addrInputElem = (document.getElementsByClassName(
          "pac-target-input"
        ) || [])[0];
        addrInputElem && addrInputElem.focus();
      }, 10);
    }
  };

  startLoading_googleMap = (msg) => {
    this.setState({
      loading_googleMap: true,
    });
  };

  endLoading_googleMap = () => {
    this.setState({
      loading_googleMap: false,
    });
  };

  startLoading_page = (msg) => {
    this.setState({
      loading_page: true,
    });
  };

  endLoading_page = () => {
    this.setState({
      loading_page: false,
    });
  };

  startLoading_promotion = (msg) => {
    this.setState({
      loading_promotion: true,
    });
  };

  endLoading_promotion = () => {
    this.setState({
      loading_promotion: false,
    });
  };

  startLoading_summary = (msg) => {
    this.setState({
      loading_summary: true,
    });
  };

  endLoading_summary = () => {
    this.setState({
      loading_summary: false,
    });
  };

  handleMethodChange = async (orderMethod) => {
    this.startLoading_googleMap();
    this.props.onMethodChange && (await this.props.onMethodChange(orderMethod));
    this.endLoading_googleMap();
  };

  // geometry:{location:{lat(), lng()}}
  handleCustomerAddressSubmit = async (addressStr, postalCode, geometry) => {
    this.startLoading_googleMap();
    this.props.onCustomerAddressSubmit &&
      (await this.props.onCustomerAddressSubmit(
        addressStr,
        postalCode,
        geometry
      ));
    this.endLoading_googleMap();
  };

  handleCustomerAddrChangeClick = () => {
    this.props.onCustomerAddrChangeClick &&
      this.props.onCustomerAddrChangeClick();
  };

  handleDeliveryNoteChange = (note) => {
    this.props.onDeliveryNoteChange && this.props.onDeliveryNoteChange(note);
  };

  handleCustomerNameChange = (val) => {
    this.props.onCustomerNameChange && this.props.onCustomerNameChange(val);
  };

  handleCustomerPhoneChange = (val) => {
    this.props.onCustomerPhoneChange && this.props.onCustomerPhoneChange(val);
  };

  handleCustomerEmailChange = (val) => {
    this.props.onCustomerEmailChange && this.props.onCustomerEmailChange(val);
  };

  handleCardNumberChange = (val) => {
    this.props.onCardNumberChange && this.props.onCardNumberChange(val);
  };

  handleCardExpirationChange = (val) => {
    this.props.onCardExpirationChange && this.props.onCardExpirationChange(val);
  };

  handleCardCVVChange = (val) => {
    this.props.onCardCVVChange && this.props.onCardCVVChange(val);
  };

  handlePaymentMethodChange = (paymentMethod) => {
    this.props.onPaymentMethodChange &&
      this.props.onPaymentMethodChange(paymentMethod);
  };

  handleBillAddressChange = (val) => {
    this.props.onBillAddressChange && this.props.onBillAddressChange(val);
  };

  handleBillZipCodeChange = (val) => {
    this.props.onBillZipCodeChange && this.props.onBillZipCodeChange(val);
  };

  handleSameAsDeliveryAddrClick = (checked) => {
    this.props.onSameAsDeliveryAddrClick &&
      this.props.onSameAsDeliveryAddrClick(checked);
  };

  handleCarMakeModelChange = (val) => {
    this.props.onCarMakeModelChange && this.props.onCarMakeModelChange(val);
  };

  handleCarColorChange = (val) => {
    this.props.onCarColorChange && this.props.onCarColorChange(val);
  };

  handleReceiveOrderInfoClick = (checked) => {
    this.props.onReceiveOrderInfoClick &&
      this.props.onReceiveOrderInfoClick(checked);
  };

  // -------------------------- below is used for small device only -----------------
  handleOrderTipChange = (pct) => {
    this.props.onOrderTipChange && this.props.onOrderTipChange(pct);
  };

  handleCustomTipClick = () => {
    this.props.onOrderCustomTipClick && this.props.onOrderCustomTipClick();
  };

  handleCustomTipChange = (tips) => {
    if (Number(tips) < 0) {
      return;
    }
    this.props.onCustomTipChange && this.props.onCustomTipChange(tips);
  };

  handlePromoCodeChange = (val) => {
    this.props.onPromoCodeChange &&
      this.props.onPromoCodeChange((val || "").toUpperCase());
  };

  handlePromoCodeApplyClick = async () => {
    const applied =
      this.props.onPromoCodeApplyClick &&
      (await this.props.onPromoCodeApplyClick());

    if (!applied) {
      this.setState({
        showPromoCodeMsg: true,
      });
      setTimeout(() => {
        this.setState({
          showPromoCodeMsg: false,
        });
      }, 2000);
    }
  };

  handlePromoCodeRemove = () => {
    this.props.onPromoCodeRemove && this.props.onPromoCodeRemove();
  };

  handlePlaceOrderClick = async (amount, evt) => {
    try {
      const { paymentMethod, PAYMENT_METHOD } = this.props;

      const token = await this.recaptchaRef.current.executeAsync();

      let token_forCreditcardCaptcha = null;
      if (PAYMENT_METHOD && paymentMethod === PAYMENT_METHOD.CREDITCARD) {
        token_forCreditcardCaptcha =
          await this.recaptchaRef.current.executeAsync();
      }

      if (!token) {
        console.error("reCAPTCHA Failed to create token");
        return;
      }

      this.startLoading_page();

      // const { captchaValue } = this.state;
      // console.log(123, captchaValue);
      // return;
      evt.preventDefault();

      fetch("/verify-captcha", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ captchaResponse: token }),
      })
        .then((response) => response.json())
        .then(async (data) => {
          console.log(234, data);
          if (data.success) {
            // Proceed with form submission
            if (this.props.onPlaceOrderClick) {
              const placed = await this.props.onPlaceOrderClick(
                amount,
                token_forCreditcardCaptcha
              );
              if (placed) {
                return;
              }
            }
          } else {
            // Handle verification failure
            console.log("something wrong");
          }

          this.endLoading_page();
        });
    } catch (ex) {
      console.error(ex);
    } finally {
    }
  };

  handleBackToMenuClick = () => {
    this.props.onBackToMenuClick && this.props.onBackToMenuClick();
  };

  handleHideNeedCardInfoMsg = () => {
    this.props.onHideNeedCardInfoMsg && this.props.onHideNeedCardInfoMsg();
  };

  //------------------------------- schedule a time ----------------------------------
  // handleScheduleTimeSelect = ()
  // orderTime: asap|schedule
  handleOrderTimeSelect = () => {
    this.props.onScheduleTimeOptionSelect &&
      this.props.onScheduleTimeOptionSelect();

    // if (selected) {
    //   this.setState({
    //     scheduledTimeSelectOpen: true,
    //   });
    // }

    // if (selected) {
    //   this.scheduleTimeInput && this.scheduleTimeInput.focus();
    // }
  };

  handleAsapSelect = () => {
    this.props.onAsapOptionSelect && this.props.onAsapOptionSelect();
  };

  handleOrderDateSelect = (hourInfo) => {
    this.props.onOrderDateSelect && this.props.onOrderDateSelect(hourInfo);
  };

  handleScheduledTimeChange = (value) => {
    this.props.onScheduledTimeChange && this.props.onScheduledTimeChange(value);
  };

  orderItemOptionRemap = (orderItemOption) => {
    if (orderItemOption) {
      let remapDic = {};
      for (let i = 0; i < orderItemOption.length; i++) {
        let itemOptionId = orderItemOption[i]._id;
        if (itemOptionId in remapDic) {
          remapDic[itemOptionId].original_index.push(i);
          remapDic[itemOptionId].qty = remapDic[itemOptionId].qty + 1;
        } else {
          orderItemOption[i]["qty"] = 1;
          orderItemOption[i]["original_index"] = [i];
          remapDic[itemOptionId] = orderItemOption[i];
        }
      }
      let finalReMap = [];
      for (let j in remapDic) {
        finalReMap.push(remapDic[j]);
      }
      return finalReMap;
    } else {
      return orderItemOption;
    }
  };

  // ------------------------------- room service -------------------------------
  handleRoomServiceRoomNumberChange = (value) => {
    this.props.onRoomServiceRoomNumberChange &&
      this.props.onRoomServiceRoomNumberChange(value);
  };

  // businessInfo : { name, address:{street, city, state, postalCode}, phone, email, type, webSite, delivery }
  render() {
    const {
      orderedItems,
      // orderedItems_PromoMethod,
      orderMethod,
      dineinTableName,
      METHOD,
      paymentMethod,
      PAYMENT_METHOD,
      businessInfo,
      customerAddressStr,
      lat,
      lng,
      deliveryNote,
      customerName,
      customerPhone,
      customerEmail,

      billingAddressStr,
      billingZipCode,

      needCustomerAddrMsgShow,
      needCustomerInfoMsgShow,
      needCardInfoMsgShow,
      needCarInfoMsgShow,
      // notAboveMinOrderForDeliveryShow,

      cardNumber,
      cardCVV,
      cardExpiration,

      receiveOrderInfo,

      freeItemNames,
      minAmount,
      freeItemNameSelected,
      orderPromotionAvailableTime,

      // -------------------- below for small device use only ----------------
      subtotal,
      tax,
      tip,
      fixedTips,
      fixedTipRate,
      discount,
      total,
      order_tips_pct,
      custom_tips,
      promoCodeApplied,
      promoCode,
      processingFeeAmt,
      serviceFeeAmt,
      deliveryFee,
      roomServiceDeliveryFee,
      deliveryOutRangeWarning,
      carMakeModel,
      carColor,
      minOrderForDelivery,

      scheduleTimeSelected,

      roomServiceRoom,
      needRoomServiceInfoMsgShow,

      isBranch,
    } = this.props;

    // console.log(177, paymentMethod, PAYMENT_METHOD);

    // console.log(123, orderMethod, METHOD);
    const notAboveMinOrderForDeliveryShow =
      subtotal < minOrderForDelivery && orderMethod == METHOD.DELIVERY;

    // const businessHour_today =
    //   businessInfo && getBusinessHour(businessInfo.businessHour);

    // const timeSchedualList = getTimeScheduleList(
    //   null,
    //   businessHour_today &&
    //     businessHour_today[businessHour_today.length - 1] &&
    //     businessHour_today[businessHour_today.length - 1].close
    // );

    const deliveryAvailable =
      businessInfo &&
      !!businessInfo.delivery &&
      checkIfWithinDeliveryHour(businessInfo.deliveryAvailableTime);

    // check if it supports roomServiceDelivery -------------
    const roomServiceDeliveryAvaiable =
      businessInfo && !!businessInfo.roomService;

    const roomService = !!(businessInfo && businessInfo.roomService);

    const curbsideAvailable = businessInfo && !!businessInfo.curbside;

    const supportCreditcardPayment =
      businessInfo && businessInfo.supportCreditcardPayment;

    const supportCashPayment = businessInfo && businessInfo.supportCashPayment;

    const curbsideSelected = METHOD && orderMethod === METHOD.CURBSIDE;
    const roomServerDeliverySelected =
      METHOD && orderMethod === METHOD.ROOM_SERVICE_DELIVERY;

    const creditcardPaymentSelected =
      supportCreditcardPayment &&
      PAYMENT_METHOD &&
      paymentMethod === PAYMENT_METHOD.CREDITCARD;

    const cashPaymentSelected =
      supportCashPayment &&
      PAYMENT_METHOD &&
      paymentMethod === PAYMENT_METHOD.CASH;

    const allowAmerExpr = businessInfo && businessInfo.allowAmericanExpressCard;

    const orderedItemsRows = (orderedItems || []).map((orderedItem, index) => {
      const {
        name,
        price,
        qty,
        orderItemOptions,
        note,
        size,
        discount,
        availableHour,
      } = orderedItem;

      let itemTotalPrice = price;
      (orderItemOptions || []).forEach((ov) => {
        itemTotalPrice += ov.price;
      });
      itemTotalPrice = itemTotalPrice * Number(qty || 0);

      const orderItemOptionsRemap = this.orderItemOptionRemap(orderItemOptions);
      let discountStr = null;
      if (discount) {
        if (discount.type === "coff") {
          // do later
        } else if (discount.type === "poff" && discount.amount != null) {
          itemTotalPrice = itemTotalPrice * (1 - discount.amount);
          discountStr =
            discount.amount == 1 ? "Free" : discount.amount * 100 + "% OFF";
        }
      }

      const isCategoryAvailableNow = checkIfWithinBusinessHour2(
        availableHour,
        this.props.scheduleDate
      );
      return (
        <Table.Row key={index} verticalAlign="top">
          <Table.Cell width={2}>
            <b>{qty}</b>
          </Table.Cell>
          <Table.Cell width={10}>
            <b>{name}</b>
            {size ? (
              <React.Fragment>
                <br />
                <span
                  style={{
                    fontSize: "smaller",
                    color: "rgb(125, 125, 125)",
                  }}
                >
                  {size}
                </span>{" "}
              </React.Fragment>
            ) : null}
            {(orderItemOptionsRemap || []).map((itemOption, optionIndex) => {
              return (
                <React.Fragment key={optionIndex}>
                  <br />
                  <span
                    style={{
                      fontSize: "smaller",
                      color: "rgb(125, 125, 125)",
                    }}
                  >
                    {itemOption.qty > 1 ? itemOption.qty + " x " : null}
                    {itemOption.name}
                  </span>
                </React.Fragment>
              );
            })}

            {isCategoryAvailableNow ? null : (
              <Label size="mini" color="red" basic style={{ display: "block" }}>
                <Icon name="warning circle" />
                This item unavailable now
              </Label>
            )}

            {note ? (
              <React.Fragment>
                <br />
                <span
                  style={{
                    fontSize: "smaller",
                    color: "rgb(125, 125, 125)",
                  }}
                >
                  Note: {note}
                </span>
              </React.Fragment>
            ) : null}
          </Table.Cell>
          <Table.Cell width={2}>
            <b>${formatPrice(itemTotalPrice)}</b>
            {discountStr ? (
              <span
                style={{
                  display: "block",
                  fontSize: "12px",
                  color: "#a5643a",
                }}
              >
                {discountStr}
              </span>
            ) : null}
          </Table.Cell>
          <Table.Cell width={2}>
            <Icon
              name="close"
              size="small"
              style={{ cursor: "pointer" }}
              onClick={async () => {
                this.startLoading_promotion();
                await this.props.onRemoveFromCartClick(index);
                this.endLoading_promotion();
              }}
            />
          </Table.Cell>
        </Table.Row>
      );
    });

    // --------------------------- add free add-on item if any ---------------------
    if (freeItemNameSelected && orderedItemsRows) {
      orderedItemsRows.push(
        <Table.Row key="free_addon" verticalAlign="top">
          <Table.Cell width={2}>
            <b>{1}</b>
          </Table.Cell>
          <Table.Cell width={10}>
            <b>{freeItemNameSelected}</b>
            <br />
            <span
              style={{
                fontSize: "smaller",
                color: "rgb(125, 125, 125)",
              }}
            >
              Note: Free addon item
            </span>
          </Table.Cell>
          <Table.Cell width={2}>
            <b>$0.00</b>
          </Table.Cell>
          <Table.Cell width={2}>
            <Icon
              name="close"
              size="small"
              style={{ cursor: "pointer" }}
              onClick={() => {
                this.props.onOrderPromotionItemSelect(null);
              }}
            />
          </Table.Cell>
        </Table.Row>
      );
    }

    const xDayBusinessHours = getXDayBusinessHour(businessInfo.businessHour, 1);

    return (
      <Segment basic className="checkout-panel" textAlign="center">
        <Dimmer inverted active={this.state.loading_page} page>
          <Loader size="huge" />
        </Dimmer>

        {/* ---------------- method ------------------ */}
        <div className="content" id={"summary_method"}>
          <div className="header">
            <Header as="h5">METHOD</Header>
          </div>
          <div className="method">
            <Form size="large" style={{ fontWeight: "600" }}>
              <Form.Group grouped>
                <Button.Group fluid basic>
                  {dineinTableName ? (
                    <Button
                      // href={"#summary_method"}
                      style={{
                        // backgroundColor:
                        //   METHOD && orderMethod === METHOD.PICKUP
                        //     ? "rgb(0, 49, 98)"
                        //     : "white",
                        border:
                          METHOD && orderMethod === METHOD.DINEIN
                            ? "2px solid rgb(0, 49, 98)"
                            : "unset",
                        fontWeight: "600",
                        opacity:
                          METHOD && orderMethod === METHOD.DINEIN ? "1" : "0.7",
                        width: "33%",
                      }}
                      onClick={() => this.handleMethodChange(METHOD.DINEIN)}
                    >
                      Dine In
                      <br />
                      Table - #{dineinTableName}
                    </Button>
                  ) : null}

                  <Button
                    // href={"#summary_method"}
                    style={{
                      // backgroundColor:
                      //   METHOD && orderMethod === METHOD.PICKUP
                      //     ? "rgb(0, 49, 98)"
                      //     : "white",
                      border:
                        METHOD && orderMethod === METHOD.PICKUP
                          ? "2px solid rgb(0, 49, 98)"
                          : "unset",
                      fontWeight: "600",
                      opacity:
                        METHOD && orderMethod === METHOD.PICKUP ? "1" : "0.7",
                      width: "33%",
                    }}
                    onClick={() => this.handleMethodChange(METHOD.PICKUP)}
                  >
                    <Image
                      // src={
                      //   METHOD && orderMethod === METHOD.PICKUP
                      //     ? pickupIconSelected
                      //     : pickupIcon
                      // }
                      src={pickupIcon}
                      size="tiny"
                      centered
                      style={{
                        width: "50px",
                        height: "50px",
                      }}
                    />
                    <br />
                    Pickup
                  </Button>

                  {!deliveryAvailable ? null : (
                    <Button
                      // href={"#summary_method"}
                      style={{
                        border:
                          METHOD && orderMethod === METHOD.DELIVERY
                            ? "2px solid rgb(0, 49, 98)"
                            : "unset",
                        fontWeight: "600",
                        opacity:
                          METHOD && orderMethod === METHOD.DELIVERY
                            ? "1"
                            : "0.7",
                        width: "33%",
                      }}
                      onClick={() => this.handleMethodChange(METHOD.DELIVERY)}
                      disabled={!deliveryAvailable}
                    >
                      <Image
                        src={deliveryIcon}
                        size="tiny"
                        centered
                        style={{
                          width: "50px",
                          height: "50px",
                        }}
                      />
                      <br />
                      {"Delivery" + (deliveryAvailable ? "" : " (unavailable)")}
                    </Button>
                  )}

                  {curbsideAvailable ? (
                    <Button
                      // href={"#summary_method"}
                      style={{
                        border:
                          METHOD && orderMethod === METHOD.CURBSIDE
                            ? "2px solid rgb(0, 49, 98)"
                            : "unset",
                        fontWeight: "600",
                        opacity:
                          METHOD && orderMethod === METHOD.CURBSIDE
                            ? "1"
                            : "0.7",
                        width: "33%",
                      }}
                      onClick={() => this.handleMethodChange(METHOD.CURBSIDE)}
                      disabled={!curbsideAvailable}
                    >
                      <Image
                        src={curbsideIcon}
                        size="tiny"
                        centered
                        style={{
                          width: "50px",
                          height: "50px",
                        }}
                      />
                      <br />
                      {"Curbside" + (curbsideAvailable ? "" : " (unavailable)")}
                    </Button>
                  ) : null}

                  {roomServiceDeliveryAvaiable ? (
                    <Button
                      style={{
                        border:
                          METHOD && orderMethod === METHOD.ROOM_SERVICE_DELIVERY
                            ? "2px solid rgb(0, 49, 98)"
                            : "unset",
                        fontWeight: "600",
                        opacity:
                          METHOD && orderMethod === METHOD.ROOM_SERVICE_DELIVERY
                            ? "1"
                            : "0.7",
                        width: "33%",
                      }}
                      onClick={() =>
                        this.handleMethodChange(METHOD.ROOM_SERVICE_DELIVERY)
                      }
                    >
                      <Image
                        src={roomServiceDeliveryIcon}
                        size="tiny"
                        centered
                        style={{
                          width: "50px",
                          height: "50px",
                        }}
                      />
                      <br />
                      {"Room Service Delivery"}
                    </Button>
                  ) : null}
                </Button.Group>
              </Form.Group>
            </Form>
            <Message
              // style={{ textAlign: "left" }}
              hidden={!notAboveMinOrderForDeliveryShow}
              error
              size="tiny"
              // icon="warning sign"
              // header={`Sorry, your order amount does not meet the minimum requirement $${minOrderForDelivery} for delivery.`}
            >{`Sorry, your order amount does not meet the minimum requirement $${minOrderForDelivery} for delivery.`}</Message>

            {/* ------------ for curbside ----------- */}
            <Transition
              visible={curbsideSelected}
              animation="fade"
              duration={200}
            >
              <div>
                <Divider />
                <Form unstackable>
                  {/* ---------------------- car info curbside ------------------ */}
                  <Form.Group>
                    <Form.Input
                      width={8}
                      label="Car Make/Model *"
                      value={carMakeModel || ""}
                      placeholder="e.g. Ford / F150"
                      onChange={(evt) =>
                        this.handleCarMakeModelChange(evt.target.value)
                      }
                    />
                    <Form.Input
                      width={8}
                      label="Car Color *"
                      value={carColor || ""}
                      placeholder="e.g. Light green"
                      onChange={(evt) =>
                        this.handleCarColorChange(evt.target.value)
                      }
                    />
                  </Form.Group>
                </Form>
                {isSmallDevice() ? null : (
                  <Message
                    style={{ margin: "0" }}
                    hidden={!needCarInfoMsgShow}
                    error
                    icon="warning sign"
                    header="Please enter car information to continue."
                  />
                )}
              </div>
            </Transition>

            {/* ------------ for room service ----------- */}
            <Transition visible={roomService} animation="fade" duration={200}>
              <div>
                <Divider />
                <Form unstackable>
                  {/* ---------------------- room service customer info ------------------ */}
                  <Form.Group>
                    <Form.Input
                      type="text"
                      pattern="[0-9]*"
                      width={8}
                      label="Room Number *"
                      value={roomServiceRoom || ""}
                      onChange={(evt) =>
                        this.handleRoomServiceRoomNumberChange(evt.target.value)
                      }
                      error={
                        this.state.showRoomServiceRoomError &&
                        verifyRoomServiceRoom(roomServiceRoom).error && {
                          content: verifyRoomServiceRoom(roomServiceRoom).error,
                        }
                      }
                      onBlur={() => {
                        this.setState({
                          showRoomServiceRoomError: true,
                        });
                      }}
                      onFocus={() => {
                        this.setState({
                          showRoomServiceRoomError: false,
                        });
                      }}
                    />
                    <Form.Input
                      width={8}
                      label="Last Name *"
                      value={customerName || ""}
                      onChange={(evt) =>
                        this.handleCustomerNameChange(evt.target.value)
                      }
                      error={
                        this.state.showCustomerNameError &&
                        verifyCustomerName(customerName).error && {
                          content: verifyCustomerName(customerName).error,
                        }
                      }
                      onBlur={() => {
                        this.setState({
                          showCustomerNameError: true,
                        });
                      }}
                      onFocus={() => {
                        this.setState({
                          showCustomerNameError: false,
                        });
                        // toUrl("#summary_payment");
                      }}
                    />
                  </Form.Group>
                </Form>
                {isSmallDevice() ? null : (
                  <Message
                    style={{ margin: "0" }}
                    hidden={!needRoomServiceInfoMsgShow}
                    error
                    icon="warning sign"
                    header="Please enter room information to continue."
                  />
                )}
              </div>
            </Transition>
          </div>
        </div>
        <Divider
          style={{
            width: "100%",
            margin: "0 auto",
          }}
        />

        {/* -------------------------------- address -------------------------------- */}
        {orderMethod === METHOD.DINEIN ||
        orderMethod === METHOD.ROOM_SERVICE_DELIVERY ? null : (
          <React.Fragment>
            <div className="content" id="summary_address">
              <div className="header">
                <Header as="h5">{`${
                  orderMethod === METHOD.DELIVERY ? "DELIVERY" : "PICKUP"
                } ADDRESS`}</Header>
                {orderMethod === METHOD.DELIVERY ? (
                  <Header
                    as="h5"
                    style={{
                      margin: "10px 0",
                      color: "#db2828",
                      cursor: "pointer",
                    }}
                    onClick={this.handleCustomerAddrChangeClick}
                  >
                    Change
                  </Header>
                ) : null}
              </div>
              <div className="google-map">
                <Dimmer inverted active={this.state.loading_googleMap}>
                  <Loader size="huge" />
                </Dimmer>

                {/* {lat != null && lng != null ? (
              <GoogleMapPanel
                width={"100%"}
                height={"150px"}
                lat={lat}
                lng={lng}
                needCustomerAddrMsgShow={needCustomerAddrMsgShow}
                addressStr={
                  orderMethod === METHOD.PICKUP
                    ? businessInfo && formatAddress(businessInfo.address)
                    : null
                }
                onCustomerAddressSelected={this.handleCustomerAddressSubmit}
              />
            ) : null} */}
                <GoogleMapPanel
                  width={"100%"}
                  height={"150px"}
                  lat={lat}
                  lng={lng}
                  needCustomerAddrMsgShow={needCustomerAddrMsgShow}
                  deliveryOutRangeWarning={deliveryOutRangeWarning}
                  onCustomerAddressSelected={this.handleCustomerAddressSubmit}
                />

                <Segment
                  basic
                  textAlign="left"
                  style={{
                    width: "100%",
                    margin: "0",
                    padding: "10px 0",
                    fontSize: "14px",
                  }}
                  size="big"
                >
                  {orderMethod === METHOD.PICKUP ||
                  orderMethod === METHOD.CURBSIDE ? (
                    <p>
                      <b>
                        {/* {businessInfo && formatAddress(businessInfo.address)} */}
                        {businessInfo && !!businessInfo.enableBranch
                          ? isBranch
                            ? formatAddress(businessInfo.branchAddress)
                            : formatAddress(businessInfo.address)
                          : businessInfo
                          ? formatAddress(businessInfo.address)
                          : null}
                      </b>
                    </p>
                  ) : customerAddressStr ? (
                    <React.Fragment>
                      <p>
                        <b>{customerAddressStr}</b>
                      </p>
                      <Form style={{ margin: "0" }}>
                        <Header as="h5">Note:</Header>
                        <TextArea
                          rows={2}
                          placeholder="e.g. ring the bell after dropoff, call upon arrival, etc."
                          value={deliveryNote || ""}
                          onChange={(evt) =>
                            this.handleDeliveryNoteChange(evt.target.value)
                          }
                        />
                      </Form>
                    </React.Fragment>
                  ) : null}
                </Segment>
              </div>
            </div>
            <Divider
              style={{
                width: "100%",
                margin: "0 auto",
              }}
            />
          </React.Fragment>
        )}

        {/* ---------------- time ------------------ */}
        {orderMethod === METHOD.DINEIN ? null : (
          <React.Fragment>
            <div className="content" id="summary_time">
              <div className="header">
                <Header as="h5">TIME</Header>
              </div>
              <div className="time">
                <Form size="mini" style={{ fontWeight: "600" }}>
                  <Form.Group grouped>
                    <Button.Group fluid basic>
                      <Button
                        href={"#summary_address"}
                        style={{
                          border: !scheduleTimeSelected
                            ? "2px solid rgb(0, 49, 98)"
                            : "unset",
                          fontWeight: "600",
                          width: "50%",
                          opacity: !scheduleTimeSelected ? "1" : "0.7",
                        }}
                        onClick={() => this.handleAsapSelect()}
                      >
                        ASAP
                      </Button>{" "}
                      <Button
                        href={"#summary_address"}
                        style={{
                          border: !!scheduleTimeSelected
                            ? "2px solid rgb(0, 49, 98)"
                            : "unset",
                          fontWeight: "600",
                          width: "50%",
                          opacity: !!scheduleTimeSelected ? "1" : "0.7",
                        }}
                        onClick={() => this.handleOrderTimeSelect()}
                        disabled={
                          !(businessInfo && businessInfo.enableFoodTimeSchedule)
                        }
                      >
                        {"Scheduled Time"}
                      </Button>
                    </Button.Group>

                    {/* <Transition
                  visible={timeSchedualList && scheduleTimeSelected}
                  animation="fade"
                  duration={200}
                > */}

                    {/* <Modal
                      dimmer={true}
                      open={this.state.scheduledTimeSelectOpen}
                      style={{ padding: "10px", minHeight: "30rem" }}
                    >
                      <div className="time">
                        <Form.Group grouped>
                          <Form.Field>
                            <label
                              style={{ fontSize: "18px", fontWeight: "600" }}
                            >
                              Please select a date:{" "}
                            </label>
                            <Button.Group fluid basic>
                              {(xDayBusinessHours || []).map(
                                (hourinfo, index) => {
                                  return (
                                    <Button
                                      key={hourinfo._id}
                                      onClick={() => {
                                        this.handleOrderDateSelect(hourinfo);
                                      }}
                                      disabled={hourinfo.disable}
                                      style={{
                                        border:
                                          this.props.scheduleDate ==
                                          hourinfo.datestr
                                            ? "2px solid rgb(0, 49, 98)"
                                            : "unset",
                                      }}
                                    >
                                      <span
                                        style={{
                                          display: "block",
                                          fontSize: "18px",
                                        }}
                                      >
                                        {index == 0
                                          ? "Today"
                                          : hourinfo.dayname[0].toUpperCase() +
                                            hourinfo.dayname
                                              .slice(1)
                                              .toLowerCase()}
                                      </span>
                                      {hourinfo.datestr}
                                    </Button>
                                  );
                                }
                              )}
                            </Button.Group>
                          </Form.Field>
                        </Form.Group>
                      </div>
                      <div
                        style={{
                          display: "block",
                          fontSize: "18px",
                          fontWeight: "600",
                          paddingTop: "20px",
                        }}
                      >
                        <Form.Field>
                          <Form.Select
                            open={this.state.scheduledTimeSelectOpen}
                            label={`Select a ${
                              orderMethod === METHOD.DELIVERY ||
                              orderMethod === METHOD.ROOM_SERVICE_DELIVERY
                                ? "delivery"
                                : "pickup"
                            } time`}
                            style={{ width: "100%", backgroundColor: "yellow" }}
                            options={this.state.timeSchedualList.map(
                              (e, index) => {
                                return {
                                  key: e,
                                  text: getTimeStringFromMin(e),
                                  value: getTimeStringFromMin(e),
                                };
                              }
                            )}
                            onChange={(evt, data) => {
                              this.handleScheduledTimeChange(data.value);
                              this.setState({
                                scheduledTimeSelectOpen: false,
                              });
                            }}
                            onClick={() => {
                              this.setState({
                                scheduledTimeSelectOpen:
                                  !this.state.scheduledTimeSelectOpen,
                              });
                            }}
                            onBlur={() => {
                              this.setState({
                                scheduledTimeSelectOpen: false,
                              });
                            }}
                          />
                        </Form.Field>
                      </div>
                    </Modal> */}

                    {scheduleTimeSelected ? (
                      <div
                        style={{
                          fontSize: "1rem",
                          margin: "10px 0",
                          backgroundColor: "yellow",
                        }}
                      >
                        <Icon name="warning circle" />
                        Scheduled on{" "}
                        <b>
                          {formatDateMonthDayYear(this.props.scheduleDate)}
                        </b>{" "}
                        @ <b>{this.props.scheduleTimeStr}</b>{" "}
                      </div>
                    ) : null}
                    {/* </Transition> */}
                  </Form.Group>
                </Form>
              </div>
            </div>
            <Divider
              style={{
                width: "100%",
                margin: "0 auto",
              }}
            />
          </React.Fragment>
        )}

        {/* ---------------- payment info ------------------ */}
        {/* {roomService ? null : ( */}
        <React.Fragment>
          <div className="content" id="summary_payment">
            <div className="header">
              <Header as="h5">PAYMENT</Header>
            </div>
            <div className="info">
              <Form size="large" style={{ fontWeight: "600" }}>
                <Form.Group style={{ margin: "0" }}>
                  <Button.Group fluid basic style={{ height: "80px" }}>
                    <Button
                      href={"#summary_payment"}
                      style={{
                        border: creditcardPaymentSelected
                          ? "2px solid rgb(0, 49, 98)"
                          : "unset",
                        width: "50%",
                        fontWeight: "600",
                        opacity: creditcardPaymentSelected ? "1" : "0.7",
                        width: "50%",
                      }}
                      onClick={() =>
                        this.handlePaymentMethodChange(
                          PAYMENT_METHOD.CREDITCARD
                        )
                      }
                      disabled={!supportCreditcardPayment}
                    >
                      <div style={{ display: "inline" }}>
                        <Image
                          src={visaIcon}
                          style={{
                            width: "30px",
                            display: "inline",
                          }}
                        />

                        <Image
                          src={masterIcon}
                          style={{
                            width: "30px",
                            display: "inline",
                          }}
                        />
                        <Image
                          src={discoverIcon}
                          style={{
                            width: "30px",
                            display: "inline",
                          }}
                        />

                        {allowAmerExpr ? (
                          <Image
                            src={americanExpIcon}
                            style={{
                              width: "30px",
                              display: "inline",
                            }}
                          />
                        ) : null}
                      </div>
                      <br />

                      {"Pay by Credit Card" +
                        (supportCreditcardPayment ? "" : " (unavailable)")}
                    </Button>
                    <Button
                      href={"#summary_time"}
                      style={{
                        border:
                          supportCashPayment &&
                          PAYMENT_METHOD &&
                          paymentMethod === PAYMENT_METHOD.CASH
                            ? "2px solid rgb(0, 49, 98)"
                            : "unset",
                        width: "50%",
                        fontWeight: "600",
                        opacity:
                          supportCashPayment &&
                          PAYMENT_METHOD &&
                          paymentMethod === PAYMENT_METHOD.CASH
                            ? "1"
                            : "0.7",
                        padding: "30px",
                        width: "50%",
                      }}
                      onClick={() =>
                        this.handlePaymentMethodChange(PAYMENT_METHOD.CASH)
                      }
                      disabled={!supportCashPayment}
                    >
                      {(roomService ? "Room Charge" : "Pay by Cash") +
                        (supportCashPayment ? "" : " (unavailable)")}
                    </Button>
                  </Button.Group>
                </Form.Group>
              </Form>

              {cashPaymentSelected && !roomService ? (
                <Message
                  // style={{ margin: "0" }}
                  size="tiny"
                  // icon="sticky note"
                  color="yellow"
                >
                  You can choose to pay by creditcard or cash when you pick up
                  the food at store, or pay upon delivery arrival.
                </Message>
              ) : null}

              <Divider />

              {creditcardPaymentSelected || cashPaymentSelected ? (
                <Form
                  unstackable
                  // onClick={() => {
                  //   console.log(123);
                  // }}
                  // href="#summary_payment"
                >
                  <Form.Group widths={2}>
                    <Form.Input
                      // href={"#summary_payment"}
                      label={
                        creditcardPaymentSelected ? "Name on Card*" : "Name *"
                      }
                      value={customerName || ""}
                      placeholder="Your name"
                      onChange={(evt) => {
                        const name = evt.target.value;
                        this.handleCustomerNameChange(
                          name && name.substring(0, 50)
                        );
                      }}
                      error={
                        this.state.showCustomerNameError &&
                        verifyCustomerName(customerName).error && {
                          content: verifyCustomerName(customerName).error,
                        }
                      }
                      onBlur={() => {
                        this.setState({
                          showCustomerNameError: true,
                        });
                      }}
                      onFocus={() => {
                        this.setState({
                          showCustomerNameError: false,
                        });
                        // toUrl("#summary_payment");
                      }}
                    />

                    <Form.Field>
                      <Form.Input
                        type="text"
                        pattern="[0-9]*"
                        label="Phone *"
                        value={formatPhoneNumber(customerPhone) || ""}
                        placeholder="(xxx)xxx-xxxx"
                        onChange={(evt) => {
                          let phoneNum = (evt.target.value || "").replace(
                            /\D+/g,
                            ""
                          );
                          this.handleCustomerPhoneChange(
                            phoneNum && phoneNum.substring(0, 10)
                          );
                        }}
                        error={
                          this.state.showCustomerPhoneError &&
                          verifyCustomerPhone(customerPhone).error && {
                            content: verifyCustomerPhone(customerPhone).error,
                          }
                        }
                        onBlur={() => {
                          this.setState({
                            showCustomerPhoneError: true,
                          });
                        }}
                        onFocus={() => {
                          this.setState({
                            showCustomerPhoneError: false,
                          });
                          // toUrl("#summary_payment");
                        }}
                      />

                      {businessInfo &&
                      businessInfo.enableReceivingOrderPlacedSMS ? (
                        <Checkbox
                          style={{ margin: "0.5em 0", fontSize: "12px" }}
                          checked={!!receiveOrderInfo}
                          label="Receive Order Info Msg"
                          // disabled
                          onChange={(evt, { checked }) => {
                            this.handleReceiveOrderInfoClick(checked);
                            // toUrl("#summary_payment");
                          }}
                        />
                      ) : null}
                    </Form.Field>
                  </Form.Group>

                  <Form.Field style={{ margin: "10px 0" }}>
                    <Form.Input
                      type="text"
                      label="Email (optional, receiving order receipt and future promotion)"
                      value={customerEmail || ""}
                      placeholder="example@email.com"
                      onChange={(evt) => {
                        const email = evt.target.value;
                        this.handleCustomerEmailChange(
                          email && email.substring(0, 50)
                        );
                      }}
                      // onFocus={() => {
                      //   toUrl("#summary_payment");
                      // }}
                    />
                  </Form.Field>
                </Form>
              ) : null}

              {isSmallDevice() ? null : (
                <Message
                  style={{ padding: "0 10px" }}
                  hidden={!needCustomerInfoMsgShow}
                  error
                  icon="warning sign"
                  header="Please enter your valid phone number and your name to continue."
                />
              )}

              <Transition
                visible={creditcardPaymentSelected}
                animation="fade"
                duration={200}
              >
                <div>
                  <Divider />
                  <Form unstackable id="payment-form">
                    {/* ---------------------- card info ------------------ */}
                    {/* <ReCAPTCHA
                      sitekey="6LdRAjIpAAAAAHEbhpxsKUUYX-IRLHzj4gjDUYeg"
                      onChange={this.onCaptchaChange}
                    /> */}

                    <Form.Group>
                      <Form.Input
                        id="tsep-cardNumDiv"
                        type="text"
                        pattern="[0-9]*"
                        width={9}
                        label="Card Number *"
                        value={formatCreditCardNumber(cardNumber) || ""}
                        placeholder="0000 0000 0000 0000"
                        onChange={(evt) => {
                          let cardNumber = (evt.target.value || "").replace(
                            /\D+/g,
                            ""
                          );
                          this.handleCardNumberChange(cardNumber);
                        }}
                        error={
                          this.state.showCardNumberError &&
                          verifyCardNumber(cardNumber, !allowAmerExpr)
                            .error && {
                            content: verifyCardNumber(
                              cardNumber,
                              !allowAmerExpr
                            ).error,
                          }
                        }
                        onBlur={() => {
                          this.setState({
                            showCardNumberError: true,
                          });
                        }}
                        onFocus={() => {
                          this.setState({
                            showCardNumberError: false,
                          });
                          this.handleHideNeedCardInfoMsg();
                        }}
                      />
                      <Form.Input
                        id="tsep-datepickerDiv"
                        type="text"
                        pattern="[0-9]*"
                        width={4}
                        label="Exp *"
                        value={formatCreditCardExpiration(cardExpiration) || ""}
                        placeholder="MM/YY"
                        onChange={(evt) => {
                          let cardExp = (evt.target.value || "").replace(
                            /\D+/g,
                            ""
                          );
                          this.handleCardExpirationChange(cardExp);
                        }}
                        error={
                          this.state.showCardExpirationError &&
                          verifyCreditCardExpiration(cardExpiration).error && {
                            content:
                              verifyCreditCardExpiration(cardExpiration).error,
                          }
                        }
                        onBlur={() => {
                          this.setState({
                            showCardExpirationError: true,
                          });
                        }}
                        onFocus={() => {
                          this.setState({
                            showCardExpirationError: false,
                          });
                          this.handleHideNeedCardInfoMsg();
                        }}
                      />
                      <Form.Input
                        type="text"
                        pattern="[0-9]*"
                        width={3}
                        label="CVV *"
                        value={formatCreditCardCVC(cardCVV) || ""}
                        placeholder="CVC"
                        onChange={(evt) => {
                          this.handleCardCVVChange(evt.target.value);
                        }}
                        error={
                          this.state.showCardCVCError &&
                          verifyCreditCardCVC(cardCVV).error && {
                            content: verifyCreditCardCVC(cardCVV).error,
                          }
                        }
                        onBlur={() => {
                          this.setState({
                            showCardCVCError: true,
                          });
                        }}
                        onFocus={() => {
                          this.setState({
                            showCardCVCError: false,
                          });
                          this.handleHideNeedCardInfoMsg();
                        }}
                      />
                    </Form.Group>

                    {/* ---------------------- billing address ------------------ */}
                    <Form.Group style={{ margin: "10px -7px" }}>
                      <Form.Input
                        width={12}
                        label="Billing Street *"
                        value={billingAddressStr || ""}
                        placeholder="0000 Main St"
                        onChange={(evt) => {
                          this.handleBillAddressChange(evt.target.value);
                        }}
                        error={
                          this.state.showAddressError &&
                          !billingAddressStr && {
                            content: "Required",
                          }
                        }
                        onBlur={() => {
                          this.setState({
                            showAddressError: true,
                          });
                        }}
                        onFocus={() => {
                          this.setState({
                            showAddressError: false,
                          });
                          this.handleHideNeedCardInfoMsg();
                        }}
                      />
                      <Form.Input
                        type="text"
                        pattern="[0-9]*"
                        width={4}
                        label="Zip Code *"
                        value={billingZipCode || ""}
                        placeholder="00000"
                        onChange={(evt) => {
                          this.handleBillZipCodeChange(evt.target.value);
                        }}
                        error={
                          this.state.showUSPostalCodeError &&
                          verifyUSPostalCode(billingZipCode).error && {
                            content: verifyUSPostalCode(billingZipCode).error,
                          }
                        }
                        onBlur={() => {
                          this.setState({
                            showUSPostalCodeError: true,
                          });
                        }}
                        onFocus={() => {
                          this.setState({
                            showUSPostalCodeError: false,
                          });
                          this.handleHideNeedCardInfoMsg();
                        }}
                      />
                    </Form.Group>

                    {orderMethod === METHOD.DELIVERY && customerAddressStr ? (
                      <Checkbox
                        style={{ fontSize: "12px" }}
                        label="Same as Delivery Address"
                        onChange={(evt, { checked }) => {
                          this.handleSameAsDeliveryAddrClick(checked);
                        }}
                      />
                    ) : null}
                  </Form>
                </div>
              </Transition>

              {creditcardPaymentSelected ? (
                <div>
                  {/* <Divider /> */}
                  <Message
                    hidden={!needCardInfoMsgShow}
                    error
                    icon="warning sign"
                    header="Your order has NOT been placed yet!"
                    content="Invalid credit card information provided."
                  />
                </div>
              ) : null}
            </div>
          </div>
          <Divider
            style={{
              width: "100%",
              margin: "0 auto",
            }}
          />
        </React.Fragment>
        {/* )} */}

        {/* ---------------- promotion free add-on item ------------------ */}

        {freeItemNames && freeItemNames.length > 0 ? (
          <React.Fragment>
            <div className="content" id="summary_promotion">
              <div className="header">
                <Header as="h5">PROMOTION</Header>
              </div>
              <div className="time" style={{ position: "relative" }}>
                <Message
                  size="tiny"
                  color="yellow"
                  style={{ fontSize: "12px" }}
                >
                  Free item on all orders over ${minAmount}. It can not be
                  combined with any other promotions.
                </Message>
                <Dimmer
                  inverted
                  active={this.state.loading_promotion}
                  inline="centered"
                >
                  <Loader size="small" />
                </Dimmer>
                <Form size="mini" style={{ fontWeight: "600" }}>
                  <Form.Group grouped>
                    <Button
                      basic
                      fluid
                      key={"no_thanks"}
                      // href={"#summary_payment"}
                      style={{
                        position: "relative",
                        fontWeight: "600",
                        opacity: !freeItemNameSelected ? "1" : "0.7",
                        margin: "15px 0",
                        borderBottom: !freeItemNameSelected
                          ? "6px solid rgb(0, 49, 98)"
                          : "unset",
                      }}
                      onClick={() =>
                        this.props.onOrderPromotionItemSelect(null)
                      }
                    >
                      No thanks
                    </Button>
                    {(freeItemNames || []).map((itemName, index) => {
                      return (
                        <Button
                          basic
                          key={index}
                          // href={"#summary_payment"}
                          style={{
                            textAlign: "left",
                            position: "relative",
                            fontWeight: "600",
                            width: "100%",
                            opacity: !!(
                              freeItemNameSelected &&
                              freeItemNameSelected === itemName
                            )
                              ? "1"
                              : "0.7",
                          }}
                          onClick={() =>
                            this.props.onOrderPromotionItemSelect(itemName)
                          }
                        >
                          {freeItemNameSelected &&
                          freeItemNameSelected === itemName ? (
                            <div
                              style={{
                                position: "absolute",
                                top: "8px",
                                left: "5px",
                              }}
                            >
                              <SelectedIcon
                                style={{
                                  width: "20px",
                                  height: "20px",
                                  display: "inline",
                                  marginLeft: "2px",
                                }}
                              />
                            </div>
                          ) : null}
                          <span style={{ paddingLeft: "15px" }}>
                            {itemName}
                          </span>
                        </Button>
                      );
                    })}

                    {/* <Button.Group fluid basic>
                      {(freeItemNames || []).map((itemName, index) => {
                        return (
                          <Button
                            key={index}
                            // href={"#summary_payment"}
                            style={{
                              position: "relative",
                              fontWeight: "600",
                              width: "50%",
                              opacity: !!(
                                freeItemNameSelected &&
                                freeItemNameSelected === itemName
                              )
                                ? "1"
                                : "0.7",
                            }}
                            onClick={() =>
                              this.props.onOrderPromotionItemSelect(itemName)
                            }
                          >
                            {freeItemNameSelected &&
                            freeItemNameSelected === itemName ? (
                              <div
                                style={{
                                  position: "absolute",
                                  top: "-10px",
                                  right: "0",
                                }}
                              >
                                <SelectedIcon
                                  style={{
                                    width: "20px",
                                    height: "20px",
                                    display: "inline",
                                    marginLeft: "2px",
                                  }}
                                />
                              </div>
                            ) : null}
                            <span>{itemName}</span>
                          </Button>
                        );
                      })}
                    </Button.Group> */}
                  </Form.Group>
                </Form>
              </div>
            </div>
            <Divider
              style={{
                width: "100%",
                margin: "0 auto",
              }}
            />
          </React.Fragment>
        ) : null}

        {/* ---------------- summary ------------------ */}
        <div className="content" id="summary_summary">
          <div className="header">
            <Header as="h5">SUMMARY</Header>
          </div>
          <div className="item-list" style={{ position: "relative" }}>
            {/* <Transition.Group
              unstackable
              as={Table}
              duration={200}
              // basic="very"
              style={{ width: "100%" }}
              striped
            > */}
            <Dimmer
              inverted
              active={this.state.loading_summary}
              inline="centered"
            >
              <Loader size="small" />
            </Dimmer>
            <Table
              unstackable
              // basic="very"
              striped
            >
              <Table.Body style={{ display: "contents" }}>
                {orderedItemsRows}
              </Table.Body>
            </Table>
            {/* </Transition.Group> */}
          </div>
        </div>
        <Divider
          style={{
            width: "100%",
            margin: "0 auto",
          }}
        />

        {/* -------------------------- place the order for small device --------------------- */}
        {!isSmallDevice() ? null : (
          <div className="content">
            <div className="item-list">
              <div
                style={{
                  width: "100%",
                  fontSize: "14px",
                  textAlign: "left",
                  fontWeight: "600",
                }}
              >
                <div style={{ margin: "5px 0" }}>
                  <span>Subtotal</span>
                  <span style={{ float: "right" }}>
                    ${formatPrice(subtotal)}
                  </span>
                </div>
                <div style={{ margin: "5px 0" }}>
                  <span>Taxes</span>
                  <span style={{ float: "right" }}>${formatPrice(tax)}</span>
                </div>
                <Divider />

                {/* ----------------------------- fixed tips ----------------- */}
                {fixedTips ? (
                  <div style={{ margin: "5px 0" }}>
                    <span>
                      Tips{" "}
                      <Popup
                        trigger={
                          <Icon
                            style={{ fontSize: "11px", display: "inline" }}
                            name="question circle"
                          ></Icon>
                        }
                        content={`${
                          fixedTipRate * 100
                        }% gratuity would be charged automatically.`}
                        on="click"
                        hideOnScroll
                      />
                    </span>
                    <span style={{ float: "right" }}>
                      ${formatPrice(fixedTips)}
                    </span>
                  </div>
                ) : null}

                {/* -------------------------- tips --------------------- */}
                <div style={{ margin: "5px 0" }}>
                  <span>{fixedTips ? "Additional Tips" : "Tips"}</span>
                  <span style={{ float: "right" }}>${formatPrice(tip)}</span>
                </div>
                <Button.Group
                  // href={"#summary_summary"}
                  size="small"
                  style={{
                    width: "calc(100%)",
                    height: "40px",
                    margin: "5px 0",
                  }}
                >
                  {["0", "0.1", "0.15", "0.20"].map((pct, index) => {
                    return (
                      <Button
                        key={index}
                        style={{ padding: "2px" }}
                        onClick={() => this.handleOrderTipChange(pct)}
                        secondary={
                          order_tips_pct != null &&
                          formatPrice(order_tips_pct) == formatPrice(pct)
                        }
                      >
                        {Number(pct) * 100}%
                      </Button>
                    );
                  })}
                  <Button
                    // href={"#summary_summary"}
                    key={10}
                    style={{ padding: "2px" }}
                    onClick={this.handleCustomTipClick}
                    secondary={order_tips_pct === "other"}
                  >
                    Other
                  </Button>
                </Button.Group>
                {order_tips_pct === "other" ? (
                  <Input
                    type="number"
                    icon="dollar"
                    iconPosition="left"
                    size="big"
                    value={custom_tips == null ? "" : custom_tips}
                    style={{
                      width: "calc(100%)",
                      height: "50px",
                      margin: "0",
                    }}
                    onChange={(evt) => {
                      //console.log(123, evt.target.value);
                      this.handleCustomTipChange(evt.target.value);
                    }}
                  />
                ) : null}

                <Divider />

                {/* // ---------------------- processing fee if applied ----------------------- */}
                {processingFeeAmt || serviceFeeAmt ? (
                  <React.Fragment>
                    <div style={{ margin: "5px 0" }}>
                      <span>
                        Processing fee{" "}
                        <Popup
                          trigger={
                            <Icon
                              style={{ fontSize: "11px", display: "inline" }}
                              name="question circle"
                            ></Icon>
                          }
                          content="This amount is charged by the restaurant as convenience fee"
                          on="click"
                          hideOnScroll
                        />
                        :
                      </span>
                      <span style={{ float: "right" }}>
                        $
                        {formatPrice(
                          (processingFeeAmt || 0) + (serviceFeeAmt || 0)
                        )}
                      </span>
                    </div>
                    <Divider />
                  </React.Fragment>
                ) : null}

                {/* // ---------------------- room service delivery fee if applied ----------------------- */}
                {roomServiceDeliveryFee &&
                orderMethod === METHOD.ROOM_SERVICE_DELIVERY ? (
                  <React.Fragment>
                    <div style={{ margin: "5px 0" }}>
                      <span>Delivery fee :</span>
                      <span style={{ float: "right" }}>
                        ${formatPrice(roomServiceDeliveryFee)}
                      </span>
                    </div>
                    <Divider />
                  </React.Fragment>
                ) : null}

                {/* // ---------------------- processing fee if applied ----------------------- */}
                {deliveryFee ? (
                  <React.Fragment>
                    <div style={{ margin: "5px 0" }}>
                      <span>Delivery fee :</span>
                      <span style={{ float: "right" }}>
                        ${formatPrice(deliveryFee)}
                      </span>
                    </div>
                    <Divider />
                  </React.Fragment>
                ) : null}

                {/* ---------------------- discount promotion code --------------------- */}
                {promoCodeApplied ? (
                  <React.Fragment>
                    <div style={{ margin: "5px 0" }}>
                      <span>Discount:</span>
                      <span style={{ float: "right" }}>
                        -${formatPrice(discount)}
                      </span>
                    </div>
                  </React.Fragment>
                ) : null}

                {promoCodeApplied ? (
                  <Button
                    icon="remove circle"
                    labelPosition="left"
                    // className="sumi-global-btn-color"
                    style={{
                      width: "100%",
                      height: "40px",
                      padding: "10px 20px",
                      textAlign: "center",
                      margin: "0",
                      backgroundColor: "#fbbd08",
                    }}
                    onClick={() => {
                      this.handlePromoCodeRemove();
                    }}
                    content={
                      promoCodeApplied.percentageOff * 100 + "% OFF APPLIED"
                    }
                  />
                ) : (
                  <React.Fragment>
                    <Input
                      size="large"
                      value={promoCode || ""}
                      style={{
                        width: "calc(100% - 80px)",
                        height: "40px",
                        margin: "0",
                      }}
                      placeholder="Add Promo Code"
                      onChange={(evt) => {
                        this.handlePromoCodeChange(evt.target.value);
                      }}
                    />

                    <Button
                      className="sumi-global-btn-color"
                      style={{
                        width: "80px",
                        height: "40px",
                        padding: "10px 20px",
                        textAlign: "center",
                        margin: "0",
                      }}
                      onClick={() => {
                        this.handlePromoCodeApplyClick();
                      }}
                    >
                      Apply
                    </Button>
                  </React.Fragment>
                )}

                {this.state.showPromoCodeMsg ? (
                  <Message error icon size="mini">
                    <p>Invalid promotion code.</p>
                  </Message>
                ) : null}

                <Divider />

                {/* ---------------------- total amount --------------------- */}
                <div
                  style={{
                    // height: "150px",
                    margin: "5px 0",
                    fontSize: "20px",
                    fontWeight: "600",
                  }}
                >
                  <span>Total</span>
                  <span style={{ float: "right" }}>${formatPrice(total)}</span>
                </div>
              </div>
            </div>
          </div>
        )}

        {/* -------------------------- place the order Button --------------------- */}

        {!isSmallDevice() ? null : (
          <div
            style={{
              width: "100%",
              // position: "fixed",
              // bottom: "0",
              left: "0",
              zIndex: "3",
              marginBottom: "50px",
            }}
          >
            <ReCAPTCHA
              ref={this.recaptchaRef}
              size="invisible"
              sitekey="6LcXIDIpAAAAALqW-bXauVO9d67fyTSgbOYESSv-"
              onChange={this.onCaptchaChange}
            />

            <Button
              className="sumi-global-btn-color"
              style={{
                width: "100%",
                height: "50px",
                padding: "10px 20px",
                textAlign: "center",
                margin: "0",
                borderRadius: "unset",
              }}
              onClick={async (evt) => {
                if (inPaymentProcess) {
                  // console.log("reject. in payment");
                  return;
                }
                // console.log("accept.");

                inPaymentProcess = true;

                orderedItems && orderedItems.length > 0
                  ? await this.handlePlaceOrderClick(total, evt)
                  : this.handleBackToMenuClick();

                inPaymentProcess = false;
              }}
            >
              {orderedItems && orderedItems.length > 0
                ? "Place the order"
                : "Back to Menu"}
            </Button>
          </div>
        )}
      </Segment>
    );
  }
}

export default OrderSummaryPanel;
