import React, { Component } from "react";
import {
  Segment,
  Modal,
  Header,
  Form,
  Divider,
  TextArea,
  Dimmer,
  Loader,
  Icon,
  Button,
  Image,
} from "semantic-ui-react";

class ItemUpdatingModal extends Component {
  // _isItemValid = (name) => {
  //   return this.props.item_added && this.props.item_added[name] != null;
  // };

  // _validateItem = () => {
  //   const ItemErr = {};

  //   const item = this.props.item_updated;

  //   if( !item ) return null;

  //   if (!item || !item.name || item.price == null) {
  //     return false;
  //   }

  //   const { size: sizes, options } = item;
  //   for (let index in sizes || []) {
  //     const size = sizes[index];
  //     if (!size || !size.name || size.price == null) {
  //       return false;
  //     }
  //   }

  //   for (let index in options || []) {
  //     const option = options[index];
  //     if (!option || !option.name || option.numberToSelect == null) {
  //       return false;
  //     }

  //     for (let subIndex in option.optionValues || []) {
  //       const optionValue = option.optionValues[subIndex];
  //       if (!optionValue || !optionValue.name || optionValue.price == null) {
  //         return false;
  //       }
  //     }
  //   }

  //   return true;
  // };

  state = {
    name_required_show: false,
    price_required_show: false,
  };

  _isValid = (name) => {
    //console.log(333, this.props.item_updated[name]);

    return (
      this.props.item_updated &&
      this.props.item_updated[name] != null &&
      String(this.props.item_updated[name]).trim() !== ""
    );
  };

  handleClose = () => {
    this.props.onClose && this.props.onClose();

    this.setState({
      name_required_show: false,
      price_required_show: false,
    });
  };

  handleSave = () => {
    if (!this._isValid("name") || !this._isValid("price")) {
      return this.setState({
        name_required_show: true,
        price_required_show: true,
      });
    }

    this.props.onSave && this.props.onSave();

    this.setState({
      name_required_show: false,
      price_required_show: false,
    });
  };

  handleItemChange = (name, val) => {
    this.props.onItemChange && this.props.onItemChange(name, val);
  };

  handleItemImageClick = () => {
    this.props.onItemImageClick && this.props.onItemImageClick();
  };

  handleItemSizeChange = (_idSize, name, val) => {
    this.props.onItemSizeChange &&
      this.props.onItemSizeChange(_idSize, name, val);
  };

  handleItemSizeAddClick = () => {
    this.props.onItemSizeAddClick && this.props.onItemSizeAddClick();
  };

  handleItemSizeRemove = (_idSize) => {
    this.props.onItemSizeRemove && this.props.onItemSizeRemove(_idSize);
  };

  handleItemOptionChange = (_idOption, name, val) => {
    this.props.onItemOptionChange &&
      this.props.onItemOptionChange(_idOption, name, val);
  };

  handleItemOptionValueChange = (_idOption, _idOptionValue, name, val) => {
    this.props.onItemOptionValueChange &&
      this.props.onItemOptionValueChange(_idOption, _idOptionValue, name, val);
  };

  handleItemOptionAddClick = () => {
    this.props.onItemOptionAddClick && this.props.onItemOptionAddClick();
  };

  handleItemOptionRemoveClick = (optionIndex) => {
    this.props.onItemOptionRemoveClick &&
      this.props.onItemOptionRemoveClick(optionIndex);
  };

  handleItemOptionValueAddClick = (optionIndex) => {
    this.props.onItemOptionValueAddClick &&
      this.props.onItemOptionValueAddClick(optionIndex);
  };

  handleItemOptionValueRemoveClick = (optionIndex, optionValueIndex) => {
    this.props.onItemOptionValueRemoveClick &&
      this.props.onItemOptionValueRemoveClick(optionIndex, optionValueIndex);
  };

  render() {
    const { item_updated, item_updated_image, loading } = this.props;

    const sizes = (item_updated || {}).size;
    const itemOptions = (item_updated || {}).options;

    return (
      <Modal
        closeIcon
        dimmer={true}
        open={!!item_updated}
        onClose={this.handleClose}
        className="goorder-online-itemDetail"
      >
        <Dimmer inverted active={!!loading}>
          <Loader size="huge" />
        </Dimmer>

        <div className="header" style={{ display: "flex" }}>
          <Image
            // src="https://www.mikafrenchsushi.com/img/portfolio/tuna%20tartare-large.jpg"
            src={item_updated_image}
            style={{ width: "150px", height: "150px", padding: "20px" }}
            onClick={this.handleItemImageClick}
            rounded
          />

          {/* <img src={item_updated && item_updated.image} /> */}

          {/* <Form style={{ width: "calc(100% - 170px)" }}> */}
          <Form style={{ width: "100%" }}>
            <Form.Group style={{ fontSize: "12px" }}>
              <Form.Input
                width={7}
                label="Name *"
                value={item_updated && item_updated.name}
                onChange={(evt) => {
                  this.handleItemChange("name", evt.target.value);
                }}
                error={this.state.name_required_show && !this._isValid("name")}
                onBlur={() => {
                  this.setState({
                    name_required_show: true,
                  });
                }}
                onFocus={() => {
                  this.setState({
                    name_required_show: false,
                  });
                }}
              />
              <Form.Input
                width={6}
                label="Name(2nd)"
                value={item_updated && item_updated.name_2nd}
                onChange={(evt) => {
                  this.handleItemChange("name_2nd", evt.target.value);
                }}
              />
              <Form.Input
                width={3}
                label="Price *"
                value={
                  item_updated && item_updated.price != null
                    ? item_updated.price
                    : ""
                }
                onChange={(evt) => {
                  const val = evt.target.value;
                  if (Number.isNaN(Number(val)) || Number(val) > 99999) {
                    return;
                  }
                  this.handleItemChange("price", evt.target.value);
                }}
                error={
                  this.state.price_required_show && !this._isValid("price")
                }
                onBlur={() => {
                  this.setState({
                    price_required_show: true,
                  });
                }}
                onFocus={() => {
                  this.setState({
                    price_required_show: false,
                  });
                }}
              />
            </Form.Group>

            {/* ----------------- item discription -------------------------------------- */}

            <TextArea
              label="Desciption"
              style={{ fontSize: "12px" }}
              rows="2"
              placeholder="Type description for this item here..."
              value={item_updated && item_updated.discription}
              onChange={(evt) => {
                this.handleItemChange("discription", evt.target.value);
              }}
            />
          </Form>
        </div>

        <div className="item-list">
          {/* ----------------- item sizes -------------------------------------- */}
          <Segment basic style={{ margin: "10px", height: null }}>
            <React.Fragment>
              <Header as="h3">
                Size Setting{" "}
                <Button
                  className="sumi-global-btn-color"
                  style={{
                    width: "60px",
                    height: "25px",
                    margin: "0",
                    padding: "0",
                    float: "right",
                    fontSize: "12px",
                  }}
                  onClick={this.handleItemSizeAddClick}
                >
                  + New
                </Button>
              </Header>
              <Divider />
              {/* <List divided relaxed size="big" style={{ marginTop: "0" }}> */}
              <Form size="tiny">
                {(sizes || []).map((itemSize, sizeIndex) => {
                  const { name, name_2nd, price, _id } = itemSize;
                  return (
                    <Form.Group key={sizeIndex} style={{ fontSize: "12px" }}>
                      <Form.Input
                        width={6}
                        fluid
                        placeholder=""
                        value={name || ""}
                        onChange={(evt) =>
                          this.handleItemSizeChange(
                            _id,
                            "name",
                            evt.target.value
                          )
                        }
                      />
                      <Form.Input
                        width={6}
                        fluid
                        value={name_2nd || ""}
                        onChange={(evt) =>
                          this.handleItemSizeChange(
                            _id,
                            "name_2nd",
                            evt.target.value
                          )
                        }
                      />
                      <Form.Input
                        width={4}
                        fluid
                        value={price != null ? price : ""}
                        onChange={(evt) => {
                          const val = evt.target.value;
                          if (
                            Number.isNaN(Number(val)) ||
                            Number(val) > 99999
                          ) {
                            return;
                          }

                          this.handleItemSizeChange(
                            _id,
                            "price",
                            evt.target.value
                          );
                        }}
                      />
                      <Icon
                        name="trash alternate"
                        color="red"
                        size="big"
                        onClick={() => this.handleItemSizeRemove(sizeIndex)}
                      />
                    </Form.Group>
                  );
                })}
              </Form>
              {/* </List> */}
            </React.Fragment>
          </Segment>

          {/* ----------------- item options -------------------------------------- */}
          <Segment basic style={{ margin: "10px" }}>
            <React.Fragment>
              <Header as="h3">
                Options Setting
                <Button
                  className="sumi-global-btn-color"
                  style={{
                    width: "60px",
                    height: "25px",
                    margin: "0",
                    padding: "0",
                    float: "right",
                    fontSize: "12px",
                  }}
                  onClick={this.handleItemOptionAddClick}
                >
                  + New
                </Button>
              </Header>
              <Divider />
              {(itemOptions || []).map((itemOption, optionIndex) => {
                const {
                  _id: _idOption,
                  name,
                  numberToSelect,
                  minNumberToSelect,
                  maxNumberToSelect,
                  required,
                  optionValues,
                } = itemOption;

                return (
                  <React.Fragment key={optionIndex}>
                    <Form size="tiny">
                      <Form.Group style={{ fontSize: "12px" }}>
                        <Form.Input
                          width={10}
                          fluid
                          placeholder=""
                          value={name || ""}
                          size={"small"}
                          label="Option Name"
                          onChange={(evt) =>
                            this.handleItemOptionChange(
                              _idOption,
                              "name",
                              evt.target.value
                            )
                          }
                        />
                        {/* <Form.Input
                          width={6}
                          fluid
                          placeholder=""
                          value={numberToSelect != null ? numberToSelect : ""}
                          size={"small"}
                          label="Required Number"
                          onChange={(evt) => {
                            const val = evt.target.value;
                            if (
                              Number.isNaN(Number(val)) ||
                              Number(val) > 99999
                            ) {
                              return;
                            }

                            this.handleItemOptionChange(
                              _idOption,
                              "numberToSelect",
                              evt.target.value
                            );
                          }}
                        /> */}
                        <Form.Input
                          width={3}
                          fluid
                          placeholder=""
                          value={
                            minNumberToSelect != null ? minNumberToSelect : ""
                          }
                          size={"small"}
                          label="Min Number"
                          onChange={(evt) => {
                            const val = evt.target.value;
                            if (
                              Number.isNaN(Number(val)) ||
                              Number(val) > 99999
                            ) {
                              return;
                            }

                            this.handleItemOptionChange(
                              _idOption,
                              "minNumberToSelect",
                              evt.target.value
                            );
                          }}
                        />
                        <Form.Input
                          width={3}
                          fluid
                          placeholder=""
                          value={
                            maxNumberToSelect != null ? maxNumberToSelect : ""
                          }
                          size={"small"}
                          label="Max Number"
                          onChange={(evt) => {
                            const val = evt.target.value;
                            if (
                              Number.isNaN(Number(val)) ||
                              Number(val) > 99999
                            ) {
                              return;
                            }

                            this.handleItemOptionChange(
                              _idOption,
                              "maxNumberToSelect",
                              evt.target.value
                            );
                          }}
                        />
                      </Form.Group>

                      {(optionValues || []).map(
                        (optionValue, optionValIndex) => {
                          const {
                            name,
                            name_2nd,
                            price,
                            _id: _idOptionValue,
                          } = optionValue;

                          return (
                            <Form.Group
                              style={{ fontSize: "12px", margin: "5px 10px" }}
                              key={optionIndex + "_" + optionValIndex}
                            >
                              <Form.Input
                                width={6}
                                fluid
                                placeholder=""
                                value={name || ""}
                                onChange={(evt) =>
                                  this.handleItemOptionValueChange(
                                    _idOption,
                                    _idOptionValue,
                                    "name",
                                    evt.target.value
                                  )
                                }
                              />
                              <Form.Input
                                width={6}
                                fluid
                                value={name_2nd || ""}
                                onChange={(evt) =>
                                  this.handleItemOptionValueChange(
                                    _idOption,
                                    _idOptionValue,
                                    "name_2nd",
                                    evt.target.value
                                  )
                                }
                              />
                              <Form.Input
                                width={4}
                                fluid
                                value={price != null ? price : ""}
                                onChange={(evt) => {
                                  const val = evt.target.value;
                                  if (
                                    Number.isNaN(Number(val)) ||
                                    Number(val) > 99999
                                  ) {
                                    return;
                                  }

                                  this.handleItemOptionValueChange(
                                    _idOption,
                                    _idOptionValue,
                                    "price",
                                    evt.target.value
                                  );
                                }}
                              />
                              <Icon
                                name="trash alternate"
                                color="red"
                                size="big"
                                onClick={() =>
                                  this.handleItemOptionValueRemoveClick(
                                    optionIndex,
                                    optionValIndex
                                  )
                                }
                              />
                            </Form.Group>
                          );
                        }
                      )}
                    </Form>
                    <Button
                      color="red"
                      style={{
                        width: "60px",
                        height: "25px",
                        margin: "10px 10px 10px 0",
                        padding: "0",
                        fontSize: "12px",
                      }}
                      onClick={() =>
                        this.handleItemOptionRemoveClick(optionIndex)
                      }
                    >
                      Remove
                    </Button>
                    <Button
                      className="sumi-global-btn-color"
                      style={{
                        height: "25px",
                        margin: "10px 10px 10px 0",
                        padding: "0 5px",
                        fontSize: "12px",
                      }}
                      onClick={() =>
                        this.handleItemOptionValueAddClick(optionIndex)
                      }
                    >
                      + New Option Value
                    </Button>

                    <Divider />
                  </React.Fragment>
                );
              })}
            </React.Fragment>
          </Segment>
        </div>

        {/* ----------------------------  bottom --------------------- */}
        <Segment className="footer" raised>
          <Button
            className="sumi-global-btn-color"
            style={{
              width: "100px",
              height: "50px",
              margin: "10px 10px 10px 0",
              float: "right",
            }}
            onClick={this.handleSave}
          >
            Save
          </Button>
          <Button
            style={{
              width: "100px",
              height: "50px",
              margin: "10px 10px 10px 0",
              float: "right",
            }}
            onClick={this.handleClose}
          >
            Cancel
          </Button>
        </Segment>
      </Modal>
    );
  }
}

export default ItemUpdatingModal;
