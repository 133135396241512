import FileDb from "./../dataModel/fileDb";
import { dataURItoBlob } from "./utils";
const axios = require("axios");

// -------------------------------------------Notice: use in client side ------------------------------------------

const CATEGORY_ROUTE_URL = "/api/categories7";
const ITEM_ROUTE_URL = "/api/items7";

/*
  only read functionalities
*/
export default class MenuDb {
  // ------------------------------------------------------ category ----------------------------------------------------
  // category:{ name, name_2nd, _idGroup }
  // return all categories
  static async getCategories(merchantId) {
    try {
      if (!merchantId)
        return console.error("Invalid merchantId(getCategories)");

      return (
        (await axios.get(`${CATEGORY_ROUTE_URL}/merchantId/${merchantId}`)) ||
        {}
      ).data;
    } catch (ex) {
      console.error(ex);
    }
  }

  // ------------------------------------------------------ category ----------------------------------------------------
  // category:{ name, name_2nd, _idGroup }
  // return all categories
  static async getCategoryById(merchantId, _idCategory) {
    try {
      if (!merchantId)
        return console.error("Invalid merchantId(getCategoryById)");

      if (!_idCategory)
        return console.error("Invalid _idCategory(getCategoryById)");

      return (
        (await axios.get(
          `${CATEGORY_ROUTE_URL}/merchantId/${merchantId}/_id/${_idCategory}`
        )) || {}
      ).data;
    } catch (ex) {
      console.error(ex);
    }
  }

  // category:{ name, name_2nd, _idGroup}
  // return updated category
  static async updateCategoryById(merchantId, _id, category) {
    try {
      if (!merchantId)
        return console.error("Invalid merchantId(updateCategoryById)");

      if (!_id) return console.error("Invalid _id(updateCategoryById)");
      return (
        (await axios.put(
          `${CATEGORY_ROUTE_URL}/merchantId/${merchantId}/_id/${_id}`,
          category
        )) || {}
      ).data;
    } catch (ex) {
      console.error(ex);
    }
  }

  // ------------------------------------------------------- item & itemOption & itemSize --------------------------------------------------
  // item:{ name, name_2nd, price, _idCategory, options:[{name, numberToSelect, optionValues:[{price, name}, ...]}, ...] }

  // return all items
  static async getItems(merchantId, limit) {
    try {
      if (!merchantId) return console.error("Invalid merchantId(getItems)");

      if (limit && limit > 0) {
        return (
          (await axios.get(
            `${ITEM_ROUTE_URL}/merchantId/${merchantId}/limit/${limit}/items`
          )) || {}
        ).data;
      }
      return (
        (await axios.get(`${ITEM_ROUTE_URL}/merchantId/${merchantId}/items`)) ||
        {}
      ).data;
    } catch (ex) {
      console.error(ex);
    }
  }

  // return item
  static async getItemById(merchantId, _id) {
    try {
      if (!merchantId) return console.error("Invalid merchantId(getItemById)");

      if (!_id) return console.error("Invalid _id(getItemById)");

      return (
        (await axios.get(
          `${ITEM_ROUTE_URL}/merchantId/${merchantId}/item/_id/${_id}`
        )) || {}
      ).data;
    } catch (ex) {
      console.error(ex);
    }
  }

  static async getItems_category(merchantId) {
    try {
      if (!merchantId)
        return console.error("Invalid merchantId(getItems_category)");

      return (
        (await axios.get(
          `${ITEM_ROUTE_URL}/merchantId/${merchantId}/items_category`
        )) || {}
      ).data;
    } catch (ex) {
      console.error(ex);
    }
  }

  // return [item, ...]
  static async getItemsByIdCategory(merchantId, _idCategory) {
    try {
      if (!merchantId)
        return console.error("Invalid merchantId(getItemsByIdCategory)");

      if (!_idCategory)
        return console.error("Invalid _idCategory(getItemsByCategoryId)");
      return (
        (await axios.get(
          `${ITEM_ROUTE_URL}/merchantId/${merchantId}/items/_idCategory/${_idCategory}`
        )) || {}
      ).data;
    } catch (ex) {
      console.error(ex);
    }
  }

  // return [item, ...]
  static async getItemsByIdCategory_category(merchantId, _idCategory) {
    try {
      if (!merchantId)
        return console.error("Invalid merchantId(getItemsByIdCategory)");

      if (!_idCategory)
        return console.error("Invalid _idCategory(getItemsByCategoryId)");
      return (
        (await axios.get(
          `${ITEM_ROUTE_URL}/merchantId/${merchantId}/items_category/_idCategory/${_idCategory}`
        )) || {}
      ).data;
    } catch (ex) {
      console.error(ex);
    }
  }

  // return [itemOption, ...]
  static async getItemOptionsById(merchantId, _id) {
    try {
      if (!merchantId)
        return console.error("Invalid merchantId(getItemOptionsById)");

      if (!_id) return console.error("Invalid _id(getItemOptionsById)");

      return (
        (await axios.get(
          `${ITEM_ROUTE_URL}/merchantId/${merchantId}/itemOptions/${_id}`
        )) || {}
      ).data;
    } catch (ex) {
      console.error(ex);
    }
  }

  // return [itemSizes, ...]
  static async getItemSizesById(merchantId, _id) {
    try {
      if (!merchantId)
        return console.error("Invalid merchantId(getItemSizesById)");

      if (!_id) return console.error("Invalid _id(getItemSizesById)");

      return (
        (await axios.get(
          `${ITEM_ROUTE_URL}/merchantId/${merchantId}/itemSizes/${_id}`
        )) || {}
      ).data;
    } catch (ex) {
      console.error(ex);
    }
  }

  static async addItem(merchantId, item, itemOptions) {
    try {
      if (!merchantId) return console.error("Invalid merchantId(addItem)");

      // return (
      //   (await axios.post(
      //     `${ITEM_ROUTE_URL}/merchantId/${merchantId}/item`,
      //     item
      //   )) || {}
      // ).data;
      const addedItem = (
        (await axios.post(
          `${ITEM_ROUTE_URL}/merchantId/${merchantId}/item`,
          item
        )) || {}
      ).data;

      if (addedItem && itemOptions) {
        for (let i = 0; i < itemOptions.length; i++) {
          const itemOption = itemOptions[i];
          if (itemOption && addedItem._id) {
            const addedItemOption = await MenuDb.addItemOptionById(
              merchantId,
              addedItem._id,
              itemOption
            );
          }
        }
        return await MenuDb.getItemById(merchantId, addedItem._id);
      } else {
        return addedItem;
      }
    } catch (ex) {
      console.error(ex);
    }
  }

  // itemSize : {name, name_2nd, price}
  // return new added itemSize
  static async addItemSizeById(merchantId, _id, itemSize) {
    try {
      if (!merchantId)
        return console.error("Invalid merchantId(addItemSizeById)");

      if (!_id) return console.error("Invalid _id(addItemSizeById)");
      return (
        (await axios.post(
          `${ITEM_ROUTE_URL}/merchantId/${merchantId}/itemSize/${_id}`,
          itemSize
        )) || {}
      ).data;
    } catch (ex) {
      console.error(ex);
    }
  }

  // itemOption: {name, numberToSelect, optionValues}
  // return new added itemOption
  static async addItemOptionById(merchantId, _id, itemOption) {
    try {
      if (!merchantId)
        return console.error("Invalid merchantId(addItemOptionById)");

      if (!_id) return console.error("Invalid _id(addItemOptionById)");
      return (
        (await axios.post(
          `${ITEM_ROUTE_URL}/merchantId/${merchantId}/itemOption/${_id}`,
          itemOption
        )) || {}
      ).data;
    } catch (ex) {
      console.error(ex);
    }
  }

  // itemOptionValue: {name, price}
  // return new added itemOptionValue
  static async addItemOptionValueByIdByIdOption(
    merchantId,
    _id,
    _idOption,
    itemOptionValue
  ) {
    try {
      if (!merchantId)
        return console.error(
          "Invalid merchantId(addItemOptionValueByIdByIdOption)"
        );

      if (!_id || !_idOption)
        return console.error(
          "Invalid _id or _idOption(addItemOptionValueByIdByIdOption)"
        );
      return (
        (await axios.post(
          `${ITEM_ROUTE_URL}/merchantId/${merchantId}/itemOptionValue/${_id}/${_idOption}`,
          itemOptionValue
        )) || {}
      ).data;
    } catch (ex) {
      console.error(ex);
    }
  }

  // item:{ name, name_2nd, price, _idCategory}
  // return updated item, no updated upon size and options
  static async updateItemById(merchantId, _id, item) {
    try {
      if (!merchantId)
        return console.error("Invalid merchantId(updateItemById)");

      if (!_id) return console.error("Invalid _id(updateItemById)");
      return (
        (await axios.put(
          `${ITEM_ROUTE_URL}/merchantId/${merchantId}/item/${_id}`,
          item
        )) || {}
      ).data;
    } catch (ex) {
      console.error(ex);
    }
  }

  // update item including size and options
  static async updateItemById_complete(merchantId, _id, item, itemImgDataUrl) {
    try {
      if (!merchantId)
        return console.error("Invalid merchantId(updateItemById_complete)");

      if (!_id) return console.error("Invalid _id(updateItemById_complete)");

      if (!item)
        return console.error("Invalid item to update(updateItemById_complete)");

      // if (itemImgDataUrl) {
      //   const blob = dataURItoBlob(itemImgDataUrl);

      //   if (blob) {
      //     const savedImageObj = await FileDb.uploadFile(blob);
      //     console.log(777, savedImageObj);
      //     // if (savedImageObj) {
      //     //   cloudinary.uploader.upload({ agent: myAgent }, function (
      //     //     error,
      //     //     result
      //     //   ) {
      //     //     console.log(result);
      //     //   });
      //     //   const res = await FileDb.remove(savedImageObj.filename);
      //     // }
      //   }
      // }

      let savedImageObj = null;
      if (itemImgDataUrl) {
        let blob = null;
        try {
          blob = dataURItoBlob(itemImgDataUrl);
        } catch (ex) {}

        if (blob) {
          savedImageObj = await FileDb.uploadFile(blob);
          if (savedImageObj) {
            item.savedImageObj = savedImageObj;
          }
        }
      }

      const data = (
        (await axios.put(
          `${ITEM_ROUTE_URL}/merchantId/${merchantId}/item_complete/${_id}`,
          item
        )) || {}
      ).data;

      savedImageObj && FileDb.remove(savedImageObj.filename);
      return data;
    } catch (ex) {
      console.error(ex);
    }
  }

  // update item image
  // static async updateItemImageById(merchantId, _id, file) {
  //   try {
  //     if (!merchantId)
  //       return console.error("Invalid merchantId(updateItemImageById)");

  //     if (!_id) return console.error("Invalid _id(updateItemImageById)");

  //     if (!file) return console.error("Invalid image(updateItemImageById)");

  //     return (
  //       (await axios.put(
  //         `${ITEM_ROUTE_URL}/merchantId/${merchantId}/itemImage/${_id}`,
  //         {
  //           image: file,
  //         }
  //       )) || {}
  //     ).data;
  //   } catch (ex) {
  //     console.error(ex);
  //   }
  // }

  // return removed item
  static async removeItemById(merchantId, _id) {
    try {
      if (!merchantId)
        return console.error("Invalid merchantId(removeItemById)");

      if (!_id) return console.error("Invalid _id(removeItemById)");
      return (
        (await axios.delete(
          `${ITEM_ROUTE_URL}/merchantId/${merchantId}/item/${_id}`
        )) || {}
      ).data;
    } catch (ex) {
      console.error(ex);
    }
  }

  static async removeItemSizeByIdByIdItemSize(merchantId, _id, _idItemSize) {
    try {
      if (!merchantId)
        return console.error(
          "Invalid merchantId(removeItemSizeByIdByIdItemSize)"
        );

      if (!_id || !_idItemSize)
        return console.error(
          "Invalid _id pr _idItemSize(removeItemSizeByIdByIdItemSize)"
        );
      return (
        (await axios.delete(
          `${ITEM_ROUTE_URL}/merchantId/${merchantId}/itemSize/${_id}/${_idItemSize}`
        )) || {}
      ).data;
    } catch (ex) {
      console.error(ex);
    }
  }

  // return removed itemOption
  static async removeItemOptionByIdByIdOption(merchantId, _id, _idOption) {
    try {
      if (!merchantId)
        return console.error(
          "Invalid merchantId(removeItemOptionByIdByIdOption)"
        );

      if (!_id || !_idOption)
        return console.error(
          "Invalid _id pr _idOption(removeItemOptionByIdByIdOption)"
        );
      return (
        (await axios.delete(
          `${ITEM_ROUTE_URL}/merchantId/${merchantId}/itemOption/${_id}/${_idOption}`
        )) || {}
      ).data;
    } catch (ex) {
      console.error(ex);
    }
  }

  // return removed itemOptionValue
  static async removeItemOptionValueByIdByIdOptionByIdOptionValue(
    merchantId,
    _id,
    _idOption,
    _idOptionValue
  ) {
    try {
      if (!merchantId)
        return console.error(
          "Invalid merchantId(removeItemOptionValueByIdByIdOptionByIdOptionValue)"
        );

      if (!_id || !_idOption || !_idOptionValue)
        return console.error(
          "Invalid _id or _idOption or _idOptionValue(removeItemOptionValueByIdByIdOptionByIdOptionValue)"
        );
      return (
        (await axios.delete(
          `${ITEM_ROUTE_URL}/merchantId/${merchantId}/itemOptionValue/${_id}/${_idOption}/${_idOptionValue}`
        )) || {}
      ).data;
    } catch (ex) {
      console.error(ex);
    }
  }

  static async removeCategoryPromoMethodById(merchantId, _idCategory) {
    try {
      if (!merchantId)
        return console.error(
          "Invalid merchantId(removeCategoryPromoMethodById)"
        );

      if (!_idCategory)
        return console.error(
          "Invalid _idCategory(removeCategoryPromoMethodById)"
        );
      return (
        (await axios.delete(
          `${CATEGORY_ROUTE_URL}/merchantId/${merchantId}/promoMethod/${_idCategory}`
        )) || {}
      ).data;
    } catch (ex) {
      console.error(ex);
    }
  }
}
