const axios = require("axios");
// const UPLOAD_FILE_URL = "/upload_file";
const UPLOAD_FILE_URL = "/api/files";

export default class FileDb {
  // file: File type. see https://developer.mozilla.org/en-US/docs/Web/API/File
  // destPath(not in use for now): String. folder path to save file on server. eg: "public"
  static async uploadFile(file) {
    try {
      const data = new FormData();
      data.append("file", file);
      return (
        (await axios.post(
          //   `${UPLOAD_FILE_URL}/upload/itemImage/merchantId/${merchantId}/_idItem/${_idItem}`,
          `${UPLOAD_FILE_URL}/upload`,
          data
        )) || {}
      ).data;
    } catch (ex) {
      console.error(ex);
    }
  }

  static async remove(filename) {
    try {
      if (!filename) return console.error("Invalid filename(FileDb.remove)");

      return (
        (await axios.delete(
          //   `${UPLOAD_FILE_URL}/upload/itemImage/merchantId/${merchantId}/_idItem/${_idItem}`,
          `${UPLOAD_FILE_URL}/remove/fileName/${filename}`
        )) || {}
      ).data;
    } catch (ex) {
      console.error(ex);
    }
  }

  static async uploadItemImage() {}
}
