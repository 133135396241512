import React from "react";
import ReactDOM from "react-dom";

import App from "./App";
import * as serviceWorker from "./serviceWorker";
import "rc-time-picker/assets/index.css";

import "./index.css";

ReactDOM.render(<App />, document.getElementById("root"));

// ReactDOM.render(
//   <form method="POST" id="payment-form">
//     <div id="tsep-cardNumDiv"></div>
//     <div id="tsep-datepickerDiv"></div>
//     <button type="submit" form="payment-form" value="Submit">
//       Submit
//     </button>
//   </form>,
//   document.getElementById("root")
// );

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
