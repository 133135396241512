import React, { Component } from "react";
import {
  Table,
  Form,
  Button,
  Message,
  List,
  Image,
  Dimmer,
  Loader,
  Segment,
  Modal,
  Header,
} from "semantic-ui-react";
import "../css/userinfo.css";
import { formatPhoneNumber, validateUserName } from "../dataModel/utils";
import AuthDb from "../dataModel/authDb";
class UserDashboardModal extends Component {
  state = {
    activeTab: "userInfo",
    userName: this.props.userInfo.name,
    name: this.props.userInfo.order_name,
    address: this.props.userInfo.order_address,
    phone: this.props.userInfo.order_phone,
    zipcode: this.props.userInfo.order_zipcode,
    showMsg: false,
    email: this.props.userInfo.email,
    login_portal: this.props.userInfo.login_portal,
    order_amount: 0,
    order_history: [],
    loyaltyAmount: 0,
    sessionWarning: "",
    loading: false,
    showUserNameError: false,
    showCustomerPhoneError: false,
    formErr: "",
  };
  handleUserLogout = () => {
    this.props.onLogoutClick && this.props.onLogoutClick();
  };
  handleDashboardClose = () => {
    this.props.handleDashboardClose && this.props.handleDashboardClose();
  };

  handleUserInfoUpdate = () => {
    if (
      this.state.userName == "" ||
      (this.state.phone.length < 10 && this.state.phone.length > 0)
    ) {
      this.setState({ formErr: "Please correct the form before submit!" });
    } else {
      let updateUser = {
        name: this.state.userName,
        order_address: this.state.address,
        order_phone: this.state.phone,
        order_zipcode: this.state.zipcode,
        order_name: this.state.name,
      };
      this.props.handleUserInfoUpdate &&
        this.props.handleUserInfoUpdate(updateUser);
    }
  };
  handleCustomerPhoneChange = (phone) => {
    this.setState({ phone: phone });
  };
  clearUserDashboardMsg = () => {
    this.props.clearUserDashboardMsg && this.props.clearUserDashboardMsg();
  };
  getLoyaltyLevel = async () => {
    this.setState({ loading: true });
    const result = await AuthDb.getOrderAmount();
    if ("orderAmount" in result) {
      this.setState({ loyaltyAmount: result.orderAmount.total });
    }
    this.getOrderHistory();

    this.setState({ loading: false });
  };
  getOrderHistory = async () => {
    this.setState({ loading: true });
    const result = await AuthDb.getOrderHistory();
    console.log(result);
    if (result.success) {
      this.setState({ order_history: result.orderHistory });
    } else {
      this.setState({ order_history: [] });
    }
    this.setState({ loading: false });
  };
  getUserInfo = async () => {
    const result = await AuthDb.getUserInfo();
    if (!result) {
      this.setState({
        sessionWarning: "You have been logged out, please refresh this page;",
      });
    }
    this.setState({ loading: false });
  };

  renderContent = () => {
    if (this.state.activeTab == "userInfo") {
      return (
        <div>
          <Form>
            <Form.Input
              className="leftAlign"
              type="text"
              label="Username *"
              placeholder="Username"
              value={this.state.userName || ""}
              onChange={(evt) => this.setState({ userName: evt.target.value })}
              error={
                this.state.showUserNameError &&
                validateUserName(this.state.userName).error && {
                  content: validateUserName(this.state.userName).error,
                }
              }
              onBlur={() => {
                this.setState({
                  showUserNameError: true,
                });
              }}
              onFocus={() => {
                this.setState({
                  showUserNameError: false,
                });
              }}
            ></Form.Input>
            {!this.state.login_portal ? (
              <Form.Input
                type="text"
                label="Email*"
                placeholder="User email"
                disabled
                value={this.state.email}
              ></Form.Input>
            ) : null}
            {this.state.login_portal ? (
              <Form.Input
                type="text"
                label="Login Through:"
                disabled
                value={this.state.login_portal}
              ></Form.Input>
            ) : null}
            <Form.Input
              type="text"
              label="Name"
              placeholder="Name"
              value={this.state.name || ""}
              onChange={(evt) => this.setState({ name: evt.target.value })}
            ></Form.Input>
            <Form.Input
              type="text"
              label="Address"
              placeholder="Address"
              value={this.state.address || ""}
              onChange={(evt) => this.setState({ address: evt.target.value })}
            ></Form.Input>
            <Form.Input
              type="text"
              label="Zip Code *"
              placeholder="00000"
              value={this.state.zipcode || ""}
              onChange={(evt) => this.setState({ zipcode: evt.target.value })}
              error={
                this.state.showUSPostalCodeError &&
                (this.state.zipcode.length < 5 ||
                  this.state.zipcode.search(/\D/g) > -1) && {
                  content: "Invalid!",
                }
              }
              onBlur={() => {
                this.setState({
                  showUSPostalCodeError: true,
                });
              }}
              onFocus={() => {
                this.setState({
                  showUSPostalCodeError: false,
                });
              }}
            ></Form.Input>
            <Form.Input
              type="text"
              label="Phone"
              value={formatPhoneNumber(this.state.phone) || ""}
              placeholder="(xxx)xxx-xxxx"
              onChange={(evt) => {
                let phoneNum = (evt.target.value || "").replace(/\D+/g, "");
                this.handleCustomerPhoneChange(
                  phoneNum && phoneNum.substring(0, 10)
                );
              }}
              error={
                this.state.showCustomerPhoneError &&
                this.state.phone.length < 10 &&
                this.state.phone.length > 0
                  ? {
                      content: "Invalid",
                    }
                  : undefined
              }
              onBlur={() => {
                this.setState({
                  showCustomerPhoneError: true,
                });
              }}
              onFocus={() => {
                this.setState({
                  showCustomerPhoneError: false,
                });
              }}
            />
          </Form>
          {this.props.userDashboardErrMsg === "" ? null : (
            <Message negative>{this.props.userDashboardErrMsg}</Message>
          )}
          {this.props.userDashboardMessage === "" ? null : (
            <Message positive>{this.props.userDashboardMessage}</Message>
          )}
          {this.state.formErr === "" ? null : (
            <Message negative>{this.state.formErr}</Message>
          )}
          {/* ----------------------------  bottom --------------------- */}
          <Segment className="footer">
            <Button
              className="dbBtnSubmit"
              style={{
                //     width: "calc(50% - 20px)",
                //     height: "50px",
                //     margin: "10px",
                //     float: "left",
                backgroundColor: "#003162",
                color: "white",
              }}
              fluid
              size="huge"
              onClick={() => this.handleUserInfoUpdate()}
            >
              Submit
            </Button>
            <Button
              className="dbBtnLogout"
              style={{
                marginTop: "10px",
                backgroundColor: "rgb(219, 40, 40)",
                color: "white",
              }}
              fluid
              size="huge"
              onClick={() => this.handleUserLogout()}
            >
              Logout
            </Button>
          </Segment>
        </div>
      );
    } else if (this.state.activeTab == "orderHistory") {
      var notes = this.state.order_history?.map((item, i) => {
        return (
          <List.Item key={i}>
            <Image avatar src={item.merchant_info[0].logoUrl} />
            <List.Content>
              <List.Header as="a">{item.merchant_info[0].name}</List.Header>
              <List.Description>
                Order Date:&nbsp;<b>{item.orderSaveDate.substring(0, 10)}</b>
                <br />
                Order Amount:&nbsp;<b>${item.total.toFixed(2)}</b>
              </List.Description>
            </List.Content>
          </List.Item>
        );
      });
      if (this.state.order_history.length > 0) {
        return <List style={{ textAlign: "left" }}>{notes}</List>;
      } else {
        return <div className="list">You don't have any order yet!</div>;
      }
    } else if (this.state.activeTab == "loyalty") {
      var notes = this.state.order_history?.map((item, i) => {
        return (
          <Table.Row key={i}>
            <Table.Cell>{item.total.toFixed(2) * 100}</Table.Cell>
            <Table.Cell>{item._id}</Table.Cell>
            <Table.Cell>{item.orderSaveDate.substring(0, 10)}</Table.Cell>
          </Table.Row>
        );
      });
      return (
        <div>
          <div>
            Currently you have{" "}
            <b style={{ fontSize: "25px" }}>
              {this.state.loyaltyAmount.toFixed(2) * 100}
            </b>{" "}
            available points.
          </div>
          <div style={{ overflowY: "auto", marginTop: "30px" }}>
            <div style={{ fontSize: "20px" }}>Transaction</div>
            <Table celled>
              <Table.Header>
                <Table.Row>
                  <Table.HeaderCell>Points</Table.HeaderCell>
                  <Table.HeaderCell>Detail</Table.HeaderCell>
                  <Table.HeaderCell>Date</Table.HeaderCell>
                </Table.Row>
              </Table.Header>
              <Table.Body>{notes}</Table.Body>
            </Table>
          </div>
        </div>
      );
    }
  };
  componentDidMount() {
    this.setState({ loading: true });
    this.getUserInfo();
    // this.setState({errMsg:"",Message})
  }
  render = () => {
    if (this.state.loading) {
      return (
        <Dimmer inverted active page>
          <Loader size="huge" />
        </Dimmer>
      );
    } else if (this.state.sessionWarning === "") {
      return (
        <div className="userinfo-modal-div" style={{ display: "block" }}>
          {/* <div className=""> */}
          <div className="white-bg center modal-header">
            <span
              className="close"
              style={{ cursor: "pointer" }}
              onClick={() => this.handleDashboardClose()}
            >
              ×
            </span>
            <div className="login-register-div">
              <div className="userinfo-tab">
                <div
                  onClick={() => {
                    this.setState({ activeTab: "userInfo" });
                    this.clearUserDashboardMsg();
                  }}
                  className={
                    this.state.activeTab == "userInfo"
                      ? "tabActive userLabel"
                      : "userLabel"
                  }
                >
                  User Info
                </div>
                <div
                  onClick={() => {
                    this.setState({ activeTab: "orderHistory" });
                    this.getOrderHistory();
                  }}
                  className={
                    this.state.activeTab == "orderHistory"
                      ? "tabActive userLabel"
                      : "userLabel"
                  }
                >
                  Order History
                </div>
                <div
                  onClick={() => {
                    this.setState({ activeTab: "loyalty" });
                    this.getLoyaltyLevel();
                  }}
                  className={
                    this.state.activeTab == "loyalty"
                      ? "tabActive userLabel"
                      : "userLabel"
                  }
                >
                  Loyalty Level
                </div>
              </div>
              <div className="ui bottom attached active tab segment">
                {this.renderContent()}
              </div>
            </div>
          </div>
          {/* </div> */}
        </div>
      );
    } else {
      return (
        //     <Modal
        //     size="tiny"
        //   >
        <Modal.Content>
          <Modal.Description>
            <Header as="h2" style={{ color: "red" }}>
              {this.state.sessionWarning}
            </Header>
          </Modal.Description>
        </Modal.Content>
        // </Modal>
      );
    }
  };
}
export default UserDashboardModal;
