import React, { Component } from "react";
import { Table, Icon, Button, Checkbox } from "semantic-ui-react";

class MenuTable extends Component {
  handleSortClick = (column) => {
    this.props.onSortClick && this.props.onSortClick(column);
  };

  handleItemClick = (item) => {
    this.props.onItemClick && this.props.onItemClick(item);
  };

  handleItemRemoveClick = (item) => {
    this.props.onItemRemoveClick && this.props.onItemRemoveClick(item);
  };

  handleItemSoldoutCheckChange = (item) => {
    this.props.onItemSoldoutCheckChange &&
      this.props.onItemSoldoutCheckChange(item);
  };

  handleItemAddClick = () => {
    this.props.onItemAddClick && this.props.onItemAddClick();
  };

  handleItemPrinterCheckboxChange = (item, _idPrinter, checked) => {
    this.props.onItemPrinterUpdate &&
      this.props.onItemPrinterUpdate(item, _idPrinter, checked);
  };

  render() {
    const { columnName, direction, printers } = this.props;

    const displayedItems = (this.props.items || []).map((item, index) => {
      const {
        _id: _idItem,
        name,
        name_2nd,
        price,
        _idCategory: category,
        discription,
        soldout,
        _idPrinters,
      } = item;

      const availablePrinters = (printers || [])
        .filter((printer) => printer.print_format != "receipt")
        .map(
          (
            { _id: _idPrinter, name: printerName, description, print_format },
            index
          ) => {
            const checked = (_idPrinters || []).indexOf(_idPrinter) > -1;
            return (
              <Checkbox
                size="small"
                style={{ margin: "0 10px" }}
                key={_idPrinter}
                label={printerName}
                disabled={print_format === "receipt"}
                checked={checked}
                onChange={(evt, { checked }) => {
                  this.handleItemPrinterCheckboxChange(
                    item,
                    _idPrinter,
                    checked
                  );
                  evt.stopPropagation();
                }}
              />
            );
          }
        );

      return (
        <Table.Row
          key={index}
          style={{ cursor: "pointer" }}
          verticalAlign="top"
          onClick={() => this.handleItemClick(item)}
          warning={!!soldout}
        >
          <Table.Cell>{name}</Table.Cell>
          <Table.Cell>{name_2nd}</Table.Cell>
          <Table.Cell>{price}</Table.Cell>
          <Table.Cell>{category && category.name}</Table.Cell>
          <Table.Cell>{discription}</Table.Cell>
          <Table.Cell
            style={{ textAlign: "center" }}
            onClick={(evt) => {
              this.handleItemSoldoutCheckChange(item);
              evt.stopPropagation();
            }}
          >
            <Checkbox label="Sold out" checked={!!soldout} />
          </Table.Cell>
          <Table.Cell style={{ overflowX: "auto" }}>
            {availablePrinters}
          </Table.Cell>

          <Table.Cell
            style={{ textAlign: "center" }}
            onClick={(evt) => {
              this.handleItemRemoveClick(item);
              evt.stopPropagation();
            }}
          >
            <Icon name="trash alternate" color="red" size="large" />
          </Table.Cell>
        </Table.Row>
      );
    });

    return (
      <Table celled sortable striped fixed singleLine size="small">
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell
              width={3}
              sorted={columnName === "name" ? direction : null}
              onClick={() => this.handleSortClick("name")}
            >
              Name
            </Table.HeaderCell>
            <Table.HeaderCell
              width={2}
              sorted={columnName === "name_2nd" ? direction : null}
              onClick={() => this.handleSortClick("name_2nd")}
            >
              Name (2nd lang)
            </Table.HeaderCell>
            <Table.HeaderCell
              width={1}
              sorted={columnName === "price" ? direction : null}
              onClick={() => this.handleSortClick("price")}
            >
              Price
            </Table.HeaderCell>
            <Table.HeaderCell
              width={2}
              sorted={columnName === "_idCategory.name" ? direction : null}
              onClick={() => this.handleSortClick("_idCategory.name")}
            >
              Category
            </Table.HeaderCell>
            <Table.HeaderCell width={2}>Description</Table.HeaderCell>
            <Table.HeaderCell width={2}>Availability</Table.HeaderCell>
            <Table.HeaderCell width={3}>Printers</Table.HeaderCell>
            <Table.HeaderCell
              width={1}
              style={{ textAlign: "center", margin: "0", padding: "0" }}
            >
              <Button
                className="sumi-global-btn-color"
                style={{
                  width: "45px",
                  height: "25px",
                  margin: "0",
                  padding: "0",
                  fontSize: "12px",
                }}
                onClick={this.handleItemAddClick}
              >
                + New
              </Button>
            </Table.HeaderCell>
          </Table.Row>
        </Table.Header>

        <Table.Body>{displayedItems}</Table.Body>
      </Table>
    );
  }
}

export default MenuTable;
