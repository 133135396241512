import React, { Component } from "react";
import "../css/auth.css";
import jwt_decode from "jwt-decode";

class GoogleAuthSect extends Component {
  constructor(props) {
    super(props);
    window.handleCredentialResponse = this.handleCredentialResponse;
    this.myRef = React.createRef();
  }
  handleCredentialResponse = (response) => {
    const user = jwt_decode(response.credential);
    this.props.handleSignIn && this.props.handleSignIn(user, "google");
  };
  componentDidMount() {
    if (window.google) {
      window.google.accounts.id.initialize({
        client_id:
          "500903849268-6c34o3begilup65rl0fcetbs4is02qio.apps.googleusercontent.com",
        callback: this.handleCredentialResponse,
      });
      window.google.accounts.id.renderButton(this.myRef.current, {
        theme: "outline",
        size: "large",
        width: this.myRef.current.clientWidth,
      });
    }
  }
  render = () => {
    return (
      <div
        className="google_button"
        style={{ marginTop: "5px", marginLeft: "20px" }}
        ref={this.myRef}
      ></div>
    );
  };
}

export default GoogleAuthSect;
