const axios = require("axios");

const USER_ROUTE_URL = "/api/users";

export default class UserDb {
  // set cookie for current user if has been set just do nothing
  /*
    return {
        uid:Strings
    }
  */
  static async getCookie() {
    try {
      return ((await axios.get(`${USER_ROUTE_URL}/cookie`)) || {}).data;
    } catch (ex) {
      console.error(ex);
    }
  }
}
