const axios = require("axios");

const PRINTER_ROUTE_URL = "/api/printers";

export default class PrinterDb {
  static async getPrinters(merchantId, queryObj) {
    try {
      if (!merchantId) return console.error("Invalid addPrinter(getPrinters)");

      return (
        (await axios.get(
          `${PRINTER_ROUTE_URL}/merchantId/${merchantId}/printers`,
          {
            params: {
              queryObj: queryObj || {},
            },
          }
        )) || {}
      ).data;
    } catch (ex) {
      console.error(ex);
    }
  }
}
