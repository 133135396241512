import React, { Component } from "react";
import { Icon } from "semantic-ui-react";

class CategoryListScrollbar extends Component {
  handleCategoryListScroll = (evt) => {
    this.props.onCategoryListScroll && this.props.onCategoryListScroll(evt);
  };

  handleCategoryChange = (category) => {
    this.props.onCategoryChange && this.props.onCategoryChange(category);
  };

  handleModalClick = () => {
    this.props.onModalClick && this.props.onModalClick();
  };

  render() {
    const {
      showLeftScrollingSign,
      showRightScrollingSign,
      categories,
      category_selected,
    } = this.props;

    return (
      <div className="category-scrolling-bar">
        {/* <div style={{ margin: "0 -1em" }}> */}
        <Icon
          disabled
          name="triangle left"
          size="large"
          className="category-scrolling-icon left noselect"
          style={{
            visibility: showLeftScrollingSign ? "visible" : "hidden",
            opacity: showLeftScrollingSign ? "1" : "0",
            // paddingRight: "5px",
          }}
        />
        {/* </div> */}
        <div
          className="category-list-icon"
          id="category-list-icon"
          onScroll={(evt) => this.handleCategoryListScroll(evt)}
        >
          {(categories || []).map((category, index) => {
            const selected =
              category_selected && category._id === category_selected._id;

            return (
              <a
                key={index}
                className="category"
                style={{
                  borderBottom: selected
                    ? "4px solid rgb(219, 40, 40)"
                    : "none",
                  color: selected ? "rgb(219, 40, 40)" : "rgb(0, 49, 98)",
                  // : "rgb(118, 118, 118)",
                }}
                href={category && "#" + category._id}
                onClick={() => this.handleCategoryChange(category)}
              >
                {category.name}
              </a>
            );
          })}
        </div>
        <Icon
          disabled
          name="triangle right"
          size="large"
          className="category-scrolling-icon right noselect"
          style={{
            visibility: showRightScrollingSign ? "visible" : "hidden",
            opacity: showRightScrollingSign ? "1" : "0",
            // paddingLeft: "5px",
          }}
        />

        <Icon
          name="list"
          size="large"
          color="black"
          className="category-scrolling-icon"
          onClick={this.handleModalClick}
        />
      </div>
    );
  }
}

export default CategoryListScrollbar;
