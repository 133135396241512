import React, { Component } from "react";
import {
  Image,
  Loader,
  Dimmer,
  Container,
  Header,
  Segment,
  Divider,
  Table,
  Icon,
  Modal,
  Button,
  Form,
} from "semantic-ui-react";
import luckoryLogo from "../imgs/logo7.png";

import BusinessInfoDb from "./../dataModel/businessInfoDb";
import OrderDb from "./../dataModel/orderDb";
import DateTimeDb from "./../dataModel/dateTimeDb";
import Email_msmDb from "../dataModel/email_msmDb";
import {
  formatAddress,
  formatPrice,
  toUrl,
  getFoodPreparingTimeObj,
} from "./../dataModel/utils";
import noticeBgImg from "../imgs/noticeBg.svg";
import {
  formatPhoneNumber,
  validateEmail,
  formatDateMonthDayYear,
} from "../dataModel/utils";

// import GoogleMapPanel from "./googleMapPanel";
// import Geocode from "react-geocode";

// const googleMapKey = "AIzaSyCi5MqSwmmuTW1R-Axt3dWOabhZUx386tg";
// Geocode.setApiKey(googleMapKey);

class OrderPlacedPanel extends Component {
  state = {
    loading: false,
    msg: null,
    orderId: null,
    orderPaid: null,
    orderDate: null,
    customerName: null,
    paymentMethod: null,
    orderMethod: null,
    orderItems: null,
    orderedItems_PromoMethod: null,
    subtotal: null,
    total: null,
    tips: null,
    fixedTips: null,
    tax: null,
    discountAmt: null,
    processingFeeAmt: null,
    serviceFeeAmt: null,
    deliveryFee: null,

    roomServiceDeliveryFee: null,
  };

  componentDidMount = async () => {
    const _idOrder = this.props._idOrder;
    const merchantId = this.props.merchantId;

    const businessInfo =
      merchantId && (await BusinessInfoDb.getBusinessInfo(merchantId));

    if (!businessInfo) return (window.location.href = "/");

    const order = await OrderDb.getOrderById(merchantId, _idOrder);

    if (!order) return (window.location.href = "/");

    const foodPreparingTimeObj =
      businessInfo && getFoodPreparingTimeObj(businessInfo.foodPreparingTime);

    const roomService = businessInfo && businessInfo.roomService;

    const {
      enabled: enableCustomerInfoCollect,
      noticeMsg: customerInfoCollectMsg,
      recevingEmail: recevingEmailCustomerInfoCollect,
      subject: subjectCustomerInfoCollect,
    } = (businessInfo && businessInfo.customerInfoCollect) || {};

    const {
      paid,
      orderId,
      orderSaveDate,
      customerName,
      paymentMethod,
      orderMethod,
      dineinTableName,
      customerAddrStr,
      orderItems,
      subtotal,
      total,
      tips,
      fixedTips,
      tax,
      discountAmt,
      dailyOrderId,
      processingFeeAmt,
      serviceFeeAmt,
      deliveryFee,
      roomServiceDeliveryFee,
      scheduleTimeStr,
      scheduleDate,
      willBeReadyInMinutes,
      isBranch,
    } = order;

    let addressStr = null;
    let lng,
      lat = null;
    if (orderMethod === "pickup" || orderMethod === "curbside") {
      const businessAddressStr = isBranch
        ? formatAddress(
            businessInfo && (businessInfo.branchAddress || businessInfo.address)
          )
        : formatAddress(businessInfo && businessInfo.address);
      // const response =
      //   businessAddressStr && (await Geocode.fromAddress(businessAddressStr));
      // const location = response && response.results[0].geometry.location;
      // lng = (location || {}).lng;
      // lat = (location || {}).lat;
      addressStr = businessAddressStr;
    } else if (orderMethod === "delivery") {
      // const response = await Geocode.fromAddress(customerAddrStr);
      // const location = response && response.results[0].geometry.location;
      // lng = (location || {}).lng;
      // lat = (location || {}).lat;
      addressStr = customerAddrStr;
    } else {
    }

    this.setState({
      businessUrl: businessInfo && businessInfo.webSite,
      businessName: isBranch
        ? businessInfo && (businessInfo.branchName || businessInfo.name)
        : businessInfo && businessInfo.name,
      orderPaid: paid,
      customerName,
      orderId,
      orderItems,
      orderDate:
        orderSaveDate &&
        (DateTimeDb.getDateObj(orderSaveDate) || {}).dateTimeStr,
      paymentMethod,
      orderMethod,
      dineinTableName,
      customerAddrStr,
      lat,
      lng,
      addressStr,
      subtotal,
      total,
      tips,
      fixedTips,
      tax,
      discountAmt,
      processingFeeAmt,
      serviceFeeAmt,
      deliveryFee,
      roomServiceDeliveryFee,
      dailyOrderId,
      merchantId,
      scheduleTimeStr,
      scheduleDate,
      foodPreparingTimeObj,
      willBeReadyInMinutes,
      roomService,

      // ------------------------------ customer info collect ------------------------

      enableCustomerInfoCollect,
      customerInfoCollectMsg,
      showCustomerInfoMsgModal: !!enableCustomerInfoCollect,
      showCustomerInfoFormModal: false,
      customerInfoCollected: enableCustomerInfoCollect
        ? { fname: null, lname: null, email: null, phone: null, address: null }
        : null,
      recevingEmailCustomerInfoCollect,
      subjectCustomerInfoCollect,
    });
  };

  startLoading = () => {
    this.setState({
      loading: true,
    });
  };

  endLoading = () => {
    this.setState({
      loading: false,
    });
  };

  handleMerchantWebsiteClick = (url) => {
    url && toUrl(url);
  };

  handleLuckoryLogoClick = () => {
    // toUrl("https://www.luckory.com/");
  };

  orderItemOptionRemap = (orderItemOption) => {
    if (orderItemOption) {
      let remapDic = {};
      for (let i = 0; i < orderItemOption.length; i++) {
        let itemOptionId = orderItemOption[i]._id;
        if (itemOptionId in remapDic) {
          remapDic[itemOptionId].original_index.push(i);
          remapDic[itemOptionId].qty = remapDic[itemOptionId].qty + 1;
        } else {
          orderItemOption[i]["qty"] = 1;
          orderItemOption[i]["original_index"] = [i];
          remapDic[itemOptionId] = orderItemOption[i];
        }
      }
      let finalReMap = [];
      for (let j in remapDic) {
        finalReMap.push(remapDic[j]);
      }
      return finalReMap;
    } else {
      return orderItemOption;
    }
  };

  handleCustomerInfoMsgClose = () => {
    this.setState({
      showCustomerInfoMsgModal: false,
    });
  };

  handleCustomerInfoEnrollClick = () => {
    this.setState({
      showCustomerInfoMsgModal: false,
      showCustomerInfoFormModal: true,
    });
  };

  handleCustomerInfoFormClose = () => {
    this.setState({
      showCustomerInfoMsgModal: true,
      showCustomerInfoFormModal: false,
    });
  };

  // customerInfoCollected
  handleCustomerInfoChange = (name, val) => {
    const customerInfoCollected = { ...this.state.customerInfoCollected };
    customerInfoCollected[name] = val;

    this.setState({
      customerInfoCollected,
    });
  };

  handleCustomerInfoFormSubmit = () => {
    const { fname, lname, email, phone, address } =
      this.state.customerInfoCollected || {};
    const { recevingEmailCustomerInfoCollect, subjectCustomerInfoCollect } =
      this.state;

    const error =
      this._verifyCustomerInfo("fname", fname) ||
      this._verifyCustomerInfo("lname", lname) ||
      this._verifyCustomerInfo("email", email) ||
      this._verifyCustomerInfo("phone", phone) ||
      this._verifyCustomerInfo("address", address);

    console.log("error", error);

    if (error) {
      const showMsgName = "showError_" + error.name;
      const update = {};
      update[showMsgName] = true;
      return this.setState(update);
    }

    // return console.log(123, recevingEmailCustomerInfoCollect);

    Email_msmDb.sendCustomerInfoInfoTo(
      this.state.customerInfoCollected,
      [recevingEmailCustomerInfoCollect],
      { subject: subjectCustomerInfoCollect }
    );

    this.setState({
      showCustomerInfoMsgModal: false,
      showCustomerInfoFormModal: false,
      showCustomerInfoSubmitCompleteInfo: true,
    });

    setTimeout(() => {
      this.setState({
        showCustomerInfoSubmitCompleteInfo: false,
      });
    }, 2000);
  };

  _verifyCustomerInfo = (name, value) => {
    if (
      name === "fname" ||
      name == "lname" ||
      name == "email" ||
      name == "phone" ||
      name == "address"
    ) {
      if (!value || value.trim() === "") {
        return {
          content: "This field needs to be filled",
          pointing: "above",
          name,
        };
      }
    }

    if (name === "email") {
      if (!validateEmail(value)) {
        return {
          content: "Invalid Email format",
          pointing: "above",
          name,
        };
      }
    }

    if (name === "phone") {
      if (value.length < 10) {
        return {
          content: "Invalid phone format",
          pointing: "above",
          name,
        };
      }
    }
  };

  render() {
    const {
      businessName,
      orderPaid,
      dailyOrderId,
      orderDate,
      customerName,
      // paymentMethod,
      orderMethod,
      dineinTableName,
      lat,
      lng,
      addressStr,
      orderItems,
      subtotal,
      total,
      tips,
      fixedTips,
      tax,
      discountAmt,
      processingFeeAmt,
      serviceFeeAmt,
      deliveryFee,
      roomServiceDeliveryFee,
      scheduleTimeStr,
      scheduleDate,
      foodPreparingTimeObj,
      willBeReadyInMinutes,
      showCustomerInfoMsgModal,
      customerInfoCollectMsg,

      customerInfoCollected,
    } = this.state;

    const orderedItemsRows = (orderItems || []).map((orderedItem, index) => {
      const { name, price, qty, orderItemOptions, note, size } = orderedItem;

      let itemTotalPrice = price;
      (orderItemOptions || []).forEach((ov) => {
        itemTotalPrice += ov.price;
      });
      itemTotalPrice = itemTotalPrice * Number(qty || 0);

      const orderItemOptionsRemap = this.orderItemOptionRemap(orderItemOptions);
      return (
        <Table.Row key={index} verticalAlign="top">
          <Table.Cell width={2}>
            <b>{qty}</b>
          </Table.Cell>
          <Table.Cell width={10}>
            <b>{name}</b>
            {size ? (
              <React.Fragment>
                <br />
                <span
                  style={{
                    fontSize: "smaller",
                    color: "rgb(125, 125, 125)",
                    fontWeight: "600",
                  }}
                >
                  {size}
                </span>{" "}
              </React.Fragment>
            ) : null}
            {(orderItemOptionsRemap || []).map((itemOption, optionIndex) => {
              return (
                <React.Fragment key={optionIndex}>
                  <br />
                  <span
                    style={{
                      fontSize: "smaller",
                      color: "rgb(125, 125, 125)",
                      fontWeight: "600",
                    }}
                  >
                    {itemOption.qty > 1 ? itemOption.qty + " x " : null}
                    {itemOption.name}
                  </span>
                </React.Fragment>
              );
            })}
            {note ? (
              <React.Fragment>
                <br />
                <span
                  style={{
                    fontSize: "smaller",
                    color: "rgb(125, 125, 125)",
                    fontWeight: "600",
                  }}
                >
                  Note: {note}
                </span>
              </React.Fragment>
            ) : null}
          </Table.Cell>
          <Table.Cell width={2}>
            <b>${formatPrice(itemTotalPrice)}</b>
          </Table.Cell>
        </Table.Row>
      );
    });

    return (
      <React.Fragment>
        {/* ------------------------------ customer Info Collect if applied ------------------- */}

        <Modal
          size="tiny"
          open={!!this.state.showCustomerInfoSubmitCompleteInfo}
          // open={true}
          closeIcon
          onClose={() => {
            this.setState({
              showCustomerInfoSubmitCompleteInfo: false,
            });
          }}
        >
          <Modal.Content style={{ textAlign: "center" }}>
            <Modal.Description>
              <Header as="h1">Submit, Thank you!</Header>
            </Modal.Description>
          </Modal.Content>
          <div
            style={{
              background: `#0e2a47 url(${noticeBgImg}) no-repeat 50%`,
              backgroundSize: "cover",
              width: "100%",
              height: "80px",
              display: "block",
              color: "white",
              textAlign: "center",
              padding: "30px 0",
              fontSize: "12px",
            }}
          >
            All rights reserved. Supported by Luckory.
          </div>
        </Modal>

        <Modal size="tiny" open={!!this.state.showCustomerInfoFormModal}>
          <Modal.Content>
            <Modal.Description>
              <Header as="h1">One more step</Header>
              <Form size="large" style={{ fontWeight: "600" }}>
                <Form.Group>
                  <Form.Input
                    width={8}
                    label="First Name *"
                    value={
                      (customerInfoCollected && customerInfoCollected.fname) ||
                      ""
                    }
                    onChange={(evt) =>
                      this.handleCustomerInfoChange("fname", evt.target.value)
                    }
                    error={
                      this.state.showError_fname &&
                      this._verifyCustomerInfo(
                        "fname",
                        customerInfoCollected && customerInfoCollected.fname
                      )
                    }
                    onBlur={() => {
                      this.setState({
                        showError_fname: true,
                      });
                    }}
                    onFocus={() => {
                      this.setState({
                        showError_fname: false,
                      });
                    }}
                  />
                  <Form.Input
                    width={8}
                    label="Last Name *"
                    value={
                      (customerInfoCollected && customerInfoCollected.lname) ||
                      ""
                    }
                    onChange={(evt) =>
                      this.handleCustomerInfoChange("lname", evt.target.value)
                    }
                    error={
                      this.state.showError_lname &&
                      this._verifyCustomerInfo(
                        "lname",
                        customerInfoCollected && customerInfoCollected.lname
                      )
                    }
                    onBlur={() => {
                      this.setState({
                        showError_lname: true,
                      });
                    }}
                    onFocus={() => {
                      this.setState({
                        showError_lname: false,
                      });
                    }}
                  />
                </Form.Group>
                <Form.Field>
                  <Form.Input
                    label="Email *"
                    value={
                      (customerInfoCollected && customerInfoCollected.email) ||
                      ""
                    }
                    onChange={(evt) =>
                      this.handleCustomerInfoChange("email", evt.target.value)
                    }
                    error={
                      this.state.showError_email &&
                      this._verifyCustomerInfo(
                        "email",
                        customerInfoCollected && customerInfoCollected.email
                      )
                    }
                    onBlur={() => {
                      this.setState({
                        showError_email: true,
                      });
                    }}
                    onFocus={() => {
                      this.setState({
                        showError_email: false,
                      });
                    }}
                  />
                </Form.Field>
                <Form.Field>
                  <Form.Input
                    type="text"
                    pattern="[0-9]*"
                    label="Phone *"
                    value={
                      (customerInfoCollected &&
                        formatPhoneNumber(customerInfoCollected.phone)) ||
                      ""
                    }
                    onChange={(evt) => {
                      let phoneNum = (evt.target.value || "").replace(
                        /\D+/g,
                        ""
                      );
                      this.handleCustomerInfoChange("phone", phoneNum);
                    }}
                    error={
                      this.state.showError_phone &&
                      this._verifyCustomerInfo(
                        "phone",
                        customerInfoCollected && customerInfoCollected.phone
                      )
                    }
                    onBlur={() => {
                      this.setState({
                        showError_phone: true,
                      });
                    }}
                    onFocus={() => {
                      this.setState({
                        showError_phone: false,
                      });
                    }}
                  />
                </Form.Field>
                <Form.Field>
                  <Form.Input
                    label="Address *"
                    placeholder="eg: 123 Street, city, GA 12345"
                    value={
                      (customerInfoCollected &&
                        customerInfoCollected.address) ||
                      ""
                    }
                    onChange={(evt) =>
                      this.handleCustomerInfoChange("address", evt.target.value)
                    }
                    error={
                      this.state.showError_address &&
                      this._verifyCustomerInfo(
                        "address",
                        customerInfoCollected && customerInfoCollected.address
                      )
                    }
                    onBlur={() => {
                      this.setState({
                        showError_address: true,
                      });
                    }}
                    onFocus={() => {
                      this.setState({
                        showError_address: false,
                      });
                    }}
                  />
                </Form.Field>
              </Form>
            </Modal.Description>
            <Modal.Actions style={{ marginTop: "15px", textAlign: "right" }}>
              <Button onClick={this.handleCustomerInfoFormClose}>Back</Button>
              <Button
                className="sumi-global-btn-color"
                onClick={this.handleCustomerInfoFormSubmit}
              >
                Submit
              </Button>
            </Modal.Actions>
          </Modal.Content>
          <div
            style={{
              background: `#0e2a47 url(${noticeBgImg}) no-repeat 50%`,
              backgroundSize: "cover",
              width: "100%",
              height: "80px",
              display: "block",
              color: "white",
              textAlign: "center",
              padding: "30px 0",
              fontSize: "12px",
            }}
          >
            All rights reserved. Supported by Luckory.
          </div>
        </Modal>

        <Modal
          size="tiny"
          // closeIcon
          // onClose={this.handleCustomerInfoMsgClose}
          open={!!this.state.showCustomerInfoMsgModal}
        >
          <Modal.Content>
            <Modal.Description>
              <Header as="h1">Dear Customer:</Header>
              <span style={{ display: "block", fontSize: "16px" }}>
                {customerInfoCollectMsg}
              </span>
            </Modal.Description>
            <Modal.Actions style={{ marginTop: "15px", textAlign: "right" }}>
              <Button onClick={this.handleCustomerInfoMsgClose}>Decline</Button>
              <Button
                className="sumi-global-btn-color"
                onClick={this.handleCustomerInfoEnrollClick}
              >
                Enroll
              </Button>
            </Modal.Actions>
          </Modal.Content>
          <div
            style={{
              background: `#0e2a47 url(${noticeBgImg}) no-repeat 50%`,
              backgroundSize: "cover",
              width: "100%",
              height: "80px",
              display: "block",
              color: "white",
              textAlign: "center",
              padding: "30px 0",
              fontSize: "12px",
            }}
          >
            All rights reserved. Supported by Luckory.
          </div>
        </Modal>

        {/* ------------------------------  header panel ----------------------------- */}
        <Segment
          style={{
            height: "50px",
            top: "-1px",
            width: "100%",
            position: "fixed",
            padding: "5px",
            margin: "0",
            zIndex: "10",
          }}
        >
          <Image
            src={luckoryLogo}
            size="small"
            style={{
              // width: "40px",
              margin: "0px auto",
              display: "inline-block",
              left: "calc(50% - 68px)",
              verticalAlign: "0",
              position: "absolute",
              height: "25px",
              width: "auto",
              top: "12px",
              cursor: "pointer",
            }}
            // className="hide-if-medium noselect"
            className="noselect"
            onClick={this.handleLuckoryLogoClick}
          />

          <Header
            as="h3"
            style={{
              margin: "0",
              display: "inline",
              lineHeight: "40px",
              cursor: "pointer",
              float: "left",
            }}
            onClick={() => {
              this.handleMerchantWebsiteClick(this.state.businessUrl);
            }}
          >
            <Icon name="home" size="large" style={{ color: "rgb(0,49,98)" }} />
          </Header>
        </Segment>
        <Container
          textAlign="center"
          style={{
            fontFamily: '"Rubik",sans-serif',
            position: "relative",
            top: "50px",
            height: "cal(100% - 50px)",
          }}
        >
          <Dimmer inverted active={this.state.loading} page>
            <Loader size="huge" />
          </Dimmer>

          <Segment basic textAlign="left" className="order-placed-panel">
            <Header as="h3" style={{ fontSize: "22px" }}>
              Your Order #{dailyOrderId} Has Been Placed
              {/* <span className="get-receipt-btn">
                <Popup
                  trigger={
                    <Icon name="question circle" className="get-receipt-icon" />
                  }
                  content="One text message containing the link of this page will be sent to your phone"
                  on="click"
                  hideOnScroll
                  position="bottom right"
                />
                Receive SMS
              </span> */}
              <span className="order-info">
                {businessName}, at {orderDate}
              </span>
            </Header>

            {orderPaid || this.state.roomService ? null : (
              <div className="warning-msg">
                <Icon name="warning circle" className="get-receipt-icon" />
                Please notice this order has NOT been paid yet. You can choose
                to pay by creditcard or cash when you pick up the food at store,
                or pay upon delivery arrival.
              </div>
            )}

            <Divider />

            {/* -------------------------------- address ----------------------------- */}
            <div
              style={{
                fontSize: "13px",
                color: "#6d6d6d",
                fontWeight: "600",
              }}
            >
              {orderMethod === "pickup" || orderMethod === "curbside" ? (
                "Pickup at:"
              ) : orderMethod === "delivery" ? (
                "Delivery to:"
              ) : orderMethod === "dinein" ? (
                <span>
                  Dinein at:{" "}
                  <span
                    style={{
                      color: "rgb(0,49,98)",
                      fontSize: "larger",
                      margin: "0 10px",
                      fontWeight: "600",
                    }}
                  >
                    Table #{dineinTableName}
                  </span>
                </span>
              ) : null}

              <a
                style={{
                  color: "rgb(0,49,98)",
                  fontSize: "larger",
                  margin: "0 10px",
                  fontWeight: "600",
                }}
                href={`http://maps.google.com/?q=${addressStr}`}
              >
                {orderMethod === "pickup" || orderMethod === "curbside"
                  ? addressStr
                  : addressStr}
              </a>
              <div style={{ margin: "10px 0" }}>
                {/* {lat != null && lng != null ? (
                <GoogleMapPanel
                  width={"100%"}
                  height={"300px"}
                  lat={lat}
                  lng={lng}
                />
              ) : null} */}
              </div>
            </div>

            {/* -------------------------------- time ----------------------------- */}
            {foodPreparingTimeObj || scheduleTimeStr ? (
              <div
                style={{
                  fontSize: "13px",
                  color: "#6d6d6d",
                  fontWeight: "600",
                }}
              >
                Ready in:
                <span
                  style={{
                    color: "rgb(0,49,98)",
                    fontSize: "larger",
                    margin: "0 10px",
                    fontWeight: "600",
                  }}
                >
                  {scheduleTimeStr ? (
                    `Scheduled on ${
                      scheduleDate && formatDateMonthDayYear(scheduleDate)
                    } @ ${scheduleTimeStr}`
                  ) : orderMethod === "delivery" ? (
                    willBeReadyInMinutes != null ? (
                      willBeReadyInMinutes + " mins for Delivery"
                    ) : foodPreparingTimeObj.fromMin_delivery ||
                      foodPreparingTimeObj.toMin_delivery ? (
                      <span>
                        {foodPreparingTimeObj.fromMin_delivery ||
                          foodPreparingTimeObj.toMin_delivery}
                        -
                        {foodPreparingTimeObj.toMin_delivery ||
                          foodPreparingTimeObj.fromMin_delivery}
                        mins for Delivery
                      </span>
                    ) : null
                  ) : orderMethod === "pickup" ? (
                    willBeReadyInMinutes != null ? (
                      willBeReadyInMinutes + " mins for Pickup"
                    ) : foodPreparingTimeObj.fromMin_pickup ||
                      foodPreparingTimeObj.toMin_pickup ? (
                      <span>
                        {foodPreparingTimeObj.fromMin_pickup ||
                          foodPreparingTimeObj.toMin_pickup}
                        -
                        {foodPreparingTimeObj.toMin_pickup ||
                          foodPreparingTimeObj.fromMin_pickup}
                        mins for Pickup
                      </span>
                    ) : null
                  ) : orderMethod === "dinein" ? (
                    willBeReadyInMinutes != null ? (
                      willBeReadyInMinutes + " mins"
                    ) : foodPreparingTimeObj.fromMin_pickup ||
                      foodPreparingTimeObj.toMin_pickup ? (
                      <span>
                        {foodPreparingTimeObj.fromMin_pickup ||
                          foodPreparingTimeObj.toMin_pickup}
                        -
                        {foodPreparingTimeObj.toMin_pickup ||
                          foodPreparingTimeObj.fromMin_pickup}
                        mins
                      </span>
                    ) : null
                  ) : null}
                </span>
              </div>
            ) : null}

            <Divider />

            <div>
              <span
                style={{
                  fontSize: "13px",
                  color: "#6d6d6d",
                  fontWeight: "600",
                }}
              >
                Items:
              </span>{" "}
              <Table
                striped
                size="small"
                unstackable
                // basic="very"
                style={{
                  maxWidth: "500px",
                  lineHeight: "16px",
                  margin: "0 auto",
                }}
              >
                <Table.Body>{orderedItemsRows}</Table.Body>
              </Table>
            </div>
            <Divider />
            <div
              style={{ fontSize: "13px", color: "#868686", textAlign: "right" }}
            >
              Subtotal:
              <span
                style={{ fontSize: "larger", color: "black", margin: "0 5px" }}
              >
                ${formatPrice(subtotal)}
              </span>
            </div>
            <div
              style={{ fontSize: "13px", color: "#868686", textAlign: "right" }}
            >
              Tax:
              <span
                style={{ fontSize: "larger", color: "black", margin: "0 5px" }}
              >
                ${formatPrice(tax)}
              </span>
            </div>
            {fixedTips ? (
              <div
                style={{
                  fontSize: "13px",
                  color: "#868686",
                  textAlign: "right",
                }}
              >
                Tips:
                <span
                  style={{
                    fontSize: "larger",
                    color: "black",
                    margin: "0 5px",
                  }}
                >
                  ${formatPrice(fixedTips)}
                </span>
              </div>
            ) : null}
            {tips ? (
              <div
                style={{
                  fontSize: "13px",
                  color: "#868686",
                  textAlign: "right",
                }}
              >
                {fixedTips ? "Additional Tips" : "Tips"}
                <span
                  style={{
                    fontSize: "larger",
                    color: "black",
                    margin: "0 5px",
                  }}
                >
                  ${formatPrice(tips)}
                </span>
              </div>
            ) : null}

            {processingFeeAmt || serviceFeeAmt ? (
              <div
                style={{
                  fontSize: "13px",
                  color: "#868686",
                  textAlign: "right",
                }}
              >
                Processing Fee:
                <span
                  style={{
                    fontSize: "larger",
                    color: "black",
                    margin: "0 5px",
                  }}
                >
                  ${formatPrice((processingFeeAmt || 0) + (serviceFeeAmt || 0))}
                </span>
              </div>
            ) : null}

            {roomServiceDeliveryFee ? (
              <div
                style={{
                  fontSize: "13px",
                  color: "#868686",
                  textAlign: "right",
                }}
              >
                Delivery Fee:
                <span
                  style={{
                    fontSize: "larger",
                    color: "black",
                    margin: "0 5px",
                  }}
                >
                  ${formatPrice(roomServiceDeliveryFee)}
                </span>
              </div>
            ) : null}
            {deliveryFee ? (
              <div
                style={{
                  fontSize: "13px",
                  color: "#868686",
                  textAlign: "right",
                }}
              >
                Delivery Fee:
                <span
                  style={{
                    fontSize: "larger",
                    color: "black",
                    margin: "0 5px",
                  }}
                >
                  ${formatPrice(deliveryFee)}
                </span>
              </div>
            ) : null}
            {discountAmt ? (
              <div
                style={{
                  fontSize: "13px",
                  color: "#868686",
                  textAlign: "right",
                }}
              >
                Discount:
                <span
                  style={{
                    fontSize: "larger",
                    color: "black",
                    margin: "0 5px",
                  }}
                >
                  -${formatPrice(discountAmt)}
                </span>
              </div>
            ) : null}
            <div
              style={{
                fontSize: "15px",
                color: "#868686",
                textAlign: "right",
                margin: "10px 0",
                fontWeight: "600",
              }}
            >
              Total:
              <span
                style={{ fontSize: "larger", color: "black", margin: "0 5px" }}
              >
                ${formatPrice(total)}{" "}
              </span>
            </div>
          </Segment>
        </Container>
        {/* ------------------------------ footer panel ----------------------------- */}

        <Divider
          className="no-margin"
          style={{ top: "50px", position: "relative" }}
        />
        <Segment
          basic
          style={{
            margin: "0",
            color: "rbg(0,49,98)",
            fontSize: "11px",
            fontWeight: "600",
            top: "50px",
            position: "relative",
          }}
        >
          <a href="/document/terms-of-service" style={{ margin: "0 10px" }}>
            Terms of Service
          </a>

          <a href="/document/privacy-policy" style={{ margin: "0 10px" }}>
            Privacy
          </a>
          <span style={{ margin: "0 10px" }}>© 2022 Luckory</span>
        </Segment>
      </React.Fragment>
    );
  }
}

export default OrderPlacedPanel;
