import React, { Component } from "react";
import {
  Segment,
  Modal,
  Header,
  Form,
  Dimmer,
  Loader,
  Button,
} from "semantic-ui-react";

class ItemOptionValueAddingModal extends Component {
  state = {
    name_required_show: false,
  };

  _isValid = (name) => {
    return (
      this.props.itemOptionValue_added &&
      this.props.itemOptionValue_added[name] != null
    );
  };

  handleClose = () => {
    this.props.onClose && this.props.onClose();

    this.setState({
      name_required_show: false,
    });
  };

  handleItemOptionValueAddChange = (name, value) => {
    this.props.onItemOptionValueAddChange &&
      this.props.onItemOptionValueAddChange(name, value);
  };

  handleItemOptionValueAddSave = () => {
    if (!this._isValid("name")) {
      return this.setState({
        name_required_show: true,
      });
    }

    this.props.onItemOptionValueAddSave &&
      this.props.onItemOptionValueAddSave();

    this.setState({
      name_required_show: false,
    });
  };

  render() {
    const { itemOptionValue_added, loading } = this.props;

    return (
      <Modal
        closeIcon
        dimmer={true}
        open={!!itemOptionValue_added}
        onClose={this.handleClose}
        className="goorder-online-itemDetail"
      >
        <Dimmer inverted active={!!loading}>
          <Loader size="huge" />
        </Dimmer>

        <Header as="h2" className="header">
          <Form>
            <Form.Group style={{ fontSize: "12px" }}>
              <Form.Input
                width={6}
                label="Option Value Name *"
                value={
                  (itemOptionValue_added && itemOptionValue_added.name) || ""
                }
                onChange={(evt) => {
                  this.handleItemOptionValueAddChange("name", evt.target.value);
                }}
                error={this.state.name_required_show && !this._isValid("name")}
                onBlur={() => {
                  this.setState({
                    name_required_show: true,
                  });
                }}
                onFocus={() => {
                  this.setState({
                    name_required_show: false,
                  });
                }}
              />
              <Form.Input
                width={6}
                label="Option Value Name(2nd)"
                value={
                  (itemOptionValue_added && itemOptionValue_added.name_2nd) ||
                  ""
                }
                onChange={(evt) => {
                  this.handleItemOptionValueAddChange(
                    "name_2nd",
                    evt.target.value
                  );
                }}
              />
              <Form.Input
                width={4}
                label="Additional Price"
                value={
                  itemOptionValue_added && itemOptionValue_added.price != null
                    ? itemOptionValue_added.price
                    : ""
                }
                onChange={(evt) => {
                  this.handleItemOptionValueAddChange(
                    "price",
                    evt.target.value
                  );
                }}
              />
            </Form.Group>
          </Form>
        </Header>

        {/* ----------------------------  bottom --------------------- */}
        <Segment className="footer" raised>
          <Button
            className="sumi-global-btn-color"
            style={{
              width: "100px",
              height: "50px",
              margin: "10px 10px 10px 0",
              float: "right",
            }}
            onClick={this.handleItemOptionValueAddSave}
          >
            Add
          </Button>
          <Button
            style={{
              width: "100px",
              height: "50px",
              margin: "10px 10px 10px 0",
              float: "right",
            }}
            onClick={this.handleClose}
          >
            Cancel
          </Button>
        </Segment>
      </Modal>
    );
  }
}

export default ItemOptionValueAddingModal;
