import React, { Component, useEffect } from "react";
import { Form, Segment, Button, Header, Message } from "semantic-ui-react";

class OwnerLoginPanel extends Component {
  state = {
    merchant_id: "",
    password: "",
  };

  handleMerchantIDChange = (val) => {
    this.setState({
      merchant_id: val,
    });
  };

  handlePasswordChange = (val) => {
    this.setState({
      password: val,
    });
  };

  handleSignIn = (merchantId, password) => {
    this.props.onSigninClick && this.props.onSigninClick(merchantId, password);
  };

  render() {
    return (
      <div style={{ height: "100vh", width: "100%" }}>
        <Segment
          style={{
            height: "50px",
            top: "0",
            width: "100%",
            position: "fixed",
            padding: "5px",
            margin: "0",
            zIndex: "10",
          }}
          raised
        >
          <div style={{ maxWidth: "300px", display: "inline-block" }}></div>
        </Segment>

        {/* <div
          style={{
            width: "100%",
            height: "calc(100% - 50px)",
            margin: "0",
            padding: "0",
            top: "50px",
            position: "relative",
          }}
        > */}
        <div
          style={{
            height: "calc(100% - 50px)",
            width: "400px",
            overflowY: "auto",
            margin: "0 auto",
            padding: "20px",
            top: "50px",
            position: "relative",
            backgroundColor: "white",
          }}
        >
          <Header as="h2" textAlign="center" style={{ width: "100%" }}>
            Sign In
          </Header>

          <Form>
            <Form.Field>
              <label>Merchant ID</label>
              <input
                value={this.state.merchant_id}
                onChange={(evt) =>
                  this.handleMerchantIDChange(evt.target.value)
                }
              />
            </Form.Field>
            <Form.Field>
              <label>Password</label>
              <input
                type="password"
                value={this.state.password}
                onChange={(evt) => this.handlePasswordChange(evt.target.value)}
              />
            </Form.Field>
            <Button
              className="sumi-global-btn-color"
              style={{
                width: "calc(100%)",
                height: "50px",
                padding: "10px 20px",
                textAlign: "center",
                margin: "10px 0",
              }}
              onClick={() => {
                this.handleSignIn(this.state.merchant_id, this.state.password);
              }}
            >
              Sign In
            </Button>
            {this.props.errMsg ? (
              <Message negative>{this.props.errMsg}</Message>
            ) : null}
          </Form>
        </div>
        {/* </div> */}
      </div>
    );
  }
}

export default OwnerLoginPanel;
