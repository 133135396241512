const axios = require("axios");
const CONTACT_ROUTE_URL = "/api/contacts";

export default class Email_msmDb {
  /*
      merchantInfo:{
        storeName, ownerName, phone, email, message,isOwner
      }
  */
  static async sendMerchantContactInfoTo(contactInfo, recipients) {
    try {
      return (
        (await axios.post(`${CONTACT_ROUTE_URL}/merchant`, {
          contactInfo,
          recipients,
        })) || {}
      ).data;
    } catch (ex) {
      console.error(ex);
    }
  }

  static async sendCustomerInfoInfoTo(customerInfo, recipients, option) {
    try {
      return (
        (await axios.post(`${CONTACT_ROUTE_URL}/customerInfo`, {
          customerInfo,
          recipients,
          option,
        })) || {}
      ).data;
    } catch (ex) {
      console.error(ex);
    }
  }
}
