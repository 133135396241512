import React, { Component } from "react";
import { Header, Segment, Icon } from "semantic-ui-react";

class CategoryListPanel extends Component {
  handleCategoryClick = (category) => {
    this.props.onCategoryClick && this.props.onCategoryClick(category);
  };

  render() {
    const { categories, category_selected } = this.props;

    return (
      <Segment className="noselect menu-category-list" basic>
        <Header as="h3">Categories</Header>

        <div className="menu-category">
          {(categories || []).map((category, index) => {
            const selected =
              category_selected && category._id === category_selected._id;

            const { promoMethod, _id } = category;

            return (
              <a href={"#" + _id} key={index}>
                <Segment
                  basic
                  compact
                  className="goorder-online-category"
                  style={
                    selected
                      ? { borderBottom: "4px solid #db2828", color: "#db2828" }
                      : null
                  }
                  onClick={() => this.handleCategoryClick(category)}
                >
                  {category && category.name}
                  {promoMethod ? (
                    <Icon
                      name="bell outline"
                      style={{ margin: "0 2px" }}
                      size="small"
                    />
                  ) : null}
                </Segment>
              </a>
            );
          })}
        </div>
      </Segment>
    );
  }
}

export default CategoryListPanel;
