import React, { Component } from "react";
import {
  Segment,
  Modal,
  Header,
  Form,
  TextArea,
  Dimmer,
  Loader,
  Button,
} from "semantic-ui-react";

class ItemAddingModal extends Component {
  state = {
    name_required_show: false,
    category_required_show: false,
    price_required_show: false,
  };

  _isValid = (name) => {
    return this.props.item_added && this.props.item_added[name] != null;
  };

  handleClose = () => {
    this.props.onClose && this.props.onClose();

    this.setState({
      name_required_show: false,
      category_required_show: false,
      price_required_show: false,
    });
  };

  handleItemAddChange = (name, value) => {
    this.props.onItemAddChange && this.props.onItemAddChange(name, value);
  };

  handleItemCategoryChange = (category) => {
    this.props.onItemCategoryChange &&
      this.props.onItemCategoryChange(category);
  };

  handleItemAddSave = () => {
    if (
      !this._isValid("name") ||
      !this._isValid("_idCategory") ||
      !this._isValid("price")
    ) {
      return this.setState({
        name_required_show: true,
        category_required_show: true,
        price_required_show: true,
      });
    }

    this.props.onItemAddSave && this.props.onItemAddSave();

    this.setState({
      name_required_show: false,
      category_required_show: false,
      price_required_show: false,
    });
  };

  render() {
    const { item_added, allCategories, loading } = this.props;

    return (
      <Modal
        closeIcon
        dimmer={true}
        open={!!item_added}
        onClose={this.handleClose}
        className="goorder-online-itemDetail"
      >
        <Dimmer inverted active={!!loading}>
          <Loader size="huge" />
        </Dimmer>

        <Header as="h2" className="header">
          <Form>
            <Form.Group style={{ fontSize: "12px" }}>
              <Form.Input
                width={8}
                label="Item Name *"
                value={(item_added && item_added.name) || ""}
                onChange={(evt) => {
                  this.handleItemAddChange("name", evt.target.value);
                }}
                error={this.state.name_required_show && !this._isValid("name")}
                onBlur={() => {
                  this.setState({
                    name_required_show: true,
                  });
                }}
                onFocus={() => {
                  this.setState({
                    name_required_show: false,
                  });
                }}
              />
              <Form.Select
                style={{ minWidth: "0", fontSize: "12px" }}
                width={8}
                label="Category *"
                options={(allCategories || []).map((category, index) => {
                  return {
                    key: index,
                    text: category.name,
                    value: category._id,
                  };
                })}
                onChange={(evt, data) =>
                  this.handleItemCategoryChange(
                    (allCategories || []).find(
                      (cate) => cate._id === data.value
                    )
                  )
                }
                error={
                  this.state.category_required_show &&
                  !this._isValid("_idCategory")
                }
                onBlur={() => {
                  this.setState({
                    category_required_show: true,
                  });
                }}
                onFocus={() => {
                  this.setState({
                    category_required_show: false,
                  });
                }}
              />
            </Form.Group>

            <Form.Group style={{ fontSize: "12px" }}>
              <Form.Input
                width={8}
                label="Item Name(2nd)"
                value={(item_added && item_added.name_2nd) || ""}
                onChange={(evt) => {
                  this.handleItemAddChange("name_2nd", evt.target.value);
                }}
              />
              <Form.Input
                width={8}
                label="Unit Price *"
                value={
                  item_added && item_added.price != null ? item_added.price : ""
                }
                onChange={(evt) => {
                  const val = evt.target.value;
                  if (Number.isNaN(Number(val)) || Number(val) > 99999) {
                    return;
                  }
                  this.handleItemAddChange("price", evt.target.value);
                }}
                error={
                  this.state.price_required_show && !this._isValid("price")
                }
                onBlur={() => {
                  this.setState({
                    price_required_show: true,
                  });
                }}
                onFocus={() => {
                  this.setState({
                    price_required_show: false,
                  });
                }}
              />
            </Form.Group>

            {/* ----------------- item discription -------------------------------------- */}

            <TextArea
              label="Desciption"
              style={{ fontSize: "12px" }}
              rows="2"
              placeholder="Type description for this item here..."
              value={(item_added && item_added.discription) || ""}
              onChange={(evt) => {
                this.handleItemAddChange("discription", evt.target.value);
              }}
            />
          </Form>
        </Header>

        {/* ----------------------------  bottom --------------------- */}
        <Segment className="footer" raised>
          <Button
            className="sumi-global-btn-color"
            style={{
              width: "100px",
              height: "50px",
              margin: "10px 10px 10px 0",
              float: "right",
            }}
            onClick={this.handleItemAddSave}
          >
            Add
          </Button>
          <Button
            style={{
              width: "100px",
              height: "50px",
              margin: "10px 10px 10px 0",
              float: "right",
            }}
            onClick={this.handleClose}
          >
            Cancel
          </Button>
        </Segment>
      </Modal>
    );
  }
}

export default ItemAddingModal;
