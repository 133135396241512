import React, { Component } from "react";
import {
  Segment,
  Modal,
  Header,
  Form,
  Divider,
  TextArea,
  Dimmer,
  Loader,
  Icon,
  Button,
  Image,
} from "semantic-ui-react";

import { Slider } from "react-semantic-ui-range";
import AvatarEditor from "react-avatar-editor";
import Dropzone from "react-dropzone";

class ItemImageEditModal extends Component {
  fileInputRef = React.createRef();
  editor = null;

  state = {
    scale: 1.2,
    image: null,
  };

  setEditorRef = (editor) => (this.editor = editor);

  handleClose = () => {
    this.props.onClose && this.props.onClose();
  };

  handleDrop = (dropped) => {
    // this.props.onImageChange && this.props.onImageChange(dropped[0]);
    this.setState({
      image: dropped[0],
    });
  };

  handleImageChange = (image) => {
    // this.props.onItemImageChange && this.props.onItemImageChange(img);
    this.setState({
      image,
    });
  };

  //   handleSaveClick = () => {
  //     if (this.editor) {
  //       // This returns a HTMLCanvasElement, it can be made into a data URL or a blob,
  //       // drawn on another canvas, or added to the DOM.
  //       const canvas = this.editor.getImage();

  //       // If you want the image resized to the canvas size (also a HTMLCanvasElement)
  //       const url = this.editor.getImageScaledToCanvas().toDataURL();

  //       //   console.log(111, canvas);
  //       //   console.log(222, url);

  //       this.props.onItemImageSaveClick && this.props.onItemImageSaveClick(url);
  //     }
  //   };

  handleCancelClick = () => {
    this.props.onClose && this.props.onClose();
  };

  handleDoneClick = () => {
    if (this.editor) {
      // This returns a HTMLCanvasElement, it can be made into a data URL or a blob,
      // drawn on another canvas, or added to the DOM.
      const canvas = this.editor.getImage();

      // If you want the image resized to the canvas size (also a HTMLCanvasElement)
      const url = this.editor.getImageScaledToCanvas().toDataURL();

      //   console.log(111, canvas);
      //   console.log(222, url);

      this.props.onDoneClick && this.props.onDoneClick(url);
    }

    //this.props.onDoneClick && this.props.onDoneClick(this.state.image);
  };

  render() {
    const { open } = this.props;

    return (
      <Modal
        closeIcon
        dimmer={true}
        open={!!open}
        onClose={this.handleClose}
        className="goorder-online-itemDetail"
        style={{ textAlign: "center" }}
      >
        <Dropzone
          onDrop={this.handleDrop}
          noClick
          noKeyboard
          style={{ width: "250px", height: "250px" }}
        >
          {({ getRootProps, getInputProps }) => (
            <div {...getRootProps()}>
              <AvatarEditor
                ref={this.setEditorRef}
                style={{
                  width: "350px",
                  height: "350px",
                  padding: "20px",
                  display: "inline-block",
                  border: "1px solid #f1f1f1",
                }}
                //image="https://www.mikafrenchsushi.com/img/portfolio/tuna%20tartare-large.jpg"
                image={this.state.image}
                border={25}
                color={[255, 255, 255, 0.6]} // RGBA
                scale={this.state.scale}
                rotate={0}
              />
              <input {...getInputProps()} />
            </div>
          )}
        </Dropzone>

        <div className="item-list">
          <Segment basic style={{ margin: "0", height: null }}>
            <Button
              content="Upload image"
              labelPosition="left"
              icon="image"
              onClick={() => this.fileInputRef.current.click()}
              className="sumi-global-btn-color"
            />
            <input
              ref={this.fileInputRef}
              type="file"
              accept="image/png, image/jpeg, image/jpg"
              hidden
              onChange={(evt) => this.handleImageChange(evt.target.files[0])}
            />

            <Slider
              style={{ margin: "20px 0" }}
              color="red"
              inverted={false}
              settings={{
                start: this.state.scale,
                min: 1,
                max: 2.5,
                step: 0.01,
                onChange: (value) => {
                  this.setState({
                    scale: value,
                  });
                },
              }}
            />
          </Segment>
        </div>

        <Segment className="footer" raised>
          <Button
            className="sumi-global-btn-color"
            style={{
              width: "100px",
              height: "50px",
              margin: "10px 10px 10px 0",
              float: "right",
            }}
            onClick={this.handleDoneClick}
          >
            Done
          </Button>

          <Button
            style={{
              width: "100px",
              height: "50px",
              margin: "10px 10px 10px 0",
              float: "right",
            }}
            onClick={this.handleCancelClick}
          >
            Cancel
          </Button>
        </Segment>
      </Modal>
    );
  }
}

export default ItemImageEditModal;
