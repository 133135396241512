const axios = require("axios");

const TRANSACTION_ROUTE_URL = "/api/transactions";

export default class TransactionDb {
  static async getTransaction(merchantId, orderId) {
    try {
      if (!merchantId)
        return console.error("Invalid merchantId(getTransaction)");

      return (
        (await axios.get(
          `${TRANSACTION_ROUTE_URL}/merchantId/${merchantId}/orderId/${orderId}`
        )) || {}
      ).data;
    } catch (ex) {
      console.error(ex);
    }
  }

  static async voidTransaction(merchantId, transactionId) {
    try {
      if (!merchantId)
        return console.error("Invalid merchantId(updateTransactionAmt)");

      if (!transactionId)
        return console.error("Invalid transactionId(updateTransactionAmt)");

      return (
        (await axios.put(
          `${TRANSACTION_ROUTE_URL}/merchantId/${merchantId}/transactionId/${transactionId}/void`
        )) || {}
      ).data;
    } catch (ex) {
      console.error(ex);
    }
  }

  static async updateTransactionAmt(merchantId, transactionId, amount) {
    try {
      if (!merchantId)
        return console.error("Invalid merchantId(updateTransactionAmt)");

      if (!transactionId)
        return console.error("Invalid transactionId(updateTransactionAmt)");

      if (amount == null || amount < 0)
        return console.error("Invalid amount(updateTransactionAmt)");

      return (
        (await axios.put(
          `${TRANSACTION_ROUTE_URL}/merchantId/${merchantId}/transactionId/${transactionId}`,
          { amount }
        )) || {}
      ).data;
    } catch (ex) {
      console.error(ex);
    }
  }
}
