import CardPointeDb from "./cardPointeDb";
import TsysDb from "./tsysDb";
import AuthorizeNetDb from "./authorizeNetDb";
import ElavonDb from "./elavonDb";
import Tsys2Db from "./tsys2Db";
import BusinessInfoDb from "./businessInfoDb";

/*
    auth, adjust, void, settle
*/
export default class ECommDb {
  /*
    creditcardInfo: {
      acount: String,
      expiry: String,
      cvv2: String,
      postal: String,
      billingAddr:String
    };

    cardPointe: return: {
            approved:Boolean,
            amount:Number,
            merchantId:String
            cardNumber:String,
            cardExpiry:String,
            cardType:String,
            authCode:String,
            retref:String
        }

        tips_optional: for tssys2 tips adjustment use

  */
  static async authorizePayment(
    merchantId,
    amount,
    creditcardInfo,
    recaptchaToken
  ) {
    try {
      const businessInfo = await BusinessInfoDb.getBusinessInfo(merchantId);
      if (!businessInfo)
        return console.error("Invalid businessInfo fetched(authorizePayment)");

      const ecomm_provider = businessInfo.ecomm_provider;
      console.log(123, ecomm_provider);
      if (!ecomm_provider)
        return console.error(
          "No Ecomm provider specified for the merchant(authorizePayment)"
        );

      if (ecomm_provider === "cardPointe") {
        return await CardPointeDb.authorizePayment(
          merchantId,
          amount,
          creditcardInfo,
          recaptchaToken
        );
      } else if (ecomm_provider === "tsys") {
        return await TsysDb.authorizePayment(
          merchantId,
          amount,
          creditcardInfo,
          recaptchaToken
        );
      } else if (ecomm_provider === "authorizeNet") {
        return await AuthorizeNetDb.authorizePayment(
          merchantId,
          amount,
          creditcardInfo
        );
      } else if (ecomm_provider === "elavon") {
        return await ElavonDb.sale(merchantId, amount, creditcardInfo);
      } else if (ecomm_provider === "tsys2") {
        console.log(123);
        return await Tsys2Db.sale(
          merchantId,
          amount,
          creditcardInfo,
          recaptchaToken
        );
      } else {
        return console.error("Unrecognized Ecomm provider(authorizePayment)");
      }
    } catch (ex) {
      console.error(ex);
    }
  }

  static async adjustPayment(merchantId, amount, retref, adjustedTips) {
    try {
      const businessInfo = await BusinessInfoDb.getBusinessInfo(merchantId);
      if (!businessInfo)
        return console.error("Invalid businessInfo fetched(adjustPayment)");

      const ecomm_provider = businessInfo.ecomm_provider;
      if (!ecomm_provider)
        return console.error(
          "No Ecomm provider specified for the merchant(adjustPayment)"
        );

      if (ecomm_provider === "cardPointe") {
        return await CardPointeDb.capturePayment(merchantId, amount, retref);
      } else if (ecomm_provider === "authorizeNet") {
        return await AuthorizeNetDb.capturePayment(merchantId, amount, retref);
      } else if (ecomm_provider === "tsys2") {
        return await Tsys2Db.adjustTip(merchantId, adjustedTips, retref);
      } else if (ecomm_provider === "tsys") {
        // todo
      } else {
        return console.error("Unrecognized Ecomm provider(adjustPayment)");
      }
    } catch (ex) {
      console.error(ex);
    }
  }

  static async voidPayment(merchantId, retref) {
    try {
      const businessInfo = await BusinessInfoDb.getBusinessInfo(merchantId);
      if (!businessInfo)
        return console.error("Invalid businessInfo fetched(adjustPayment)");

      const ecomm_provider = businessInfo.ecomm_provider;
      if (!ecomm_provider)
        return console.error(
          "No Ecomm provider specified for the merchant(adjustPayment)"
        );

      if (ecomm_provider === "cardPointe") {
        return await CardPointeDb.voidPayment(merchantId, retref);
      } else if (ecomm_provider === "authorizeNet") {
        return await AuthorizeNetDb.voidPayment(merchantId, retref);
      } else if (ecomm_provider === "tsys") {
        return await TsysDb.voidPayment(merchantId, retref);
      } else if (ecomm_provider === "tsys2") {
        return await Tsys2Db.void(merchantId, retref);
      } else {
        return console.error("Unrecognized Ecomm provider(voidPayment)");
      }
    } catch (ex) {
      console.error(ex);
    }
  }

  static async settlePayment(merchantId, retref) {}
}
