import React, { Component } from "react";
import {
  Button,
  Header,
  List,
  Segment,
  Divider,
  Form,
  TextArea,
  Popup,
  Modal,
  Input,
  Icon,
  Checkbox,
  Image,
  Transition,
} from "semantic-ui-react";

import { ReactComponent as SpicyLogo } from "../imgs/chilli.svg";
import { ReactComponent as RawLogo } from "../imgs/meat.svg";
import { ReactComponent as VeganLogo } from "../imgs/leaf.svg";

import {
  formatPrice,
  checkIfWithinBusinessHour2,
  getAvailableHourStr,
  checkItemOptionMeetsRequirement,
} from "../dataModel/utils";

import MenuDb from "./../dataModel/menuDb";

class ItemListPanel extends Component {
  constructor(props) {
    super(props);
    this.itemInfoDiv = React.createRef();
  }
  state = {
    addToCartLoading: false,
    itemInfoHeight: 50,
  };

  startLoading = () => {
    this.setState({
      addToCartLoading: true,
    });
  };

  endLoading = () => {
    this.setState({
      addToCartLoading: false,
    });
  };

  handleItemModalClose = () => {
    this.props.onItemModalClose && this.props.onItemModalClose();
  };

  handleItemClick = (item) => {
    this.props.onItemClick && this.props.onItemClick(item);
  };

  handleItemQtyChange = (value) => {
    this.props.onItemQtyChange && this.props.onItemQtyChange(value);
  };

  handleItemNoteChange = (value) => {
    this.props.onItemNoteChange && this.props.onItemNoteChange(value);
  };

  handleItemSizeChange = (checked, itemSize) => {
    this.props.onItemSizeChange &&
      this.props.onItemSizeChange(checked, itemSize);
  };

  handleAddToCartClick = async () => {
    if (this.state.addToCartLoading) {
      return;
    }

    const {
      itemSizes,
      itemSize_selected,
      itemOption_selected,
      itemOptions,
      item_selected,
      merchantId,
    } = this.props;
    const needSize = itemSizes && itemSizes.length > 0;
    const missingSize = needSize && !itemSize_selected;
    const missOptions = !checkItemOptionMeetsRequirement(
      itemOption_selected,
      itemOptions
    );
    if (!!missingSize || !!missOptions) {
      return;
    }

    let orderItemOptions = [];
    for (let _idItemOption in itemOption_selected || {}) {
      const optionVals = itemOption_selected[_idItemOption];
      orderItemOptions = orderItemOptions.concat(optionVals || []);
    }

    orderItemOptions.sort((a, b) =>
      a.itemOption_order_id > b.itemOption_order_id ? 1 : -1
    );
    // available hour

    this.startLoading();
    let category_selected = await MenuDb.getCategoryById(
      merchantId,
      item_selected && item_selected._idCategory
    );
    this.endLoading();

    // let category_selected =
    //   this.props.category || this.props.item_selected._idCategory;

    const orderedItem = {
      _id: this.props.item_selected && this.props.item_selected._id,
      _idCategory:
        this.props.item_selected && this.props.item_selected._idCategory,
      _idPrinter:
        this.props.item_selected && this.props.item_selected._idPrinter,
      _idPrinters:
        this.props.item_selected && this.props.item_selected._idPrinters,
      name: this.props.item_selected && this.props.item_selected.name,
      name_2nd: this.props.item_selected && this.props.item_selected.name_2nd,
      price: this.props.itemSize_selected
        ? this.props.itemSize_selected.price
        : this.props.item_selected && this.props.item_selected.price,
      qty: this.props.itemQty,
      orderItemOptions: orderItemOptions,
      size: this.props.itemSize_selected && this.props.itemSize_selected.name,
      size_2nd:
        this.props.itemSize_selected && this.props.itemSize_selected.name_2nd,
      note: this.props.itemNote,
      availableHour: category_selected && category_selected.availableHour,
    };

    //console.log(123, category_selected && category_selected.availableHour);

    this.props.onAddToCartClick && this.props.onAddToCartClick(orderedItem);
  };

  handleItemOptionChange = (
    checked,
    optionValue,
    _idItemOption,
    numberToSelect,
    minNumberToSelect,
    maxNumberToSelect
  ) => {
    this.props.onItemOptionChange &&
      this.props.onItemOptionChange(
        checked,
        optionValue,
        _idItemOption,
        numberToSelect,
        minNumberToSelect,
        maxNumberToSelect
      );
  };

  handleItemOptionDecreaseClick = (
    optionValue,
    _idItemOption,
    numberToSelect,
    minNumberToSelect,
    maxNumberToSelect
  ) => {
    this.props.onItemOptionDecreaseClick &&
      this.props.onItemOptionDecreaseClick(
        optionValue,
        _idItemOption,
        numberToSelect,
        minNumberToSelect,
        maxNumberToSelect
      );
  };

  handleItemOptionIncreaseClick = (
    optionValue,
    _idItemOption,
    numberToSelect,
    minNumberToSelect,
    maxNumberToSelect
  ) => {
    this.props.onItemOptionIncreaseClick &&
      this.props.onItemOptionIncreaseClick(
        optionValue,
        _idItemOption,
        numberToSelect,
        minNumberToSelect,
        maxNumberToSelect
      );
  };
  handleToggleListClick = (optionID) => {
    this.props.onToggleListClick && this.props.onToggleListClick(optionID);
  };
  render() {
    const {
      // category: category_selected, // not in use
      // items, // not in use, use itemsGroupbyCategory instead
      itemsGroupbyCategory,
      item_selected,
      itemOptions,
      itemSizes, // [{name:"Large", price: 2.99}]
      itemQty,
      // itemOptionVals_selected,
      itemOption_selected,
      itemSize_selected, //{name:"Large", price: 2.99}
      itemNote,
      offBusinessHour,
      businessInfo,
      loading,
    } = this.props;

    const {
      showRawItemNote,
      showSpicyItemNote,
      showVeganItemNote,
      showSecondLanguage,
      hideItemSpecialInstructionInput,
    } = businessInfo || {};

    // console.log(123, item_selected);

    // const showSecondLanguage = true;

    let itemTotalPrice = 0;
    if (item_selected) {
      itemTotalPrice += itemSize_selected
        ? itemSize_selected.price
        : item_selected.price;

      for (let _idItemOption in itemOption_selected || {}) {
        const optionVals = itemOption_selected[_idItemOption];
        (optionVals || []).forEach((ov) => {
          itemTotalPrice += ov.price;
        });
      }

      // (itemOptionVals_selected || []).forEach((ov) => {
      //   itemTotalPrice += ov.price;
      // });
    }

    const needSize = itemSizes && itemSizes.length > 0;
    const missingSize = needSize && !itemSize_selected;
    const missOptions = !checkItemOptionMeetsRequirement(
      itemOption_selected,
      itemOptions
    );

    // const isDesktopScreenSize = useMediaQuery({ minWidth: 1280 });
    // const isTabletScreenSize = useMediaQuery({ minWidth: 920, maxWidth: 1280 });
    // const isMobileScreenSize = useMediaQuery({ maxWidth: 920 });

    itemTotalPrice = itemTotalPrice * Number(itemQty || 0);

    // ------------------------------------ item segments -------------------------------------
    const itemsByCate = (itemsGroupbyCategory || []).map(
      ({ category, items }, index) => {
        if (!category) return;

        const {
          _id,
          name,
          name_2nd,
          availableHour,
          promoMethod,
          description: cate_description,
        } = category;

        // check if category is available
        const categoryAvaiable = checkIfWithinBusinessHour2(
          availableHour,
          this.props.scheduleDate
        );

        const itemSegments = (items || []).map((item, index) => {
          if (!item) return;

          const {
            _idCategory,
            name,
            name_2nd,
            price,
            discription,
            raw_sign,
            spicy_sign,
            vegan_sign,
            imgUrl,
            soldout,
          } = item;

          return (
            <Segment
              disabled={!categoryAvaiable || soldout}
              key={index}
              size="big"
              textAlign="left"
              className="noselect menu-item"
              onClick={() =>
                categoryAvaiable && !soldout && this.handleItemClick(item)
              }
              // raised
              style={{ boxShadow: "none" }}
            >
              <div
                style={{
                  display: "flex",
                  height: "100%",
                  position: "relative",
                }}
              >
                <div
                  style={{
                    width: imgUrl ? "calc(100% - 120px)" : "100%",
                    padding: "11px",
                  }}
                >
                  <div className="item_name">
                    {name}

                    {soldout ? (
                      <span
                        style={{
                          fontSize: "40px",
                          position: "absolute",
                          left: "calc(50% - 80px)",
                          top: "calc(50% - 13px)",
                          zIndex: "1",
                        }}
                      >
                        {/* <Icon name="info circle" /> */}
                        SOLD OUT
                      </span>
                    ) : null}

                    {showSecondLanguage && name_2nd ? (
                      <span
                        style={{
                          fontSize: "13px",
                          fontWeight: "400",
                          color: "rgb(135, 145, 157)",
                          display: "block",
                        }}
                      >
                        {name_2nd}
                      </span>
                    ) : null}
                  </div>

                  <span className="item-discription">{discription}</span>

                  <span className="item_price">
                    ${formatPrice(price)}
                    {!spicy_sign || !showSpicyItemNote ? null : (
                      <SpicyLogo
                        style={{
                          width: "15px",
                          height: "15px",
                          display: "inline",
                          marginLeft: "10px",
                        }}
                      />
                    )}
                    {!raw_sign || !showRawItemNote ? null : (
                      <RawLogo
                        style={{
                          width: "15px",
                          height: "15px",
                          display: "inline",
                          marginLeft: "10px",
                        }}
                      />
                    )}
                    {!vegan_sign || !showVeganItemNote ? null : (
                      <VeganLogo
                        style={{
                          width: "15px",
                          height: "15px",
                          display: "inline",
                          marginLeft: "10px",
                        }}
                      />
                    )}
                  </span>
                </div>

                {imgUrl ? (
                  <div style={{ width: "123px", height: "100%" }}>
                    <Image
                      src={imgUrl}
                      size="small"
                      style={{ width: "100%", height: "100%" }}
                      // style={{ height: "100%", width: "auto" }}
                      onError={(i) => (i.target.style.display = "none")}
                    />
                  </div>
                ) : null}
              </div>
            </Segment>
          );
        });

        return (
          <div
            key={index}
            id={_id}
            className="items-by-category"
            style={{
              marginBottom: "30px",
              paddingTop: "120px",
              marginTop: "-120px",
              display: itemSegments.length > 0 ? "inherit" : "none",
            }}
          >
            <Header as="h2">
              <span className="category_name">{name}</span>

              {/* {showSecondLanguage && name_2nd ? (
                <span
                  style={{
                    fontSize: "13px",
                    fontWeight: "400",
                    color: "rgb(135, 145, 157)",
                    display: "block",
                  }}
                >
                  {name_2nd}
                </span>
              ) : null} */}

              {!categoryAvaiable ? (
                <span className="category_name_availablity">
                  <Icon name="info circle" />
                  Available hours: {getAvailableHourStr(availableHour)}
                </span>
              ) : null}

              {promoMethod && promoMethod.name ? (
                <span className="category_name_availablity">
                  <Icon name="bell outline" />
                  {promoMethod.name}
                </span>
              ) : null}

              {cate_description ? (
                <div>
                  <span className="category-discription">
                    {cate_description}
                  </span>
                </div>
              ) : null}
            </Header>

            {itemSegments}
          </div>
        );
      }
    );
    const selcectedItemOptions = [];
    for (let _idItemOption in itemOption_selected || {}) {
      const optionVals = itemOption_selected[_idItemOption];
      (optionVals || []).forEach((ov) => {
        // itemTotalPrice += ov.price;
        selcectedItemOptions.push({ name: ov.name, name_2nd: ov.name_2nd });
      });
    }
    const itemInfoHeight = this.itemInfoDiv.current
      ? this.itemInfoDiv.current.offsetHeight.toString()
      : "50px";
    return (
      <Segment
        basic
        textAlign="left"
        // style={{ height: "100%", width: "100%", margin: "0", padding: "20px" }}
        className="menu-item-list"
      >
        <div style={{ height: "100%" }}>
          {/* -------------------------------  items panel ----------------------------- */}
          {itemsByCate}

          {loading ? (
            <div
              style={{
                color: "#003162",
                fontWeight: "600",
                textAlign: "center",
              }}
            >
              <Icon name="sync alternate" loading={true} /> Loading more items
              for you...
            </div>
          ) : null}

          <div
            style={{
              margin: "20px 0",
              fontFamily: `DD-TTNorms, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"`,
              fontSize: "10px",
              fontWeight: "500",
            }}
          >
            {showSpicyItemNote ? (
              <div>
                <SpicyLogo
                  style={{
                    width: "15px",
                    height: "15px",
                    display: "inline",
                    marginRight: "5px",
                  }}
                />
                : Spicy
              </div>
            ) : null}

            {showVeganItemNote ? (
              <div>
                <VeganLogo
                  style={{
                    width: "15px",
                    height: "15px",
                    display: "inline",
                    marginRight: "5px",
                  }}
                />
                : Vegan
              </div>
            ) : null}

            {showRawItemNote ? (
              <div>
                <RawLogo
                  style={{
                    width: "15px",
                    height: "15px",
                    display: "inline",
                    marginRight: "5px",
                    lineHeight: "12px",
                  }}
                />
                : Raw/undercooked. Please notice that consuming raw or
                undercooked meats, poultry, seafood, shellfish, or eggs may
                increase your risk for foodborne illness
              </div>
            ) : null}
          </div>
        </div>

        {/* ------------------------------  item detail panel ----------------------------- */}
        <Modal
          // closeIcon
          open={!!item_selected}
          onClose={this.handleItemModalClose}
          className="goorder-online-itemDetail"
        >
          <div className="item-info" ref={this.itemInfoDiv}>
            <div className="item-name">
              <Icon
                name="close"
                onClick={this.handleItemModalClose}
                style={{ marginRight: "10px", cursor: "pointer" }}
              />
              {item_selected && item_selected.name}{" "}
              {itemSize_selected && itemSize_selected.name
                ? `(${itemSize_selected.name})`
                : null}
              {showSecondLanguage && item_selected && item_selected.name_2nd ? (
                <span
                  style={{
                    fontSize: "13px",
                    fontWeight: "400",
                    color: "rgb(135, 145, 157)",
                    margin: "0 10px",
                    display: "inline",
                  }}
                >
                  {item_selected.name_2nd}
                  {showSecondLanguage &&
                  itemSize_selected &&
                  itemSize_selected.name_2nd ? (
                    <span
                      style={{
                        fontSize: "13px",
                        fontWeight: "400",
                        color: "rgb(135, 145, 157)",
                        display: "inline",
                      }}
                    >
                      ({itemSize_selected.name_2nd})
                    </span>
                  ) : null}
                </span>
              ) : null}
              {/* <Divider style={{ margin: "5px 0", padding: "0" }} /> */}
            </div>
            {/* <div className="item-option">
              <div
                style={{
                  fontSize: "13px",
                  lineHeight: "15px",
                }}
              >
                {itemSize_selected && itemSize_selected.name}
                {showSecondLanguage &&
                itemSize_selected &&
                itemSize_selected.name_2nd ? (
                  <span
                    style={{
                      fontSize: "13px",
                      fontWeight: "400",
                      color: "rgb(135, 145, 157)",
                      margin: "0 10px",
                      display: "inline",
                    }}
                  >
                    {itemSize_selected.name_2nd}
                  </span>
                ) : null}
              </div>
            </div> */}
            <div className="item-option">
              {selcectedItemOptions.map((option, index) => {
                return (
                  <div
                    key={index + "_options"}
                    style={{
                      fontSize: "13px",
                      lineHeight: "15px",
                    }}
                  >
                    {option.name}
                    {showSecondLanguage && option && option.name_2nd ? (
                      <span
                        style={{
                          fontSize: "10px",
                          fontWeight: "200",
                          color: "rgb(135, 145, 157)",
                          margin: "0 10px",
                          display: "inline",
                        }}
                      >
                        {option.name_2nd}
                      </span>
                    ) : null}
                  </div>
                );
              })}
            </div>
          </div>

          {/* <div className="item-list" style={{ top: itemInfoHeight + "px" }}> */}
          <div className="item-list">
            {/* ----------------- item discription -------------------------------------- */}
            {item_selected && item_selected.discription ? (
              <span className="item-discription">
                {item_selected.discription}
              </span>
            ) : null}

            {item_selected && item_selected.imgUrl ? (
              <div className="item-image">
                <Image
                  className="item-image"
                  src={item_selected.imgUrl}
                  size="small"
                  style={{ width: "100%", height: "100%", objectFit: "cover" }}
                  onError={(i) => (i.target.style.display = "none")}
                />
              </div>
            ) : null}
            {/* ----------------- item sizes -------------------------------------- */}
            {!needSize ? null : (
              <Segment basic style={{ margin: "0", height: null }}>
                <React.Fragment>
                  <Header as="h4">
                    Choose{" "}
                    {item_selected && item_selected.size_alias
                      ? item_selected.size_alias
                      : "Size"}{" "}
                    <span
                      style={{
                        fontSize: "13px",
                        color: "rgb(96 96 96)",
                        margin: "10px 15px",
                        lineHeight: "15px",
                        backgroundColor: "yellow",
                        fontFamily:
                          "Lato,'Helvetica Neue',Arial,Helvetica,sans-serif",
                      }}
                    >
                      Required
                    </span>
                  </Header>
                  <Divider />
                  <List divided relaxed size="big" style={{ marginTop: "0" }}>
                    {(itemSizes || []).map((itemSize, sizeIndex) => {
                      const { name, name_2nd: sizeName_2nd, price } = itemSize;
                      return (
                        <List.Item key={sizeIndex}>
                          <Checkbox
                            name="itemSize"
                            radio
                            checked={
                              !!(
                                itemSize_selected &&
                                itemSize_selected.name === name
                              )
                            }
                            style={{
                              width: "100%",
                              fontSize: "16px",
                              padding: "5px 10px",
                              fontFamily: `"Rubik", sans-serif`,
                            }}
                            label={
                              <label>
                                {name}
                                {showSecondLanguage && sizeName_2nd ? (
                                  <span
                                    style={{
                                      fontSize: "14px",
                                      fontWeight: "400",
                                      color: "rgb(135, 145, 157)",
                                      margin: "10px",
                                    }}
                                  >
                                    {sizeName_2nd}
                                  </span>
                                ) : null}
                                <span style={{ float: "right" }}>${price}</span>
                              </label>
                            }
                            onChange={(e, { checked }) =>
                              this.handleItemSizeChange(checked, itemSize)
                            }
                          />
                        </List.Item>
                      );
                    })}
                  </List>
                </React.Fragment>
              </Segment>
            )}
            {/* ----------------- item options -------------------------------------- */}
            {!itemOptions || itemOptions.length <= 0 ? null : (
              <Segment basic style={{ margin: "0" }}>
                <React.Fragment>
                  <Header as="h4" style={{ marginBottom: "0" }}>
                    Choose available options
                  </Header>
                  <List divided relaxed size="big" style={{ marginTop: "0" }}>
                    {(itemOptions || []).map((itemOption, optionIndex) => {
                      const {
                        _id: _idItemOption,
                        name,
                        name_2nd: optionName_2nd,
                        numberToSelect,
                        optionValues,
                        itemOption_order_id,
                        minNumberToSelect,
                        maxNumberToSelect,
                      } = itemOption;

                      let min, max;
                      if (
                        minNumberToSelect != null &&
                        maxNumberToSelect != null
                      ) {
                        min = minNumberToSelect;
                        max = maxNumberToSelect;
                      } else if (minNumberToSelect) {
                        min = minNumberToSelect;
                        max =
                          maxNumberToSelect != null
                            ? maxNumberToSelect
                            : Number.MAX_SAFE_INTEGER;
                      } else if (maxNumberToSelect) {
                        min = minNumberToSelect != null ? minNumberToSelect : 0;
                        max = maxNumberToSelect;
                      } else {
                        min = numberToSelect;
                        max =
                          numberToSelect == 0
                            ? Number.MAX_SAFE_INTEGER
                            : numberToSelect;
                      }

                      const required = min > 0;
                      const requiredMsg = required
                        ? min === max
                          ? `Any ${min} required`
                          : max == Number.MAX_SAFE_INTEGER
                          ? `At least ${min} required`
                          : `Any ${min} required. Up to ${max}`
                        : max > 0 && max != Number.MAX_SAFE_INTEGER
                        ? `Up to ${max}`
                        : null;

                      return (
                        <React.Fragment key={optionIndex}>
                          <List.Item style={{ paddingTop: "10px" }}>
                            <Header as="h2">
                              <span className="item-option-name">{name}</span>
                              {showSecondLanguage && optionName_2nd ? (
                                <span
                                  style={{
                                    fontSize: "13px",
                                    fontWeight: "400",
                                    color: "rgb(135, 145, 157)",
                                    margin: "10px",
                                  }}
                                >
                                  {optionName_2nd}
                                </span>
                              ) : null}

                              {requiredMsg ? (
                                <span
                                  style={{
                                    fontSize: "13px",
                                    color: "rgb(96 96 96)",
                                    margin: "10px 15px",
                                    lineHeight: "15px",
                                    backgroundColor: "yellow",
                                  }}
                                >
                                  {requiredMsg}
                                </span>
                              ) : null}
                            </Header>
                          </List.Item>
                          {(optionValues || []).map(
                            (optionValue, optionValIndex) => {
                              const {
                                name,
                                name_2nd: optionValName_2nd,
                                price,
                                _id,
                              } = optionValue;

                              optionValue["itemOption_order_id"] =
                                itemOption_order_id;
                              const checked = !!(
                                itemOption_selected &&
                                (itemOption_selected[_idItemOption] || []).find(
                                  (optionVal) => optionVal._id === _id
                                )
                              );

                              let qtySelected = 0;
                              itemOption_selected &&
                                (
                                  itemOption_selected[_idItemOption] || []
                                ).forEach((optionVal) => {
                                  if (optionVal._id === _id) {
                                    qtySelected++;
                                  }
                                });

                              if (
                                !this.props.toogleList[itemOption._id] &&
                                optionValIndex >= 5
                              ) {
                                return null;
                              }

                              return (
                                <List.Item
                                  key={optionIndex + "_" + optionValIndex}
                                >
                                  <Checkbox
                                    name="itemSize"
                                    style={{
                                      width: "100%",
                                      fontSize: "16px",
                                      padding: "5px 10px",
                                    }}
                                    label={
                                      <label>
                                        {name}
                                        {showSecondLanguage &&
                                        optionValName_2nd ? (
                                          <span
                                            style={{
                                              fontSize: "14px",
                                              fontWeight: "400",
                                              color: "rgb(135, 145, 157)",
                                              margin: "10px",
                                            }}
                                          >
                                            {optionValName_2nd}
                                          </span>
                                        ) : null}

                                        <span
                                          style={{
                                            float: "right",
                                            fontFamily: `"Rubik", sans-serif`,
                                          }}
                                        >
                                          +${price}
                                        </span>
                                      </label>
                                    }
                                    checked={
                                      qtySelected && qtySelected > 0
                                        ? true
                                        : false
                                    }
                                    onChange={(evt, { checked }) => {
                                      this.handleItemOptionChange(
                                        checked,
                                        optionValue,
                                        _idItemOption,
                                        numberToSelect,
                                        min,
                                        max
                                      );
                                      evt.stopPropagation();
                                    }}
                                  />

                                  <Transition
                                    visible={
                                      !!(
                                        ((numberToSelect != null &&
                                          numberToSelect > 1) ||
                                          !numberToSelect) &&
                                        qtySelected &&
                                        qtySelected > 0
                                      )
                                    }
                                    animation="fade down"
                                    duration={500}
                                  >
                                    <div style={{ marginLeft: "30px" }}>
                                      <span style={{ margin: "0 10px" }}>
                                        ×{qtySelected}
                                      </span>
                                      <Button.Group>
                                        <Button
                                          size="mini"
                                          className="sumi-global-btn-color"
                                          style={{
                                            fontSize: "18px",
                                            padding: "0 15px",
                                            height: "20px",
                                          }}
                                          onClick={(evt) => {
                                            this.handleItemOptionDecreaseClick(
                                              optionValue,
                                              _idItemOption,
                                              numberToSelect,
                                              min,
                                              max
                                            );
                                            evt.stopPropagation();
                                          }}
                                        >
                                          -
                                        </Button>
                                        <Button
                                          size="mini"
                                          className="sumi-global-btn-color"
                                          style={{
                                            fontSize: "18px",
                                            padding: "0 15px",
                                            height: "20px",
                                          }}
                                          onClick={(evt) => {
                                            this.handleItemOptionIncreaseClick(
                                              optionValue,
                                              _idItemOption,
                                              numberToSelect,
                                              min,
                                              max
                                            );
                                            evt.stopPropagation();
                                          }}
                                        >
                                          +
                                        </Button>
                                      </Button.Group>
                                    </div>
                                  </Transition>
                                </List.Item>
                              );
                            }
                          )}
                          {this.props.toogleList[itemOption._id] ? (
                            optionValues.length < 6 ? null : (
                              <List.Item
                                style={{ textAlign: "center" }}
                                onClick={() =>
                                  this.handleToggleListClick(itemOption._id)
                                }
                              >
                                <span
                                  style={{
                                    color: "#003162",
                                    fontSize: "15px",
                                    cursor: "pointer",
                                  }}
                                >
                                  Show Less
                                  <Icon
                                    name="chevron circle up"
                                    style={{ margin: "0 5px" }}
                                  />
                                </span>
                              </List.Item>
                            )
                          ) : optionValues.length < 6 ? null : (
                            <List.Item
                              style={{ textAlign: "center" }}
                              onClick={() =>
                                this.handleToggleListClick(itemOption._id)
                              }
                            >
                              <span
                                style={{
                                  color: "#003162",
                                  fontSize: "15px",
                                  cursor: "pointer",
                                }}
                              >
                                Show More ...
                                <Icon
                                  name="chevron circle down"
                                  style={{ margin: "0 5px" }}
                                />
                              </span>
                            </List.Item>
                          )}
                        </React.Fragment>
                      );
                    })}
                  </List>
                </React.Fragment>
              </Segment>
            )}

            <Form style={{ margin: "12px 12px 90px" }}>
              <Header as="h3">Special instructions</Header>
              <div
                style={{
                  margin: "10px 0",
                  fontSize: "12px",
                  color: "#a5643a",
                }}
              >
                <span>
                  <Icon name="info circle" />
                  Please notice that the instruction may need extra cost or not
                  be applied.
                </span>
              </div>
              <TextArea
                placeholder={
                  !!hideItemSpecialInstructionInput
                    ? "Please contact Merchant directly if you have any special instructions"
                    : "Eg: No spicy No peanut. Please note adding options may cost extra!"
                }
                value={itemNote}
                onChange={(evt) => this.handleItemNoteChange(evt.target.value)}
                disabled={!!hideItemSpecialInstructionInput}
              />
            </Form>
          </div>

          {/* ----------------------------  bottom --------------------- */}
          <Segment className="footer" raised>
            <Segment
              basic
              textAlign="center"
              style={{
                margin: "10px",
                padding: "0",
                display: "inline-block",
                width: "calc(50% - 20px)",
              }}
              className="noselect"
            >
              <Icon
                name="minus"
                size="large"
                onClick={() =>
                  itemQty > 1 && this.handleItemQtyChange(itemQty - 1)
                }
                style={{ cursor: "pointer" }}
              />
              <Input
                size="large"
                className="itemQty"
                value={itemQty}
                style={{
                  width: "55px",
                  height: "50px",
                  margin: "0 5px",
                }}
              />
              <Icon
                name="add"
                size="large"
                onClick={() => this.handleItemQtyChange(itemQty + 1)}
                style={{ cursor: "pointer" }}
              />
            </Segment>

            <Popup
              on="click"
              position="top center"
              pinned
              inverted
              disabled={!missingSize && !missOptions}
              content={
                missingSize
                  ? "Item size is required."
                  : missOptions
                  ? "Exact number of option needs to be chosen."
                  : null
              }
              trigger={
                <Button
                  className="sumi-global-btn-color"
                  style={{
                    width: "calc(50% - 20px)",
                    height: "50px",
                    margin: "10px",
                    fontSize: "12px",
                    fontFamily: `"Rubik", sans-serif`,
                  }}
                  onClick={this.handleAddToCartClick}
                >
                  {this.state.addToCartLoading ? (
                    <Icon loading name="spinner" />
                  ) : null}
                  Add to Cart ${formatPrice(itemTotalPrice)}
                </Button>
              }
            />
          </Segment>
        </Modal>
      </Segment>
    );
  }
}

export default ItemListPanel;
