import { getDistance, convertDistance } from "geolib";
const axios = require("axios");
const UTIL_ROUTE_URL = "/api/utils";
// const distance = require("google-distance");

export function formatDateMonthDay(inputDate) {
  try {
    if (!inputDate)
      return console.error(
        "Invalid date provided when formatting dateMonthDay"
      );
    const date = inputDate instanceof Date ? inputDate : new Date(inputDate);
    return date.getMonth() + 1 + "/" + date.getDate();
  } catch (ex) {
    console.error(ex);
  }
}

// "02/08/2020"
export function formatDateMonthDayYear(inputDate) {
  try {
    if (!inputDate)
      return console.error(
        "Invalid date provided when formatting dateMonthDay"
      );
    const date = inputDate instanceof Date ? inputDate : new Date(inputDate);
    return (
      ("0" + (date.getMonth() + 1)).slice(-2) +
      "/" +
      ("0" + date.getDate()).slice(-2) +
      "/" +
      date.getFullYear()
    );
  } catch (ex) {
    console.error(ex);
  }
}

export function getDayName(inputDate) {
  try {
    if (!inputDate)
      return console.error("Invalid date provided when getting dayName");

    const dayNames = ["Sun", "Mon", "Tues", "Wed", "Thur", "Fri", "Sat"];
    const date = inputDate instanceof Date ? inputDate : new Date(inputDate);

    return dayNames[date.getDay()];
  } catch (ex) {
    console.error(ex);
  }
}

export function formatDateHourMin(inputDate) {
  try {
    if (!inputDate)
      return console.error(
        "Invalid date provided when formatting dateMonthDay"
      );
    const date = inputDate instanceof Date ? inputDate : new Date(inputDate);
    const minStr =
      date.getMinutes() < 10 ? "0" + date.getMinutes() : date.getMinutes() + "";
    const hourStr = date.getHours() + "";
    return hourStr + ":" + minStr;
  } catch (ex) {
    console.error(ex);
  }
}

export function formatDateHourMinSec(inputDate) {
  try {
    if (!inputDate)
      return console.error(
        "Invalid date provided when formatting dateMonthDay"
      );
    const date = inputDate instanceof Date ? inputDate : new Date(inputDate);
    return date.getHours() + ":" + date.getMinutes() + ":" + date.getSeconds();
  } catch (ex) {
    console.error(ex);
  }
}

export function formatPhoneNumber(inputPhone) {
  try {
    if (!inputPhone || inputPhone.length === 0) return inputPhone;

    const length = inputPhone.length;
    if (length <= 3) return "(" + inputPhone;
    if (length <= 6)
      return (
        "(" + inputPhone.substring(0, 3) + ")" + inputPhone.substring(3, length)
      );
    if (length <= 10)
      return (
        "(" +
        inputPhone.substring(0, 3) +
        ")" +
        inputPhone.substring(3, 6) +
        "-" +
        inputPhone.substring(6, length)
      );
    else
      return (
        "(" +
        inputPhone.substring(0, 3) +
        ")" +
        inputPhone.substring(3, 6) +
        "-" +
        inputPhone.substring(6, 10)
      );
  } catch (ex) {
    console.error(ex);
  }
}

/*
  return:{
    days:Number, hours:Number, minutes:Number }
  }
*/
export function getDiffBetweenTwoDates(dateStr1, dateStr2) {
  if (dateStr1 == null || dateStr2 == null) {
    return null;
  }

  const date1 = dateStr1 instanceof Date ? dateStr1 : new Date(dateStr1);
  const date2 = dateStr2 instanceof Date ? dateStr2 : new Date(dateStr2);

  let diffInSeconds = Math.abs(date1 - date2) / 1000;

  // calculate days
  const days = Math.floor(diffInSeconds / 86400);
  diffInSeconds -= days * 86400;
  // console.log("calculated days", days);

  // calculate hours
  const hours = Math.floor(diffInSeconds / 3600) % 24;
  diffInSeconds -= hours * 3600;
  // console.log("calculated hours", hours);

  // calculate minutes
  const minutes = Math.floor(diffInSeconds / 60) % 60;
  diffInSeconds -= minutes * 60;
  // console.log("minutes", minutes);

  return {
    days,
    hours,
    minutes,
  };
}

/*
  return:{
    days:Number, hours:Number, minutes:Number }
  }
*/
export function getDateDiffObject(dateValue) {
  if (dateValue == null) {
    return null;
  }
  let diffInSeconds = Math.abs(dateValue) / 1000;
  // calculate days
  const days = Math.floor(diffInSeconds / 86400);
  diffInSeconds -= days * 86400;
  // console.log("calculated days", days);

  // calculate hours
  const hours = Math.floor(diffInSeconds / 3600) % 24;
  diffInSeconds -= hours * 3600;
  // console.log("calculated hours", hours);

  // calculate minutes
  const minutes = Math.floor(diffInSeconds / 60) % 60;
  diffInSeconds -= minutes * 60;
  // console.log("minutes", minutes);

  return {
    days,
    hours,
    minutes,
  };
}

// return String
export function formatPrice(price) {
  if (price == null) return 0;
  // const factor = Math.pow(10, places);
  // return Math.floor(number * factor) / factor;
  return Number.parseFloat(price).toFixed(2);
}

export function formatPriceNumber(price) {
  if (price == null) return 0;
  // const factor = Math.pow(10, places);
  // return Math.floor(number * factor) / factor;
  return Math.round(Number.parseFloat(price) * 100) / 100;
}

export function capitalFirst(str) {
  if (!str) return str;

  return str.charAt(0).toUpperCase() + str.slice(1);
}

export function getMonthName(inputDate) {
  try {
    const monthNames = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sept",
      "Oct",
      "Nov",
      "Dec",
    ];

    if (!inputDate)
      return console.error(
        "Invalid date provided when formatting dateMonthDay"
      );
    const date = inputDate instanceof Date ? inputDate : new Date(inputDate);

    return monthNames[date.getMonth()];
  } catch (ex) {
    console.error(ex);
  }
}

export function getDate(inputDate) {
  try {
    if (!inputDate)
      return console.error("Invalid date provided when getting day number");
    const date = inputDate instanceof Date ? inputDate : new Date(inputDate);
    return date.getDate();
  } catch (ex) {
    console.error(ex);
  }
}

// ----------------------get dateRange { startDate, endDate } ---------------
export function getWeekDateRange(dateSrc) {
  try {
    const curr = dateSrc ? new Date(dateSrc.getTime()) : new Date();
    var first = curr.getDate() - curr.getDay(); // First day is the day of the month - the day of the week
    // var last = first + 6; // last day is the first day + 6

    var startDate = new Date(curr.setDate(first));
    var endDate = new Date(curr.setDate(startDate.getDate() + 6));

    startDate.setHours(0, 0, 0, 0);
    endDate.setHours(23, 59, 59, 0);

    return {
      startDate,
      endDate,
    };

    // const date = dateSrc ? new Date(dateSrc.getTime()) : new Date();
    // const day = date.getDay(); // 0=sunday  -  6

    // // // -------------------  monday to sunday -----------
    // // const firstDate = date.getDate() - (day == 0 ? 6 : day - 1);
    // // const lastDate = date.getDate() + ((7 - day) % 7);

    // // ---------------------- sunday to monday ------------
    // const firstDate = date.getDate() - day;
    // const lastDate = firstDate + 6;

    // const startDate = new Date(date.setDate(firstDate));
    // const endDate = new Date(date.setDate(lastDate));

    // console.log(123, startDate.toUTCString(), endDate.toUTCString());

    // startDate.setHours(0, 0, 0, 0);
    // endDate.setHours(23, 59, 59, 0);

    // return {
    //   startDate,
    //   endDate,
    // };
  } catch (ex) {
    console.error(ex);
  }
}

export function getMonthDateRange(dateSrc) {
  try {
    const date = dateSrc ? new Date(dateSrc.getTime()) : new Date();

    const startDate = new Date(date.setDate(1));

    date.setMonth(date.getMonth() + 1);
    const lastDate = new Date(date.setDate(0));

    startDate.setHours(0, 0, 0, 0);
    lastDate.setHours(23, 59, 59, 0);

    return {
      startDate: startDate,
      endDate: lastDate,
    };
  } catch (ex) {
    console.error(ex);
  }
}

export function getYearDateRange(dateSrc) {
  try {
    const date = dateSrc ? new Date(dateSrc.getTime()) : new Date();

    return {
      startDate: new Date(date.getFullYear(), 0, 1),
      endDate: new Date(date.getFullYear(), 12, 0),
    };
  } catch (ex) {
    console.error(ex);
  }
}

// if date is null, use today
export function getOneDayDateRange(date) {
  try {
    const date1 = date ? new Date(date.getTime()) : new Date();
    return {
      startDate: date1.setHours(0, 0, 0, 0),
      endDate: date1.setHours(23, 59, 59, 0),
    };
  } catch (ex) {
    console.error(ex);
  }
}

// "-05"
export function getLocalTimezoneUTCOffset() {
  const localTimeOffset = new Date().getTimezoneOffset(); // in minutes
  const lcaolTimeOffsetHours = Math.abs(localTimeOffset) / 60; // in hours
  if (localTimeOffset > 0) {
    return "-" + ("0" + lcaolTimeOffsetHours).slice(-2);
  }
  return ("0" + lcaolTimeOffsetHours).slice(-2);
}

export function calItemPrice(orderedItem) {
  if (!orderedItem) return 0;

  const { price, qty, orderItemOptions, discount } = orderedItem;
  let itemTotalPrice = price;
  (orderItemOptions || []).forEach((ov) => {
    itemTotalPrice += ov.price || 0;
  });
  itemTotalPrice = itemTotalPrice * Number(qty || 0);

  if (discount && discount.type === "poff") {
    itemTotalPrice = itemTotalPrice * (1 - discount.amount || 0);
  }

  return itemTotalPrice;
}

// orderedItem{
//   name,String,
//   qty:Number,
//   price:Number,
//   orderItemOptions: [itemOptionValueSchema],
//   orderItemModifys: [orderItemModifySchema],
//   discount: discountObjSchema,
//   note: String
// }
export function calSubTotal(orderedItems) {
  let subTotal = 0;
  (orderedItems || []).forEach((orderedItem) => {
    const { price, qty, orderItemOptions, discount } = orderedItem;
    let itemTotalPrice = price;
    (orderItemOptions || []).forEach((ov) => {
      itemTotalPrice += ov.price;
    });
    itemTotalPrice = itemTotalPrice * Number(qty || 0);

    if (discount && discount.type === "poff") {
      itemTotalPrice = itemTotalPrice * (1 - discount.amount || 0);
    }

    subTotal += itemTotalPrice;
  });
  return subTotal;
}

export function countOrderItems(order) {
  if (!order) return 0;

  let count = 0;
  (order.orderItems || []).forEach((orderItem) => {
    count += orderItem.qty || 0;
  });
  return count;
}

// set startDate to the begining of startDate, and the end of endDate
export function preProcessDateRange(dateRange) {
  if (!dateRange) return dateRange;

  const { startDate: start, endDate: end } = dateRange;
  const startDate = new Date(start);
  const endDate = new Date(end);
  startDate.setHours(0, 0, 0, 0); // set as every beginning of the day
  endDate.setHours(23, 59, 59, 0); // set as every end of the day

  return {
    startDate,
    endDate,
  };
}

/*
  important: exclude void orders
  return {
    subtotal:Number,
    tax:Number,
    tips:Number,
    discount:Number,
    total:Number
    number:Number
  }
*/
export function calOrdersPaidByCreditcard(orders) {
  let subtotal = 0;
  let tax = 0;
  let tips = 0;
  let discountAmt = 0;
  let total = 0;
  let number = 0;
  let processingFee = 0;
  let serviceFeeAmt = 0;
  let deliveryFee = 0;
  (orders || []).forEach((order) => {
    if (order && !!order.paid && order.paymentMethod === "creditcard") {
      if (!order.hasVoid) {
        number++;
        subtotal += order.subtotal || 0;
        tax += order.tax || 0;

        tips += order.tips || 0;
        tips += order.fixedTips || 0;

        discountAmt += order.discountAmt || 0;
        total += order.total || 0;
        processingFee += order.processingFeeAmt || 0;
        serviceFeeAmt += order.serviceFeeAmt || 0;

        deliveryFee += order.deliveryFee || 0;
        deliveryFee += order.roomServiceDeliveryFee || 0;
      }
    }
  });
  return {
    subtotal,
    tax,
    tips,
    discountAmt,
    total,
    number,
    processingFee,
    serviceFeeAmt,
    deliveryFee,
  };
}

/*
  important: exclude void orders
  return {
    subtotal:Number,
    tax:Number,
    tips:Number,
    discount:Number,
    total:Number
    number:Number
  }
*/
export function calOrdersPaidByCash(orders) {
  let subtotal = 0;
  let tax = 0;
  let tips = 0;
  let discountAmt = 0;
  let total = 0;
  let number = 0;
  let processingFee = 0;
  let serviceFeeAmt = 0;
  let deliveryFee = 0;
  (orders || []).forEach((order) => {
    if (order && order.paymentMethod === "cash") {
      if (!order.hasVoid) {
        number++;
        subtotal += order.subtotal || 0;
        tax += order.tax || 0;
        tips += order.tips || 0;
        tips += order.fixedTips || 0;

        discountAmt += order.discountAmt || 0;
        total += order.total || 0;
        processingFee += order.processingFeeAmt || 0;
        serviceFeeAmt += order.serviceFeeAmt || 0;

        deliveryFee += order.deliveryFee || 0;
        deliveryFee += order.roomServiceDeliveryFee || 0;
      }
    }
  });
  return {
    subtotal,
    tax,
    tips,
    discountAmt,
    total,
    number,
    processingFee,
    serviceFeeAmt,
    deliveryFee,
  };
}

/*
  important: exclude void orders
  return {
    subtotal:Number,
    tax:Number,
    tips:Number,
    discount:Number,
    total:Number
    number:Number
  }
*/
export function calOrders(orders) {
  let subtotal = 0;
  let tax = 0;
  let tips = 0;
  let discountAmt = 0;
  let total = 0;
  let number = 0;
  let processingFee = 0;
  let serviceFeeAmt = 0;
  let deliveryFee = 0;
  (orders || []).forEach((order) => {
    if (!order.hasVoid) {
      number++;
      subtotal += order.subtotal || 0;
      tax += order.tax || 0;

      tips += order.tips || 0;
      tips += order.fixedTips || 0;

      discountAmt += order.discountAmt || 0;
      total += order.total || 0;
      processingFee += order.processingFeeAmt || 0;
      serviceFeeAmt += order.serviceFeeAmt || 0;

      deliveryFee += order.deliveryFee || 0;
      deliveryFee += order.roomServiceDeliveryFee || 0;
    }
  });
  return {
    subtotal,
    tax,
    tips,
    discountAmt,
    total,
    number,
    processingFee,
    serviceFeeAmt,
    deliveryFee,
  };
}

/*
  address:{
    street:String,
    city:String,
    state:String,
    postalCode:String
  }
*/
export function formatAddress(address) {
  const addressStr =
    address &&
    address.street +
      ", " +
      address.city +
      ", " +
      address.state +
      " " +
      address.postalCode +
      ", " +
      address.country;

  return addressStr;
}

export function formatAddressWithoutCountryName(address) {
  const addressStr =
    address &&
    address.street +
      ", " +
      address.city +
      ", " +
      address.state +
      " " +
      address.postalCode;

  return addressStr;
}

export function padZero(num, size) {
  var s = num + "";
  while (s.length < size) s = "0" + s;
  return s;
}

// day: monday|tuesday|wednesday|thursday|friday|saturday|sunday
// current day if null
// return [ "3:30PM - 9:30PM", "3:30PM - 9:30PM", .. ]
export function getBusinessHourStr(businessHour, day) {
  if (!businessHour) return [];

  const dayNames = [
    "sunday",
    "monday",
    "tuesday",
    "wednesday",
    "thursday",
    "friday",
    "saturday",
  ];

  const ranges =
    day && dayNames.indexOf(day.toLowerCase()) > -1
      ? businessHour[day]
      : businessHour[dayNames[new Date().getDay()]];

  let rangeStrs = [];
  if (ranges) {
    rangeStrs = ranges.map(({ open, close }) => {
      if (open == null || close == null) return "Closed";

      const hr_open = Math.floor(open / 60);
      const min_open = padZero(open - hr_open * 60, 2);

      const hr_close = Math.floor(close / 60);
      const min_close = padZero(close - hr_close * 60, 2);

      let openStr = null;
      if (hr_open < 12) {
        openStr = hr_open + ":" + min_open + "AM";
      } else if (hr_open === 12) {
        openStr = hr_open + ":" + min_open + "PM";
      } else {
        openStr = hr_open - 12 + ":" + min_open + "PM";
      }

      let closeStr = null;
      if (hr_close < 12) {
        closeStr = hr_close + ":" + min_close + "AM";
      } else if (hr_close === 12) {
        closeStr = hr_close + ":" + min_close + "PM";
      } else {
        closeStr = hr_close - 12 + ":" + min_close + "PM";
      }

      return openStr + " - " + closeStr;
    });
  }
  return rangeStrs && rangeStrs.length > 0 ? rangeStrs : ["Closed"];
}

export function getBusinessHour(businessHour, day) {
  if (!businessHour) return [];

  const dayNames = [
    "sunday",
    "monday",
    "tuesday",
    "wednesday",
    "thursday",
    "friday",
    "saturday",
  ];

  return day && dayNames.indexOf(day.toLowerCase()) > -1
    ? businessHour[day]
    : businessHour[dayNames[new Date().getDay()]];
}

export function getXDayBusinessHour(businessHour, num) {
  if (!businessHour || num == null) return [];
  const dayNames = [
    "sunday",
    "monday",
    "tuesday",
    "wednesday",
    "thursday",
    "friday",
    "saturday",
  ];
  let res = [];
  let i = 0;
  let count = 0;
  const config = {
    year: "numeric",
    month: "short",
    day: "2-digit",
  };
  while (num > count && i < 7) {
    const today = new Date();
    const tomorrow = new Date(today);
    tomorrow.setDate(tomorrow.getDate() + i);
    let tmr = tomorrow.getDay();
    if (businessHour[dayNames[tmr]].length != 0) {
      const dateTimeFormat = new Intl.DateTimeFormat("default", config);
      let format = businessHour[dayNames[tmr]][0];
      format["dayname"] = dayNames[tmr];
      format["datestr"] = dateTimeFormat.format(tomorrow);
      format["date"] = tomorrow;
      format["disable"] = false;
      res.push(format);
      count = count + 1;
    } else {
      const dateTimeFormat = new Intl.DateTimeFormat("default", config);
      let format = {};
      format["_id"] = i;
      format["dayname"] = dayNames[tmr];
      format["datestr"] = dateTimeFormat.format(tomorrow);
      format["disable"] = true;
      res.push(format);
    }
    i = i + 1;
  }
  return res;
}

export function getAvailableHourStr(availableHour, day, textOptioal) {
  if (!availableHour) return textOptioal ? [textOptioal] : ["Not available"];

  const dayNames = [
    "sunday",
    "monday",
    "tuesday",
    "wednesday",
    "thursday",
    "friday",
    "saturday",
  ];

  const ranges =
    day && dayNames.indexOf(day.toLowerCase()) > -1
      ? availableHour[day]
      : availableHour[dayNames[new Date().getDay()]];

  if (!ranges) return textOptioal ? [textOptioal] : ["Not available"];

  let rangeStrs = [];
  if (ranges) {
    rangeStrs = ranges.map(({ open, close }) => {
      if (open == null || close == null) return textOptioal || "Not available";

      const hr_open = Math.floor(open / 60);
      const min_open = padZero(open - hr_open * 60, 2);

      const hr_close = Math.floor(close / 60);
      const min_close = padZero(close - hr_close * 60, 2);

      let openStr = null;
      if (hr_open < 12) {
        openStr = hr_open + ":" + min_open + "AM";
      } else if (hr_open === 12) {
        openStr = hr_open + ":" + min_open + "PM";
      } else {
        openStr = hr_open - 12 + ":" + min_open + "PM";
      }

      let closeStr = null;
      if (hr_close < 12) {
        closeStr = hr_close + ":" + min_close + "AM";
      } else if (hr_close === 12) {
        closeStr = hr_close + ":" + min_close + "PM";
      } else {
        closeStr = hr_close - 12 + ":" + min_close + "PM";
      }

      if (openStr === closeStr) {
        return "Not available today";
      }

      return openStr + " - " + closeStr;
    });
  }

  return rangeStrs && rangeStrs.length > 0
    ? rangeStrs
    : textOptioal
    ? [textOptioal]
    : ["Not available"];
}

// second from 0 - 1440
export function secondsToTimeStr(seconds) {
  if (seconds == null) return "";

  const hr = Math.floor(seconds / 60);
  const min = padZero(seconds - hr * 60, 2);

  let str = "";
  if (hr < 12) {
    str = hr + ":" + min + "AM";
  } else if (hr === 12) {
    str = hr + ":" + min + "PM";
  } else {
    str = hr - 12 + ":" + min + "PM";
  }

  return str;
}

// not in business hour if not specify
export function checkIfWithinBusinessHour(businessHour, date) {
  if (!businessHour) return true;

  const dayNames = [
    "sunday",
    "monday",
    "tuesday",
    "wednesday",
    "thursday",
    "friday",
    "saturday",
  ];

  const targetDate = (date && new Date(date)) || new Date();
  const dayName = dayNames[targetDate.getDay()];
  const hourRanges = businessHour[dayName];

  for (let index in hourRanges || []) {
    const { open, close } = hourRanges[index];
    const min = targetDate.getHours() * 60 + targetDate.getMinutes();
    if (min >= open && min <= close) return true;
  }
  return false;
}

// available if not specify
export function checkIfWithinBusinessHour2(availableHour, date) {
  if (!availableHour) return true;

  const dayNames = [
    "sunday",
    "monday",
    "tuesday",
    "wednesday",
    "thursday",
    "friday",
    "saturday",
  ];

  const targetDate = (date && new Date(date)) || new Date();
  const dayName = dayNames[targetDate.getDay()];
  const hourRanges = availableHour[dayName];

  if (!hourRanges || hourRanges.length <= 0) return true;

  for (let index in hourRanges) {
    const { open, close } = hourRanges[index];
    const min = targetDate.getHours() * 60 + targetDate.getMinutes();
    if (min >= open && min <= close) return true;
  }
  return false;

  // if (!hourRanges || hourRanges.length <= 0) return true;

  // const targetDate = (date && new Date(date)) || new Date();

  // for (let index in hourRanges || []) {
  //   const { open, close } = hourRanges[index];
  //   const min = targetDate.getHours() * 60 + targetDate.getMinutes();
  //   if (min >= open && min <= close) return true;
  // }
  // return false;
}

/*
  itemOption:{_idItemOption:[optionVal, ...]}
  itemOptions:[ {_id, numberToSelect, required ...} ]
*/
export function checkItemOptionMeetsRequirement(
  itemOption_selected,
  itemOptions
) {
  if (!itemOptions) return true;

  for (let index in itemOptions) {
    const itemOption = itemOptions[index];

    const { numberToSelect, minNumberToSelect, maxNumberToSelect } = itemOption;
    let min, max;
    if (minNumberToSelect != null && maxNumberToSelect != null) {
      min = minNumberToSelect;
      max = maxNumberToSelect;
    } else if (minNumberToSelect) {
      min = minNumberToSelect;
      max =
        maxNumberToSelect != null ? maxNumberToSelect : Number.MAX_SAFE_INTEGER;
    } else if (maxNumberToSelect) {
      min = minNumberToSelect != null ? minNumberToSelect : 0;
      max = maxNumberToSelect;
    } else {
      min = numberToSelect;
      max = numberToSelect == 0 ? Number.MAX_SAFE_INTEGER : numberToSelect;
    }

    // if (itemOption.numberToSelect == null) continue;
    // if (itemOption.numberToSelect === 0) continue;

    const optionVals_selected = (itemOption_selected || {})[itemOption._id];

    const optionVals_selected_num = optionVals_selected
      ? optionVals_selected.length
      : 0;
    if (optionVals_selected_num < min || optionVals_selected_num > max) {
      return false;
    }

    // if (
    //   !optionVals_selected ||
    //   optionVals_selected.length !== itemOption.numberToSelect
    // )
    //   return false;
  }
  return true;
}

export function getTimeStringFromMin(totalMins) {
  if (totalMins == null || totalMins < 0) return "";

  const hours = Math.floor(totalMins / 60);
  const mins = totalMins - hours * 60;
  if (hours == 12) {
    return "12:" + padZero(mins, 2) + "PM";
  } else if (hours == 0 || hours == 24) {
    return "12:" + padZero(mins, 2) + "AM";
  } else if (hours > 12) {
    return padZero(hours - 12, 2) + ":" + padZero(mins, 2) + "PM";
  } else {
    return padZero(hours, 2) + ":" + padZero(mins, 2) + "AM";
  }

  // return padZero(hours, 2) + ":" + padZero(mins, 2);
}

//------------------------------ format creditcard info --------------------------
// 0000 0000 0000 0000 or 0000 000000 00000
export function formatCreditCardNumber(cardNumber) {
  if (!cardNumber || cardNumber.length === 0) return cardNumber;

  const length = cardNumber.length;
  const isAmericanExpress = cardNumber[0] === "3";

  if (isAmericanExpress) {
    // 15 digits
    if (length <= 4) return cardNumber;
    if (length <= 10)
      return cardNumber.substring(0, 4) + " " + cardNumber.substring(4, length);
    if (length <= 15)
      return (
        cardNumber.substring(0, 4) +
        " " +
        cardNumber.substring(4, 10) +
        " " +
        cardNumber.substring(10, length)
      );
    else
      return (
        cardNumber.substring(0, 4) +
        " " +
        cardNumber.substring(4, 10) +
        " " +
        cardNumber.substring(10, 15)
      );
  } else {
    // 16 digits
    if (length <= 4) return cardNumber;
    if (length <= 8)
      return cardNumber.substring(0, 4) + " " + cardNumber.substring(4, length);
    if (length <= 12)
      return (
        cardNumber.substring(0, 4) +
        " " +
        cardNumber.substring(4, 8) +
        " " +
        cardNumber.substring(8, length)
      );
    else
      return (
        cardNumber.substring(0, 4) +
        " " +
        cardNumber.substring(4, 8) +
        " " +
        cardNumber.substring(8, 12) +
        " " +
        cardNumber.substring(12, 16)
      );
  }
}

export function getCreditCardType(cardNumber) {
  if (!cardNumber || cardNumber.length === 0) return null;

  if (cardNumber[0] === 4) return "VISA";
  if (cardNumber[0] === 3) return "American Express";
  if (cardNumber[0] === 5) return "MasterCard";
  if (cardNumber[0] === 6) return "Discover";
  return "Other";
}

export function maskCreditCardNumber(cardNumber) {
  if (!cardNumber) return null;

  return "xxxxxxxxxxxx" + cardNumber.slice(-4);
}

export function formatCreditCardCVC(cvc) {
  if (!cvc) return null;

  return cvc.substring(0, 4);
}

export function formatCreditCardExpiration(exp) {
  if (!exp) return null;

  const length = exp.length;

  if (length <= 2) return exp;
  return exp.substring(0, 2) + "/" + exp.substring(2, 4);
}

// ---------------------------- verify room service room ----------------------
export function verifyRoomServiceRoom(roomNum) {
  if (!roomNum || roomNum.length === 0) return { error: "Required" };

  return {};
}

//------------------------------ verify creditcard info --------------------------
export function verifyCustomerName(name) {
  if (!name || name.length === 0) return { error: "Required" };

  return {};
}

export function verifyCustomerPhone(phone) {
  if (!phone || phone.length === 0) return { error: "Required" };

  if (phone.length < 10) return { error: "Invalid" };

  return {};
}

export function verifyCardNumber(cardNumber, notAllowAmerica) {
  if (!cardNumber || cardNumber.length === 0) return { error: "Required" };

  if (cardNumber.length < 15) return { error: "Invalid" };

  if (notAllowAmerica && cardNumber[0] === "3") {
    return { error: "This merchant is not accepting American Express" };
  }

  if (
    cardNumber[0] !== "3" &&
    cardNumber[0] !== "4" &&
    cardNumber[0] !== "5" &&
    cardNumber[0] !== "6"
  ) {
    return { error: "Invalid" };
  }

  return {};
}

export function verifyCreditCardCVC(cvc) {
  if (!cvc || cvc.length <= 0) return { error: "Required" };

  if (cvc.length < 3) return { error: "Invalid" };

  return {};
}

// MMDD
export function verifyCreditCardExpiration(exp) {
  if (!exp || exp.length <= 0) return { error: "Required" };

  if (exp.length < 4) return { error: "Invalid" };

  const mm = Number(exp.substring(0, 2));
  const yy = Number(exp.substring(2, 4));

  if (Number.isNaN(mm) || Number.isNaN(yy)) {
    return { error: "Invalid" };
  }

  const now = new Date();
  const nowMon = now.getMonth() + 1;
  const nowYear = Number((now.getFullYear() + "").substring(2, 4));

  if (yy < nowYear || (yy === nowYear && mm < nowMon)) {
    return { error: "Invalid" };
  }

  return {};
}

export function verifyUSPostalCode(postalCode) {
  if (!postalCode || postalCode.length <= 0) return { error: "Required" };

  if (postalCode.length < 5) return { error: "Invalid" };
  if (postalCode.search(/\D/g) > -1) return { error: "Invalid" };

  return {};
}

// Minimum eight characters, at least one uppercase letter, one lowercase letter and one number:
// "^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z\d]{8,}$"
export function verifyMerchantPsw(psw) {
  if (!psw || psw.length <= 0) return { error: "Required" };

  const isValid = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z\d]{8,}$/.test(psw);

  if (!isValid)
    return {
      error:
        "Password needs to be minimum eight characters, at least one uppercase letter, one lowercase letter and one number",
    };

  return {};
}

//-----------------------------------------------

// desktop browser
export function isLargeDevice() {
  return window && window.innerWidth >= "1184";
}

// ipad tablet
export function isMediumDevice() {
  return window && window.innerWidth >= "640" && window.innerWidth < "1184";
}

// mobile device
export function isSmallDevice() {
  return window && window.innerWidth < "640";
}

export function toUrl(url) {
  return window && (window.location.href = url);
}

export function isCurrentDayOrder(order) {
  if (!order) return false;

  const now = new Date();
  const orderDate = new Date(order && order.orderSaveDate);

  // console.log(123, orderDate);
  // console.log(123, now.getFullYear(), orderDate.getFullYear());
  // console.log(123, now.getMonth(), orderDate.getMonth());
  // console.log(123, now.getDate(), orderDate.getDate());

  return (
    orderDate &&
    now.getFullYear() === orderDate.getFullYear() &&
    now.getMonth() === orderDate.getMonth() &&
    now.getDate() === orderDate.getDate()
  );
}

export function dataURItoBlob(dataURI) {
  // convert base64 to raw binary data held in a string
  // doesn't handle URLEncoded DataURIs - see SO answer #6850276 for code that does this
  var byteString = atob(dataURI.split(",")[1]);

  // separate out the mime component
  var mimeString = dataURI.split(",")[0].split(":")[1].split(";")[0];

  // write the bytes of the string to an ArrayBuffer
  var ab = new ArrayBuffer(byteString.length);
  var ia = new Uint8Array(ab);
  for (var i = 0; i < byteString.length; i++) {
    ia[i] = byteString.charCodeAt(i);
  }

  //Old Code
  //write the ArrayBuffer to a blob, and you're done
  //var bb = new BlobBuilder();
  //bb.append(ab);
  //return bb.getBlob(mimeString);

  //New Code
  return new Blob([ab], { type: mimeString });
}

export function getDistanceInMiles(location1, location2) {
  try {
    if (!location1 || !location2) return null;

    return convertDistance(
      getDistance(
        { latitude: location1.latitude, longitude: location1.longitude },
        { latitude: location2.latitude, longitude: location2.longitude }
      ),
      "mi"
    );
  } catch (ex) {
    console.error(ex);
  }
}

export async function getGoogleDistance(location1, location2) {
  try {
    if (!location1 || !location2) return null;

    return (
      (await axios.get(`${UTIL_ROUTE_URL}/getGoogleDistance`, {
        params: {
          location1,
          location2,
        },
      })) || {}
    ).data;
  } catch (ex) {
    console.error(ex);
  }
}

// export function getDistanceInMiles_google(location1, location2) {
//   try {
//     if (!location1 || !location2) return null;

//     const disObj = distance.get({
//       origin: `${location1.latitude},${location1.longitude} `,
//       destination: `${location2.latitude},${location2.longitude} `,
//       units: "imperial",
//     });

//     return disObj;
//   } catch (ex) {
//     console.error(ex);
//   }
// }

// ------------------------------ food preparing time --------------------------------
// day: monday|tuesday|wednesday|thursday|friday|saturday|sunday
// return {status, estimate}
export function getFoodPreparingTimeObj(foodPreparingTime, day) {
  if (!foodPreparingTime) return null;

  const dayNames = [
    "sunday",
    "monday",
    "tuesday",
    "wednesday",
    "thursday",
    "friday",
    "saturday",
  ];

  return day && dayNames.indexOf(day.toLowerCase()) > -1
    ? foodPreparingTime[day]
    : foodPreparingTime[dayNames[new Date().getDay()]];
}

// ----------------------------- category promoMethod ---------------------------------

// return object: key is _idCategory, value is array of orderedItems
export function groupOrderedItemsByCategory(orderedItems) {
  if (!orderedItems || orderedItems.length <= 0) return {};

  const orderedItemsObj = {};
  for (let index = 0; index < orderedItems.length; index++) {
    const orderedItem = orderedItems[index];
    const _idCategory = (orderedItem || {})._idCategory;
    if (!_idCategory) continue;

    if (orderedItemsObj[_idCategory]) {
      orderedItemsObj[_idCategory].push(orderedItem);
    } else {
      orderedItemsObj[_idCategory] = [orderedItem];
    }
  }

  return orderedItemsObj;
}

export function addPromoMethod(items, promoMethod) {
  try {
    const items_copy = [...items];

    if (!items || items.length <= 0 || !promoMethod) return items_copy;

    const { type, X, Y } = promoMethod;
    if (type === "BUY_X_GET_Y_FREE") {
      const x = Number(X);
      const y = Number(Y);
      if (Number.isNaN(x) || Number.isNaN(y) || x === 0 || y === 0) {
        return items_copy;
      }

      // const items_each = _expandOrderItems(items);
      const items_each = items_copy;

      // sort items by ( item price + itemoption price ) desc
      items_each.sort((item1, item2) => {
        const price1 = calItemPrice(item1);
        const price2 = calItemPrice(item2);

        if (price1 === price2) return 0;
        if (price1 > price2) return -1;
        return 1;
      });

      let newItems = [];
      while (items_each.length > 0) {
        newItems = newItems.concat(items_each.splice(0, x));
        if (items_each.length > 0) {
          const freeItems = items_each.splice(0, y).map((i) => {
            const item = { ...i };

            item.discount = { type: "poff", amount: 1 };
            // item.price = 0;
            // name && (item.name = item.name + " " + name);
            return item;
          });
          newItems = newItems.concat(freeItems);
        }
      }
      return newItems;
    }
    return items_copy;
  } catch (ex) {
    console.error(ex);
  }
}

export function checkIfWithinDeliveryHour(hourRange, date) {
  if (!hourRange) return true;

  const targetDate = (date && new Date(date)) || new Date();

  const { open, close } = hourRange;
  const min = targetDate.getHours() * 60 + targetDate.getMinutes();
  if (min >= open && min <= close) return true;

  return false;
}

// open, close in minutes
// if open is null start from closest whole time ( 30mins interval )
export function getTimeScheduleList(open, close) {
  try {
    let minutesOpen = null,
      minutesClose;
    if (open != null) {
      minutesOpen = open;
    } else {
      const now = new Date();
      minutesOpen = now.getHours() * 60 + now.getMinutes();
    }

    minutesClose = close != null ? close : 1440;

    const start = Math.floor(minutesOpen / 30) + 2; // +2
    const end = Math.floor(minutesClose / 30); // -1

    const intervals = [];
    for (let index = start; index <= end; index++) {
      intervals.push(index * 30);
    }

    return intervals;
  } catch (ex) {
    return [];
  }
}

export function checkIfWithinOrderPromotionHour(hourRange, date) {
  if (!hourRange) return true;

  const targetDate = (date && new Date(date)) || new Date();

  const { open, close } = hourRange;
  const min = targetDate.getHours() * 60 + targetDate.getMinutes();
  if (min >= open && min <= close) return true;

  return false;
}

export function isToday(inputDate) {
  const today = new Date();

  const date = inputDate instanceof Date ? inputDate : new Date(inputDate);

  return (
    date.getDate() == today.getDate() &&
    date.getMonth() == today.getMonth() &&
    date.getFullYear() == today.getFullYear()
  );
}

export function validateEmail(email) {
  return String(email)
    .toLowerCase()
    .match(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    );
}

export function validateUserName(username) {
  if (!username || username.length == 0) return { error: "Required" };
  if (username.length < 5)
    return { error: "Username should be more than 5 characters!" };
  return {};
}
