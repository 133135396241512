import React, { Component } from "react";
import { Form, Button, Message } from "semantic-ui-react";
import "../css/auth.css";
import AuthDb from "../dataModel/authDb";
import GoogleAuthSect from "./googleAuthSect";
import ReCAPTCHA from "react-google-recaptcha";
const DELAY = 1500;
class UserAuthModal extends Component {
  constructor(props, ...args) {
    super(props, ...args);
    this.state = {
      userEmail: "",
      email: "",
      userName: "",
      loginPassword: "",
      password: "",
      confirmPassword: "",
      activeTab: "signIn",
      registerSuccess: false,
      errMsg: "",
      value: "",
      load: false,
      validCaptcha: false,
      Message: "",
      showConfirmPswMsg: false,
      showEmailMsg: false,
      showMsg: "",
    };
    this._reCaptchaRef = React.createRef();
  }
  _isMounted = false;
  componentDidMount() {
    this._isMounted = true;
    if (this._isMounted) {
      setTimeout(() => {
        this.setState({ load: true });
      }, DELAY);
    }
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  handleChange = (value) => {
    this.setState({ value, validCaptcha: true });
    if (value === null) this.setState({ validCaptcha: false });
  };

  handleSignIn = (user, external) => {
    if (!external) {
      if (user.email === "" || user.password === "") {
        this.setState({
          showMsg: "Please enter username and password to login",
        });
        return;
      } else {
        this.setState({ showMsg: "" });
      }
    }
    this.props.onSigninClick && this.props.onSigninClick(user, external);
  };

  handleSignUp = async (name, email, psw, cpwd) => {
    this.setState({ errMsg: "" });
    if (psw !== cpwd) {
      this.setState({ errMsg: "Password doesn't match!" });
      return;
    }
    const record = await AuthDb.register(name, email, psw);
    if (record) {
      if (record.success != "false") {
        this.setState({
          registerSuccess: true,
          Message:
            "You have succesfully registered! Will automatically log you in soon..",
        });
        setTimeout(
          () => this.handleSignIn({ email: email, password: psw }, false),
          5000
        );
      } else {
        this.setState({ errMsg: record.errMessage });
      }
    } else {
      this.setState({
        errMsg: "Something went wrong, please try again later",
      });
    }
  };

  renderContent = () => {
    const { load } = this.state || {};
    if (this.state.activeTab == "signUp") {
      return (
        <Form>
          <Form.Input
            label="User name"
            fluid
            icon="user"
            iconPosition="left"
            placeholder="User name"
            className="auth-input-field"
            value={this.state.userName}
            onChange={(evt) => this.setState({ userName: evt.target.value })}
            error={
              this.state.showUserNameMsg &&
              (this.state.userName.length < 5 ||
                this.state.userName.length > 50)
                ? {
                    content: "Username must be between 5~50 characters",
                  }
                : undefined
            }
            onBlur={() => {
              this.setState({
                showUserNameMsg: true,
              });
            }}
            onFocus={() => {
              this.setState({
                showUserNameMsg: false,
              });
            }}
          />
          <Form.Input
            fluid
            icon="mail"
            label="email"
            iconPosition="left"
            placeholder="example@email.com"
            className="auth-input-field"
            value={this.state.userEmail}
            onChange={(evt) => this.setState({ userEmail: evt.target.value })}
            error={
              this.state.showEmailMsg &&
              !/\S+@\S+\.\S+/.test(this.state.userEmail)
                ? {
                    content: "Please enter a valid email",
                  }
                : undefined
            }
            onBlur={() => {
              this.setState({
                showEmailMsg: true,
              });
            }}
            onFocus={() => {
              this.setState({
                showEmailMsg: false,
              });
            }}
          />
          <Form.Input
            fluid
            icon="keyboard"
            label="Password"
            iconPosition="left"
            placeholder="Password"
            type="password"
            className="auth-input-field"
            value={this.state.password}
            onChange={(evt) => this.setState({ password: evt.target.value })}
          />
          <Form.Input
            fluid
            icon="keyboard"
            iconPosition="left"
            label="Confirm Password"
            placeholder="Confirm Password"
            type="password"
            className="auth-input-field"
            value={this.state.confirmPassword}
            onChange={(evt) =>
              this.setState({ confirmPassword: evt.target.value })
            }
            error={
              this.state.showConfirmPswMsg &&
              this.state.confirmPassword !== this.state.password
                ? {
                    content: "Password doesn't match!",
                  }
                : undefined
            }
            onBlur={() => {
              this.setState({
                showConfirmPswMsg: true,
              });
            }}
            onFocus={() => {
              this.setState({
                showConfirmPswMsg: false,
              });
            }}
          />

          {load && (
            <ReCAPTCHA
              style={{ display: "inline-block" }}
              className="captchadiv"
              theme="light"
              ref={this._reCaptchaRef}
              sitekey="6Ldf7qAgAAAAAEfz3lbAsyeuiNX4ki7F1vjIilXf"
              onChange={this.handleChange}
            />
          )}
          <Button
            disabled={!this.state.validCaptcha}
            color="teal"
            style={{ backgroundColor: "#003162" }}
            fluid
            size="huge"
            onClick={() =>
              this.handleSignUp(
                this.state.userName,
                this.state.userEmail,
                this.state.password,
                this.state.confirmPassword
              )
            }
          >
            Sign up
          </Button>

          <Button
            fluid
            size="huge"
            style={{ marginTop: "5px" }}
            onClick={() => this.setState({ activeTab: "signIn" })}
          >
            Already Registered?
          </Button>
          {this.state.errMsg ? (
            <Message negative>{this.state.errMsg}</Message>
          ) : null}
          {this.state.registerSuccess ? (
            <Message positive>{this.state.Message}</Message>
          ) : null}
        </Form>
      );
    } else if (this.state.activeTab == "signIn") {
      return (
        <Form>
          <Form.Input
            fluid
            icon="mail"
            label="Email"
            iconPosition="left"
            placeholder="E-mail address"
            className="auth-input-field"
            value={this.state.email}
            onChange={(evt) => this.setState({ email: evt.target.value })}
          />
          <Form.Input
            fluid
            icon="keyboard"
            iconPosition="left"
            placeholder="Password"
            label="Password"
            type="password"
            className="auth-input-field"
            value={this.state.loginPassword}
            onChange={(evt) =>
              this.setState({ loginPassword: evt.target.value })
            }
          />

          <Button
            color="teal"
            style={{ backgroundColor: "#003162" }}
            fluid
            size="huge"
            onClick={() => {
              this.handleSignIn(
                { email: this.state.email, password: this.state.loginPassword },
                false
              );
            }}
          >
            Login
          </Button>

          <Button
            fluid
            size="huge"
            style={{ marginTop: "5px" }}
            onClick={() => this.setState({ activeTab: "signUp" })}
          >
            Not Registered?
          </Button>
          <div className="social-divider">
            <span style={{ background: "#fff", padding: "0 10px" }}>or</span>
          </div>
          <GoogleAuthSect handleSignIn={this.handleSignIn} />

          {this.props.errMsg ? (
            <Message negative>{this.props.errMsg}</Message>
          ) : null}
          {this.state.showMsg ? (
            <Message negative>{this.state.showMsg}</Message>
          ) : null}
        </Form>
      );
    }
  };

  render = () => {
    return (
      <div className="white-bg center modal-header">
        <div className="login-register-div">
          <div className="">
            <div
              onClick={() => this.setState({ activeTab: "signIn" })}
              className={
                this.state.activeTab == "signIn"
                  ? "tabActive tabLabel"
                  : "tabLabel"
              }
            >
              Sign In
            </div>
            <div
              onClick={() => this.setState({ activeTab: "signUp" })}
              className={
                this.state.activeTab == "signUp"
                  ? "tabActive tabLabel"
                  : "tabLabel"
              }
            >
              Sign Up
            </div>
          </div>
          <div className="ui bottom attached active tab segment">
            {this.renderContent()}
          </div>
        </div>
      </div>
    );
  };
}
export default UserAuthModal;
