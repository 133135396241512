const axios = require("axios");

const BUSINESSINFO_ROUTE_URL = "/api/businessInfos";

/*  business format 

  name: {
    type: String
  },
  address: {
    type: addressSchema
  },
  phone: {
    type: String
  },
  email: {
    type: String
  },
  type: {
    type: String
  },
  webSite: {
    type: String
  },
  logourl: {
    type: String
  },
*/

export default class BusinessInfoDb {
  static async getBusinessInfo(merchantId) {
    try {
      if (!merchantId)
        return console.error("Invalid merchantId(getBusinessInfo)");

      return (
        (await axios.get(
          `${BUSINESSINFO_ROUTE_URL}/merchantId/${merchantId}/businessInfo`
        )) || {}
      ).data;
    } catch (ex) {
      console.error(ex);
    }
  }

  static async getPromoCode(merchantId, code) {
    try {
      if (!merchantId) return console.error("Invalid merchantId(getPromoCode)");

      if (!code) return null;

      return (
        (await axios.get(
          `${BUSINESSINFO_ROUTE_URL}/merchantId/${merchantId}/promoCode/${code}`
        )) || {}
      ).data;
    } catch (ex) {
      console.error(ex);
    }
  }

  static async getAutoAppliedPromoCode(merchantId, amount) {
    try {
      if (!merchantId || !amount)
        return console.error(
          "Invalid merchantId or amount(getAutoAppliedPromoCode)"
        );

      return (
        (await axios.get(
          `${BUSINESSINFO_ROUTE_URL}/merchantId/${merchantId}/autoAppliedPromoCode/amount/${amount}`
        )) || {}
      ).data;
    } catch (ex) {
      console.error(ex);
    }
  }

  static async verify(merchantId, psw) {
    try {
      if (!merchantId) return console.error("Invalid merchantId(verify)");

      if (!psw) {
        return console.error("Invalid password(verify)");
      }

      return (
        (await axios.get(
          `${BUSINESSINFO_ROUTE_URL}/merchantId/${merchantId}/password_checking/${psw}`
        )) || {}
      ).data;
    } catch (ex) {
      console.error(ex);
    }
  }

  static async updateBusinessInfo(merchantId, businessInfo) {
    try {
      if (!merchantId)
        return console.error("Invalid merchantId(updateBusinessInfo)");

      return (
        (await axios.put(
          `${BUSINESSINFO_ROUTE_URL}/merchantId/${merchantId}/businessInfo`,
          businessInfo
        )) || {}
      ).data;
    } catch (ex) {
      console.error(ex);
    }
  }
}
