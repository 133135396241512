const axios = require("axios");

const ORDER_ROUTE_URL = "/api/orders7";

/*  order format 
  POST
  order:{
    _id: ObjectID, ( existing only if saved )
    orderId:String, ( existing only if saved )
    orderId:String,
    subOrderId:String,
    orderType:{
      name:String
    },
    orderItems:[{
      name:String,
      name_2nd:String,
      basePrice:Number,
      size:String,
      qty:Number,
      orderItemOptions:[
        {
          name:String,
          name_2nd:String,
          price:Number
        },...],
      orderItemModifys:[{
        name:String,
        name_2nd:String,
        price:Number,
        actionName:String:
      },...],
      discount:{
        type:"coff"|"poff",
        amount:Number
      }
    },...],
    _idCustomerInfo:ObjectID,
    _idServer:ObjectID,
    dineinTableName:String,
    discount:{
      type:"coff"|"poff",
      amount:Number
    },
    guestNum:Number,
    taxRate:Number,
    subtotal:Number,
    total:Number
  }
*/

/*
      GET
      order : {
        subOrderId,
        orderType,
        orderItems,
        _idCustomerInfo:{
          _id:ObjectID,
          phone:String,
          address:{
            country:String,
            _id:ObjectID,
            street:String,
            city:String,
            state:String,
            postalCode:String
          },
          fname:String,
          lname:String
        },
        _idServer,
        dineinTableName,
        discount,
        guestNum,
        taxRate,
        subtotal,
        total
      }
  */

export default class OrderDb {
  // ------------------------------------ order -----------------------------

  // add/save order
  /*
      order : {
        orderId, // if not provided, db side will assign next available orderId to it
        subOrderId, 
        orderType,
        orderItems,
        _idCustomerInfo:ObjectId,
        _idServer,
        dineinTableName,
        discount,
        guestNum,
        taxRate,
        subtotal,
        total
      }
  */
  static async addOrder(merchantId, order) {
    try {
      if (!merchantId) return console.error("Invalid merchantId(addOrder)");

      return (
        (await axios.post(
          `${ORDER_ROUTE_URL}/merchantId/${merchantId}/order`,
          order
        )) || {}
      ).data;
    } catch (ex) {
      console.error(ex);
    }
  }

  // return all orders if _idOrders is null
  static async getOrders(merchantId, _idOrders) {
    try {
      if (!merchantId) return console.error("Invalid merchantId(getOrders)");

      if (_idOrders) {
        return (
          (await axios.get(
            `${ORDER_ROUTE_URL}/merchantId/${merchantId}/orders/_ids`,
            {
              params: {
                _idOrders,
              },
            }
          )) || {}
        ).data;
      } else {
        return (
          (await axios.get(
            `${ORDER_ROUTE_URL}/merchantId/${merchantId}/orders`
          )) || {}
        ).data;
      }
    } catch (ex) {
      console.error(ex);
    }
  }

  static async getLatestOrder(merchantId) {
    try {
      if (!merchantId)
        return console.error("Invalid merchantId(getLatestOrder)");

      return (
        (await axios.get(
          `${ORDER_ROUTE_URL}/merchantId/${merchantId}/latestOrder`
        )) || {}
      ).data;
    } catch (ex) {
      console.error(ex);
    }
  }

  // return order with given orderId
  static async getOrderByOrderId(merchantId, orderId) {
    try {
      if (!merchantId)
        return console.error("Invalid merchantId(getOrderByOrderId)");

      if (!orderId) return console.error("Invalid orderId(getOrderByOrderId)");

      return (
        (await axios.get(
          `${ORDER_ROUTE_URL}/merchantId/${merchantId}/order/orderId/${orderId}`
        )) || {}
      ).data;
    } catch (ex) {
      console.error(ex);
    }
  }

  // return order with given _idOrder
  static async getOrderById(merchantId, _idOrder) {
    try {
      if (!merchantId) return console.error("Invalid merchantId(getOrderById)");

      if (!_idOrder) return console.error("Invalid _idOrder(getOrderById)");

      return (
        (await axios.get(
          `${ORDER_ROUTE_URL}/merchantId/${merchantId}/order/_id/${_idOrder}`
        )) || {}
      ).data;
    } catch (ex) {
      console.error(ex);
    }
  }

  // if dateRange is null, all period be applied
  static async getOrdersByQueryWithinDateRange(
    merchantId,
    queryObj,
    dateRangeObj
  ) {
    try {
      if (!merchantId)
        return console.error(
          "Invalid merchantId(getOrdersByQueryWithinDateRange)"
        );

      return (
        (await axios.get(
          `${ORDER_ROUTE_URL}/merchantId/${merchantId}/orders/query`,
          {
            params: {
              queryObj: queryObj || {},
              dateRangeObj: dateRangeObj,
            },
          }
        )) || {}
      ).data;
    } catch (ex) {
      console.error(ex);
    }
  }

  // if dateRange is null, all period be applied
  static async getOrdersByQueryWithinDateRange_daily(
    merchantId,
    queryObj,
    dateRangeObj
  ) {
    try {
      if (!merchantId)
        return console.error(
          "Invalid merchantId(getOrdersByQueryWithinDateRange)"
        );

      // dateRangeObj &&
      //   dateRangeObj.startDate &&
      //   (dateRangeObj.startDate = new Date(dateRangeObj.startDate));

      // dateRangeObj &&
      //   dateRangeObj.endDate &&
      //   (dateRangeObj.endDate = new Date(dateRangeObj.endDate));

      return (
        (await axios.get(
          `${ORDER_ROUTE_URL}/merchantId/${merchantId}/orders/daily`,
          {
            params: {
              queryObj: queryObj || {},
              dateRangeObj: dateRangeObj,
            },
          }
        )) || {}
      ).data;
    } catch (ex) {
      console.error(ex);
    }
  }

  // return order with given orderId
  static async updateOrderByIdOrder(merchantId, _idOrder, order) {
    try {
      if (!merchantId)
        return console.error("Invalid merchantId(updateOrderByIdOrder)");

      if (!_idOrder)
        return console.error("Invalid _idOrder(updateOrderByIdOrder)");

      return (
        (await axios.put(
          `${ORDER_ROUTE_URL}/merchantId/${merchantId}/order/_idOrder/${_idOrder}`,
          order
        )) || {}
      ).data;
    } catch (ex) {
      console.error(ex);
    }
  }

  // send order ready in minutes SMS
  static async sendOrderReadyMinutesSMS(
    merchantId,
    _idOrder,
    orderReadyInMinutes
  ) {
    try {
      if (!merchantId)
        return console.error("Invalid merchantId(sendOrderReadyMinutesSMS)");

      if (orderReadyInMinutes == null)
        return console.error(
          "Invalid orderReadyInMinutes(sendOrderReadyMinutesSMS)"
        );

      const updatedOrder = (
        (await axios.put(
          `${ORDER_ROUTE_URL}/merchantId/${merchantId}/order/_idOrder/${_idOrder}`,
          { willBeReadyInMinutes: orderReadyInMinutes }
        )) || {}
      ).data;

      if (!updatedOrder) {
        console.log("Fail to update order (sendOrderReadyMinutesSMS)");
        return null;
      }

      return (
        (await axios.post(
          `${ORDER_ROUTE_URL}/merchantId/${merchantId}/sendOrderTimeSMS`,
          updatedOrder
        )) || {}
      ).data;
    } catch (ex) {
      console.error(ex);
    }
  }
}
