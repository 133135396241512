import React, { Component } from "react";
import {
  Segment,
  Modal,
  Header,
  Form,
  Dimmer,
  Loader,
  Button,
} from "semantic-ui-react";

class ItemSizeAddingModal extends Component {
  state = {
    name_required_show: false,
    price_required_show: false,
  };

  _isValid = (name) => {
    return this.props.itemSize_added && this.props.itemSize_added[name] != null;
  };

  handleClose = () => {
    this.props.onClose && this.props.onClose();

    this.setState({
      name_required_show: false,
      price_required_show: false,
    });
  };

  handleItemSizeAddChange = (name, value) => {
    this.props.onItemSizeAddChange &&
      this.props.onItemSizeAddChange(name, value);
  };

  handleItemSizeAddSave = () => {
    if (!this._isValid("name") || !this._isValid("price")) {
      return this.setState({
        name_required_show: true,
        price_required_show: true,
      });
    }

    this.props.onItemSizeAddSave && this.props.onItemSizeAddSave();

    this.setState({
      name_required_show: false,
      price_required_show: false,
    });
  };

  render() {
    const { itemSize_added, loading } = this.props;

    return (
      <Modal
        closeIcon
        dimmer={true}
        open={!!itemSize_added}
        onClose={this.handleClose}
        className="goorder-online-itemDetail"
      >
        <Dimmer inverted active={!!loading}>
          <Loader size="huge" />
        </Dimmer>

        <Header as="h2" className="header">
          <Form>
            <Form.Group style={{ fontSize: "12px" }}>
              <Form.Input
                width={6}
                label="Size Name *"
                value={(itemSize_added && itemSize_added.name) || ""}
                onChange={(evt) => {
                  this.handleItemSizeAddChange("name", evt.target.value);
                }}
                error={this.state.name_required_show && !this._isValid("name")}
                onBlur={() => {
                  this.setState({
                    name_required_show: true,
                  });
                }}
                onFocus={() => {
                  this.setState({
                    name_required_show: false,
                  });
                }}
              />
              <Form.Input
                width={6}
                label="Size Name(2nd)"
                value={(itemSize_added && itemSize_added.name_2nd) || ""}
                onChange={(evt) => {
                  this.handleItemSizeAddChange("name_2nd", evt.target.value);
                }}
              />
              <Form.Input
                width={4}
                label="Unit Price *"
                value={
                  itemSize_added && itemSize_added.price != null
                    ? itemSize_added.price
                    : ""
                }
                onChange={(evt) => {
                  const val = evt.target.value;
                  if (Number.isNaN(Number(val)) || Number(val) > 99999) {
                    return;
                  }
                  this.handleItemSizeAddChange("price", val);
                }}
                error={
                  this.state.price_required_show && !this._isValid("price")
                }
                onBlur={() => {
                  this.setState({
                    price_required_show: true,
                  });
                }}
                onFocus={() => {
                  this.setState({
                    price_required_show: false,
                  });
                }}
              />
            </Form.Group>
          </Form>
        </Header>

        {/* ----------------------------  bottom --------------------- */}
        <Segment className="footer" raised>
          <Button
            className="sumi-global-btn-color"
            style={{
              width: "100px",
              height: "50px",
              margin: "10px 10px 10px 0",
              float: "right",
            }}
            onClick={this.handleItemSizeAddSave}
          >
            Add
          </Button>
          <Button
            style={{
              width: "100px",
              height: "50px",
              margin: "10px 10px 10px 0",
              float: "right",
            }}
            onClick={this.handleClose}
          >
            Cancel
          </Button>
        </Segment>
      </Modal>
    );
  }
}

export default ItemSizeAddingModal;
