const axios = require("axios");

const MERCHANT_USER_ROUTE_URL = "/api/merchantUsers";

export default class MerchantUserDb {
  // if signed in return record, otherwise return null
  static async checkStatus() {
    try {
      const authUser = (
        (await axios.get(`${MERCHANT_USER_ROUTE_URL}/isAuth`)) || {}
      ).data;

      return authUser;
    } catch (ex) {
      return null;
    }
  }

  // return record if signin successful, return null if not
  static async signin(merchantId, password) {
    try {
      if (!merchantId || !password)
        return console.error("Invalid Credential(signin)");

      const resObj = await axios.post(
        `${MERCHANT_USER_ROUTE_URL}/merchantLogin`,
        {
          merchantUserName: merchantId,
          merchantPassword: password,
        }
      );

      return (resObj || {}).data; // true or false
    } catch (ex) {
      console.error(ex);
    }
  }

  static async signout() {
    try {
      return (
        (await axios.post(`${MERCHANT_USER_ROUTE_URL}/merchantLogout`)) || {}
      ).data;
    } catch (ex) {
      console.error(ex);
    }
  }
}
