const axios = require("axios");

const VERIFICATION_ROUTE_URL = "/api/verification";

export default class VerificationDb {
  static async verifyPhoneNum(phoneNum, recaptchaToken, verificationCode) {
    try {
      if (!phoneNum) return console.error("Invalid phoneNum(verifyPhoneNum)");
      // if (!recaptchaToken)
      //   return console.error("Invalid recaptchaToken(verifyPhoneNum)");

      return (
        (await axios.post(`${VERIFICATION_ROUTE_URL}/verifyPhone`, {
          phoneNum,
          recaptchaToken,
          verificationCode,
        })) || {}
      ).data;
    } catch (ex) {
      console.error(ex);
    }
  }

  static async sendVerificationCodeTo(phoneNum, recaptchaToken) {
    try {
      if (!phoneNum) return console.error("Invalid phoneNum(verifyPhoneNum)");
      // if (!recaptchaToken)
      //   return console.error("Invalid recaptchaToken(verifyPhoneNum)");

      return (
        (await axios.post(`${VERIFICATION_ROUTE_URL}/sendVeriCode`, {
          phoneNum,
          recaptchaToken,
        })) || {}
      ).data;
    } catch (ex) {
      console.error(ex);
    }
  }
}
